import PropTypes from 'prop-types'
import { Dimensions, StyleSheet, View } from 'react-native'

const { width } = Dimensions.get('window')

const OrfiCTA = ({ callToAction }) => <View style={styles.CTAContainer}>{callToAction}</View>

OrfiCTA.propTypes = {
  callToAction: PropTypes.element,
}

OrfiCTA.defaultProps = {
  callToAction: <></>,
}

const styles = StyleSheet.create({
  CTAContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: width * 0.12,
  },
})

export default OrfiCTA
