import { useNavigation, useRoute } from '@react-navigation/native'
import { Button, Divider, Input } from '@rneui/themed'
import { useFormik } from 'formik'
import React from 'react'
import { ImageBackground, ScrollView, useWindowDimensions, View } from 'react-native'

import { IMAGE_EMAIL_BACKGROUND } from '@src/assets/images'
import { showErrorMessage, showSuccessMessage } from '@src/components/FlashMessage'
import Text from '@src/components/Text'
import { colors } from '@src/config/theme'
import { usePasswordResetMutation } from '@src/graphql/types'
import { NewPasswordScreenRouteProps, NewPasswordScreenNavigationProps } from '@src/types'

import styles from './NewPasswordScreen.styles'
import validationSchema from './NewPasswordScreen.validation'

const NewPasswordScreen = () => {
  const navigation = useNavigation<NewPasswordScreenNavigationProps>()
  const { params } = useRoute<NewPasswordScreenRouteProps>()
  const [passwordReset, { loading }] = usePasswordResetMutation()

  const resetPassword = async ({ password }) => {
    const { code, email } = params
    try {
      await passwordReset({ variables: { password, code, email } })
      showSuccessMessage({ message: '', description: 'Your password has been reset' })
      goBack()
    } catch (error) {
      showErrorMessage({ message: '', description: error.message })
    }
  }

  const handleOnSubmit = async (values) => await resetPassword(values)

  const { values, errors, touched, handleChange, setFieldTouched, handleSubmit } = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    onSubmit: handleOnSubmit,
  })

  const { width, height } = useWindowDimensions()

  const goBack = () => {
    navigation.goBack()
  }

  return (
    <ImageBackground source={IMAGE_EMAIL_BACKGROUND} style={[styles.bg, { width, height }]}>
      <ScrollView
        contentContainerStyle={[styles.container, { width, height }]}
        keyboardShouldPersistTaps="handled">
        <View style={[styles.skip, { marginBottom: height / 5 }]}>
          <Button
            onPress={goBack}
            title="CANCEL"
            type="clear"
            titleStyle={styles.backButtonTitle}
          />
        </View>
        <View style={styles.wrapper}>
          <View>
            <Text style={styles.mainTitle}>YOUR NEW PASSWORD</Text>
            <Text style={styles.subTitle}>
              {
                'Please enter your new password below.\nMake sure it consists of at least 8 characters.'
              }
            </Text>
          </View>
          <View style={styles.inputView}>
            <Input
              placeholder="Enter new password"
              placeholderTextColor={colors.grey7}
              textAlign="center"
              value={values.password}
              onChangeText={handleChange('password')}
              onBlur={() => setFieldTouched('password')}
              errorMessage={touched.password && errors.password ? errors.password : undefined}
              errorStyle={styles.centre}
              secureTextEntry
              autoCapitalize="none"
              enablesReturnKeyAutomatically
            />
            <Divider />
            <Input
              placeholder="Confirm new password"
              placeholderTextColor={colors.grey7}
              textAlign="center"
              value={values.passwordConfirmation}
              onChangeText={handleChange('passwordConfirmation')}
              onBlur={() => setFieldTouched('passwordConfirmation')}
              errorMessage={
                touched.passwordConfirmation && errors.passwordConfirmation
                  ? errors.passwordConfirmation
                  : undefined
              }
              errorStyle={styles.centre}
              secureTextEntry
              autoCapitalize="none"
              enablesReturnKeyAutomatically
            />
          </View>
        </View>
        <View style={styles.btnView}>
          <View style={styles.innerButtonView}>
            <Button title="Save" onPress={handleSubmit} loading={loading} disabled={loading} />
          </View>
        </View>
      </ScrollView>
    </ImageBackground>
  )
}

export default NewPasswordScreen
