import Constants from 'expo-constants'
import { Platform, StyleSheet, useWindowDimensions } from 'react-native'

import { colors } from '@src/config/theme'

const useStyle = () => {
  const { height, width } = useWindowDimensions()

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#FFFFFF',
      ...Platform.select({
        web: {
          alignItems: 'center',
        },
      }),
    },
    webContainer: {
      width,
      maxWidth: 700,
      marginTop: height / 4,
    },

    content: {
      flex: 1,
    },

    header: {
      height: 50,
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingHorizontal: 20,
      alignItems: 'center',
      borderBottomWidth: 1,
      borderBottomColor: colors.greyOutline,
      marginTop: Platform.select({ ios: 0, android: Constants.statusBarHeight }),
    },
    title: {
      fontSize: 14,
      fontWeight: 'bold',
      color: colors.secondary,
    },
    clearButton: {
      color: colors.primary,
      fontSize: 12,
      padding: 0,
      margin: 0,
    },
    iconButton: {
      marginRight: 20,
    },
    rowContainer: {
      flexDirection: 'row',
    },
    rowIcon: {
      marginRight: 20,
    },
    rowTitle: {
      fontSize: 14,
      fontWeight: 'bold',
      color: colors.black,
      marginRight: 20,
    },
    rowComment: {
      fontSize: 12,
      color: colors.black,
      opacity: 0.5,
    },
  })

  return styles
}

export default useStyle
