import { Button, Icon } from '@rneui/themed'
import LottieView from 'lottie-react-native'
import PropTypes from 'prop-types'
import React from 'react'
import {
  Dimensions,
  Modal,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native'

import Fail from '@src/assets/lottie/Fail.json'
import LoadingPulse from '@src/assets/lottie/Loading.json'
import Success from '@src/assets/lottie/Success.json'
import DualButton from '@src/components/DualButton'
import Text from '@src/components/Text'
import { colors, fonts } from '@src/config/theme'

const { height, width } = Dimensions.get('screen')

const BankedPopup = ({ visible, close, tryAgain, status, price, onBackHome, onGroup, onShare }) => {
  let animation = null
  let title = ''
  let message = ''
  let loop = true
  switch (status) {
    case 'PAYMENT_PENDING':
      animation = LoadingPulse
      title = 'CONFIRMING PAYMENT'
      message = "This won't take long..."
      break
    case 'FAILED':
      animation = Fail
      loop = false
      title = 'PAYMENT FAILED'
      message =
        'There was something wrong with your payment.\nPlease try again and if you’re still having trouble,\nsend us an issue report and we will rectify it as soon\nas possible.'
      break
    case 'ATTENDING':
      animation = Success
      loop = false
      title = "YOU'RE IN"
      message = `Your payment of £${price} has been confirmed and your\nplace has been reserved. Have fun!`
      break
    default:
      animation = LoadingPulse
      title = 'CONFIRMING PAYMENT'
      message = "This won't take long..."
      loop = true
      break
  }
  return (
    <Modal transparent visible={visible} animationType="none" onRequestClose={close}>
      <TouchableWithoutFeedback onPress={close}>
        <View style={{ ...StyleSheet.absoluteFill, ...styles.container }}>
          <TouchableWithoutFeedback>
            <View style={styles.content}>
              <LottieView style={styles.lottieView} source={animation} autoPlay loop={loop} />
              <View style={styles.texts}>
                <Text style={styles.title}>{title}</Text>
                <Text style={styles.comment}>{message}</Text>
              </View>

              {status === 'FAILED' ? (
                <View style={styles.gradientButtonContainer}>
                  <Button
                    title="Back"
                    onPress={close}
                    containerStyle={styles.buttonContainer}
                    buttonStyle={[styles.buttonStyle]}
                    titleStyle={styles.buttonText}
                  />
                </View>
              ) : null}
              {status === 'ATTENDING' ? (
                <>
                  <TouchableOpacity onPress={onShare} style={styles.shareContainer}>
                    <View style={styles.shareContainer}>
                      <Icon
                        type="feather"
                        name="share-2"
                        size={28}
                        color={colors.primary}
                        iconStyle={styles.iconStyle}
                      />
                      <Text style={styles.shareText}>SHARE</Text>
                    </View>
                  </TouchableOpacity>
                  <View style={styles.buttons}>
                    <DualButton
                      leftTitle="BACK"
                      rightTitle="TO GROUP"
                      leftOnPress={onBackHome}
                      rightOnPress={onGroup}
                    />
                  </View>
                </>
              ) : null}
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  )
}

const buttonWidth = (width * 0.8 - 60 - 10) / 2

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#1119',
    alignItems: 'center',
    zIndex: 1000,
  },
  content: {
    width: '80%',
    borderRadius: 6,
    padding: 20,
    shadowOffset: { width: 2, height: 2 },
    shadowColor: 'black',
    shadowOpacity: 0.2,
    backgroundColor: colors.white,
    marginTop: height / 5,
    alignItems: 'center',
  },
  texts: {
    paddingHorizontal: 10,
    alignItems: 'center',
  },
  icon: {
    marginVertical: 30,
  },
  title: {
    color: colors.secondary,
    fontSize: 13,
    fontFamily: fonts.default.bold,
    textAlign: 'center',
  },
  comment: {
    margin: 10,
    color: colors.secondary,
    fontSize: 10,
    textAlign: 'center',
    marginVertical: 20,
    lineHeight: 17,
  },
  shareContainer: {
    alignSelf: 'center',
    marginVertical: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  shareText: {
    fontSize: 12,
    marginTop: 8,
    color: colors.primary,
  },
  buttonStyle: {
    borderRadius: 23,
    height: 42,
    width: '100%',
    backgroundColor: colors.secondary,
    paddingVertical: 0,
  },
  gradientButtonStyle: {
    borderRadius: 23,
    height: 42,
    width: '100%',
    backgroundColor: colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 0,
  },
  buttonText: {
    color: colors.white,
    fontSize: 12,
    fontFamily: fonts.default.semiBold,
    textAlign: 'center',
  },
  buttonContainer: {
    borderRadius: 23,
    height: 35,
    width: '80%',
    marginRight: 10,
  },
  gradientButtonContainer: {
    height: 35,
    width: '80%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gradientButtonText: {
    fontSize: 12,
    fontFamily: fonts.default.semiBold,
  },
  gradientButton: { width: buttonWidth },
  buttons: {
    justifyContent: 'space-between',
    marginTop: 20,
    width: '100%',
  },
  lottieView: {
    width: 130,
    height: 80,
    marginBottom: 10,
  },
  iconStyle: {
    paddingTop: 4,
  },
})

BankedPopup.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  tryAgain: PropTypes.func.isRequired,
  onGroup: PropTypes.func.isRequired,
  onBackHome: PropTypes.func.isRequired,
  onShare: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
}

export default BankedPopup
