import { LinkingOptions } from '@react-navigation/native'
import { makeUrl } from 'expo-linking'

import { RootStackParamList } from '@src/types'

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [makeUrl('/'), 'https://app.orfiactive.com', 'exps://app.orfiactive.com'],
  config: {
    screens: {
      MainTab: 'main/',
      SessionDetailScreen: 'session/:sessionId',
      PaymentCompletedScreen: 'banked/:status/:paymentId',
      DashboardInviteReceivedScreen: 'groupInvite/:uuid',
    },
  },
}

export default linking
