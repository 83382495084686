import { useNavigation, useRoute } from '@react-navigation/native'
import { Button, Icon } from '@rneui/themed'
import { Collapse, CollapseBody, CollapseHeader } from 'accordion-collapse-react-native'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useLayoutEffect, useState } from 'react'
import { ActivityIndicator, SafeAreaView, ScrollView, View, StyleSheet } from 'react-native'

import { showErrorMessage, showSuccessMessage } from '@src/components/FlashMessage'
import HeaderIcon from '@src/components/HeaderIcon'
import OrfiListItem from '@src/components/ListItem/OrfiListItem'
import Loading from '@src/components/Loading'
import MembersModal from '@src/components/PopupModal/MembersModal'
import TrackTraceModal from '@src/components/PopupModal/TrackTracePopup'
import Text from '@src/components/Text'
import { colors, fonts } from '@src/config/theme'
import { GET_SESSION_QUERY } from '@src/graphql/queries'
import {
  useRequestTrackTraceGroupMutation,
  useSessionQuery,
  useUpdateSessionAttendanceMutation,
} from '@src/graphql/types'
import { AttendeesListScreenNavigationProps, AttendeesListScreenRouteProps } from '@src/types.d'
import { formatAttendingCount } from '@src/utils/forms'

import styles from './AttendeesListScreen.styles'

const color = {
  PAID: '#07CC0E',
  'CASH ON SITE': '#F91874',
  'WAITING LIST': '#707070',
}

const AttendeesListScreen = () => {
  const navigation = useNavigation<AttendeesListScreenNavigationProps>()
  const { params } = useRoute<AttendeesListScreenRouteProps>()
  const [paidCollapse, setPaidCollapse] = useState(true)
  const [cashCollapse, setCashCollapse] = useState(true)
  const [waitingCollapse, setWaitingCollapse] = useState(true)
  const [modalVisible, setModalVisible] = useState(false)
  const [requestSent, setRequestSent] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)

  const { data: sessionQuery, loading } = useSessionQuery({
    variables: { id: params.sessionId },
  })

  const [requestInfo, { loading: requestLoading }] = useRequestTrackTraceGroupMutation({
    onCompleted: () => {
      setRequestSent(true)
      setModalVisible(true)
    },
  })

  const [updateAttendanceInfo, { loading: updateAttendanceLoading }] =
    useUpdateSessionAttendanceMutation()

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <HeaderIcon
          icon={{
            name: 'x',
            type: 'feather',
            size: 26,
          }}
        />
      ),
    })
  }, [])

  useLayoutEffect(() => {
    if (sessionQuery) {
      const memberCount = formatAttendingCount(
        sessionQuery?.session?.usersAttendingCount,
        sessionQuery?.session?.maxParticipants,
        false,
      )

      navigation.setOptions({
        title: `PARTICIPANTS ${memberCount ? `(${memberCount})` : ''}`,
      })
    }
  }, [sessionQuery, navigation])

  if (loading) return <Loading />

  const onAvatarPress = (user) => {
    toggleUser(user)
    setModalOpen(true)
  }

  const onMemberPress = (user) => {
    navigation.navigate('PublicProfileScreen', { userId: user.id })
    toggleUser()
  }

  const makeRequest = () => {
    try {
      requestInfo({
        variables: { groupUuid: session.group.uuid, sessionUuid: session.uuid },
      })
    } catch (error) {
      console.log(error)
    }
  }

  const checkInUser = async (user) => {
    try {
      await updateAttendanceInfo({
        variables: {
          sessionUuid: session.uuid,
          userId: user.id,
          attendance: !user.attendance,
        },
        refetchQueries: [
          {
            query: GET_SESSION_QUERY,
            variables: {
              id: params?.sessionId,
            },
          },
        ],
        awaitRefetchQueries: true,
      })

      showSuccessMessage({
        message: '',
        description: `${user.name}'s attendance has been successfully registered`,
      })
    } catch (err) {
      showErrorMessage({ message: '', description: err?.message })
    }
    toggleUser()
  }

  const { session = {} } = sessionQuery
  const { membership } = session

  const getPrice = (price, title) => {
    if (title === 'WAITING LIST') return null
    if (!price?.value || price?.value == '0') return 'FREE'
    return `£${price?.value}`
  }

  const attendeeCTA = (price, title, user) =>
    updateAttendanceLoading && selectedUser.id === user.id ? (
      <ActivityIndicator />
    ) : (
      <Text
        style={{
          color: color[title],
          fontFamily: fonts.default.bold,
          fontSize: !price?.value || price?.value === '0' ? 14 : 20,
          paddingRight: 10,
        }}>
        {getPrice(price, title)}
      </Text>
    )

  const getChevronIcon = (title) => {
    switch (title) {
      case 'PAID':
        return paidCollapse ? 'chevron-down' : 'chevron-right'
      case 'CASH ON SITE':
        return cashCollapse ? 'chevron-down' : 'chevron-right'
      case 'WAITING LIST':
        return waitingCollapse ? 'chevron-down' : 'chevron-right'
    }
  }

  const handleCollapse = (collapse, title) => {
    switch (title) {
      case 'PAID':
        setPaidCollapse(collapse)
        break
      case 'CASH ON SITE':
        setCashCollapse(collapse)
        break
      case 'WAITING LIST':
        setWaitingCollapse(collapse)
        break
    }
  }

  const toggleUser = (user = null) => {
    if (selectedUser) setSelectedUser(null)
    else setSelectedUser(user)
  }

  const getMembersActions = () => {
    if (!selectedUser) return []
    return [
      {
        label: 'View Profile',
        action: () => {
          onMemberPress(selectedUser)
          setModalOpen(false)
          toggleUser()
        },
        labelColor: colors.secondary,
      },
      {
        label: 'Transfer',
        action: () => {
          navigation.navigate('SelectSessionTransferScreen', {
            user: selectedUser,
            groupUuid: session.group.uuid,
            sessionId: session.id,
          })
          setModalOpen(false)
          toggleUser()
        },
        labelColor: colors.secondary,
      },
      {
        label: selectedUser.attendance ? 'Uncheck Attendance' : 'Check-in',
        action: () => {
          checkInUser(selectedUser)
          setModalOpen(false)
        },
        labelColor: colors.secondary,
      },
    ]
  }

  const paidMembers = membership.filter(
    (mb) => mb.paymentMethod === 'APP' && mb.status !== 'WAITING',
  )

  const cashMembers = membership.filter(
    (mb) => mb.paymentMethod === 'SITE' && mb.status !== 'WAITING',
  )

  const waitingListMembers = membership.filter((mb) => mb.status === 'WAITING')

  const members = [
    { title: 'PAID', data: paidMembers, index: 0, state: paidCollapse },
    { title: 'CASH ON SITE', data: cashMembers, index: 1, state: cashCollapse },
    { title: 'WAITING LIST', data: waitingListMembers, index: 2, state: waitingCollapse },
  ]

  const flattenedStyle = StyleSheet.flatten([
    { color: color.PAID },
    { fontFamily: fonts.default.bold },
  ])

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView>
        {members.map(({ title, data, index, state }) => (
          <Collapse
            onToggle={(isCollapsed) => handleCollapse(isCollapsed, title)}
            style={styles.titleContainer}
            isExpanded={state}
            key={index}>
            <CollapseHeader>
              <View
                style={[
                  styles.sectionTitle,
                  {
                    borderBottomColor: color[title],
                    marginTop: 35,
                  },
                ]}>
                <Text style={{ fontFamily: fonts.default.bold, color: color[title] }}>{title}</Text>
                <Icon name={getChevronIcon(title)} type="feather" color={color[title]} />
              </View>
            </CollapseHeader>
            <CollapseBody>
              {data.map(({ user, price, createdAt, attendance, uuid }) => (
                <OrfiListItem
                  key={user?.id}
                  type="reduced"
                  avatarUri={user?.avatar}
                  avatarPlaceholder={`${user?.name[0]}${user?.name[1]}`}
                  title={
                    session.group.isAdmin ? (
                      <View style={styles.titleView}>
                        <Text style={{ paddingRight: 7 }}>{user.name}</Text>
                        <Icon
                          name="more-horizontal"
                          type="feather"
                          size={18}
                          color={colors.secondary}
                        />
                      </View>
                    ) : (
                      user.name
                    )
                  }
                  bottomText={
                    session.group.isAdmin &&
                    attendance && (
                      <View style={styles.checkInView}>
                        <Icon name="check" type="feather" size={16} color={color.PAID} />
                        <Text style={flattenedStyle}>Checked-in</Text>
                      </View>
                    )
                  }
                  subtitle={`Joined on ${moment(createdAt).format('DD MMM')}`}
                  onPress={() =>
                    session.group.isAdmin
                      ? onAvatarPress({ ...user, attendance, uuid })
                      : onMemberPress(user)
                  }
                  divider
                  callToAction={session.group.isAdmin ? attendeeCTA(price, title, user) : null}
                />
              ))}
            </CollapseBody>
          </Collapse>
        ))}
      </ScrollView>
      {session?.group?.isOwner && session?.usersAttendingCount ? (
        <View style={styles.requestBtn}>
          <Button
            title={requestSent ? 'Requested' : 'Request Information'}
            onPress={makeRequest}
            disabled={requestSent}
            loading={requestLoading}
          />
        </View>
      ) : null}
      <TrackTraceModal visible={modalVisible} onClose={() => setModalVisible(false)} />
      <MembersModal
        visible={modalOpen}
        toggle={() => {
          setModalOpen(false)
          toggleUser()
        }}
        actions={getMembersActions()}
      />
    </SafeAreaView>
  )
}

AttendeesListScreen.propTypes = {
  route: PropTypes.object.isRequired,
}

export default AttendeesListScreen
