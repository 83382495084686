import { StyleSheet } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const styles = StyleSheet.create({
  bottomView: {
    position: 'absolute',
    right: 0,
    left: 0,
    paddingTop: 10,
    paddingBottom: 20,
    bottom: 0,
    alignItems: 'center',
    backgroundColor: colors.white,
    shadowRadius: 2,
    shadowOffset: {
      width: 0,
      height: -1,
    },
    shadowColor: colors.grey,
    shadowOpacity: 1.0,
    elevation: 4,
  },
  bottomButtonContainer: {
    width: '70%',
    borderRadius: 60,
  },
  dividerVw: {
    paddingHorizontal: 14,
    flexDirection: 'row',
    alignItems: 'center',
  },
  divider: {
    flex: 1,
    marginLeft: 16,
    borderBottomColor: colors.grey,
    borderBottomWidth: 0.8,
  },
  feeInfoView: { marginTop: 25, marginBottom: 125 },
  feeInfo: {
    alignSelf: 'flex-end',
    textDecorationLine: 'underline',
    fontFamily: fonts.default.bold,
    textTransform: 'uppercase',
  },
  headerVW: {
    height: 100,
    justifyContent: 'center',
    alignSelf: 'center',
    width: '65%',
  },
  headerText: {
    color: colors.secondary,
    fontSize: 15,
    fontFamily: fonts.default.bold,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  upcomingSessionText: {
    textAlign: 'center',
  },
  buttonStyle: {
    color: colors.white,
  },
})

export default styles
