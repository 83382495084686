import { StackActions, useNavigation, useRoute } from '@react-navigation/native'
import { Button, Divider, Overlay, Image } from '@rneui/themed'
import * as Calendar from 'expo-calendar'
import * as Linking from 'expo-linking'
import { sortBy, take } from 'lodash'
import moment from 'moment'
import { FC, useContext, useEffect, useLayoutEffect, useState } from 'react'
import {
  Alert,
  Platform,
  RefreshControl,
  SafeAreaView,
  ScrollView,
  Share,
  TouchableOpacity,
  View,
} from 'react-native'

import { DEFAULT_EVENT_IMAGE } from '@src/assets/images'
import EmptyNotice from '@src/components/EmptyNotice'
import HeaderIcon from '@src/components/HeaderIcon'
import Loading from '@src/components/Loading'
import AddCalendarPopup from '@src/components/PopupModal/AddCalendarPopup'
import LeaveSessionPopup from '@src/components/PopupModal/LeaveSessionPopup'
import LeaveWaitingListPopup from '@src/components/PopupModal/LeaveWaitingListPopup'
import NotifyPopup from '@src/components/PopupModal/NotifyPopup'
import SessionFullPopup from '@src/components/PopupModal/SessionFullPopup'
import WaitingListPopup from '@src/components/PopupModal/WaitingListPopup'
import FeaturesForm from '@src/components/Sessions/FeaturesForm'
import Paragraph from '@src/components/Sessions/Paragraph'
import ParticipantsList from '@src/components/Sessions/ParticipantsList'
import ProfileForm from '@src/components/Sessions/ProfileForm'
import Text from '@src/components/Text'
import { colors } from '@src/config/theme'
import { AuthContext } from '@src/context/AuthContext'
import { attendingOptions } from '@src/graphql/apollo'
import { GET_SESSION_MEMBERSHIP, GET_SESSION_QUERY, ME_QUERY } from '@src/graphql/queries'
import {
  useLeaveSessionMutation,
  useJoinSessionWaitingListMutation,
  useSessionQuery,
  useMeQuery,
  MembershipStatus,
} from '@src/graphql/types'
import { SessionDetailScreenNavigationProps, SessionDetailScreenRouteProps } from '@src/types'
import { formatAttendingCount } from '@src/utils/forms'
import { excludedActivityTypes } from '@src/utils/share'

import EventLinksSection from './components/EventLinksSection'
import MapView from './components/MapView'
import styles from './SessionDetailScreen.styles'

const SessionDetailScreen: FC = () => {
  const navigation = useNavigation<SessionDetailScreenNavigationProps>()
  const {
    params: { sessionId, sessionUpdated = false },
  } = useRoute<SessionDetailScreenRouteProps>()

  const { isAuthenticated } = useContext(AuthContext)
  const [sessionFullModalVisible, setSessionFullModalVisible] = useState(false)
  const [notifyModalVisible, setNotifyModalVisible] = useState(false)
  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState(false)
  const [userAddOptionsModal, setUserAddOptionsModal] = useState(false)
  const [waitingListModal, setWaitingListModal] = useState(false)
  const [mapOpening, setMapOpening] = useState(false)
  const [joinedWaitingList, setJoinedWaitingList] = useState(false)
  const [leaveWaitingListModal, setLeaveWaitingListModal] = useState(false)
  const [isAddToCalendar, setIsAddToCalendar] = useState(false)

  const { data: meQuery } = useMeQuery({ skip: !isAuthenticated })
  const {
    data: { session } = {},
    loading: sessionLoading,
    error: sessionError,
    refetch: sessionQueryRefetch,
  } = useSessionQuery({
    variables: {
      id: parseInt(sessionId, 10),
    },
    pollInterval: 30000,
  })

  const paymentStatus = (membershipStatus: { membershipStatus: MembershipStatus }) =>
    session?.membership
      .filter(({ user: { uuid } }) => uuid === meQuery?.me?.uuid)
      .map(({ status }) => status)
      .includes(membershipStatus)

  const [leaveSessionMutation, { loading: leavingLoading }] = useLeaveSessionMutation()
  const [joinSessionWaitingList, { loading: joinWaitingLoading }] =
    useJoinSessionWaitingListMutation()

  useLayoutEffect(() => {
    const navigateToDuplicateSession = () => {
      navigation.navigate('DuplicateSessionScreen', {
        sessionId: session?.id,
      })
    }

    const headerRight = () => {
      return (
        <View style={styles.headerContainer}>
          {session?.group.isAdmin && (
            <HeaderIcon name="copy" type="feather" onPress={navigateToDuplicateSession} right />
          )}

          <HeaderIcon
            name="calendar"
            type="feather"
            onPress={() => setIsAddToCalendar(true)}
            right
          />
          <HeaderIcon name="share-2" type="feather" onPress={onShare} />
        </View>
      )
    }

    navigation.setOptions({
      headerRight,
    })
  }, [navigation, onShare, session])

  useEffect(() => {
    if (sessionUpdated) {
      sessionQueryRefetch()
    }
  }, [sessionQueryRefetch, sessionUpdated])

  const getFutureSessions = (date, sessions = []) => {
    const upcomingSessions = take(sessions, 15).filter(
      (i) => moment(i.startAt) > moment(date) && i.status.toLowerCase() !== 'cancelled',
    )
    return sortBy(take(upcomingSessions, 20), ['startAt'])
  }

  const handleCalendar = async () => {
    setIsAddToCalendar(false)
    const { status } = await Calendar.requestCalendarPermissionsAsync()
    if (Platform.OS === 'ios') {
      const { status: reminderStatus } = await Calendar.requestRemindersPermissionsAsync()
      createEventInCalendar(status === 'granted' && reminderStatus === 'granted')
    } else {
      createEventInCalendar(status === 'granted')
    }
  }

  const createEventInCalendar = async (status) => {
    if (status) {
      let mainCalendar = null
      if (Platform.OS === 'ios') {
        mainCalendar = await Calendar.getDefaultCalendarAsync()
      } else {
        const androidCals = await Calendar.getCalendarsAsync()
        mainCalendar = androidCals.find(
          (c) =>
            c.isPrimary ||
            (c.allowsModifications && c.isSynced && c.isVisible) ||
            c.name === 'OrfiCalendar',
        )
      }

      if (!mainCalendar) {
        mainCalendar = await createCalendar()
      }

      try {
        await Calendar.createEventAsync(mainCalendar.id, {
          endDate: moment(session.endAt, "YYYY-MM-DD'T'HH:mm:ss.sssZ").toDate(),
          startDate: moment(session.startAt, "YYYY-MM-DD'T'HH:mm:ss.sssZ").toDate(),
          title: session.name,
          location: session.address,
          notes: `https://app.orfiactive.com/session/${session.id}`,
          url: `https://app.orfiactive.com/session/${session.id}`,
          alarms: [
            { relativeOffset: -60, method: Calendar.AlarmMethod.ALARM },
            { relativeOffset: -30, method: Calendar.AlarmMethod.ALARM },
          ],
        })
        Alert.alert('Congrats', 'This session has been added to your calendar')
      } catch (e) {
        console.log({ e })
        Alert.alert('Oops', 'Sorry, this session could not be added to your calendar')
      }
    } else {
      Alert.alert('Permission Denied', 'Calendar permission is required')
    }
  }

  //  create a calendar for android
  const createCalendar = async () => {
    const defaultCalendarSource = { isLocalAccount: true, name: 'OrfiCalendar' }
    const newCalendarID = await Calendar.createCalendarAsync({
      title: 'OrfiCalendar',
      color: colors.primary,
      entityType: Calendar.EntityTypes.EVENT,
      sourceId: defaultCalendarSource?.id,
      source: defaultCalendarSource,
      name: 'OrfiCalendar',
      ownerAccount: 'personal',
      accessLevel: Calendar.CalendarAccessLevel.OWNER,
    })

    return { id: newCalendarID }
  }

  const onShare = async () => {
    const dateFormat = moment(session.startAt).format('MMMM D')
    const timeFormat = moment(session.startAt).format('HH:mm')
    const url = `https://app.orfiactive.com/session/${session.id}`

    try {
      const result = await Share.share(
        {
          title: `${session.name}`,
          message: `Fancy some ${
            session?.activity[0]?.name
          } on ${dateFormat} at ${timeFormat}? Follow the link for details: ${
            Platform.OS === 'android' ? url : ''
          }`,
          url,
        },
        {
          excludedActivityTypes,
        },
      )

      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      alert(error.message)
    }
  }

  const onAvatarPress = () => {
    navigation.navigate('PublicProfileScreen', { userId: session.owner.id })
  }

  const onPressAttendees = () => {
    navigation.navigate('AttendeesListScreen', { sessionId })
  }

  const onCloseSessionFullModal = () => {
    setSessionFullModalVisible(false)
  }

  const onBackSessionFullModal = () => {
    setSessionFullModalVisible(false)
    setNotifyModalVisible(false)
  }

  const onEdit = () => {
    navigation.navigate('EditSessionScreen', { sessionId: session.id })
  }

  const onJoin = () => {
    const { prices = [] } = session
    const priceRange = getEventPriceRange(prices)
    const priceId = prices[0] ? prices[0].id : null
    if (session.maxParticipants === session.usersAttendingCount) {
      toggleWaitingListDialog()
      return
    }
    if (priceRange === '£0') {
      navigation.navigate('ConfirmationScreen', {
        paymentMethod: 'SITE',
        priceId,
        sessionId: session.id,
      })
    } else {
      navigation.navigate('JoiningOptionsScreen', {
        sessionId: session.id,
      })
    }
  }

  const toggleConfirmationDialog = () => {
    setIsConfirmationDialogVisible(!isConfirmationDialogVisible)
  }

  const toggleUserMoreDialog = () => {
    setUserAddOptionsModal(!userAddOptionsModal)
  }

  const toggleActivityInfoDialog = () => {
    setActivityInfoModal(!activityInfoModal)
  }

  const toggleWaitingListDialog = () => {
    setWaitingListModal(!waitingListModal)
    setJoinedWaitingList(false)
  }

  const toggleLeaveWaitingListDialog = () => {
    setLeaveWaitingListModal(!leaveWaitingListModal)
  }

  const onLeave = async (notInWaitingList = true) => {
    if (notInWaitingList) {
      toggleConfirmationDialog()
    }

    await leaveSessionMutation({
      variables: {
        sessionId: parseInt(session.id, 10),
      },
      refetchQueries: [
        { query: GET_SESSION_QUERY, variables: { id: parseInt(session.id, 10) } },
        { query: ME_QUERY },
      ],
      awaitRefetchQueries: true,
    })
      .then(async () => {})
      .catch(() => {})
      .finally(() => {
        setWaitingListModal(false)
        setLeaveWaitingListModal(false)
      })
  }

  const onCancel = async () => {
    const { id, startAt } = session
    navigation.navigate('CancelSessionScreen', {
      sessionId: id,
      name: session.name,
      startAt,
      groupUuid: session.group.uuid,
    })
  }

  const onCloseNotifyModal = () => {
    setNotifyModalVisible(false)
  }

  const getEventPriceRange = (prices) => {
    if (prices.length > 1) {
      const sorted = [...prices].sort((a, b) => a.value > b.value)
      const priceRange = `£${sorted[0].value.toFixed(2)} - £${sorted[
        sorted.length - 1
      ].value.toFixed(2)}`
      return priceRange
    }
    return `£${prices?.[0]?.value.toFixed(2) || 0}`
  }

  const inviteFriends = async () => {
    await onShare()
    toggleUserMoreDialog()
  }

  const goToMapApp = () => {
    setMapOpening(true)
    const address = encodeURIComponent(`${session.address}`)
    let url
    if (Platform.OS === 'ios') {
      url = `http://maps.apple.com/?daddr=${address}`
    } else {
      url = `http://maps.google.com/?daddr=${address}`
    }

    Linking.canOpenURL(url)
      .then((supported) => {
        setMapOpening(false)
        if (supported) {
          return Linking.openURL(url)
        }
      })
      .catch(() => {})
  }

  const onJoinWaitingList = async () => {
    await joinSessionWaitingList({
      variables: {
        sessionId: session.id,
      },
      refetchQueries: [
        { query: GET_SESSION_QUERY, variables: { id: session.id } },
        {
          query: GET_SESSION_MEMBERSHIP,
          variables: {
            ...attendingOptions,
          },
        },
      ],
      awaitRefetchQueries: true,
    })
      .then(async (res) => {
        setJoinedWaitingList(true)
      })
      .catch((e) => {})
  }

  const onJoinedWaitingList = () => {
    setWaitingListModal(!waitingListModal)
    setJoinedWaitingList(false)
  }

  const goToDashboard = (session) => {
    if (session?.group?.uuid) {
      navigation.navigate('DashboardGroupScreen', {
        groupUuid: session.group.uuid,
        name: session.name,
        fromSession: true,
      })
    }
  }

  if (sessionLoading) return <Loading />

  if (sessionError) {
    return (
      <EmptyNotice
        title="SESSION NOT FOUND"
        text={`Hmm, we can't find the session you're looking for.
    \n It may have been cancelled by the organiser, or maybe it just got lost in the Matrix`}
      />
    )
  }

  const priceRange = getEventPriceRange(session.prices)
  const defaultImg = session.activity[0]?.defaultBannerImage
    ? { uri: session.activity[0]?.defaultBannerImage }
    : DEFAULT_EVENT_IMAGE
  const location = {
    longitude: session.addressLongitude,
    latitude: session.addressLatitude,
    latitudeDelta: 0.0922,
    longitudeDelta: 0.0421,
  }

  const inWaitingList = (session?.membership || [])
    .filter((ssn) => ssn.status === 'WAITING')
    .find((ssn) => ssn.user.id === meQuery?.me?.id)

  const submitButton = {}
  const now = moment()
  const passed = now > moment(session.endAt ? session.endAt : session.startAt)
  const inProgress = now > moment(session.startAt) && now < moment(session.endAt)
  const sessionCancelled = session.status.toLowerCase() === 'cancelled'
  let isSingleButton = false
  let canLeave = false
  let disabled = false
  switch (true) {
    case !isAuthenticated || !meQuery || !meQuery.me:
      submitButton.title = 'Join'
      isSingleButton = true
      submitButton.onPress = () => navigation.navigate('AuthScreen', { screenCategory: 1 })
      break
    case sessionCancelled:
      submitButton.title = 'Cancelled'
      disabled = true
      submitButton.onPress = () => {}
      break
    case passed:
      submitButton.title = 'Past'
      disabled = true
      submitButton.onPress = () => {}
      break
    case paymentStatus(MembershipStatus.Attending):
      submitButton.title = 'Leave'
      canLeave = true
      submitButton.onPress = toggleConfirmationDialog
      break
    case paymentStatus(MembershipStatus.PaymentProcessing):
      submitButton.title = 'Payment Pending'
      submitButton.disabled = true
      break
    case paymentStatus(MembershipStatus.PaymentPending):
      submitButton.title = 'Continue Payment'
      submitButton.onPress = onJoin
      break
    case inProgress && !session.group.isAdmin:
      submitButton.title = 'In progress'
      submitButton.onPress = onJoin
      break
    case session.isOwner || session.group.isAdmin:
      submitButton.title = 'Edit'
      submitButton.onPress = onEdit
      break
    case !!inWaitingList:
      submitButton.title = session.isJoinable ? 'Join' : 'Withdraw'
      isSingleButton = true
      canLeave = !session.isJoinable
      submitButton.onPress = session.isJoinable ? onJoin : toggleConfirmationDialog
      break
    default:
      submitButton.title = 'Join'
      isSingleButton = true
      submitButton.onPress = onJoin
      break
  }
  const { title } = submitButton

  const upcomingSessions = getFutureSessions(session.startAt, session.group.sessions)

  const participants = session.membershipAttending

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView
        contentContainerStyle={styles.content}
        refreshControl={
          <RefreshControl refreshing={sessionLoading} onRefresh={sessionQueryRefetch} />
        }>
        <ProfileForm
          avatar={session.owner.avatar}
          title={session.name}
          comment={session.address}
          isOwner={session.isOwner}
          name={`${session.owner.name} ${session.owner?.surname}`}
          onAvatarPress={onAvatarPress}
          address={session.address}
          date={session.startAt}
          verifiedStyle={styles.profileForm}
          setIsAddToCalendar={setIsAddToCalendar}
        />
        <View>
          <Image
            source={session.image ? { uri: session.image } : defaultImg}
            style={styles.sessionImage}
            resizeMode="cover"
          />
        </View>
        <FeaturesForm
          onPressItems={{
            Attendees: onPressAttendees,
            Activity: toggleActivityInfoDialog,
          }}
          session={session}
          priceRange={priceRange}
        />
        <Divider />
        {(session?.group.isAdmin || session?.usersAttendingCount >= session?.minParticipants) && (
          <View style={styles.participantView}>
            <View style={styles.participantsContainer}>
              <Text style={styles.participantsText}>
                Participants{' '}
                <Text style={styles.participantsAttending}>
                  {formatAttendingCount(
                    session?.usersAttendingCount,
                    session?.maxParticipants,
                    false,
                  )}
                </Text>
              </Text>
              <Button
                type="clear"
                title="View all participants"
                titleStyle={styles.participantsButton}
                onPress={onPressAttendees}
              />
            </View>
            <ParticipantsList
              data={participants}
              viewAll={onPressAttendees}
              userActions={toggleUserMoreDialog}
              isOwner={session?.isOwner}
              session={session}
            />
          </View>
        )}
        <MapView location={location} />
        <Divider />
        <View style={styles.addressView}>
          <View style={styles.addressTextContainer}>
            <Text style={styles.addressText}>{session?.address}</Text>
          </View>
          <View style={styles.addressSpacer} />
          <View>
            <Button
              buttonStyle={styles.mapButton}
              onPress={goToMapApp}
              loading={mapOpening}
              type="clear"
              title="GET DIRECTIONS"
            />
          </View>
        </View>
        <Divider />
        <Paragraph title="ABOUT" comment={session?.description} />
        <Divider />
        <Paragraph title="RULES / NOTES" comment={session?.notes} />
        <Divider />
        <EventLinksSection links={session?.links} />
        <Divider />
        <Text style={styles.futureSessionText}>Future sessions</Text>
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={styles.futureSessionScrollView}>
          {upcomingSessions.map((i) => (
            <TouchableOpacity
              style={styles.futureSessionPressable}
              onPress={() =>
                navigation.dispatch(
                  StackActions.replace('SessionDetailScreen', {
                    sessionId: i.id,
                    name: session.name,
                  }),
                )
              }
              key={i.id}>
              <Text style={styles.futureSessionDate}>
                {moment(i.startAt).format('dddd, DD MMM')}
              </Text>
              <Text style={styles.futureSessionTime}>
                {moment(i.startAt).format('H:mma')} - {moment(i.endAt).format('H:mma')}
              </Text>
            </TouchableOpacity>
          ))}
        </ScrollView>
        {sessionCancelled && (
          <View style={styles.cancelView}>
            <Text style={styles.cancelReason}>{session.cancellationReason}</Text>
          </View>
        )}
      </ScrollView>
      <View>
        <View style={styles.bottomButtonContainer}>
          <View style={session.group.isAdmin ? styles.ownerButtonView : styles.ownerButtonView2}>
            {session.group.isAdmin && !sessionCancelled ? (
              <Button
                title="Cancel Session"
                onPress={onCancel}
                disabled={disabled || leavingLoading || joinWaitingLoading}
                loading={leavingLoading || joinWaitingLoading}
                containerStyle={styles.cancelSessionButton}
                buttonStyle={styles.cancelSessionButton}
              />
            ) : null}
            <Button
              title={
                session.group.isAdmin
                  ? `${title}`
                  : `${title} ${disabled || canLeave ? '' : `(${priceRange})`}`
              }
              onPress={submitButton.onPress}
              disabled={disabled || leavingLoading || joinWaitingLoading || submitButton.disabled}
              loading={leavingLoading || joinWaitingLoading}
              containerStyle={{
                width: isSingleButton ? '70%' : '45%',
              }}
              buttonStyle={{
                backgroundColor: canLeave ? colors.secondary : colors.primary,
              }}
            />
            {!session.group.isAdmin && session.isUserAttending ? (
              <Button
                title="Go to Group"
                onPress={() => goToDashboard(session)}
                containerStyle={styles.groupButton}
              />
            ) : null}
          </View>
        </View>
        <SessionFullPopup
          visible={sessionFullModalVisible}
          onClose={onCloseSessionFullModal}
          onJoin={onJoinWaitingList}
          onBack={onBackSessionFullModal}
        />
        <NotifyPopup visible={notifyModalVisible} onClose={onCloseNotifyModal} />
        <LeaveSessionPopup
          onClose={toggleConfirmationDialog}
          onLeave={onLeave}
          visible={isConfirmationDialogVisible}
        />
        <LeaveWaitingListPopup
          toggle={toggleLeaveWaitingListDialog}
          leaveWaitList={onLeave}
          joined={joinedWaitingList}
          loading={leavingLoading}
          visible={leaveWaitingListModal}
          inWaitingList={inWaitingList}
        />
        <AddCalendarPopup
          onClose={() => setIsAddToCalendar(false)}
          onAdd={handleCalendar}
          visible={isAddToCalendar}
        />
        <WaitingListPopup
          onJoined={onJoinedWaitingList}
          toggle={toggleWaitingListDialog}
          joinWaitList={onJoinWaitingList}
          leaveWaitList={onLeave}
          joined={joinedWaitingList}
          members={session?.membership || []}
          userID={meQuery && meQuery.me ? meQuery.me.id : ''}
          loading={joinWaitingLoading}
          visible={waitingListModal}
        />
        <Overlay
          isVisible={userAddOptionsModal}
          windowBackgroundColor="rgba(0, 0, 0, .5)"
          overlayBackgroundColor="#fff"
          width="70%"
          height="auto"
          onBackdropPress={toggleUserMoreDialog}
          overlayStyle={styles.modalStyle}>
          <View>
            <Button
              title={session?.isUserAttending ? 'Joined' : 'Join Session'}
              titleStyle={styles.buttonTitle}
              type="clear"
              disabled={session?.isOwner || session?.isUserAttending || sessionCancelled || passed}
              onPress={() => {
                onJoin()
                toggleUserMoreDialog()
              }}
            />
            <Divider style={styles.buttonDivider} />
            <Button
              title="Invite friends"
              titleStyle={styles.buttonTitle}
              type="clear"
              onPress={inviteFriends}
            />
          </View>
        </Overlay>
      </View>
    </SafeAreaView>
  )
}

export default SessionDetailScreen
