import { StackActions, useNavigation } from '@react-navigation/native'
import { Button, Input } from '@rneui/themed'
import { useFormik } from 'formik'
import React from 'react'
import { ImageBackground, SafeAreaView, ScrollView, useWindowDimensions, View } from 'react-native'

import { IMAGE_EMAIL_BACKGROUND } from '@src/assets/images'
import { showErrorMessage } from '@src/components/FlashMessage'
import Text from '@src/components/Text'
import { colors } from '@src/config/theme'
import { useSendPasswordResetEmailMutation } from '@src/graphql/types'
import { ResetPasswordScreenNavigationProps } from '@src/types'

import styles from './ResetPasswordScreen.styles'
import validationSchema from './ResetPasswordScreen.validation'

const ResetPasswordScreen = () => {
  const navigation = useNavigation<ResetPasswordScreenNavigationProps>()

  const [sendEmail, { loading }] = useSendPasswordResetEmailMutation()

  const { width, height } = useWindowDimensions()

  const handleOnSubmit = async ({ email }: { email: string }) => {
    try {
      await sendEmail({ variables: { email } })
      navigation.dispatch(StackActions.replace('ConfirmPasswordScreen', { email }))
    } catch (error) {
      showErrorMessage({ message: '', description: error?.message })
    }
  }

  const { values, handleChange, setFieldTouched, errors, touched, handleSubmit } = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      email: '',
    },
    onSubmit: handleOnSubmit,
  })

  return (
    <ImageBackground source={IMAGE_EMAIL_BACKGROUND} style={[styles.bg, { width, height }]}>
      <SafeAreaView>
        <ScrollView
          contentContainerStyle={[styles.container, { width, height }]}
          keyboardShouldPersistTaps="handled">
          <View style={[styles.skip, { marginBottom: height / 5 }]}>
            <Button
              onPress={() => navigation.goBack()}
              title="CANCEL"
              type="clear"
              titleStyle={styles.backButtonTitle}
            />
          </View>
          <View style={styles.wrapper}>
            <View>
              <Text style={styles.mainTitle}>RESET PASSWORD</Text>
              <Text style={styles.subTitle}>
                {
                  'In order to reset your password, please enter\nthe email address you’ve used to sign up.'
                }
              </Text>
            </View>
            <View style={styles.inputView}>
              <Input
                placeholder="Enter your email"
                placeholderTextColor={colors.grey7}
                textAlign="center"
                value={values.email}
                keyboardType="email-address"
                onChangeText={handleChange('email')}
                onBlur={() => setFieldTouched('email')}
                errorMessage={touched.email && errors.email ? errors.email : undefined}
                errorStyle={styles.centre}
                autoCapitalize="none"
                enablesReturnKeyAutomatically
              />
            </View>
          </View>
          <View style={[styles.buttonView]}>
            <View style={styles.innerButtonView}>
              <Button
                title="Send request"
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                onPress={handleSubmit as any}
                loading={loading}
                disabled={loading}
              />
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
    </ImageBackground>
  )
}

export default ResetPasswordScreen
