import {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useReducer,
  useMemo,
} from 'react'

import { client } from '@src/graphql/apollo'
import { useLoginMutationMutation, useUpdateUserPushMutation } from '@src/graphql/types'
import { deleteAuthToken, getAuthToken, setAuthToken, isValidAuthToken } from '@src/utils/auth'
import { deleteNotificationTimestamp } from '@src/utils/notificationTimestamp'
import registerForPushNotificationsAsync from '@src/utils/registerForPushNotificationsAsync'

import { ActionEnum, AuthState, ReducerActions, AuthContextProps } from './types.d'

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  token: undefined,
}

const reducer = (state: AuthState, action: ReducerActions) => {
  switch (action.type) {
    case ActionEnum.INITIALISE: {
      const { isAuthenticated } = action.payload

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
      }
    }
    case ActionEnum.LOGIN: {
      const { token } = action.payload
      return {
        ...state,
        isAuthenticated: true,
        token,
      }
    }
    case ActionEnum.LOGOUT: {
      return {
        ...state,
        isAuthenticated: false,
      }
    }
    default: {
      return { ...state }
    }
  }
}

export const AuthContext = createContext<AuthContextProps>({} as AuthContextProps)

export const AuthProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState)
  const [loginMutation] = useLoginMutationMutation()
  const [updateUserMutation] = useUpdateUserPushMutation()

  const dispatchInitialise = (isAuthenticated: boolean) =>
    dispatch({
      type: ActionEnum.INITIALISE,
      payload: { isAuthenticated },
    })

  const dispatchLogin = (token: string) =>
    dispatch({
      type: ActionEnum.LOGIN,
      payload: { token },
    })

  const dispatchLogout = () =>
    dispatch({
      type: ActionEnum.LOGOUT,
    })

  const authContext = useMemo(
    () => ({
      login: async (email: string, password: string) => {
        const { data } = await loginMutation({
          variables: { email, password },
          onCompleted: async () => {
            const pushToken = await registerForPushNotificationsAsync()
            if (pushToken) {
              await updateUserMutation({
                variables: {
                  pushNotificationToken: pushToken,
                },
              })
            }
          },
        })

        if (data?.tokenAuth?.token) {
          await setAuthToken(data.tokenAuth.token)
          dispatchLogin(data.tokenAuth.token)
        }
      },

      loginToken: async (token: string) => {
        try {
          const pushToken = await registerForPushNotificationsAsync()
          if (pushToken) {
            await updateUserMutation({
              variables: {
                pushNotificationToken: pushToken,
              },
            })
          }
        } catch (error) {}

        await setAuthToken(token)
        dispatchLogin(token)
      },

      logout: async () => {
        await Promise.all([client.clearStore(), deleteAuthToken(), deleteNotificationTimestamp()])
        dispatchLogout()
      },
    }),
    [],
  )

  useEffect(() => {
    const initialise = async () => {
      try {
        const authToken = await getAuthToken()

        if (authToken && isValidAuthToken(authToken)) {
          dispatchInitialise(true)
        } else {
          deleteAuthToken()
          dispatchInitialise(false)
        }
      } catch (err) {
        console.error(err)
        deleteAuthToken()
        dispatchInitialise(false)
      }
    }

    initialise()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        ...state,
        ...authContext,
      }}>
      {children}
    </AuthContext.Provider>
  )
}
