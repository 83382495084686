import { useNavigation, useRoute } from '@react-navigation/native'
import { Button, Divider, Icon, Input, ListItem, Overlay } from '@rneui/themed'
import * as ImagePicker from 'expo-image-picker'
import * as MediaLibrary from 'expo-media-library'
import { useFormik } from 'formik'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import {
  BackHandler,
  ImageBackground,
  Keyboard,
  SafeAreaView,
  ScrollView,
  TouchableOpacity,
  View,
} from 'react-native'

import ConfirmDialog from '@src/components/ConfirmDialog'
import DualButton from '@src/components/DualButton'
import EmptyNotice from '@src/components/EmptyNotice'
import HeaderIcon from '@src/components/HeaderIcon'
import Loading from '@src/components/Loading'
import Text from '@src/components/Text'
import { colors } from '@src/config/theme'
import { GET_GROUP, ME_QUERY } from '@src/graphql/queries'
import {
  useGroupQuery,
  useUpdateGroupMutation,
  useUpdateGroupWithImageMutation,
} from '@src/graphql/types'
import { EditGroupScreenNavigationProps, EditGroupScreenRouteProps } from '@src/types'
import generateRNFile from '@src/utils/generateRNFile'

import styles from './EditGroupScreen.styles'
import validationSchema from './EditGroupScreen.validation'

const EditGroupScreen = () => {
  const [imgDialog, setImgDialog] = useState(false)
  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState(false)
  const navigation = useNavigation<EditGroupScreenNavigationProps>()
  const { params } = useRoute<EditGroupScreenRouteProps>()

  const {
    loading,
    data: groupData,
    error,
  } = useGroupQuery({
    variables: {
      groupUuid: params?.groupUuid,
    },
  })

  const { group } = groupData

  const submitGroup = async (values) => {
    const update = values?.newImage ? updateGroup : updateGroupWithoutImage
    try {
      await update({
        variables: {
          ...values,
          image: values?.newImage,
          groupUuid: group.uuid,
        },
        refetchQueries: [
          { query: GET_GROUP, variables: { groupUuid: params?.groupUuid } },
          { query: ME_QUERY },
        ],
        awaitRefetchQueries: true,
      })
    } catch (err) {
      console.log('error', err)
    }
  }

  const {
    values,
    dirty,
    errors,
    touched,
    isSubmitting,
    handleChange,
    setFieldTouched,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      name: group.name ? group.name : '',
      image: group.image ? group.image : undefined,
      description: group.description ? group.description : '',
      isPrivate: group.isPrivate ? group.isPrivate : true,
    },
    onSubmit: submitGroup,
  })

  const [updateGroup, { loading: editLoading }] = useUpdateGroupWithImageMutation({
    onCompleted: () => {
      navigation.goBack()
    },
  })

  const [updateGroupWithoutImage, { loading: editNoImageLoading }] = useUpdateGroupMutation({
    onCompleted: () => {
      navigation.goBack()
    },
  })

  const checkInputStates = () => {
    if (dirty) {
      setIsConfirmationDialogVisible(true)
    } else {
      navigation.goBack()
    }
  }

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <HeaderIcon onPress={() => checkInputStates()} />,
    })
  }, [dirty, navigation])

  useEffect(() => {
    const backHandler = BackHandler.addEventListener('hardwareBackPress', () => checkInputStates())
    return () => backHandler.remove()
  })

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => <Button title="CANCEL" onPress={() => checkInputStates()} type="clear" />,
    })
  }, [navigation])

  if (loading) return <Loading />
  if (error) {
    return <EmptyNotice title="NOTHING TO SHOW" text="We could not find this group" />
  }

  const pickImage = async () => {
    const { status } = await MediaLibrary.requestPermissionsAsync()
    if (status !== 'granted') {
      // eslint-disable-next-line no-alert
      alert('Sorry, we need camera roll permissions to make this work!')
    } else {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
      })
      if (!result.canceled) {
        const file = generateRNFile(result.assets[0].uri, 'Cover')
        setFieldTouched('newImage')
        setFieldValue('newImage', file)
      }
    }
  }

  const toggleConfirmationDialog = () => {
    setIsConfirmationDialogVisible(!isConfirmationDialogVisible)
  }

  const imageSrc = values.newImage ? values.newImage.uri : values.image

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.content}>
        <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={styles.scrollVw}>
          <Input
            label="GROUP NAME"
            labelStyle={styles.label}
            containerStyle={styles.inputContainer}
            returnKeyType="done"
            value={values.name}
            onSubmitEditing={Keyboard.dismiss}
            placeholder={'e.g "Saturday morning tennis practice"'}
            placeholderTextColor={colors.greyOutline}
            onBlur={() => setFieldTouched('name')}
            onChangeText={handleChange('name')}
            errorMessage={touched.name && errors.name ? errors.name : undefined}
            editable={!isSubmitting}
          />
          <View style={styles.coverPhotoContainer}>
            <Text style={styles.coverPhotoText}>COVER PHOTO</Text>
            <ImageBackground
              style={styles.imageBackground}
              source={{ uri: imageSrc }}
              resizeMode="cover">
              <TouchableOpacity
                style={styles.coverPressable}
                onPress={() => (imageSrc ? setImgDialog(true) : pickImage())}>
                <Icon name="plus" type="feather" color={colors.grey6} />
                <Text style={styles.mediaText}>Add media</Text>
              </TouchableOpacity>
            </ImageBackground>
          </View>

          <Input
            label="INFORMATION"
            placeholder="Give some details about the group"
            placeholderTextColor={colors.greyOutline}
            value={values.description}
            onBlur={() => setFieldTouched('description')}
            onChangeText={handleChange('description')}
            // errorMessage={touched.description && errors.description ? errors.description : undefined}
            editable={!isSubmitting}
            inputContainerStyle={styles.descriptionContainer}
            inputStyle={styles.descriptionStyle}
            labelStyle={styles.label}
            containerStyle={styles.inputContainer}
            multiline
          />
          <ListItem
            style={styles.listItemStyle}
            onPress={() => setFieldValue('isPrivate', !values.isPrivate)}>
            <ListItem.Content>
              <ListItem.Title style={styles.listItemTitle}>THIS IS A PRIVATE GROUP</ListItem.Title>
            </ListItem.Content>

            <ListItem.CheckBox
              checked={values.isPrivate}
              onPress={() => setFieldValue('isPrivate', !values.isPrivate)}
            />
          </ListItem>
        </ScrollView>
        <View style={styles.buttonContainer}>
          <DualButton
            leftTitle="Cancel"
            rightTitle="Submit"
            leftOnPress={() => checkInputStates()}
            rightOnPress={handleSubmit}
            loading={editLoading || editNoImageLoading}
            disabled={editLoading || editNoImageLoading}
          />
        </View>
        <Overlay
          isVisible={imgDialog}
          windowBackgroundColor="rgba(0, 0, 0, .5)"
          overlayBackgroundColor="#fff"
          width="70%"
          height="auto"
          onBackdropPress={() => setImgDialog(false)}
          overlayStyle={styles.modalStyle}>
          <View style={styles.modalContainer}>
            <Button
              title="Remove"
              titleStyle={styles.removeButton}
              type="clear"
              onPress={() => {
                setFieldValue('newImage', '')
                setFieldValue('image', '')
                setImgDialog(false)
              }}
            />
            <Divider style={styles.divider} />
            <Button
              title="Add Image"
              titleStyle={styles.addButton}
              type="clear"
              onPress={pickImage}
            />
          </View>
        </Overlay>
      </View>
      <ConfirmDialog
        title="Careful..."
        message="If you exit this page your updates will be lost."
        negativeButton={{
          title: 'Cancel',
          onPress: toggleConfirmationDialog,
        }}
        positiveButton={{
          title: 'Exit',
          onPress: () => {
            toggleConfirmationDialog()
            navigation.goBack()
          },
        }}
        visible={isConfirmationDialogVisible}
      />
    </SafeAreaView>
  )
}

export default EditGroupScreen
