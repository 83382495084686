import { useNavigation, useRoute } from '@react-navigation/native'
import { StackActions } from '@react-navigation/native'
import { Button, Divider, Icon } from '@rneui/themed'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FlatList, SafeAreaView, TouchableOpacity, View } from 'react-native'

import { showErrorMessage, showSuccessMessage } from '@src/components/FlashMessage'
import Loading from '@src/components/Loading'
import Text from '@src/components/Text'
import { colors } from '@src/config/theme'
import { GET_SESSION_QUERY } from '@src/graphql/queries'
import { useSessionQuery, useTransferSessionMutation } from '@src/graphql/types'
import {
  TransferMembershipOptionScreenNavigationProps,
  TransferMembershipOptionScreenRouteProps,
} from '@src/types'

import { styles, itemStyles } from './TransferMembershipOptionScreen.styles'

const popAction = StackActions.pop(2)

const ListItem = ({ amount, comment, selected, onPress }) => (
  <TouchableOpacity style={itemStyles.container} onPress={onPress}>
    <View style={itemStyles.left}>
      <View style={itemStyles.leftText}>
        <Text style={itemStyles.price}>£{amount}</Text>
        <Text style={itemStyles.comment}>{comment}</Text>
      </View>
      <Divider />
    </View>
    {selected ? (
      <Icon
        color={colors.success}
        size={32}
        type={selected ? 'ionicon' : 'font-awesome'}
        name={selected ? 'ios-checkmark-circle' : 'circle-thin'}
      />
    ) : (
      <View style={itemStyles.empty} />
    )}
  </TouchableOpacity>
)

const TransferMembershipOptionScreen = () => {
  const navigation = useNavigation<TransferMembershipOptionScreenNavigationProps>()
  const { params } = useRoute<TransferMembershipOptionScreenRouteProps>()
  const [selectedPrice, setSelectedPrice] = useState({})

  const { data: sessionQuery, loading } = useSessionQuery({
    variables: {
      id: params.sessionId,
    },
  })

  const [transferUser, { loading: transferLoading }] = useTransferSessionMutation()

  useEffect(() => {
    if (session?.prices?.length >= 1) setSelectedPrice(session.prices[0])
  }, [sessionQuery])

  const onTransferUser = async () => {
    const { user } = params
    try {
      await transferUser({
        variables: { membershipUuid: user.uuid, priceUuid: selectedPrice.uuid },
        refetchQueries: [{ query: GET_SESSION_QUERY, variables: { id: params.prevSessionId } }],
        awaitRefetchQueries: true,
      })
      const date = moment(session?.startAt).format('DD MMMM, H:mm')

      showSuccessMessage(
        'Transfer Successful',
        `${user.name} has been transferred\nto ${session.name} session ${date}`,
      )
      navigation.dispatch(popAction)
    } catch (error) {
      showErrorMessage({ message: '', description: error?.message })
    }
  }

  if (loading) return <Loading />

  const { session } = sessionQuery

  return (
    <SafeAreaView style={styles.container}>
      <FlatList
        data={session.prices}
        keyExtractor={(item) => item.id}
        selectedPriceId={selectedPrice.id}
        renderItem={({ item }) => (
          <ListItem
            amount={item.value.toFixed(2)}
            comment={item.description}
            selected={item.id === selectedPrice.id}
            onPress={() => setSelectedPrice(item)}
          />
        )}
        ListHeaderComponent={() => (
          <View style={styles.comments}>
            <View>
              <Text style={styles.commentHeader}>
                {'Select from the available\nmembership options below.'}
              </Text>
            </View>
          </View>
        )}
        style={styles.content}
      />
      <View>
        <View style={styles.singleBtn}>
          <Button
            title="Transfer"
            onPress={onTransferUser}
            titleStyle={styles.buttonText}
            containerStyle={styles.buttonContainer}
            loading={transferLoading}
            disabled={transferLoading || !selectedPrice}
          />
        </View>
      </View>
    </SafeAreaView>
  )
}

TransferMembershipOptionScreen.propTypes = {
  route: PropTypes.object.isRequired,
}

export default TransferMembershipOptionScreen
