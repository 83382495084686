import { Divider } from '@rneui/themed'
import { Dimensions, StyleSheet, View } from 'react-native'

import { colors } from '@src/config/theme'

const { width } = Dimensions.get('window')

const OrfiDivider = () => (
  <View style={styles.dividerContainer}>
    <View style={styles.viewStyle} />
    <Divider style={styles.divider} />
  </View>
)

const styles = StyleSheet.create({
  dividerContainer: {
    paddingHorizontal: 14,
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 15,
  },
  divider: {
    height: 0.8,
    flex: 1,
    backgroundColor: colors.grey6,
  },
  viewStyle: {
    width: width * 0.15,
  },
})

export default OrfiDivider
