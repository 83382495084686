import { useNavigation } from '@react-navigation/native'
import { Button, Divider, Icon } from '@rneui/themed'
import moment from 'moment'
import React from 'react'
import { useLayoutEffect } from 'react'
import { FlatList, SectionList, View } from 'react-native'

import { GROUP_FALLBACK } from '@src/assets/images'
import HeaderIcon from '@src/components/HeaderIcon'
import OrfiListItem from '@src/components/ListItem/OrfiListItem'
import Loading from '@src/components/Loading'
import Text from '@src/components/Text'
import TopBanner from '@src/components/TopBanner'
import { colors } from '@src/config/theme'
import { useMeQuery } from '@src/graphql/types'
import { GroupScreenNavigationProps } from '@src/types'
import getInitials from '@src/utils/getInitials'

import styles from './GroupScreen.styles'

const GroupScreen = () => {
  const navigation = useNavigation<GroupScreenNavigationProps>()
  const { data: meData, loading, error, refetch } = useMeQuery()

  useLayoutEffect(() => {
    const headerRight = () => (
      <HeaderIcon
        name="user-plus"
        type="feather"
        onPress={() => navigation.navigate('CreateGroupScreen')}
        right
      />
    )
    navigation.setOptions({
      headerRight,
    })
  })

  if (loading || error) return <Loading />

  const onPress = (item) => {
    navigation.navigate('DashboardGroupScreen', {
      groupUuid: item?.uuid,
      name: item?.name,
    })
  }

  const renderItem = ({ item }) => {
    const { sessions, groupUsers, activities, name, image } = item

    const acts = activities.map((i) => i?.name).join(', ')

    const nextSession = sessions.filter(
      (i) => moment() <= moment(i.startAt) && i.status.toLowerCase() !== 'cancelled',
    )[0]

    return (
      <OrfiListItem
        avatarUri={image || GROUP_FALLBACK}
        avatarPlaceholder={getInitials(name)}
        topBadge=""
        title={name}
        subtitle={`${acts}  ${acts ? '|' : ''} ${groupUsers.length} members`}
        bottomText={
          nextSession ? `Next session at ${moment(nextSession.startAt).format('dddd, h:mm')}` : ''
        }
        callToAction={<Icon name="chevron-right" type="feather" color={colors.grey} size={20} />}
        onPress={() => onPress(item)}
        divider
      />
    )
  }

  const { me } = meData
  const groupsManaged = me?.groupuserSet
    .reduce((prev, curr) => [curr.group, ...prev], [])
    .filter((grp) => grp.isAdmin)

  const groupsJoined = me?.groupuserSet
    .reduce((prev, curr) => [curr.group, ...prev], [])
    .filter((grp) => !grp.isAdmin)

  const allData = [
    { title: 'Groups you manage', data: [groupsManaged], index: 0 },
    { title: "Groups you've joined", data: [groupsJoined], index: 1 },
  ]

  return (
    <>
      <TopBanner />
      <View style={styles.container}>
        <SectionList
          sections={allData}
          keyExtractor={(item, index) => index.toString()}
          stickySectionHeadersEnabled={false}
          contentContainerStyle={styles.sectionList}
          renderSectionHeader={({ section: { title } }) => (
            <View style={styles.sectionTitleVw}>
              <Text style={styles.sectionTitle}>{title}</Text>
              <Divider />
            </View>
          )}
          renderItem={({ item, section: { index } }) => (
            <FlatList
              keyExtractor={(i) => i.uuid}
              data={item}
              renderItem={renderItem}
              ListEmptyComponent={() => (
                <View style={styles.emptyFlatList}>
                  <Text style={styles.textFlatList}>
                    {index
                      ? "Looks like you're not a member of any groups yet."
                      : 'You don’t currently manage any groups. Become a trendsetter and create one now!'}
                  </Text>

                  {!index ? (
                    <Button
                      title="Create"
                      titleStyle={styles.exploreTitle}
                      type="clear"
                      onPress={() => navigation.navigate('CreateGroupScreen')}
                    />
                  ) : undefined}
                </View>
              )}
            />
          )}
          onRefresh={refetch}
          refreshing={loading}
        />
      </View>
    </>
  )
}

export default GroupScreen
