import { useNavigation } from '@react-navigation/native'
import { Button, Input } from '@rneui/themed'
import * as ImagePicker from 'expo-image-picker'
import * as MediaLibrary from 'expo-media-library'
import { useFormik } from 'formik'
import { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { BackHandler, Keyboard, SafeAreaView, ScrollView, View } from 'react-native'

import ConfirmDialog from '@src/components/ConfirmDialog'
import { showSuccessMessage } from '@src/components/FlashMessage'
import HeaderIcon from '@src/components/HeaderIcon'
import Loading from '@src/components/Loading'
import OrfiAvatar from '@src/components/OrfiAvatar'
import Text from '@src/components/Text'
import { colors } from '@src/config/theme'
import { AuthContext } from '@src/context/AuthContext'
import { ME_QUERY } from '@src/graphql/queries'
import { useMeQuery, useUpdateUserMutation } from '@src/graphql/types'
import { EditProfileScreenNavigationProps } from '@src/types.d'
import generateRNFile from '@src/utils/generateRNFile'

import styles from './EditProfileScreen.styles'
import validationSchema from './EditProfileScreen.validation'

const EditProfileScreen = () => {
  const { logout } = useContext(AuthContext)
  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState(false)
  const navigation = useNavigation<EditProfileScreenNavigationProps>()

  const { data: { me = { email: '' } } = {} } = useMeQuery()

  const [updateUserMutation, { loading }] = useUpdateUserMutation()

  const submitUpdate = async (values, { setFieldError }) => {
    const valueMap = {
      ...values,
      avatar: values?.newAvatar,
    }
    try {
      await updateUserMutation({
        variables: valueMap,
        refetchQueries: [{ query: ME_QUERY }],
      })
      if (values.email !== me?.email) {
        handleLogOut()
      } else navigation.goBack()
    } catch (error) {
      setFieldError('form', error.message)
    }
  }

  const {
    values,
    touched,
    errors,
    dirty,
    handleChange,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      avatar: me.avatar || undefined,
      name: me?.name || '',
      surname: me?.surname || '',
      email: me?.email || '',
      newPassword: null,
      accountNumber: me?.accountNumber || '',
      accountSortCode: me?.accountSortCode || '',
      accountName: me?.accountName || '',
    },
    onSubmit: submitUpdate,
  })

  const checkInputStates = () => {
    if (!me) {
      navigation.goBack()
      return
    }
    if (dirty) {
      setIsConfirmationDialogVisible(true)
    } else {
      navigation.goBack()
    }
  }

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <HeaderIcon onPress={() => checkInputStates()} />,
    })
  }, [dirty, navigation])

  useEffect(() => {
    const backHandler = BackHandler.addEventListener('hardwareBackPress', () => checkInputStates())
    return () => backHandler.remove()
  })

  const renderConfirmDialog = () => (
    <ConfirmDialog
      title="Careful..."
      message="Are you sure? Changes will be lost."
      negativeButton={{
        title: 'Stay',
        onPress: () => setIsConfirmationDialogVisible(false),
      }}
      positiveButton={{
        title: 'Exit',
        onPress: () => {
          setIsConfirmationDialogVisible(false)
          navigation.goBack()
        },
      }}
      visible={isConfirmationDialogVisible}
    />
  )

  const pickImage = async () => {
    const { status } = await MediaLibrary.requestPermissionsAsync()
    if (status !== 'granted') {
      // eslint-disable-next-line no-alert
      alert('Sorry, we need camera roll permissions to make this work!')
    } else {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
      })
      if (!result.canceled) {
        const file = generateRNFile(result.assets[0].uri, 'Avatar')
        setFieldTouched('newAvatar')
        setFieldValue('newAvatar', file)
      }
    }
  }

  const handleLogOut = () => {
    logout()

    showSuccessMessage({
      message: '',
      description: 'Your email has been changed, please log in again',
    })

    setTimeout(() => {
      navigation.navigate('AuthScreen', { screenCategory: 0 })
    }, 220)
  }

  if (loading) return <Loading />

  const imageSource = values.newAvatar ? values?.newAvatar?.uri : values?.avatar

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.content}>
        {errors.form && <Text>{errors.form}</Text>}
        <View style={styles.center}>
          <OrfiAvatar
            type="profile"
            status="edit"
            size="large"
            uri={imageSource}
            onPress={pickImage}
          />
        </View>
        <Input
          label="FIRST NAME"
          onSubmitEditing={Keyboard.dismiss}
          placeholderTextColor={colors.greyOutline}
          value={values.name}
          onBlur={() => setFieldTouched('name')}
          onChangeText={handleChange('name')}
          errorMessage={touched.name && errors.name ? errors.name : undefined}
          editable={!loading}
        />
        <Input
          label="LAST NAME"
          onSubmitEditing={Keyboard.dismiss}
          placeholderTextColor={colors.greyOutline}
          value={values.surname}
          onBlur={() => setFieldTouched('surname')}
          onChangeText={handleChange('surname')}
          errorMessage={touched.surname && errors.surname ? errors.surname : undefined}
          editable={!loading}
        />
        <Input
          label="EMAIL"
          onSubmitEditing={Keyboard.dismiss}
          placeholderTextColor={colors.greyOutline}
          value={values.email}
          onBlur={() => setFieldTouched('email')}
          onChangeText={handleChange('email')}
          errorMessage={touched.email && errors.email ? errors.email : undefined}
          editable={!loading}
        />
        <Input
          label="PASSWORD"
          onSubmitEditing={Keyboard.dismiss}
          placeholderTextColor={colors.greyOutline}
          value={values.newPassword}
          onBlur={() => setFieldTouched('newPassword')}
          onChangeText={handleChange('newPassword')}
          errorMessage={touched.newPassword && errors.newPassword ? errors.newPassword : undefined}
          editable={!loading}
          secureTextEntry
        />
      </ScrollView>

      <View style={styles.bottom}>
        <Button
          title="SAVE"
          onPress={handleSubmit}
          titleStyle={styles.resetTitle}
          disabled={loading}
          loading={loading}
        />
      </View>
      {renderConfirmDialog()}
    </SafeAreaView>
  )
}

export default EditProfileScreen
