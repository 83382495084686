import { StackActions } from '@react-navigation/native'
import { useNavigation, useRoute } from '@react-navigation/native'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Platform, Share, View } from 'react-native'

import BankedPopup from '@src/components/PopupModal/BankedPopup'
import { attendingOptions } from '@src/graphql/apollo'
import { useSessionMembershipQuery } from '@src/graphql/types'
import {
  PaymentCompletedScreenNavigationProps,
  PaymentCompletedScreenRouteProps,
} from '@src/types.d'

import styles from './PaymentCompletedScreen.styles'

const PaymentCompletedScreen = () => {
  const navigation = useNavigation<PaymentCompletedScreenNavigationProps>()
  const { params } = useRoute<PaymentCompletedScreenRouteProps>()
  const [status, setStatus] = useState('')
  const [membership, setMembership] = useState(null)
  const [visible, setVisible] = useState(false)

  const { data: upcoming, error: upcomingError } = useSessionMembershipQuery({
    variables: attendingOptions,
    fetchPolicy: 'network-only',
    pollInterval: 1000,
  })

  useEffect(() => {
    setTimeout(() => setVisible(true), 2200)
  }, [])

  useEffect(() => {
    if (upcoming && params?.paymentId) {
      const userMembership = upcoming.sessionMembership.find(
        (m) => m.bankedPaymentId === params.paymentId,
      )
      if (userMembership) {
        setMembership(userMembership)
        setStatus(userMembership.status)
      } else setStatus('FAILED')
    }
  }, [upcoming, params])

  const onShare = async () => {
    const dateFormat = moment(membership.session.startAt).format('MMMM D')
    const timeFormat = moment(membership.session.startAt).format('HH:mm')
    const url = `https://app.orfiactive.com/session/${membership.session.id}`

    try {
      const result = await Share.share({
        message: `I've just joined ${
          membership.session?.activity[0]?.name
        } on ${dateFormat} at ${timeFormat}, check it out here. ${
          Platform.OS === 'android' ? url : ''
        }`,
        url,
      })

      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (err) {
      alert(err.message)
    }
  }

  const onGroup = () => {
    const { uuid } = membership.session.group
    setVisible(false)
    navigation.dispatch(StackActions.replace('DashboardGroupScreen', { groupUuid: uuid }))
  }

  const onClose = () => {
    setVisible(false)
    navigation.goBack()
  }

  const onExplore = () => {
    setVisible(false)
    navigation.navigate('HomeStackScreen')
  }

  return (
    <View style={styles.container}>
      <BankedPopup
        status={upcomingError ? 'FAILED' : status}
        price={membership?.price?.value || 0}
        visible={visible}
        tryAgain={() => {}}
        close={onClose}
        onShare={onShare}
        onGroup={onGroup}
        onBackHome={onExplore}
      />
    </View>
  )
}

export default PaymentCompletedScreen
