import { Button, Icon } from '@rneui/themed'
import PropTypes from 'prop-types'
import React from 'react'
import { Dimensions, Modal, StyleSheet, TouchableWithoutFeedback, View } from 'react-native'

import DualButton from '@src/components/DualButton'
import Text from '@src/components/Text'
import { colors, fonts } from '@src/config/theme'

const { height, width } = Dimensions.get('screen')

const WaitingListPopup = ({
  visible,
  toggle,
  onJoined,
  leaveWaitList,
  joinWaitList,
  joined,
  members,
  userID,
  loading,
}) => {
  const inWaitingList = members
    .filter((ssn) => ssn.status === 'WAITING')
    .find((ssn) => ssn.user.id === userID)

  return (
    <Modal transparent visible={visible} animationType="none" onRequestClose={toggle}>
      <TouchableWithoutFeedback onPress={toggle}>
        <View style={{ ...StyleSheet.absoluteFill, ...styles.container }}>
          <TouchableWithoutFeedback>
            <View style={styles.content}>
              <Icon
                color={colors.secondary}
                size={40}
                containerStyle={styles.icon}
                type="feather"
                name={joined ? 'thumbs-up' : 'alert-circle'}
                iconStyle={styles.iconStyle}
              />
              <View style={styles.texts}>
                <Text style={styles.title}>
                  {joined ? 'YOU’RE ON THE LIST!' : 'THIS SESSION IS NOW FULL!'}
                </Text>
                <Text style={styles.comment}>
                  {joined
                    ? 'We’ll notify you if space opens up on this event, giving you the chance to join. Good luck!'
                    : 'The maximum number of participants for this session has been reached. However, you can still join the waiting list and if anyone drops out, we’ll let you know.'}
                </Text>
              </View>

              {joined ? (
                <Button
                  title="Got it"
                  onPress={onJoined}
                  containerStyle={styles.gotItButtonContainer}
                  titleStyle={styles.gradientButtonText}
                />
              ) : (
                <DualButton
                  leftTitle="Back"
                  rightTitle={inWaitingList ? 'Leave' : 'Join'}
                  leftOnPress={toggle}
                  rightOnPress={inWaitingList ? leaveWaitList : joinWaitList}
                  disabled={loading}
                  loading={loading}
                />
              )}
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  )
}

const buttonWidth = (width * 0.8 - 60 - 10) / 2

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#1119',
    alignItems: 'center',
    zIndex: 1000,
  },
  content: {
    width: '80%',
    borderRadius: 6,
    padding: 20,
    shadowOffset: {
      width: 2,
      height: 2,
    },
    shadowColor: 'black',
    shadowOpacity: 0.2,
    backgroundColor: colors.white,
    marginTop: height / 5,
    alignItems: 'center',
  },
  texts: {
    paddingHorizontal: 10,
    alignItems: 'center',
  },
  icon: {
    marginVertical: 30,
  },
  title: {
    color: colors.secondary,
    fontSize: 13,
    // fontWeight: 'bold',
    fontFamily: fonts.default.bold,
    textAlign: 'center',
  },
  comment: {
    margin: 10,
    color: colors.secondary,
    fontSize: 10,
    textAlign: 'center',
    marginVertical: 20,
    lineHeight: 17,
  },
  buttonStyle: {
    borderRadius: 23,
    height: 42,
    width: '100%',
    backgroundColor: colors.secondary,
    alignItems: 'center',
    paddingVertical: 0,
  },
  button: {
    width: buttonWidth,
  },
  gradientButtonText: {
    fontSize: 12,
    fontFamily: fonts.default.semiBold,
  },
  gotItButtonContainer: {
    height: 42,
    width: '80%',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  iconStyle: {
    lineHeight: 40,
  },
})

WaitingListPopup.propTypes = {
  visible: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  joinWaitList: PropTypes.func.isRequired,
  leaveWaitList: PropTypes.func.isRequired,
  joined: PropTypes.bool.isRequired,
  members: PropTypes.array.isRequired,
  userID: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  onJoined: PropTypes.func.isRequired,
}

export default WaitingListPopup
