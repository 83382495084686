import { createIconSet } from '@expo/vector-icons'

const glyphMap = {
  aerobics: '\uf100',
  american_football: '\uf101',
  archery: '\uf102',
  athletics: '\uf103',
  badminton: '\uf104',
  base_jumping: '\uf105',
  baseball: '\uf106',
  basketball: '\uf107',
  bmx: '\uf108',
  bocce: '\uf109',
  body_building: '\uf10a',
  bowling: '\uf10b',
  bowls: '\uf10c',
  boxing: '\uf10d',
  calisthenics: '\uf10e',
  canoe: '\uf10f',
  capoeira: '\uf110',
  cards: '\uf111',
  cave: '\uf112',
  cheerleading: '\uf113',
  chess: '\uf114',
  cricket: '\uf115',
  crossfit: '\uf116',
  cycling: '\uf117',
  dance: '\uf118',
  darts: '\uf119',
  decathlon: '\uf11a',
  discgolf: '\uf11b',
  disco: '\uf11c',
  diving: '\uf11d',
  dog_training: '\uf11e',
  equestrian: '\uf11f',
  falconry: '\uf120',
  fencing: '\uf121',
  fish: '\uf122',
  fitness_class: '\uf123',
  foosball: '\uf124',
  footbag_net: '\uf125',
  football: '\uf126',
  footgolf: '\uf127',
  futsal: '\uf128',
  go_kart: '\uf129',
  golf: '\uf12a',
  gym: '\uf12b',
  gymnastics: '\uf12c',
  handball: '\uf12d',
  hang_gliding: '\uf12e',
  heptathlon: '\uf12f',
  high_jump: '\uf130',
  hiking: '\uf131',
  hobby_horsing: '\uf132',
  hockey: '\uf133',
  ice_hockey: '\uf134',
  ice_skating: '\uf135',
  jump: '\uf136',
  karate: '\uf137',
  kayaking: '\uf138',
  kitesurfing: '\uf139',
  korfball: '\uf13a',
  lacrosse: '\uf13b',
  long_jump: '\uf13c',
  meditation: '\uf13d',
  motorsports: '\uf13e',
  mountain_biking: '\uf13f',
  mountainboarding: '\uf140',
  netball: '\uf141',
  orienteering: '\uf142',
  paddle_tennis: '\uf143',
  paintball: '\uf144',
  parasports: '\uf145',
  pilates: '\uf146',
  pool: '\uf147',
  posing_workshop: '\uf148',
  quad_biking: '\uf149',
  quidditch: '\uf14a',
  racquet: '\uf14b',
  rock_climbing: '\uf14c',
  roller_disco: '\uf14d',
  rollerblade: '\uf14e',
  rounders: '\uf14f',
  rowing: '\uf150',
  rugby: '\uf151',
  running: '\uf152',
  sailing: '\uf153',
  scuba_diving: '\uf154',
  shooting: '\uf155',
  shot_put: '\uf156',
  shuffleboard: '\uf157',
  skateboarding: '\uf158',
  ski: '\uf159',
  snowboarding: '\uf15a',
  softball: '\uf15b',
  squash: '\uf15c',
  surfing: '\uf15d',
  swimming: '\uf15e',
  switch: '\uf15f',
  table_tennis: '\uf160',
  tamburello: '\uf161',
  teams: '\uf185',
  tennis: '\uf162',
  tug_of_war: '\uf163',
  ultimate_frisbee: '\uf164',
  underwater_hockey: '\uf165',
  volleyball: '\uf166',
  vx: '\uf167',
  walking: '\uf168',
  water_polo: '\uf169',
  waterskiing: '\uf16a',
  weightlifting: '\uf16b',
  wheelchair: '\uf16c',
  wind_surfing: '\uf16d',
  wrestling: '\uf16e',
  yachting: '\uf16f',
  yoga: '\uf170',
  zumba: '\uf171',
  age: '\uf172',
  celebration: '\uf173',
  clap: '\uf174',
  fees: '\uf175',
  venus: '\uf176',
  genders: '\uf177',
  mail: '\uf178',
  mars: '\uf179',
  malefemale: '\uf17a',
  'payment-failed': '\uf17b',
  pin: '\uf17c',
  price: '\uf17d',
  smiley: '\uf17e',
  ticket: '\uf17f',
  transfer: '\uf180',
  whistle: '\uf181',
  wallet: '\uf182',
  time_machine: '\uf184',
}

const OrfiIcon = createIconSet(glyphMap, 'OrfiIcons', '../assets/fonts/OrfiIcons.ttf')

export default OrfiIcon
