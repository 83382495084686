import { Platform, StyleSheet } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const common = StyleSheet.create({
  bold: {
    ...Platform.select({
      android: {
        ...fonts.android.bold,
      },
      default: {
        fontWeight: 'bold',
      },
    }),
  },
})

const styles = StyleSheet.create({
  text: {
    ...Platform.select({
      android: {
        ...fonts.android.regular,
      },
    }),
    fontFamily: fonts.default.regular,
  },
  labelStyle: {
    color: colors.black,
    fontSize: 12,
    ...common.bold,
  },
  linkStyle: {},
})

export default styles
