import { StyleSheet, useWindowDimensions } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const useStyle = () => {
  const { height, width } = useWindowDimensions()

  const buttonWidth = (width * 0.8 - 40 - 10) / 2

  const styles = StyleSheet.create({
    container: {
      backgroundColor: '#1119',
      alignItems: 'center',
      zIndex: 1000,
    },
    content: {
      width: '80%',
      borderRadius: 6,
      padding: 20,
      paddingTop: 50,
      shadowOffset: { width: 2, height: 2 },
      shadowColor: 'black',
      shadowOpacity: 0.2,
      backgroundColor: colors.white,
      marginTop: height / 4,
      alignItems: 'center',
    },
    shareText: {
      fontSize: 12,
      marginTop: 8,
      color: colors.primary,
    },
    shareContainer: {
      alignSelf: 'center',
      marginVertical: 10,
      alignItems: 'center',
      justifyContent: 'center',
    },
    texts: {
      paddingHorizontal: 10,
      alignItems: 'center',
    },
    icon: {
      marginVertical: 30,
    },
    title: {
      color: colors.secondary,
      fontSize: 12,
      fontFamily: fonts.default.bold,
      textAlign: 'center',
    },
    comment: {
      marginHorizontal: 10,
      color: colors.secondary,
      fontSize: 12,
      textAlign: 'center',
      marginVertical: 20,
      lineHeight: 17,
    },
    buttonStyle: {
      borderRadius: 23,
      height: 42,
      backgroundColor: colors.secondary,
      alignItems: 'center',
      paddingVertical: 0,
    },
    gradientButtonStyle: {
      borderRadius: 23,
      height: 42,
      backgroundColor: colors.secondary,
      justifyContent: 'center',
      alignItems: 'center',
      paddingVertical: 0,
    },
    buttonText: {
      color: colors.white,
      fontSize: 10,
      fontFamily: fonts.default.semiBold,
      textAlign: 'center',
    },
    buttonContainer: {
      borderRadius: 23,
      height: 35,
      justifyContent: 'center',
      alignItems: 'center',
      width: buttonWidth,
      marginRight: 10,
    },
    button: { width: '45%' },
    gradientButtonContainer: {
      height: 35,
      width: buttonWidth,
      justifyContent: 'center',
      alignItems: 'center',
    },
    gradientButtonText: {
      fontSize: 10,
      fontFamily: fonts.default.semiBold,
    },
    gradientButton: { width: '45%' },
    buttons: {
      justifyContent: 'space-between',
      width: '100%',
      marginTop: 20,
    },
  })

  return styles
}
export default useStyle
