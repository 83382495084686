import React, { useEffect, useState } from 'react'
import {
  ActivityIndicator,
  Alert,
  BackHandler,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'

import { getPayment } from './banked'
import ProviderList from './ProviderList'
import ProviderLoading from './ProviderLoading'
import { BACK, BRAND, CANCEL, HEADER_SUBTITLE } from './strings'

const AWAITING_PAYMENT_CONSENT = 'awaiting_payment_consent'
const AWAITING_PROVIDER = 'awaiting_provider'

const Checkout = (props) => {
  const [payment, setPayment] = useState({})
  const [errorMessage, setErrorMessage] = useState('')
  const [currentProviderId, setCurrentProviderId] = useState(undefined)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const backHandler = BackHandler.addEventListener('hardwareBackPress', handleBackButtonClick)
    return () => backHandler.remove()
  }, [])

  useEffect(() => {
    getPaymentApi()
  }, [])

  const getPaymentApi = async () => {
    try {
      const data = await getPayment(props.paymentId, props.apiKey)
      if (data.state !== AWAITING_PAYMENT_CONSENT && data.state !== AWAITING_PROVIDER) {
        setPayment({ state: 'error' })
        setErrorMessage('Payment has been paid')
      } else {
        setPayment({
          id: data.id,
          lineItems: data.line_items,
          payee: data.payee,
          payerDetailRequired: data.payer_details_required,
          amount: data.amount,
          amountFormatted: data.amount_formatted,
          providerBrands: data.provider_brands,
          state: data.state,
          termsAndConditionsUrl: data.terms_and_conditions,
          currency: data.currency,
          endToEndId: data.end_to_end_id,
          affiliateId: data.affiliate_id,
          logo: data.logo,
        })
      }
    } catch (error) {
      setPayment({ ...payment, state: 'error' })
      setErrorMessage(error.message)
    } finally {
      setLoading(false)
    }
  }

  const currentProviderBrand = (currentProviderId) => {
    const { providerBrands } = payment
    const list = providerBrands?.find((providerBrand) =>
      providerBrand?.providers?.map((provider) => provider.id).includes(currentProviderId),
    )
    return list
  }

  const currentProvider = (currentProviderId) =>
    currentProviderBrand(currentProviderId)?.providers?.find(
      (provider) => provider.id === currentProviderId,
    )

  const chooseProvider = (id) => {
    const newProvider = currentProvider(id)
    if (newProvider.status === 'UNAVAILABLE') {
      Alert.alert(newProvider.status_detail)
    } else {
      setCurrentProviderId(id)
    }
  }

  const cancelProvider = () => {
    setCurrentProviderId(undefined)
  }

  const providerSelected = () => currentProviderId !== undefined

  const handleBackButtonClick = () => {
    if (providerSelected()) {
      cancelProvider()
    } else {
      props.onCancel()
    }
    return true
  }

  if (loading) {
    return (
      <View style={styles.centeredContainer}>
        <ActivityIndicator size="large" />
      </View>
    )
  }

  if (errorMessage) {
    return (
      <View style={styles.container}>
        <TouchableOpacity onPress={props.onCancel}>
          <Text style={styles.cancel}>{CANCEL}</Text>
        </TouchableOpacity>
        <View style={styles.centeredContainer}>
          <Text style={styles.subtitle}>{errorMessage}</Text>
        </View>
      </View>
    )
  }
  if (payment) {
    return (
      <View style={styles.container}>
        <View style={styles.header}>
          <View style={styles.innerHeader}>
            {!providerSelected() ? (
              <Text style={styles.brand}>{BRAND}</Text>
            ) : (
              <TouchableOpacity onPress={cancelProvider}>
                <Text style={styles.brand}>{BACK}</Text>
              </TouchableOpacity>
            )}
            <TouchableOpacity onPress={props.onCancel}>
              <Text style={styles.subtitle}>{CANCEL}</Text>
            </TouchableOpacity>
          </View>
          <Text style={styles.title}>Pay {payment.amountFormatted}</Text>
          <Text style={styles.subtitle}>{HEADER_SUBTITLE}</Text>
        </View>
        {!providerSelected() ? (
          <ProviderList providerBrands={payment.providerBrands} chooseProvider={chooseProvider} />
        ) : (
          <ProviderLoading
            paymentId={props.paymentId}
            providerId={currentProviderId}
            onCancel={cancelProvider}
            providerBrand={currentProviderBrand(currentProviderId)}
            amountFormatted={payment.amountFormatted}
            termsAndConditionsUrl={payment.termsAndConditionsUrl}
            apiKey={props.apiKey}
          />
        )}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FAFAFA',
    width: '100%',
  },
  header: {
    borderBottomColor: '#EEEEEE',
    borderBottomWidth: 1,
    marginBottom: 0,
    marginLeft: '5%',
    marginRight: '5%',
    width: '90%',
  },
  innerHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  brand: {
    color: '#292929',
    fontFamily: 'Hermes-RegularCond',
    fontSize: 14,
  },
  title: {
    marginTop: 30,
    marginBottom: 5,
    color: '#292929',
    fontSize: 14,
  },
  subtitle: {
    marginBottom: 15,
    color: '#989898',
    fontFamily: 'Hermes-RegularCond',
    fontSize: 14,
  },
  centeredContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  cancel: {
    marginRight: '5%',
    color: '#989898',
    fontFamily: 'Hermes-RegularCond',
    fontSize: 14,
    textAlign: 'right',
  },
})
export default Checkout
