import { useRoute } from '@react-navigation/native'
import { useNavigation } from '@react-navigation/native'
import { Input } from '@rneui/themed'
import { FC, useEffect } from 'react'
import { FlatList, View, Pressable } from 'react-native'

import EmptyNotice from '@src/components/EmptyNotice'
import Loading from '@src/components/Loading'
import WallItem from '@src/components/WallItem'
import { colors } from '@src/config/theme'
import { useWallPostsQuery } from '@src/graphql/types'
import { DashboardWallScreenNavigationProps, DashboardWallScreenRouteProps } from '@src/types'
import isDefined from '@src/utils/isDefined'

import styles from './DashboardWallScreen.styles'

const DashboardWallScreen: FC = () => {
  const navigation = useNavigation<DashboardWallScreenNavigationProps>()
  const {
    params: { groupUuid },
  } = useRoute<DashboardWallScreenRouteProps>()
  const {
    data: { wallPosts = [] } = {},
    loading,
    fetchMore,
    refetch,
    startPolling,
    stopPolling,
  } = useWallPostsQuery({
    variables: { groupUuid, parentUuid: undefined, offset: 0, limit: 5 },
  })

  useEffect(() => {
    startPolling(15000)
    return () => {
      stopPolling()
    }
  }, [startPolling, stopPolling])

  //  Remove null or undefined entries from the wallPosts array
  const filteredPosts = wallPosts.filter(isDefined)
  const fetchMoreData = () => {
    return fetchMore({ variables: { offset: wallPosts.length } })
  }

  if (loading && !wallPosts[0]) return <Loading />

  return (
    <View style={styles.container}>
      <FlatList
        data={filteredPosts}
        keyExtractor={(post) => post.uuid}
        onRefresh={refetch}
        refreshing={false}
        onEndReachedThreshold={0.5}
        onEndReached={fetchMoreData}
        renderItem={({ item }) => <WallItem post={item} refetch={refetch} />}
        ListEmptyComponent={
          <EmptyNotice
            title="NOTHING TO SHOW"
            text={"This is where you'll find posts on the group's wall!"}
          />
        }
      />
      <View style={styles.outerInputContainer}>
        <Pressable
          onPress={() => navigation.navigate('DashboardWallCommentReplyScreen', { groupUuid })}>
          <Input
            placeholder="Add a comment"
            placeholderTextColor={colors.black}
            editable={false}
            inputContainerStyle={styles.inputContainer}
            inputStyle={styles.inputStyle}
            multiline
            pointerEvents="none"
          />
        </Pressable>
      </View>
    </View>
  )
}

export default DashboardWallScreen
