import Constants from 'expo-constants'
import { StyleSheet, Platform } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  content: {
    flex: 1,
  },
  icon: {
    opacity: 0.5,
    width: 25,
    lineHeight: 20,
  },
  title: {
    marginTop: 5,
    color: colors.black,
    fontSize: 14,
    fontFamily: fonts.default.semiBold,
  },
  delButton: {
    marginTop: 30,
    opacity: 0.25,
    width: 120,
    marginLeft: 10,
  },
  delButtonTitle: {
    fontSize: 14,
    color: colors.secondary,
  },
  listItemContainer: {
    padding: 20,
  },
  header: {
    height: 50,
    paddingHorizontal: 20,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: Platform.select({ ios: 0, android: Constants.statusBarHeight }),
    borderBottomWidth: 1,
    borderBottomColor: colors.greyOutline,
    position: 'relative',
  },
  headerTitle: {
    fontSize: 14,
    // fontWeight: 'bold',
    fontFamily: fonts.default.bold,
    color: colors.secondary,
    textAlign: 'center',
  },
  headerRight: {
    flexDirection: 'row',
  },
  contentContainerStyle: {
    borderBottomWidth: 0,
    paddingBottom: 0,
  },
  versionContainer: {
    width: '100%',
    padding: 12,
    alignItems: 'center',
  },
  versionText: {
    fontWeight: 'bold',
    color: colors.grey,
  },
})

export default styles
