import { useNavigation, useRoute } from '@react-navigation/native'
import { useState } from 'react'
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete'
import { View, SafeAreaView } from 'react-native'

import getEnvVars from '@src/config/environment'
import { SelectVenueScreenNavigationProps, SelectVenueScreenRouteProps } from '@src/types'

import useStyle from './SelectVenueScreen.styles'
import { LocationType } from './types'

const SelectVenueScreen = () => {
  const styles = useStyle()
  const navigation = useNavigation<SelectVenueScreenNavigationProps>()
  const {
    params: { backRoute },
  } = useRoute<SelectVenueScreenRouteProps>()
  const [venueSearch, setVenueSearch] = useState('')
  const { autocompleteKey } = getEnvVars()

  const goBack = async (result) => {
    setVenueSearch(result)
    const addressResult = await geocodeByAddress(result.label)
    const { lat, lng } = await getLatLng(addressResult[0])

    const venueAddress: LocationType = {
      address: result.label,
      latitude: lat,
      longitude: lng,
      countryCode: 'GB',
    }

    navigation.navigate(backRoute, { venueAddress })
  }

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.webContainer}>
        <GooglePlacesAutocomplete
          apiKey={autocompleteKey}
          apiOptions={{
            language: 'en',
            region: 'GB',
          }}
          autocompletionRequest={{
            componentRestrictions: {
              country: ['gb'],
            },
          }}
          selectProps={{
            value: venueSearch,
            onChange: goBack,
          }}
          debounce={200}
          minLengthAutocomplete={3}
        />
      </View>
    </SafeAreaView>
  )
}

export default SelectVenueScreen
