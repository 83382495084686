import { Icon } from '@rneui/themed'
import PropTypes from 'prop-types'
import React from 'react'
import { Dimensions, Modal, StyleSheet, TouchableWithoutFeedback, View } from 'react-native'

import DualButton from '@src/components/DualButton'
import Text from '@src/components/Text'
import { colors, fonts } from '@src/config/theme'

const { height, width } = Dimensions.get('screen')

const NoWalletAccountPopup = ({ visible, toggle, onSetUpAccount }) => (
  <Modal transparent visible={visible} animationType="none" onRequestClose={toggle}>
    <TouchableWithoutFeedback onPress={toggle}>
      <View style={{ ...StyleSheet.absoluteFill, ...styles.container }}>
        <TouchableWithoutFeedback>
          <View style={styles.content}>
            <Icon
              color={colors.secondary}
              size={40}
              containerStyle={styles.icon}
              type="orfi"
              name="wallet"
              iconStyle={styles.iconStyle}
            />
            <View style={styles.texts}>
              <Text style={styles.title}>ADD BANK DETAILS</Text>
              <Text style={styles.comment}>
                We’d love to help you take payments, but we don’t have your bank details! Set that
                up in your wallet to get cracking.
              </Text>
            </View>

            <View style={styles.buttons}>
              <DualButton
                leftTitle="Back"
                rightTitle="Add"
                leftOnPress={toggle}
                rightOnPress={() => {
                  onSetUpAccount()
                  toggle()
                }}
              />
            </View>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </TouchableWithoutFeedback>
  </Modal>
)

const buttonWidth = (width * 0.8 - 60 - 10) / 2

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#1119',
    alignItems: 'center',
    zIndex: 1000,
  },
  content: {
    width: '80%',
    borderRadius: 6,
    padding: 20,
    shadowOffset: { width: 2, height: 2 },
    shadowColor: 'black',
    shadowOpacity: 0.2,
    backgroundColor: colors.white,
    marginTop: height / 5,
    alignItems: 'center',
  },
  texts: {
    paddingHorizontal: 10,
    alignItems: 'center',
  },
  icon: {
    marginVertical: 30,
  },
  title: {
    color: colors.secondary,
    fontSize: 13,
    fontFamily: fonts.default.bold,
    textAlign: 'center',
  },
  comment: {
    margin: 10,
    color: colors.secondary,
    fontSize: 10,
    textAlign: 'center',
    marginVertical: 20,
    lineHeight: 17,
  },
  buttonStyle: {
    borderRadius: 23,
    height: 42,
    width: '100%',
    backgroundColor: colors.secondary,
    alignItems: 'center',
    paddingVertical: 0,
  },
  gradientButtonStyle: {
    borderRadius: 23,
    height: 42,
    width: '100%',
    backgroundColor: colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 0,
  },
  buttonText: {
    color: colors.white,
    fontSize: 12,
    fontFamily: fonts.default.semiBold,
    textAlign: 'center',
  },
  buttonContainer: {
    borderRadius: 23,
    height: 35,
    justifyContent: 'center',
    alignItems: 'center',
    width: buttonWidth,
    marginRight: 10,
  },
  button: { width: buttonWidth },
  gradientButtonContainer: {
    height: 35,
    width: buttonWidth,
    justifyContent: 'center',
    alignItems: 'center',
  },
  gradientButtonText: {
    fontSize: 12,
    fontFamily: fonts.default.semiBold,
  },
  gradientButton: {
    width: buttonWidth,
  },
  buttons: {
    justifyContent: 'space-between',
    marginTop: 20,
  },
  iconStyle: {
    lineHeight: 40,
  },
})

NoWalletAccountPopup.propTypes = {
  visible: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSetUpAccount: PropTypes.func.isRequired,
}

export default NoWalletAccountPopup
