/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { loadAsync } from 'expo-font'
import * as SplashScreen from 'expo-splash-screen'
import { useContext, useEffect, useState } from 'react'

import { AuthContext } from '@src/context/AuthContext'

function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = useState(false)
  const { isInitialised } = useContext(AuthContext)

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync()

        // Load fonts
        await loadAsync({
          OrfiIcons: require('@src/assets/fonts/OrfiIcons.ttf'),
          'SourceSansPro-Light': require('@src/assets/fonts/SourceSansPro-Light.ttf'),
          'SourceSansPro-Regular': require('@src/assets/fonts/SourceSansPro-Regular.ttf'),
          'SourceSansPro-Bold': require('@src/assets/fonts/SourceSansPro-Bold.ttf'),
          'SourceSansPro-SemiBold': require('@src/assets/fonts/SourceSansPro-Semibold.ttf'),
          'SourceSansPro-Italic': require('@src/assets/fonts/SourceSansPro-It.ttf'),
          'Hermes-RegularCond': require('@src/assets/fonts/Hermes-RegularCond.otf'),
          'Hermes-LightCond': require('@src/assets/fonts/Hermes-LightCond.otf'),
        })
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e)
      } finally {
        setLoadingComplete(true)
      }
    }

    loadResourcesAndDataAsync()
  }, [])

  useEffect(() => {
    if (isInitialised && isLoadingComplete) {
      SplashScreen.hideAsync()
    }
  }, [isInitialised, isLoadingComplete])

  return isLoadingComplete
}

export default useCachedResources
