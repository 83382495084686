import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  accountName: Yup.string()
    .matches(/^([\w-]{2,})+\s+([\w\s-]{2,})+$/i, {
      message: 'Enter the exact name on your account',
      excludeEmptyString: true,
    })
    .required('Account Name is required'),
  accountNumber: Yup.string()
    .min(8, 'The account number should contain 8 digits')
    .max(8, 'The account number should contain 8 digits')
    .required('Account Number is required'),
  accountSortCode: Yup.string()
    .min(8, 'Minimum of 6  characters')
    .max(8, 'Maximum of 6  characters')
    .required('Sort Code is required'),
})

export default validationSchema
