import { Button } from '@rneui/themed'
import PropTypes from 'prop-types'
import React from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'

import OrfiAvatar from '@src/components/OrfiAvatar'
import Text from '@src/components/Text'
import { colors, fonts } from '@src/config/theme'

const ParticipantsList = ({ data = [], isOwner, session, userActions }) => {
  const participants = data.map((p) => ({
    user: p.user,
    paymentMethod: p.paymentMethod,
    owner: p.owner,
  }))

  const paymentStatus = (payment) => {
    if (payment && session.group.isAdmin) {
      if (payment === 'APP') return 'paid'
      return 'cash'
    }
    return null
  }

  return (
    <View style={styles.wrapper}>
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.scrollViewContainer}>
        {session?.group?.isAdmin && session.isJoinable && (
          <View style={styles.joinView}>
            <View style={styles.joinViewSub}>
              <Button
                icon={{
                  type: 'feather',
                  name: 'plus',
                  size: 24,
                  color: colors.primary,
                }}
                onPress={userActions}
                type="clear"
              />
            </View>
            <Text style={[styles.name, { color: colors.primary }]}>{'Join\nSession'}</Text>
          </View>
        )}
        {participants.map((p, idx) => {
          const name = p.user.name.split(' ')[0]
          const payment = p.paymentMethod
          return (
            <View key={p.user.id} style={styles.container}>
              <View style={styles.avatarContainer}>
                <View style={styles.avatarInnerContainer}>
                  <OrfiAvatar
                    size="medium"
                    uri={p.user.avatar}
                    title={`${name[0]}${name[1]}`}
                    bottomLabel={paymentStatus(payment)}
                    raised
                  />
                </View>
                <Text style={styles.name}>
                  {name}
                  {p?.owner ? '\n(Organiser)' : ''}
                </Text>
              </View>
            </View>
          )
        })}
        {participants.length < 20 && (
          <View style={styles.addView}>
            <Button
              icon={{
                type: 'feather',
                name: 'plus',
                size: 16,
                color: '#6A6A6A',
              }}
              onPress={userActions}
              type="clear"
            />
          </View>
        )}
        {participants.length < 20 && (
          <View style={styles.addView}>
            <Button
              icon={{
                type: 'feather',
                name: 'plus',
                size: 16,
                color: '#6A6A6A',
              }}
              onPress={userActions}
              type="clear"
            />
          </View>
        )}
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    justifyContent: 'center',
  },
  container: {
    marginRight: 10,
    alignItems: 'center',
  },
  joinView: {
    alignItems: 'center',
    marginRight: 10,
  },
  joinViewSub: {
    width: 55,
    height: 55,
    borderRadius: 35,
    borderStyle: 'dashed',
    justifyContent: 'center',
    borderColor: colors.primary,
    borderWidth: 2,
    marginRight: 0,
  },
  addView: {
    marginRight: 10,
    width: 55,
    height: 55,
    borderRadius: 35,
    borderStyle: 'dashed',
    borderColor: '#6A6A6A',
    borderWidth: 1,
    justifyContent: 'center',
  },
  viewAll: {
    width: 70,
    height: 70,
    borderRadius: 35,
    borderColor: colors.primary,
    borderWidth: 1,
    justifyContent: 'center',
  },
  name: {
    textAlign: 'center',
    marginTop: 5,
    fontSize: 11,
    fontFamily: fonts.default.bold,
  },
  payment: {
    width: 40,
    height: 20,
    borderRadius: 10,
    position: 'absolute',
    bottom: 5,
    right: -10,
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 20,
  },
  paymentText: {
    textAlign: 'center',
    color: colors.white,
    fontFamily: fonts.default.bold,
    fontSize: 10,
  },
  scrollViewContainer: {
    paddingVertical: 15,
  },
  avatarContainer: {
    alignItems: 'center',
  },
  avatarInnerContainer: {
    width: 55,
    height: 55,
  },
})

ParticipantsList.propTypes = {
  data: PropTypes.array.isRequired,
  isOwner: PropTypes.bool.isRequired,
  userActions: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired,
}

export default ParticipantsList
