import { useNavigation, useRoute } from '@react-navigation/native'
import { ListItem, SearchBar } from '@rneui/themed'
import orderBy from 'lodash/orderBy'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FlatList, View } from 'react-native'

import Loading from '@src/components/Loading'
import { useActivitesQuery } from '@src/graphql/types'
import { SelectActivityScreenNavigationProps, SelectActivityScreenRouteProps } from '@src/types'

import styles from './SelectActivityScreen.styles'

const SelectActivityScreen = () => {
  const navigation = useNavigation<SelectActivityScreenNavigationProps>()
  const { params } = useRoute<SelectActivityScreenRouteProps>()
  const { loading, data } = useActivitesQuery()

  const [activityData, setActivityData] = useState([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (data) {
      const ordered = orderBy(data?.activities, ['featured', 'name'])
      setActivityData(ordered)
    }
  }, [data])

  if (loading) return <Loading />

  const { backRoute } = params
  const searchString = search.toLowerCase()

  const activities = (activityData || []).filter(
    (activity) => activity.name.toLowerCase().search(searchString) !== -1,
  )

  return (
    <View style={styles.container}>
      <SearchBar placeholder="Search..." onChangeText={setSearch} value={search} />
      <FlatList
        data={activities}
        keyExtractor={(item, index) => `key-${index}`}
        renderItem={({ item }) => (
          <ListItem
            onPress={() => {
              navigation.navigate(`${backRoute}`, { activity: item })
            }}
            bottomDivider>
            <ListItem.Content style={styles.activityItem}>
              <ListItem.Title style={styles.activityName}>{item.name}</ListItem.Title>
            </ListItem.Content>
            <ListItem.Chevron />
          </ListItem>
        )}
      />
    </View>
  )
}

SelectActivityScreen.propTypes = {
  route: PropTypes.object.isRequired,
}

export default SelectActivityScreen
