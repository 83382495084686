import { StyleSheet } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    flex: 1,
  },
  tabGrid: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 75,
  },
  tabButtonClear: {
    fontFamily: fonts.default.bold,
    textTransform: 'uppercase',
    color: colors.secondary,
    fontSize: 9,
    marginTop: 3,
    paddingHorizontal: 3,
  },
  tabButton: (width) => ({
    width: width / 5,
    height: 75,
    padding: 4,
    justifyContent: 'center',
    alignItems: 'center',
    borderLeftWidth: 0.5,
    borderRightWidth: 0.5,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: colors.grey,
  }),
  emptyTitle: {
    fontSize: 17,
    fontFamily: fonts.default.bold,
    textTransform: 'uppercase',
    marginBottom: 15,
    color: colors.secondary,
  },
  emptyDesc: {
    textAlign: 'center',
    color: colors.secondary,
  },
  listHeaderTitleVwSession: {
    paddingHorizontal: 16,
    marginTop: 45,
    marginBottom: -5,
    alignItems: 'center',
  },
  listHeaderTitleVw: {
    paddingHorizontal: 16,
    marginTop: 85,
    marginBottom: 5,
    alignItems: 'center',
  },
  listHeaderTitle: {
    color: colors.secondary,
    fontFamily: fonts.default.bold,
    textTransform: 'uppercase',
    fontSize: 15,
    paddingBottom: 7,
  },
  bottomButtonContainer: {
    paddingTop: 10,
    paddingBottom: 20,
    paddingHorizontal: 55,
  },
  dualButtonContainer: {
    paddingTop: 10,
    paddingBottom: 20,
  },
  flexCenter: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  participants: {
    color: colors.primary,
  },
  buttonContainerStyle: {
    flex: 1,
    marginHorizontal: 4,
  },
  innerButtonContainer: {
    width: '90%',
    alignSelf: 'center',
    paddingTop: 10,
  },
  sessionButtonContainer: {
    width: '70%',
    alignSelf: 'center',
    marginTop: 40,
  },
  centeredText: {
    textAlign: 'center',
  },
  flatListTextContainer: {
    alignItems: 'center',
    marginTop: 10,
  },
  firstNewSessionButton: {
    width: '70%',
    marginTop: 40,
  },
  flatListContentContainer: {
    paddingBottom: 150,
  },
  iconStyle: {
    color: colors.secondary,
  },
})

export default styles
