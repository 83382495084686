import { useNavigation, useRoute } from '@react-navigation/native'
import { Icon } from '@rneui/themed'
import moment from 'moment'
import React from 'react'
import { SectionList, View } from 'react-native'

import EmptyNotice from '@src/components/EmptyNotice'
import OrfiListItem from '@src/components/ListItem/OrfiListItem'
import Loading from '@src/components/Loading'
import { colors } from '@src/config/theme'
import { useGroupQuery } from '@src/graphql/types'
import {
  DashboardPassedSessionScreenNavigationProps,
  DashboardPassedSessionScreenRouteProps,
} from '@src/types'
import getInitials from '@src/utils/getInitials'

import styles from './DashboardPassedSessionScreen.styles'

const DashboardPassedSessionScreen = () => {
  const navigation = useNavigation<DashboardPassedSessionScreenNavigationProps>()
  const { params } = useRoute<DashboardPassedSessionScreenRouteProps>()

  const { loading, data: groupData } = useGroupQuery({
    variables: {
      groupUuid: params?.groupUuid,
    },
  })

  if (loading) return <Loading />

  const formatSubtitle = ({ startAt }) => {
    const weekday = moment(startAt).format('dddd')
    const day = moment(startAt).format('DD')
    const month = moment(startAt).format('MMM')
    const startTime = moment(startAt).format('HH:mm')
    return `${weekday}, ${day} ${month}, ${startTime}`
  }

  const onPressSession = (sessionId) => {
    navigation.navigate('SessionDetailScreen', { sessionId })
  }

  const { group } = groupData
  const { sessions } = group

  const passedSessions = sessions.filter((session) => moment().isAfter(session.startAt))
  const lists = passedSessions.length
    ? [
        {
          title: 'Passed',
          index: 1,
          data: passedSessions,
        },
      ]
    : []

  const passedSessionCTA = () => (
    <Icon name="chevron-right" type="feather" color={colors.grey} size={20} />
  )

  const renderItem = ({ item }) => (
    <OrfiListItem
      status="inactive"
      avatarUri={item?.image || item?.activity[0]?.defaultBannerImage}
      avatarPlaceholder={getInitials(item?.owner?.name)}
      title={item?.name}
      subtitle={formatSubtitle(item)}
      bottomText={item?.address}
      onPress={() => onPressSession(item.id)}
      callToAction={passedSessionCTA()}
      divider
    />
  )

  const emptySessionsComponent = () => (
    <View style={styles.emptySectionList}>
      <EmptyNotice
        title="NOTHING TO SHOW"
        text="This is a brand new group with no past sessions just yet."
        icon={{ name: 'info', type: 'feather' }}
      />
    </View>
  )

  return (
    <View style={styles.container}>
      <SectionList
        sections={lists}
        keyExtractor={(item) => item.id}
        renderItem={renderItem}
        contentContainerStyle={styles.sectionListContainer}
        ListEmptyComponent={emptySessionsComponent}
      />
    </View>
  )
}

export default DashboardPassedSessionScreen
