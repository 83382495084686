import { StyleSheet } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  content: {
    paddingBottom: 150,
  },
  participantView: {
    alignSelf: 'center',
    justifyContent: 'center',
    marginTop: 30,
    marginBottom: 20,
    width: '90%',
  },
  topShadow: {
    position: 'absolute',
    zIndex: 100,
    top: -5,
    height: 35,
    width: '100%',
  },
  addressView: {
    flexDirection: 'row',
    paddingVertical: 20,
    paddingHorizontal: 20,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addressText: {
    fontFamily: fonts.default.bold,
    color: colors.secondary,
  },
  orgTitle: {
    color: colors.secondary,
    fontSize: 21,
    fontFamily: fonts.default.bold,
    textAlign: 'center',
    width: '85%',
  },
  date: {
    color: colors.primary,
    fontSize: 13,
    fontFamily: fonts.default.bold,
    marginBottom: 15,
    width: '80%',
    textAlign: 'center',
  },
  orgBy: {
    fontSize: 14,
    fontFamily: fonts.default.bold,
    color: colors.secondary,
    marginTop: 3,
  },
  bottomButtonContainer: {
    backgroundColor: colors.white,
    alignItems: 'center',
    paddingBottom: 10,
    paddingTop: 4,
  },
  buttonContainer: {
    borderRadius: 23,
    height: 46,
    shadowOffset: { width: 2, height: 2 },
    shadowColor: 'black',
    elevation: 4,
    shadowOpacity: 0.2,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    borderRadius: 23,
    height: 46,
    padding: 0,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: colors.white,
  },
  ownerButtonView: {
    // width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginBottom: 10,
    marginTop: 10,
    width: '100%',
  },
  ownerButtonView2: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  loadingModal: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  cancelView: {
    marginTop: 60,
    paddingHorizontal: 20,
  },
  cancelReason: {
    textAlign: 'center',
    color: colors.grey7,
    fontFamily: fonts.default.bold,
  },
  headerButtonStyle: {
    paddingHorizontal: 5,
  },
  headerContainer: {
    flexDirection: 'row',
  },
  profileForm: {
    width: 40,
    height: 40,
    right: 0,
  },
  sessionImage: {
    height: 200,
    width: '100%',
  },
  participantsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 'auto',
  },
  participantsText: {
    fontSize: 18,
  },
  participantsAttending: {
    fontFamily: fonts.default.bold,
  },
  participantsButton: {
    textDecorationLine: 'underline',
  },
  addressTextContainer: {
    flex: 7,
  },
  addressSpacer: {
    flex: 1,
  },
  buttonDivider: {
    backgroundColor: colors.grey,
  },
  buttonTitle: {
    color: colors.black,
  },
  modalStyle: {
    padding: 0,
  },
  groupButton: {
    width: '45%',
  },
  mapButton: {
    padding: 0,
  },
  futureSessionText: {
    color: colors.secondary,
    fontSize: 16,
    fontFamily: fonts.default.bold,
    marginBottom: 15,
    marginTop: 20,
    paddingHorizontal: 20,
    textTransform: 'uppercase',
  },
  futureSessionPressable: {
    borderWidth: 1,
    borderColor: colors.primary,
    borderRadius: 26,
    width: 175,
    height: 52,
    marginRight: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  futureSessionDate: {
    color: colors.primary,
    fontFamily: fonts.default.bold,
    fontSize: 15,
  },
  futureSessionTime: {
    color: colors.primary,
  },
  futureSessionScrollView: {
    paddingLeft: 20,
  },
  cancelSessionButton: {
    borderRadius: 16,
    height: 40,
    paddingHorizontal: 20,
    backgroundColor: colors.secondary,
  },
})

export default styles
