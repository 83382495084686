import { createTheme } from '@rneui/themed'
import { moderateScale } from 'react-native-size-matters'

const fonts = {
  android: {
    regular: {
      fontFamily: 'SourceSansPro-Regular', // 'sans-serif',
    },
    light: {
      fontFamily: 'SourceSansPro-Light', // 'sans-serif-light',
    },
    condensed: {
      fontFamily: 'SourceSansPro-Regular',
    },
    condensed_light: {
      fontFamily: 'SourceSansPro-Light',
    },
    black: {
      // note(brentvatne): sans-serif-black is only supported on Android 5+,
      // we can detect that here and use it in that case at some point.
      // fontFamily: 'sans-serif',
      // fontWeight: 'bold',
      fontFamily: 'SourceSansPro-Regular',
    },
    thin: {
      fontFamily: 'SourceSansPro-Light', // 'sans-serif-thin',
    },
    medium: {
      fontFamily: 'SourceSansPro-Regular',
    },
    bold: {
      fontFamily: 'SourceSansPro-Bold',
    },
  },
  default: {
    bold: 'SourceSansPro-Bold',
    semiBold: 'SourceSansPro-SemiBold',
    regular: 'SourceSansPro-Regular',
    light: 'SourceSansPro-Light',
    italic: 'SourceSansPro-Italic',
  },
}

const colors = {
  primary: '#F91874',
  primaryOpc: '#F9187499',
  white: '#fff',
  white1: '#ebebec',
  success: '#07CC0E',
  error: '#FF5F56',
  red: '#FF0E00',
  lightBlue: '#EDF1FA',
  secondary: '#292C34',
  black: '#000',
  black1: '#352641',
  grey: '#ccc',
  grey1: '#0b0c0e',
  grey2: '#22242b',
  grey3: '#383c47',
  grey4: '#656d80',
  grey5: '#7f869a',
  grey6: '#c7c8cc',
  grey7: '#707070',
  grey8: '#F0EFEF',
  grey9: '#949599',
  greyOutline: '#EAEAEA',
}

const theme = createTheme({
  lightColors: colors,
  components: {
    Avatar: {
      overlayContainerStyle: {
        backgroundColor: '#F4F4F4',
      },
      placeholderStyle: {
        backgroundColor: '#F4F4F4',
      },
      titleStyle: {
        color: '#CFD0D1',
        fontSize: 14,
      },
    },
    Button: {
      buttonStyle: {
        borderRadius: 16,
        height: 40,
        paddingHorizontal: 20,
      },
      titleStyle: {
        fontFamily: fonts.default.bold,
        fontSize: 14,
      },
    },
    Input: {
      containerStyle: {
        marginVertical: moderateScale(15),
      },
      inputContainerStyle: {
        borderColor: colors.greyOutline,
      },
      inputStyle: {
        marginLeft: 10,
        fontSize: 14,
        fontFamily: fonts.default.regular,
      },
      rightIconContainerStyle: {
        paddingRight: 10,
      },
      labelStyle: {
        color: colors.secondary,
        marginBottom: 10,
        fontSize: 12,
        fontFamily: fonts.default.bold,
        fontWeight: 'normal',
        textTransform: 'uppercase',
      },
      keyboardAppearance: 'light',
      blurOnSubmit: false,
    },
    SearchBar: {
      containerStyle: {
        backgroundColor: colors.primary,
      },
      inputContainerStyle: {
        backgroundColor: colors.white,
      },
    },
    ListItem: {},
    ListItemCheckBox: {
      checkedColor: '#03D20A',
      iconType: 'material-community',
      checkedIcon: 'check-circle',
      uncheckedIcon: 'checkbox-blank-circle-outline',
      size: 32,
    },
    CheckBox: {
      containerStyle: {
        backgroundColor: 'transparent',
        borderWidth: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: 0,
        marginRight: 16,
        marginBottom: 0,
        paddingTop: 0,
      },
      textStyle: {
        fontSize: 14,
        fontFamily: fonts.default.bold,
        color: colors.secondary,
      },
      wrapperStyle: {
        justifyContent: 'space-between',
      },
    },
    Text: {
      style: {
        fontFamily: fonts.default.regular,
      },
    },
    Icon: {
      iconStyle: {
        lineHeight: 24,
      },
    },
  },
})

export { colors, fonts }
export default theme
