import { StyleSheet } from 'react-native'

import { colors } from '@src/config/theme'

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
  },
})

export default styles
