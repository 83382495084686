import { ImageSourcePropType } from 'react-native'

export const IMAGE_LOGO_LIGHT_ALT: ImageSourcePropType = require('./images/logo-light-alt.png')
export const IMAGE_LOGO_DARK: ImageSourcePropType = require('./images/logo-dark.png')
export const IMAGE_LOGO_LIGHT: ImageSourcePropType = require('./images/logo-light.png')
export const IMAGE_LOGO: ImageSourcePropType = require('./images/logo.png')
export const IMAGE_ICON: ImageSourcePropType = require('./images/icon.png')
export const IMAGE_AUTH_BACKGROUND: ImageSourcePropType = require('./images/new-auth-background.png')
export const IMAGE_EMAIL_BACKGROUND: ImageSourcePropType = require('./images/auth-verify-background.png')
export const IMAGE_CREATE_BACKGROUND: ImageSourcePropType = require('./images/create-background.png')
export const IMAGE_EVENT_HEADER: ImageSourcePropType = require('./images/event-header.png')
export const DEFAULT_EVENT_IMAGE: ImageSourcePropType = require('./images/generic.jpg')
export const GROUP_FALLBACK: ImageSourcePropType = require('./images/group-fallback-image.jpg')
export const ORFI_ICON: ImageSourcePropType = require('./images/notification.png')
