import { StyleSheet } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '90%',
    alignSelf: 'center',
    paddingVertical: 6,
  },
  bottomButtonContainer: {
    position: 'absolute',
    right: 0,
    left: 0,
    paddingTop: 10,
    paddingBottom: 20,
    bottom: 0,
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  bottomButtonInnerContainer: {
    width: '75%',
  },
  scrollViewContainer: {
    flex: 1,
    backgroundColor: colors.white,
  },
  titleText: {
    color: colors.secondary,
    marginVertical: 30,
    textAlign: 'center',
  },
  subTitleText: {
    color: colors.secondary,
    fontFamily: fonts.default.bold,
    fontSize: 16,
  },
  innerContainer: {
    flex: 1,
    alignItems: 'center',
  },
  touchableOpacity: {
    width: '85%',
    marginTop: 20,
  },
  linkContainer: {
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: colors.primary,
    borderRadius: 50,
    height: 50,
  },
  linkInnerContainer: {
    width: '80%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  linkText: {
    color: colors.primary,
    fontFamily: fonts.default.semiBold,
    fontSize: 13,
    textAlign: 'center',
  },
  iconContainer: {
    width: '20%',
    justifyContent: 'center',
    alignItems: 'center',
    borderLeftWidth: 1,
    borderLeftColor: colors.primary,
  },
  FlashMessageTitle: {
    fontFamily: fonts.default.bold,
    fontSize: 18,
  },
})

export default styles
