import * as Yup from 'yup'

import { validName } from '@src/utils/validation'

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .matches(validName, 'Special or accented characters are not supported')
    .required('First name is required'),
  surname: Yup.string()
    .trim()
    .matches(validName, 'Special or accented characters are not supported')
    .required('Last name is required'),
  email: Yup.string()
    .strict(false)
    .trim()
    .email("This doesn't look like an email")
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Password has to be longer than 8 characters')
    .required('Password is required'),
})

export default validationSchema
