import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  column: {
    flex: 1,
  },
  stepContainer: {
    width: '100%',
    alignSelf: 'center',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '90%',
    alignSelf: 'center',
    paddingVertical: 10,
  },
  button: {
    flexDirection: 'column',
    flex: 1,
    paddingHorizontal: 6,
  },
  graButtonContainer: {
    height: 46,
    borderRadius: 23,
  },
  graButton: {
    height: 46,
    borderRadius: 23,
    padding: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  graText: {
    fontWeight: 'bold',
    fontSize: 14,
  },
})

export default styles
