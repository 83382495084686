export const defaultGender = 'MIXED'
export const pickerGender = [
  { label: 'Male', value: 'MALE' },
  { label: 'Female', value: 'FEMALE' },
  { label: 'Mixed', value: 'MIXED' },
  { label: 'Neutral', value: 'NEUTRAL' },
]

export const defaultSkillLevel = 'OPEN'
export const pickerSkillLevel = [
  { label: 'Beginner', value: 'BEGINNER' },
  { label: 'Intermediate', value: 'INTERMEDIATE' },
  { label: 'Advanced', value: 'PRO' },
  { label: 'All ', value: 'OPEN' },
]

export const defaultPrivacyValue = 'PUBLIC'
export const pickerPrivacy = [
  { label: 'Public', value: 'PUBLIC' },
  { label: 'Private', value: 'PRIVATE' },
]

export const defaultVenue = 'INDOOR'
export const pickerVenue = [
  { label: 'Indoor', value: 'INDOOR' },
  { label: 'Outdoor', value: 'OUTDOOR' },
]

export const defaultRefundPolicy = 'NONE'
export const pickerRefundPolicy = [
  { label: 'No Refunds', value: 'NONE' },
  { label: 'Partial Refund', value: 'PARTIAL' },
  { label: 'Full Refund', value: 'FULL' },
]

export const debugCreate = {
  acceptCash: false,
  activity: {
    id: 1,
    name: 'Activity One',
  },
  notes: 'Some notes',
  description: 'An awesome description',
  gender: 'MALE',
  maxParticipants: '100',
  maxAge: '99',
  minParticipants: '1',
  minAge: '1',
  name: 'Debug Group',
  prices: [
    {
      description: 'Free',
      value: '0.00',
    },
    {
      description: 'Not Free',
      value: '5.59',
    },
  ],
  privacy: 'PUBLIC',
  refundPolicy: 'NONE',
  rules: 'Some fun tules',
  sessions: [
    {
      date: '2019-05-08',
      endAt: '',
      startAt: '2019-05-07T23:00:00.000Z',
    },
    {
      date: '2019-05-01',
      endAt: '',
      startAt: '2019-04-30T23:00:00.000Z',
    },
  ],
  skillLevel: 'OPEN',
  venueType: 'INDOOR',
  venueAddress: {
    address: 'On a train',
    latitude: 52.3555177,
    longitude: -1.1743196999999999,
  },
}

export const userGenderPicker = [
  { label: 'Male', value: 'MALE' },
  { label: 'Female', value: 'FEMALE' },
]

export const recurringTypeOnce = 'ONCE'
export const recurringTypeDaily = 'DAILY'
export const recurringTypeWeekly = 'WEEKLY'
export const recurringTypeMonthly = 'MONTHLY'

export const recurringType = [
  { label: 'Once', value: recurringTypeOnce, key: 'ONCE' },
  { label: 'Daily', value: recurringTypeDaily, key: 'DAILY' },
  { label: 'Weekly', value: recurringTypeWeekly, key: 'WEEKLY' },
  { label: 'Monthly', value: recurringTypeMonthly, key: 'MONTHLY' },
]

export const daysOfTheWeek = [
  { label: 'M', value: 0, key: 'MON' },
  { label: 'T', value: 1, key: 'TUE' },
  { label: 'W', value: 2, key: 'WED' },
  { label: 'T', value: 3, key: 'THU' },
  { label: 'F', value: 4, key: 'FRI' },
  { label: 'S', value: 5, key: 'SAT' },
  { label: 'S', value: 6, key: 'SUN' },
]

export const defaultPayMethod = 'SITE'
