import jwtDecode from 'jwt-decode'

import { getItem, setItem, deleteItem } from './storage'

interface AuthToken {
  name: string
  exp: number
}

const TOKEN_NAME = 'ORFI_TOKEN'

export const getAuthToken = () => getItem(TOKEN_NAME)

export const setAuthToken = (authToken: string) => setItem(TOKEN_NAME, authToken)

export const deleteAuthToken = () => deleteItem(TOKEN_NAME)

export const isValidAuthToken = (accessToken: string) => {
  const decoded = jwtDecode<AuthToken>(accessToken)
  const currentTime = Date.now() / 1000

  return decoded.exp > currentTime
}

export default isValidAuthToken
