import { StyleSheet } from 'react-native'

import { colors } from '@src/config/theme'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  content: {
    flex: 1,
  },
  scrollVw: { paddingTop: 15, paddingBottom: 150 },
  inputContainer: { paddingLeft: 30, paddingRight: 30, paddingTop: 10 },
  label: { alignSelf: 'flex-start' },
  addBtn: {
    width: '100%',
    alignSelf: 'center',
    flexDirection: 'row',
    paddingBottom: 20,
  },
  buttonContainerStyle: {
    flex: 1,
    marginHorizontal: 7,
  },
  deleteButton: {
    backgroundColor: colors.secondary,
  },
})

export default styles
