import { useRoute } from '@react-navigation/native'
import { Button, Icon } from '@rneui/themed'
import * as Clipboard from 'expo-clipboard'
import { ScrollView, Share, TouchableOpacity, View } from 'react-native'

import EmptyNotice from '@src/components/EmptyNotice'
import { showSuccessMessage } from '@src/components/FlashMessage'
import Loading from '@src/components/Loading'
import Text from '@src/components/Text'
import { colors } from '@src/config/theme'
import { useMeQuery } from '@src/graphql/types'
import { DashboardInviteMemberScreenRouteProps } from '@src/types'
import { excludedActivityTypes } from '@src/utils/share'

import styles from './DashboardInviteMemberScreen.styles'

const DashboardInviteMemberScreen = () => {
  const { params } = useRoute<DashboardInviteMemberScreenRouteProps>()
  const { data, error, loading } = useMeQuery()

  const copyToClipboard = async () => {
    const url = `https://app.orfiactive.com/groupInvite/${params.groupUuid}`

    await Clipboard.setStringAsync(url)
    showSuccessMessage({
      message: 'Copied to clipboard',
      duration: 6000,
    })
  }

  const onShare = async () => {
    const url = `https://app.orfiactive.com/groupInvite/${params.groupUuid}`

    try {
      const result = await Share.share(
        {
          title: `${params?.groupName}`,
          message: `${data?.me?.name} ${data?.me?.surname} has invited you to join ${params?.groupName} group. Are you in? ${url}`,
          url,
        },
        {
          excludedActivityTypes,
        },
      )

      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (err) {
      // eslint-disable-next-line no-alert
      alert(err.message)
    }
  }

  if (loading) return <Loading />
  if (error) return <EmptyNotice title="Oops" text="You need to be logged in to invite others." />

  return (
    <ScrollView contentContainerStyle={styles.scrollViewContainer}>
      <View style={styles.innerContainer}>
        <Text style={styles.titleText}>
          {'Share the link below to invite\nmembers to the Group'}
        </Text>

        <Text style={styles.subTitleText}>Group invitation link</Text>

        <TouchableOpacity style={styles.touchableOpacity} onPress={copyToClipboard}>
          <View style={styles.linkContainer}>
            <View style={styles.linkInnerContainer}>
              <Text style={styles.linkText}>{`app.orfiactive.com/${params.groupUuid}`}</Text>
            </View>
            <View style={styles.iconContainer}>
              <Icon name="copy" type="feather" color={colors.primary} />
            </View>
          </View>
        </TouchableOpacity>
      </View>

      <View style={styles.bottomButtonContainer}>
        <View style={styles.bottomButtonInnerContainer}>
          <Button title="Share Link" onPress={onShare} />
        </View>
      </View>
    </ScrollView>
  )
}

export default DashboardInviteMemberScreen
