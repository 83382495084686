import { useNavigation } from '@react-navigation/native'
import { Icon } from '@rneui/themed'
import React, { useState } from 'react'
import { ImageBackground, SafeAreaView, TouchableWithoutFeedback, View } from 'react-native'

import { IMAGE_CREATE_BACKGROUND } from '@src/assets/images'
import Loading from '@src/components/Loading'
import AddGroupPopup from '@src/components/PopupModal/AddGroupPopup'
import Text from '@src/components/Text'
import TopBanner from '@src/components/TopBanner'
import { colors } from '@src/config/theme'
import { useMeQuery } from '@src/graphql/types'
import { CreateScreenNavigationProps } from '@src/types'

import useStyle from './CreateScreen.styles'

const CreateScreen = () => {
  const [visible, setVisible] = useState(false)
  const navigation = useNavigation<CreateScreenNavigationProps>()
  const styles = useStyle()

  const { loading, data: meData } = useMeQuery()

  if (loading) return <Loading />

  const { me } = meData

  const handleClickSession = () => {
    if (me?.groupuserSet.length) {
      navigation.navigate('SelectGroupScreen')
    } else {
      setVisible(true)
    }
  }

  return (
    <ImageBackground source={IMAGE_CREATE_BACKGROUND} style={styles.bg}>
      <TopBanner />
      <SafeAreaView style={styles.container}>
        <View style={styles.headerContainer}>
          <Text style={styles.header}>What would you like to do?</Text>
        </View>

        <TouchableWithoutFeedback onPress={() => navigation.navigate('CreateGroupScreen')}>
          <View style={[styles.formContainer, { backgroundColor: colors.lightBlue }]}>
            <View style={styles.icon}>
              <Icon color={colors.secondary} size={20} type="feather" name="users" />
            </View>
            <View style={styles.texts}>
              <Text style={styles.title}>
                + create a new <Text style={styles.titleExtension}>group</Text>
              </Text>
              <Text style={styles.comment}>
                {
                  'Creating a group makes organisation much easier.\nInvite and chat to members, view upcoming sessions and find group details, all in one place.'
                }
              </Text>
            </View>
          </View>
        </TouchableWithoutFeedback>

        <TouchableWithoutFeedback onPress={handleClickSession}>
          <View style={styles.formContainer}>
            <View style={styles.icon}>
              <Icon
                color={colors.secondary}
                size={26}
                type="orfi"
                name="teams"
                iconStyle={styles.orfiIcon}
              />
            </View>
            <View style={styles.texts}>
              <Text style={styles.title}>
                + add a new <Text style={styles.titleExtension}>session</Text>
              </Text>
              <Text style={styles.comment}>
                {
                  'It’s time to get active!\nCreate a new session for your group in just a few taps.'
                }
              </Text>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </SafeAreaView>
      <AddGroupPopup visible={visible} onClose={() => setVisible(false)} />
    </ImageBackground>
  )
}

export default CreateScreen
