import { Button } from '@rneui/themed'
import { FC } from 'react'
import { View } from 'react-native'

import styles from './DualButton.styles'
import { DualButtonPropsType } from './types'

const DualButton: FC<DualButtonPropsType> = ({
  leftTitle,
  rightTitle,
  disabled,
  loading,
  leftOnPress,
  rightOnPress,
}) => {
  return (
    <View style={styles.container}>
      <Button
        title={leftTitle}
        onPress={leftOnPress}
        containerStyle={styles.leftButtonContainer}
        buttonStyle={styles.leftButtonStyle}
        disabled={disabled}
        loading={loading}
      />
      <Button
        title={rightTitle}
        onPress={rightOnPress}
        containerStyle={styles.rightButtonContainer}
        disabled={disabled}
        loading={loading}
      />
    </View>
  )
}

export default DualButton
