import { Avatar, Badge, Icon } from '@rneui/themed'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'

import { ORFI_ICON } from '@src/assets/images'
import { colors, fonts } from '@src/config/theme'

const allSizes = {
  small: {
    image: 40,
    topBadge: 16,
    topBadgePosition: -2.5,
    icon: 8,
    overlayText: 5,
    bottomLabelText: 5,
    bottomLabelPosition: -6,
    bottomLabelHeight: 11.5,
  },
  medium: {
    image: 55,
    topBadge: 20,
    topBadgePosition: 1,
    icon: 9,
    overlayText: 9,
    bottomLabelText: 8,
    bottomLabelPosition: -7,
    bottomLabelHeight: 15,
  },
  large: {
    image: 90,
    topBadge: 20,
    topBadgePosition: 7,
    icon: 12,
    overlayText: 13,
    bottomLabelText: 11,
    bottomLabelPosition: -0,
    bottomLabelHeight: 20,
  },
}

const renderImage = (uri, title, status, type) => {
  if (uri) {
    if (typeof uri === 'number') return { source: uri }
    return {
      source: {
        uri,
      },
    }
  }
  if (type === 'notification') {
    return { source: ORFI_ICON }
  }
  if (type === 'profile' && status !== 'edit') {
    return {
      icon: {
        type: 'feather',
        name: 'user',
        size: 60,
        color: colors.grey6,
      },
    }
  }
  if (type !== 'profile') {
    return {
      title,
      titleStyle: styles.title,
    }
  }
  return {}
}

const OrfiAvatar = ({
  type,
  status,
  size,
  uri,
  title,
  border,
  raised,
  topBadge,
  bottomLabel,
  onPress,
}) => {
  const [sizes, setSizes] = useState(allSizes.medium)

  const getSizes = (avatarSize) => {
    switch (avatarSize) {
      case 'small':
        setSizes(allSizes.small)
        break
      case 'large':
        setSizes(allSizes.large)
        break
      default:
        setSizes(allSizes.medium)
    }
  }

  useEffect(() => {
    getSizes(size)
  }, [])

  const renderBorder = (hasBorder) =>
    hasBorder
      ? {
          style: {
            borderWidth: sizes.image * 0.06,
            borderColor: colors.white,
            borderRadius: sizes.image,
          },
        }
      : null

  const renderTopBadge = (badgeType) => {
    if (badgeType === 'check') {
      return (
        <Badge
          value={<Icon type="feather" name="check" size={sizes.icon * 1.2} color={colors.white} />}
          badgeStyle={{
            borderRadius: sizes.image,
            width: sizes.topBadge,
            height: sizes.topBadge,
            borderWidth: 1.5,
            borderColor: colors.white,
            backgroundColor: colors.success,
          }}
          containerStyle={{
            position: 'absolute',
            borderRadius: sizes.image,
            width: sizes.topBadge,
            height: sizes.topBadge,
            top: sizes.topBadgePosition,
            right: sizes.topBadgePosition,
          }}
        />
      )
    }
    if (typeof badgeType === 'number') {
      return (
        <Badge
          value={
            <Text style={{ fontSize: sizes.icon, color: colors.white, fontWeight: 'bold' }}>
              {badgeType}
            </Text>
          }
          badgeStyle={{
            borderRadius: sizes.image,
            width: sizes.topBadge,
            height: sizes.topBadge,
            borderWidth: 1.5,
            borderColor: colors.white,
            backgroundColor: colors.primary,
          }}
          containerStyle={{
            position: 'absolute',
            borderRadius: sizes.image,
            width: sizes.topBadge,
            height: sizes.topBadge,
            top: sizes.topBadgePosition,
            right: sizes.topBadgePosition,
          }}
        />
      )
    }
    return null
  }

  const renderBottomLabel = (labelText) => {
    const badgeColor = labelText === 'paid' ? colors.success : colors.grey4
    return (
      <Badge
        value={
          <Text
            style={{
              fontSize: sizes.bottomLabelText,
              color: colors.white,
              fontFamily: fonts.default.bold,
            }}>
            {labelText.toUpperCase()}
          </Text>
        }
        badgeStyle={{
          height: sizes.bottomLabelHeight,
          borderRadius: sizes.image,
          paddingHorizontal: 4,
          borderWidth: 1.5,
          borderColor: colors.white,
          backgroundColor: badgeColor,
        }}
        containerStyle={{
          height: sizes.bottomLabelHeight,
          position: 'absolute',
          borderRadius: sizes.image,
          paddingHorizontal: 4,
          bottom: 0,
          right: sizes.bottomLabelPosition,
        }}
      />
    )
  }

  const renderOverlay = (avatarStatus, avatarType) => {
    if (avatarStatus === 'default' || avatarType === 'notification') {
      return null
    }
    if (avatarStatus === 'edit') {
      return (
        <View
          style={{
            width: sizes.image,
            height: sizes.image,
            borderRadius: sizes.image,
            ...styles.pinkOverlay,
          }}>
          <Icon
            type="feather"
            name="camera"
            size={sizes.image / 3.5}
            color={colors.white}
            containerStyle={styles.iconContainer}
          />
        </View>
      )
    }
    return (
      <View
        style={{
          width: sizes.image,
          height: sizes.image,
          borderRadius: sizes.image,
          ...styles.overlay,
        }}>
        <Text
          style={{
            width: sizes.image,
            fontSize: sizes.overlayText,
            ...styles.overlayText,
          }}>
          {avatarType === 'notification' ||
          avatarStatus === 'default' ||
          avatarStatus === 'inactive'
            ? ' '
            : avatarStatus}
        </Text>
      </View>
    )
  }

  return (
    <View
      style={{
        width: sizes.image,
        height: sizes.image,
        ...styles.avatarContainer,
      }}>
      <TouchableOpacity {...renderBorder(border)} disabled={!onPress} onPress={onPress}>
        <Avatar
          rounded
          size={sizes.image}
          overlayContainerStyle={styles.overlayContainer}
          containerStyle={raised ? styles.raised : {}}
          {...renderImage(uri, title, status, type)}
        />
        {renderOverlay(status, type)}
      </TouchableOpacity>
      {topBadge ? renderTopBadge(topBadge) : null}
      {bottomLabel ? renderBottomLabel(bottomLabel) : null}
    </View>
  )
}

const styles = StyleSheet.create({
  avatarContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlayContainer: {
    backgroundColor: colors.grey8,
  },
  overlayText: {
    flex: 1,
    paddingTop: '40%',
    color: colors.white,
    textAlign: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    fontFamily: fonts.default.bold,
  },
  title: {
    fontSize: 30,
    textTransform: 'uppercase',
    color: colors.grey9,
    fontFamily: fonts.default.regular,
  },
  overlay: {
    backgroundColor: 'rgba(70, 70, 70, 0.7)',
    position: 'absolute',
  },
  pinkOverlay: {
    backgroundColor: colors.primaryOpc,
    position: 'absolute',
  },
  iconContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  raised: {
    shadowOffset: { width: 2, height: 2 },
    shadowColor: 'black',
    shadowOpacity: 0.2,
    shadowRadius: 2.62,
    elevation: 4,
  },
})

OrfiAvatar.propTypes = {
  type: PropTypes.string,
  status: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  uri: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  border: PropTypes.bool,
  raised: PropTypes.bool,
  topBadge: PropTypes.oneOfType([PropTypes.oneOf(['orfi', 'check', '']), PropTypes.number]),
  bottomLabel: PropTypes.oneOf(['paid', 'cash', '']),
  onPress: PropTypes.func,
}

OrfiAvatar.defaultProps = {
  type: 'default',
  status: 'default',
  size: 'medium',
  uri: '',
  title: '',
  border: false,
  raised: false,
  topBadge: '',
  bottomLabel: '',
  onPress: null,
}

export default OrfiAvatar
