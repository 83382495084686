import Constants from 'expo-constants'
import { StyleSheet, Platform } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  content: {
    padding: 10,
    flex: 1,
  },
  bottom: {
    paddingVertical: 20,
    paddingHorizontal: 50,
  },
  row: {
    flexDirection: 'row',
  },
  column: {
    flex: 1,
  },
  inputTouchableOpacity: {
    width: '100%',
  },
  input: {
    fontSize: 14,
    color: colors.black,
    opacity: 0.5,
    fontStyle: 'italic',
    marginLeft: 0,
  },
  rightIcon: {
    marginRight: 0,
    padding: 0,
  },
  empty: {
    opacity: 0,
  },
  rememberText: {
    fontSize: 12,
    color: colors.black,
  },
  resetTitle: {
    fontSize: 14,
    fontFamily: fonts.default.bold,
  },
  header: {
    height: 50,
    position: 'relative',
    justifyContent: 'center',
    paddingHorizontal: 20,
    alignItems: 'center',
    marginTop: Platform.select({ ios: 0, android: Constants.statusBarHeight }),
    borderBottomWidth: 1,
    borderBottomColor: colors.greyOutline,
  },
  headerTitle: {
    fontSize: 14,
    fontFamily: fonts.default.bold,
    color: colors.secondary,
  },
  HeaderIcon: {
    position: 'absolute',
    left: 10,
  },
  headerRight: {
    position: 'absolute',
    right: 10,
  },
  headerRightText: {
    color: colors.primary,
    fontSize: 12,
    padding: 0,
    margin: 0,
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default styles
