import { StyleSheet } from 'react-native'

import { colors } from '@src/config/theme'

const styles = StyleSheet.create({
  inputContainer: {
    borderBottomWidth: 0,
    paddingVertical: 16,
    paddingHorizontal: 10,
    backgroundColor: colors.white,
    borderRadius: 16,
    height: '90%',
  },
  inputStyle: {
    height: '100%',
    textAlignVertical: 'top',
  },
  postComment: {
    alignSelf: 'flex-end',
    paddingRight: 8,
    paddingTop: 8,
  },
})

export default styles
