import { useNavigation, useRoute } from '@react-navigation/native'
import { Button, Icon } from '@rneui/themed'
import { StatusBar } from 'expo-status-bar'
import { orderBy } from 'lodash'
import moment from 'moment'
import { useMemo, useLayoutEffect } from 'react'
import { FlatList, TouchableOpacity, useWindowDimensions, View } from 'react-native'

import DualButton from '@src/components/DualButton'
import EmptyNotice from '@src/components/EmptyNotice'
import HeaderIcon from '@src/components/HeaderIcon'
import OrfiListItem from '@src/components/ListItem/OrfiListItem'
import Loading from '@src/components/Loading'
import Text from '@src/components/Text'
import { colors } from '@src/config/theme'
import {
  MembershipStatus,
  SessionType,
  useGroupQuery,
  useSessionMembershipQuery,
} from '@src/graphql/types'
import { DashboardGroupScreenNavigationProps, DashboardGroupScreenRouteProps } from '@src/types'
import getInitials from '@src/utils/getInitials'

import styles from './DashboardGroupScreen.styles'

const DashboardGroupScreen = () => {
  const navigation = useNavigation<DashboardGroupScreenNavigationProps>()
  const {
    params: { groupUuid, fromSession = false },
  } = useRoute<DashboardGroupScreenRouteProps>()
  const { width } = useWindowDimensions()

  const tabs = useMemo(
    () => [
      {
        name: 'Wall',
        navRoute: () => navigation.navigate('DashboardWallScreen', { groupUuid }),
        icon: 'message-square',
      },
      {
        name: 'Calendar',
        navRoute: () => navigation.navigate('DashboardSessionsScreen', { groupUuid }),
        icon: 'calendar',
      },
      {
        name: 'Members',
        navRoute: () => navigation.navigate('DashboardMembersScreen', { groupUuid }),
        icon: 'users',
      },
      {
        name: 'About',
        navRoute: () => navigation.navigate('DashboardAboutScreen', { groupUuid }),
        icon: 'info',
      },
      {
        name: 'More',
        navRoute: () => navigation.navigate('DashboardMoreScreen', { groupUuid }),
        icon: 'more-horizontal',
      },
    ],
    [navigation, groupUuid],
  )

  const {
    loading,
    data: { group = { uuid: '', name: '' } } = {},
    error,
    refetch,
  } = useGroupQuery({
    variables: {
      groupUuid: groupUuid,
    },
  })
  const { sessions } = group

  const date = moment(new Date()).format('YYYY-MM-DDT00:00:00+00:00')

  const { data: { sessionMembership = [] } = {} } = useSessionMembershipQuery({
    variables: { startAt_Gte: date },
  })

  useLayoutEffect(() => {
    if (!group?.uuid) {
      return
    }
    const navigateToGroups = () => {
      if (fromSession) {
        navigation.goBack()
      } else {
        navigation.navigate('AttendingScreen')
      }
    }

    const headerLeft = () => <HeaderIcon onPress={navigateToGroups} color={colors.white} />
    const headerRight = () => (
      <HeaderIcon
        name="user-plus"
        type="feather"
        color={colors.white}
        onPress={() =>
          navigation.navigate('DashboardInviteMemberScreen', {
            groupUuid: group.uuid,
            groupName: group.name,
          })
        }
      />
    )

    navigation.setOptions({
      headerLeft,
      headerRight,
      title: group.name,
    })
  }, [navigation, group, fromSession])

  if (loading) return <Loading />
  if (error) {
    return (
      <EmptyNotice
        title="GROUP NOT FOUND"
        text={
          "Hmm, we can't find the group you're looking for.\n It may have been deleted by the organiser, or maybe it just got lost in the Matrix"
        }
      />
    )
  }

  const onPress = (session: SessionType) => {
    const { id } = session
    navigation.navigate('SessionDetailScreen', { sessionId: id })
  }

  const renderParticipants = (session: SessionType) => {
    const { maxParticipants, usersAttendingCount } = session
    const participants =
      usersAttendingCount === 1 && maxParticipants === null ? 'Participant' : 'Participants'
    const limit = maxParticipants === null ? '' : `/${maxParticipants}`
    return (
      <Text style={styles.participants}>{`${usersAttendingCount + limit} ${participants}`}</Text>
    )
  }

  const upcomingSessions = orderBy(
    sessions?.filter((s: SessionType) => moment() <= moment(s.startAt)),
    ['startAt'],
    ['asc'],
  )

  const attendingFilter = upcomingSessions?.filter(
    ({ status }) => status === MembershipStatus.Attending,
  )

  return (
    <View style={styles.container}>
      <StatusBar style="light" />
      <View style={styles.tabGrid}>
        {tabs.map(({ name, icon, navRoute }) => (
          <TouchableOpacity key={name} onPress={navRoute} style={styles.tabButton(width)}>
            <Icon name={icon} type="feather" size={24} iconStyle={styles.iconStyle} />
            <Text numberOfLines={1} style={styles.tabButtonClear}>
              {name}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
      {group.isAdmin && !sessions.length ? (
        <View style={styles.flexCenter}>
          <Text style={styles.emptyTitle}>Get your group active</Text>
          <Text style={styles.centeredText}>
            {
              "It's now time to add some sessions to the\ngroup for your members to attend.\n\n\nTap the button below to get started."
            }
          </Text>
          <View style={styles.firstNewSessionButton}>
            <Button
              title="+ Add Sessions"
              onPress={() => navigation.navigate('CreateSessionScreen', { groupUuid: groupUuid })}
            />
          </View>
        </View>
      ) : group.isAdmin && !upcomingSessions.length ? (
        <View style={styles.flexCenter}>
          <Text style={styles.emptyTitle}>No more upcoming sessions </Text>
          <Text style={styles.centeredText}>
            {
              'You know the drill, create some brand\nnew sessions or duplicate old ones to\nget your group active.\n\n\nSelect a button below to get started.'
            }
          </Text>
        </View>
      ) : group.isAdmin ? (
        <FlatList
          keyExtractor={(grp) => grp.uuid}
          data={upcomingSessions || []}
          renderItem={({ item }) => (
            <OrfiListItem
              key={item.id}
              status={item.status.toLowerCase() === 'cancelled' ? 'cancelled' : 'default'}
              avatarUri={item?.image || item?.activity[0]?.defaultBannerImage}
              avatarPlaceholder={getInitials(item?.name)}
              title={item.name}
              subtitle={moment(item?.startAt).format('dddd, DD MMM, H:mm')}
              bottomText={renderParticipants(item)}
              callToAction={
                <Icon name="chevron-right" type="feather" color={colors.grey} size={20} />
              }
              onPress={() => onPress(item)}
              divider
            />
          )}
          ListHeaderComponent={() => (
            <View style={styles.listHeaderTitleVwSession}>
              <Text style={styles.listHeaderTitle}>Upcoming Sessions</Text>
            </View>
          )}
          contentContainerStyle={styles.flatListContentContainer}
          onRefresh={refetch}
          refreshing={loading}
        />
      ) : null}

      {!group.isAdmin && !sessions.length ? (
        <View style={styles.flexCenter}>
          <Text style={styles.emptyTitle}>NO SESSIONS AVAILABLE, YET</Text>
          <Text style={styles.centeredText}>
            {
              'The organiser has not yet created any\nsessions for this group.\n\n\nMake sure to let the group know you want to get active!'
            }
          </Text>
        </View>
      ) : !group.isAdmin ? (
        <FlatList
          keyExtractor={(grp) => grp.id}
          data={upcomingSessions || []}
          renderItem={({ item }) => (
            <OrfiListItem
              status={item.status.toLowerCase() === 'cancelled' ? 'cancelled' : 'default'}
              avatarUri={item?.image || item?.activity[0]?.defaultBannerImage}
              avatarPlaceholder={getInitials(item?.name)}
              title={item?.name}
              subtitle={moment(item?.startAt).format('dddd, DD MMM, H:mm')}
              bottomText={renderParticipants(item)}
              callToAction={
                <Icon name="chevron-right" type="feather" color={colors.grey} size={20} />
              }
              onPress={() => onPress(item)}
              divider
            />
          )}
          ListHeaderComponent={() => (
            <View
              style={
                upcomingSessions.length ? styles.listHeaderTitleVwSession : styles.listHeaderTitleVw
              }>
              <Text style={styles.listHeaderTitle}>
                {upcomingSessions.length && attendingFilter.length
                  ? 'SESSIONS YOU’RE ATTENDING'
                  : upcomingSessions.length
                  ? 'TIME TO JOIN A SESSION'
                  : 'NO SESSIONS AVAILABLE, YET'}
              </Text>
            </View>
          )}
          ListEmptyComponent={
            <View style={styles.flatListTextContainer}>
              <Text style={styles.centeredText}>
                {'The organiser has not added\nany new sessions.'}
              </Text>
            </View>
          }
          contentContainerStyle={styles.flatListContentContainer}
          onRefresh={refetch}
          refreshing={loading}
        />
      ) : null}
      {sessions.length ? (
        <View style={!group.isAdmin ? styles.bottomButtonContainer : styles.dualButtonContainer}>
          {!group.isAdmin ? (
            <>
              {!upcomingSessions.length ? null : (
                <Button
                  title="View Group Calendar"
                  onPress={() =>
                    navigation.navigate('DashboardSessionsScreen', {
                      groupUuid: group.uuid,
                    })
                  }
                />
              )}
            </>
          ) : (
            <View style={styles.innerButtonContainer}>
              <DualButton
                leftTitle="Duplicate Session"
                rightTitle="+ New Session"
                leftOnPress={() =>
                  navigation.navigate('SelectSessionScreen', {
                    groupUuid: group.uuid,
                  })
                }
                rightOnPress={() =>
                  navigation.navigate('CreateSessionScreen', {
                    groupUuid: group.uuid,
                  })
                }
              />
            </View>
          )}
        </View>
      ) : null}
    </View>
  )
}

export default DashboardGroupScreen
