import { StyleSheet } from 'react-native'

import { colors } from '@src/config/theme'

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  outerInputContainer: {
    height: '15%',
  },
  inputContainer: {
    borderBottomWidth: 0,
    paddingVertical: 16,
    paddingHorizontal: 10,
    backgroundColor: colors.white,
    borderRadius: 16,
  },
  inputStyle: {
    height: '100%',
    textAlignVertical: 'top',
  },
})

export default styles
