import { useNavigation, useRoute } from '@react-navigation/native'
import { Button, Divider, Icon } from '@rneui/themed'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FlatList, SafeAreaView, TouchableOpacity, View } from 'react-native'

import DualButton from '@src/components/DualButton'
import Loading from '@src/components/Loading'
import Text from '@src/components/Text'
import { colors } from '@src/config/theme'
import { useSessionQuery } from '@src/graphql/types'
import { JoiningOptionsScreenNavigationProps, JoiningOptionsScreenRouteProps } from '@src/types.d'

import { styles, itemStyles } from './JoiningOptionsScreen.styles'

const ListItem = ({ amount, comment, selected, onPress }) => (
  <TouchableOpacity style={itemStyles.container} onPress={onPress}>
    <View style={itemStyles.left}>
      <View style={itemStyles.leftText}>
        <Text style={itemStyles.price}>£{amount}</Text>
        <Text style={itemStyles.comment}>{comment}</Text>
      </View>
      <Divider />
    </View>
    {selected ? (
      <Icon
        color={colors.success}
        size={32}
        type={selected ? 'ionicon' : 'font-awesome'}
        name={selected ? 'ios-checkmark-circle' : 'circle-thin'}
        iconStyle={itemStyles.icon}
      />
    ) : (
      <View style={itemStyles.empty} />
    )}
  </TouchableOpacity>
)

const JoiningOptionsScreen = () => {
  const navigation = useNavigation<JoiningOptionsScreenNavigationProps>()
  const { params } = useRoute<JoiningOptionsScreenRouteProps>()
  const [selectedPrice, setSelectedPrice] = useState({})

  const { data: sessionQuery, loading } = useSessionQuery({
    variables: {
      id: params.sessionId,
    },
  })

  useEffect(() => {
    if (session?.prices?.length >= 1) setSelectedPrice(session.prices[0])
  }, [sessionQuery])

  const onJoinCard = () => {
    goToConfirmationScreen('APP')
  }

  const onJoinCash = () => goToConfirmationScreen('SITE')

  const goToConfirmationScreen = (mode = 'APP') => {
    navigation.navigate('ConfirmationScreen', {
      paymentMethod: mode,
      priceId: selectedPrice.id,
      sessionId: session.id,
    })
  }

  if (loading) return <Loading />

  const { session } = sessionQuery

  return (
    <SafeAreaView style={styles.container}>
      <FlatList
        data={session.prices}
        keyExtractor={(item) => item.id}
        selectedPriceId={selectedPrice.id}
        renderItem={({ item }) => (
          <ListItem
            amount={item.value.toFixed(2)}
            comment={item.description}
            selected={item.id === selectedPrice.id}
            onPress={() => setSelectedPrice(item)}
          />
        )}
        ListHeaderComponent={() => (
          <View style={styles.comments}>
            <View>
              <Text style={styles.commentHeader}>Select from the options below.</Text>
              {/* <Text style={styles.commentText}>
                Once confirmed, we will let the organiser know that you’d like to join their session
                and update you as soon as we get a response. You can find your Pending request in
                the Attending tab.
              </Text> */}
            </View>
          </View>
        )}
        style={styles.content}
      />
      <View>
        {session.paymentMethod === 'ALL' ? (
          <View style={styles.buttons}>
            <DualButton
              leftTitle="Cash"
              rightTitle="Pay now"
              leftOnPress={onJoinCash}
              rightOnPress={onJoinCard}
            />
          </View>
        ) : (
          <View style={styles.singleBtn}>
            <Button
              title={
                session.paymentMethod === 'APP'
                  ? selectedPrice?.value
                    ? 'Pay now'
                    : 'Join now'
                  : 'Cash at Venue'
              }
              onPress={session.paymentMethod === 'APP' ? onJoinCard : onJoinCash}
              titleStyle={styles.buttonText}
              containerStyle={styles.buttonContainer}
            />
          </View>
        )}
      </View>
    </SafeAreaView>
  )
}

JoiningOptionsScreen.propTypes = {
  route: PropTypes.object.isRequired,
}

export default JoiningOptionsScreen
