import { StyleSheet } from 'react-native'

import { fonts, colors } from '@src/config/theme'

const styles = StyleSheet.create({
  bannerContainer: {
    height: 35,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    zIndex: 10,
  },
  button: {
    backgroundColor: colors.white,
    height: 25,
    padding: 0,
  },
  buttonTitle: {
    padding: 0,
    margin: 0,
    color: colors.primary,
    fontSize: 13,
    fontFamily: fonts.default.bold,
    textAlign: 'center',
  },
  buttonContainer: {
    borderRadius: 60,
  },
})

export default styles
