import { StyleSheet } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const iconSize = 25

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  content: {
    flex: 1,
  },
  buttons: {
    marginHorizontal: 20,
    flexDirection: 'row',
    marginVertical: 20,
  },
  singleBtn: {
    marginHorizontal: 20,
    flexDirection: 'row',
    marginVertical: 20,
    width: '70%',
    alignSelf: 'center',
  },
  comments: {
    width: '60%',
    alignSelf: 'center',
    marginTop: 40,
    marginBottom: 30,
  },
  commentHeader: {
    color: colors.secondary,
    fontSize: 14,
    // fontFamily: fonts.default.bold,
    textAlign: 'center',
    marginBottom: 10,
  },
  commentText: {
    color: colors.secondary,
    fontSize: 12,
    textAlign: 'center',
  },
  buttonContainer: {
    flex: 1,
    margin: 5,
  },
  button: {
    backgroundColor: colors.secondary,
  },
  buttonText: {
    color: colors.white,
    fontSize: 14,
    fontFamily: fonts.default.bold,
  },
})

export const itemStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 25,
  },
  empty: {
    width: iconSize,
    height: iconSize,
    borderRadius: iconSize / 2,
    borderWidth: 1,
    borderColor: colors.grey8,
  },
  left: {
    flex: 1,
    marginRight: 10,
  },
  leftText: {
    flexDirection: 'row',
    paddingVertical: 20,
  },
  price: {
    color: colors.black,
    fontSize: 14,
    fontFamily: fonts.default.bold,
    marginRight: 20,
  },
  comment: {
    color: colors.black,
    fontSize: 12,
  },
})
