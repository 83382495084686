import { useNavigation } from '@react-navigation/native'
import { Button, Divider, Icon, Input, ListItem, Overlay } from '@rneui/themed'
import * as ImagePicker from 'expo-image-picker'
import * as MediaLibrary from 'expo-media-library'
import { useFormik } from 'formik'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import {
  BackHandler,
  ImageBackground,
  Keyboard,
  Pressable,
  SafeAreaView,
  ScrollView,
  View,
} from 'react-native'

import ConfirmDialog from '@src/components/ConfirmDialog'
import DualButton from '@src/components/DualButton'
import HeaderIcon from '@src/components/HeaderIcon'
import Text from '@src/components/Text'
import { colors } from '@src/config/theme'
import { ME_QUERY } from '@src/graphql/queries'
import { useCreateGroupMutation } from '@src/graphql/types'
import { CreateGroupScreenNavigationProps } from '@src/types'
import generateRNFile from '@src/utils/generateRNFile'

import styles from './CreateGroupScreen.styles'
import validationSchema from './CreateGroupScreen.validation'

const CreateGroupScreen = () => {
  const navigation = useNavigation<CreateGroupScreenNavigationProps>()
  const [imgDialog, setImgDialog] = useState(false)
  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState(false)
  const [createGroup, { loading }] = useCreateGroupMutation()

  const submitGroup = async (values) => {
    try {
      const { data } = await createGroup({
        variables: {
          ...values,
        },
        refetchQueries: [{ query: ME_QUERY }],
        awaitRefetchQueries: true,
      })
      if (data?.createGroup) {
        navigation.navigate('DashboardGroupScreen', { groupUuid: data?.createGroup?.group?.uuid })
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const {
    values,
    errors,
    touched,
    isSubmitting,
    dirty,
    handleChange,
    setFieldTouched,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      name: '',
      image: '',
      description: '',
      isPrivate: false,
    },
    onSubmit: submitGroup,
  })

  const checkInputStates = () => {
    if (dirty) {
      setIsConfirmationDialogVisible(true)
    } else {
      navigation.goBack()
    }
  }

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <HeaderIcon onPress={() => checkInputStates()} />,
      headerRight: () => <Button title="CANCEL" onPress={() => checkInputStates()} type="clear" />,
    })
  }, [dirty])

  useEffect(() => {
    const backHandler = BackHandler.addEventListener('hardwareBackPress', () => checkInputStates())
    return () => backHandler.remove()
  })

  const toggleConfirmationDialog = () => {
    setIsConfirmationDialogVisible(!isConfirmationDialogVisible)
  }

  const pickImage = async () => {
    const { status } = await MediaLibrary.requestPermissionsAsync()
    if (status !== 'granted') {
      // eslint-disable-next-line no-alert
      alert('Sorry, we need camera roll permissions to make this work!')
    } else {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
      })

      if (!result.canceled) {
        const file = generateRNFile(result.assets[0].uri, 'Cover')
        setFieldTouched('image')
        setFieldValue('image', file)
      }
    }
  }

  const imageSrc = values.image ? values.image.uri : undefined

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.content}>
        <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={styles.scrollVw}>
          <Input
            label="GROUP NAME"
            labelStyle={styles.label}
            containerStyle={styles.inputContainer}
            returnKeyType="done"
            value={values.name}
            onSubmitEditing={Keyboard.dismiss}
            placeholder={'e.g "Saturday morning tennis practice"'}
            placeholderTextColor={colors.greyOutline}
            onBlur={() => setFieldTouched('name')}
            onChangeText={handleChange('name')}
            errorMessage={touched.name && errors.name ? errors.name : undefined}
            editable={!isSubmitting}
          />
          <View style={styles.coverPhotoContainer}>
            <Text style={styles.coverPhotoText}>COVER PHOTO</Text>
            <ImageBackground
              style={styles.imageBackground}
              source={{ uri: imageSrc }}
              resizeMode="cover">
              <Pressable style={styles.coverPhotoPressable} onPress={pickImage}>
                <Icon name="plus" type="feather" color={colors.grey6} />
                <Text style={styles.coverPhotoMediaPressable}>Add media</Text>
              </Pressable>
            </ImageBackground>
          </View>

          <Input
            label="INFORMATION"
            placeholder="Give some details about the group"
            placeholderTextColor={colors.greyOutline}
            value={values.description}
            onBlur={() => setFieldTouched('description')}
            onChangeText={handleChange('description')}
            // errorMessage={touched.description && errors.description ? errors.description : undefined}
            editable={!isSubmitting}
            inputContainerStyle={styles.descriptionContainer}
            inputStyle={styles.descriptionStyle}
            labelStyle={styles.label}
            containerStyle={styles.inputContainer}
            multiline
          />
          <ListItem
            style={styles.listItemStyle}
            onPress={() => setFieldValue('isPrivate', !values.isPrivate)}>
            <ListItem.Content>
              <ListItem.Title style={styles.listItemTitle}>THIS IS A PRIVATE GROUP</ListItem.Title>
            </ListItem.Content>

            <ListItem.CheckBox
              checked={values.isPrivate}
              onPress={() => setFieldValue('isPrivate', !values.isPrivate)}
            />
          </ListItem>
        </ScrollView>
        <View style={styles.buttonContainer}>
          <DualButton
            leftTitle="Cancel"
            rightTitle="Publish"
            leftOnPress={() => checkInputStates()}
            rightOnPress={handleSubmit}
          />
        </View>
      </View>
      <Overlay
        isVisible={imgDialog}
        onBackdropPress={() => setImgDialog(false)}
        overlayStyle={styles.overlayStyle}>
        <View style={styles.overlayContainer}>
          <Button
            title="Remove"
            titleStyle={styles.removeImageButton}
            type="clear"
            onPress={async () => {
              setImgDialog(false)
              setFieldValue('image', '')
            }}
          />
          <Divider style={styles.divider} />
          <Button
            title="Add Image"
            titleStyle={styles.addImageButton}
            type="clear"
            onPress={pickImage}
          />
        </View>
      </Overlay>
      <ConfirmDialog
        title="Careful..."
        message="If you exit this page your updates will be lost."
        negativeButton={{
          title: 'Cancel',
          onPress: () => toggleConfirmationDialog(),
        }}
        positiveButton={{
          title: 'Exit',
          onPress: () => {
            toggleConfirmationDialog()
            navigation.goBack()
          },
        }}
        visible={isConfirmationDialogVisible}
      />
    </SafeAreaView>
  )
}

export default CreateGroupScreen
