import * as mime from 'react-native-mime-types'

function generateRNFile(uri: string, name: string) {
  return {
    uri,
    type: mime.lookup(uri) || 'image',
    name,
  }
}

export default generateRNFile
