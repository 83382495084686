import * as Yup from 'yup'

const phoneNumberRegex = /^((\+44)|(0)) ?\d{4} ?\d{6}$/

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('FIRST NAME is required'),
  email: Yup.string().required('Email is required'),
  lastName: Yup.string().required('LAST NAME is required'),
  phoneNumber: Yup.string()
    .trim()
    .required('Phone number is required')
    .matches(phoneNumberRegex, "This doesn't look like a phone number"),
})

export default validationSchema
