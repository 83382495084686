import { useNavigation, useRoute } from '@react-navigation/native'
import { Icon, SearchBar } from '@rneui/themed'
import { Collapse, CollapseBody, CollapseHeader } from 'accordion-collapse-react-native'
import moment from 'moment'
import React, { useLayoutEffect, useState } from 'react'
import {
  ActivityIndicator,
  RefreshControl,
  SafeAreaView,
  ScrollView,
  View,
  StyleSheet,
} from 'react-native'

import EmptyNotice from '@src/components/EmptyNotice'
import { showErrorMessage, showSuccessMessage } from '@src/components/FlashMessage'
import HeaderIcon from '@src/components/HeaderIcon'
import OrfiListItem from '@src/components/ListItem/OrfiListItem'
import Loading from '@src/components/Loading'
import MembersModal from '@src/components/PopupModal/MembersModal'
import Text from '@src/components/Text'
import { colors, fonts } from '@src/config/theme'
import { GET_GROUP, GET_GROUPS_REQUESTS } from '@src/graphql/queries'
import {
  useApproveRejectRequestJoinGroupMutation,
  useGroupQuery,
  useGroupRequestsQuery,
  useMeQuery,
  useRemoveMemberGroupMutation,
  useSetAdminGroupMutation,
} from '@src/graphql/types'
import { DashboardMembersScreenNavigationProps, DashboardMembersScreenRouteProps } from '@src/types'
import getInitials from '@src/utils/getInitials'

import styles from './DashboardMembersScreen.styles'
import { BACK } from '../../../rn-banked-checkout/strings'

const sectionColors = {
  0: colors.primary,
  1: colors.secondary,
}

const DashboardMembersScreen = () => {
  const navigation = useNavigation<DashboardMembersScreenNavigationProps>()
  const { params } = useRoute<DashboardMembersScreenRouteProps>()

  const [searchText, updateSearchText] = useState('')
  const [memberRequests, setMemberRequests] = useState(true)
  const [currentMembers, setCurrentMembers] = useState(true)
  const [selectedUser, setSelectedUser] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)

  const { data: meQuery, error: meError } = useMeQuery()

  const {
    loading,
    data: { group } = {},
    error,
    refetch: groupRefetch,
  } = useGroupQuery({
    variables: {
      groupUuid: params?.groupUuid,
    },
  })

  const {
    loading: requestLoading,
    data: requestData,
    refetch: requestRefetch,
  } = useGroupRequestsQuery({
    variables: {
      groupUuid: params?.groupUuid,
    },
    skip: !group?.isAdmin,
  })

  const [approveOrRejectUser, { loading: approveOrRejectLoading }] =
    useApproveRejectRequestJoinGroupMutation({
      onCompleted: async () => {
        await Promise.all([groupRefetch(), requestRefetch()])
      },
    })

  const [promoteUser, { loading: promoteLoading }] = useSetAdminGroupMutation()

  const [removeUser, { loading: removeLoading }] = useRemoveMemberGroupMutation()

  useLayoutEffect(() => {
    if (!group?.uuid) {
      return
    }

    const headerRight = () => (
      <HeaderIcon
        name="user-plus"
        type="feather"
        onPress={() =>
          navigation.navigate('DashboardInviteMemberScreen', {
            groupUuid: group.uuid,
            groupName: group.name,
          })
        }
      />
    )

    navigation.setOptions({
      headerTitle: group.name,
      headerRight,
    })
  }, [group, navigation])

  if (loading || requestLoading) {
    return <Loading />
  }

  if (error || meError) {
    return (
      <EmptyNotice title="NOTHING TO SHOW" text="Seems like there are no members for this group." />
    )
  }

  const getChevronIcon = (index) => {
    switch (index) {
      case 0:
        return memberRequests ? 'chevron-down' : 'chevron-right'
      case 1:
        return currentMembers ? 'chevron-down' : 'chevron-right'
    }
  }

  const handleCollapse = (collapse, index) => {
    switch (index) {
      case 0:
        setMemberRequests(collapse)
        break
      case 1:
        setCurrentMembers(collapse)
        break
    }
  }

  const showWarning = () => {
    showErrorMessage({
      message: 'Warning',
      description: 'Oops an error has occurred. Please try again',
      duration: 6000,
    })
  }

  const onApprove = async (user) => {
    toggleUser(user)
    try {
      await approveOrRejectUser({
        variables: {
          groupUuid: group.uuid,
          requestUserId: user?.id,
          accept: true,
        },
        refetchQueries: [
          {
            query: GET_GROUP,
            variables: {
              groupUuid: params?.groupUuid,
            },
          },
          {
            query: GET_GROUPS_REQUESTS,
            variables: {
              groupUuid: params?.groupUuid,
            },
          },
        ],
        awaitRefetchQueries: true,
      })

      showSuccessMessage({
        message: 'Success',
        description: `${user?.name} ${user?.surname} has been added`,
      })
    } catch (err) {
      console.log('err', err)
      showWarning()
    }
    toggleUser()
  }

  const onReject = async (user) => {
    toggleUser(user)
    try {
      await approveOrRejectUser({
        variables: {
          groupUuid: group.uuid,
          requestUserId: user?.id,
          accept: false,
        },
        refetchQueries: [
          {
            query: GET_GROUP,
            variables: {
              groupUuid: params?.groupUuid,
            },
          },
          {
            query: GET_GROUPS_REQUESTS,
            variables: {
              groupUuid: params?.groupUuid,
            },
          },
        ],
        awaitRefetchQueries: true,
      })

      showSuccessMessage({
        message: 'Success',
        description: `${user?.name} ${user?.surname} has been declined`,
      })
    } catch (err) {
      console.log('err', err)
      showWarning()
    }
    toggleUser()
  }

  const onPromote = async (user) => {
    try {
      await promoteUser({
        variables: {
          groupUuid: group.uuid,
          userId: user.id,
          isAdmin: true,
        },
        refetchQueries: [
          {
            query: GET_GROUP,
            variables: {
              groupUuid: params?.groupUuid,
            },
          },
        ],
        awaitRefetchQueries: true,
      })

      showSuccessMessage({
        message: 'Success',
        description: `${user?.name} ${user?.surname} is now Admin of ${group?.name}`,
      })
    } catch (err) {
      console.log('err', err)
      showWarning()
    }
    toggleUser()
  }

  const onDemote = async (user) => {
    try {
      await promoteUser({
        variables: {
          groupUuid: group.uuid,
          userId: user.id,
          isAdmin: false,
        },
        refetchQueries: [
          {
            query: GET_GROUP,
            variables: {
              groupUuid: params?.groupUuid,
            },
          },
        ],
        awaitRefetchQueries: true,
      })

      showSuccessMessage({
        message: 'Success',
        description: `${user?.name} ${user?.surname} is no longer an Admin of ${group?.name}`,
      })
    } catch (err) {
      console.log('err', err)
      showWarning()
    }
    toggleUser()
  }

  const onRemove = async (user) => {
    try {
      await removeUser({
        variables: {
          groupUuid: group.uuid,
          userId: user.id,
        },
        refetchQueries: [
          {
            query: GET_GROUP,
            variables: {
              groupUuid: params?.groupUuid,
            },
          },
        ],
        awaitRefetchQueries: true,
      })

      showSuccessMessage({
        message: 'Success',
        description: `${user?.name} ${user?.surname} has been removed from group`,
      })
    } catch (err) {
      console.log('err', err)
      showWarning()
    }
    toggleUser()
  }

  const onMemberPress = (user) => {
    navigation.navigate('PublicProfileScreen', { userId: user.id })
  }

  const getMembersActions = () => {
    if (!selectedUser) return []
    return [
      {
        label: 'View Profile',
        action: () => {
          onMemberPress(selectedUser)
          setModalOpen(false)
        },
        labelColor: colors.secondary,
      },
      !selectedUser.isAdmin
        ? {
            label: 'Promote to Admin',
            action: () => {
              onPromote(selectedUser)
              setModalOpen(false)
            },
            labelColor: colors.secondary,
          }
        : {
            label: 'Demote to Member',
            action: () => {
              onDemote(selectedUser)
              setModalOpen(false)
            },
            labelColor: colors.secondary,
          },
      {
        label: 'Remove',
        action: () => {
          onRemove(selectedUser)
          setModalOpen(false)
        },
        labelColor: colors.primary,
      },
    ].filter((i) => i)
  }

  const toggleUser = (user = null) => {
    if (selectedUser) setSelectedUser(null)
    else setSelectedUser(user)
  }

  const { groupUsers } = group
  const groupRequests = requestData?.groupRequests || []

  const searchRequests = (groupRequests || []).filter(
    (grp) =>
      grp.requestUser?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
      grp.requestUser?.surname?.toLowerCase().includes(searchText.toLowerCase()),
  )

  const searchMembers = (groupUsers || []).filter(
    (grp) =>
      grp.user.name.toLowerCase().includes(searchText.toLowerCase()) ||
      grp.user.surname.toLowerCase().includes(searchText.toLowerCase()),
  )

  const members = [
    {
      title: `MEMBER REQUESTS (${searchRequests.length})`,
      data: searchRequests,
      index: 0,
      state: memberRequests,
    },
    {
      title: `CURRENT MEMBERS (${searchMembers.length})`,
      data: searchMembers.sort((x, y) => y.isAdmin - x.isAdmin),
      index: 1,
      state: currentMembers,
    },
  ]

  const renderSubtitle = (isAdmin, index, createdAt) =>
    index ? (
      <Text>
        {isAdmin && (
          <Text style={styles.subtitleTextPrimary}>
            Admin
            <Text style={styles.subtitleTextSecondary}> | </Text>
          </Text>
        )}
        Member since {moment(createdAt).format('Do MMM, YYYY')}
      </Text>
    ) : (
      `Requested ${moment(createdAt).fromNow()}`
    )

  const handleRefresh = () => {
    Promise.all([requestRefetch(), groupRefetch()])
  }

  return (
    <SafeAreaView style={styles.container}>
      <SearchBar
        containerStyle={styles.searchBarContainer}
        inputContainerStyle={styles.searchBarInputContainer}
        inputStyle={styles.searchBarInputStyle}
        placeholder="Search by name"
        onChangeText={updateSearchText}
        value={searchText}
      />
      <ScrollView
        refreshControl={
          <RefreshControl refreshing={requestLoading || loading} onRefresh={handleRefresh} />
        }>
        {members.map(({ title, data, index, state }) => (
          <Collapse
            onToggle={(isCollapsed) => handleCollapse(isCollapsed, index)}
            isExpanded={state}
            style={styles.collapseStyle}
            key={index}>
            <CollapseHeader>
              <View
                style={StyleSheet.flatten([
                  styles.sectionTitle,
                  {
                    borderBottomColor: sectionColors[index],
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  },
                ])}>
                <Text style={{ fontFamily: fonts.default.bold, color: sectionColors[index] }}>
                  {title}
                </Text>
                <Icon name={getChevronIcon(index)} type="feather" color={sectionColors[index]} />
              </View>
            </CollapseHeader>
            <CollapseBody>
              {data.map(({ user, requestUser, createdAt, isAdmin = false }) => {
                const isYou = user && user.id === meQuery.me.id
                return (
                  <OrfiListItem
                    key={createdAt}
                    title={
                      requestUser
                        ? `${requestUser?.name} ${requestUser?.surname}`
                        : `${user?.name} ${user?.surname}`
                    }
                    subtitle={renderSubtitle(isAdmin, index, createdAt)}
                    avatarUri={user?.avatar || requestUser?.avatar}
                    avatarPlaceholder={getInitials(requestUser ? requestUser?.name : user?.name)}
                    callToAction={
                      group.isAdmin ? (
                        !index ? (
                          <View style={styles.iconContainer}>
                            {approveOrRejectLoading && selectedUser.id === requestUser.id ? (
                              <ActivityIndicator />
                            ) : (
                              <Icon
                                name="x-circle"
                                type="feather"
                                size={30}
                                color={colors.primary}
                                onPress={() => onReject(requestUser)}
                                iconStyle={styles.iconStyle}
                              />
                            )}
                            {approveOrRejectLoading && selectedUser.id === requestUser.id ? (
                              <ActivityIndicator />
                            ) : (
                              <Icon
                                name="check-circle"
                                type="feather"
                                size={30}
                                color={colors.success}
                                onPress={() => onApprove(requestUser)}
                                iconStyle={styles.iconStyle}
                              />
                            )}
                          </View>
                        ) : !isYou ? (
                          <>
                            {(promoteLoading || removeLoading) && selectedUser.id === user.id ? (
                              <ActivityIndicator />
                            ) : (
                              <Icon
                                name="more-horizontal"
                                type="feather"
                                size={30}
                                color={colors.secondary}
                                onPress={() => {
                                  toggleUser({ ...user, isAdmin })
                                  setModalOpen(true)
                                }}
                              />
                            )}
                          </>
                        ) : null
                      ) : null
                    }
                    onPress={() => (index ? onMemberPress(user) : null)}
                  />
                )
              })}
            </CollapseBody>
          </Collapse>
        ))}
      </ScrollView>
      <MembersModal
        visible={modalOpen}
        toggle={() => {
          setModalOpen(false)
          toggleUser()
        }}
        actions={getMembersActions()}
      />
    </SafeAreaView>
  )
}

export default DashboardMembersScreen
