const common = {
  termsUrl: 'https://www.orfiactive.com/privacy-policy-and-terms',
  privacyPolicyUrl: 'https://www.orfiactive.com/privacy-policy-and-terms',
  faqUrl: 'https://www.orfiactive.com/faq',
  autocompleteKey: 'AIzaSyBMtYYhDuygH_fzr3PfGRvQykp5BubDDD0',
}

const ENV = {
  test: {
    // apiUrl: 'https://api-test.orfiactive.com/graphql/',
    apiUrl: 'https://api.orfiactive.com/graphql/',
    bankedKey: '2fec683a2ee446ab30392aab488ec364',
  },
  prod: {
    apiUrl: 'https://api.orfiactive.com/graphql/',
    bankedKey: '8378a1926a33b0470207b31b732c9779',
  },
}

const getEnvVars = (env = 'prod') => {
  let config
  switch (env) {
    case 'staging':
    case 'prod':
      config = ENV.prod
      break
    default:
      config = ENV.prod
      break
  }

  return {
    ...common,
    ...config,
  }
}

export default getEnvVars
