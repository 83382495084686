import { StyleSheet } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
    backgroundColor: colors.white,
  },
  container: {
    flex: 1,
    alignItems: 'center',
    width: '80%',
    alignSelf: 'center',
    paddingTop: 90,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '90%',
    alignSelf: 'center',
    paddingVertical: 6,
  },
  bottomButtonContainer: {
    position: 'absolute',
    right: 0,
    left: 0,
    paddingTop: 10,
    paddingBottom: 20,
    bottom: 0,
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  title: {
    color: colors.secondary,
    fontFamily: fonts.default.bold,
    fontSize: 16,
    marginTop: 20,
  },
  text: {
    color: colors.secondary,
    marginVertical: 30,
    textAlign: 'center',
  },
  scrollView: {
    flex: 1,
  },
  bottomButtonInnerContainer: {
    width: '75%',
  },
  buttonStyle: {
    backgroundColor: colors.secondary,
  },
})

export default styles
