import React from 'react'
import { FlatList } from 'react-native'

import styles from './ProviderList.styles'

import Provider from '../Provider'

const ProviderList = (props) => {
  const getProviders = () =>
    props.providerBrands
      .map((providerBrand) =>
        providerBrand.providers.map((provider) => ({ logo: providerBrand.logo, ...provider })),
      )
      .flat()
  return (
    <FlatList
      data={getProviders()}
      renderItem={({ item }) => (
        <Provider
          title={item.name}
          logo={item.logo}
          id={item.id}
          status={item.status}
          statusDetail={item.status_detail}
          onPress={props.chooseProvider}
        />
      )}
      keyExtractor={(item) => item.id}
      contentContainerStyle={styles.flatListContainer}
    />
  )
}

export default ProviderList
