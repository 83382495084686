import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native'
import { Button } from '@rneui/themed'
import { useFormik } from 'formik'
import { useEffect, useLayoutEffect, useState } from 'react'
import { BackHandler, SafeAreaView, View, KeyboardAvoidingView } from 'react-native'

import ConfirmDialog from '@src/components/ConfirmDialog'
import DualButton from '@src/components/DualButton'
import { showErrorMessage } from '@src/components/FlashMessage'
import StepFiveSummary from '@src/components/forms/CreateEvent/StepFiveSummary'
import StepFourForm from '@src/components/forms/CreateEvent/StepFourForm'
import StepOneForm from '@src/components/forms/CreateEvent/StepOneForm'
import StepThreeForm from '@src/components/forms/CreateEvent/StepThreeForm'
import StepTwoForm from '@src/components/forms/CreateEvent/StepTwoForm'
import HeaderIcon from '@src/components/HeaderIcon'
import Loading from '@src/components/Loading'
import NoWalletAccountPopup from '@src/components/PopupModal/NoWalletAccount'
import ProgressBar from '@src/components/ProgressBar'
import {
  defaultGender,
  defaultPayMethod,
  defaultPrivacyValue,
  defaultRefundPolicy,
  defaultSkillLevel,
  defaultVenue,
} from '@src/config/forms'
import { GET_GROUP, ME_QUERY } from '@src/graphql/queries'
import { useCreateMultipleSessionsMutation } from '@src/graphql/types'
import { useMeQuery } from '@src/graphql/types'
import { CreateSessionScreenRouteProps, CreateSessionScreenNavigationProps } from '@src/types'
import { calculateSessions } from '@src/utils/forms'

import styles from './CreateSessionScreen.styles'
import {
  Step1Schema,
  Step2Schema,
  Step3Schema,
  Step4Schema,
} from './CreateSessionScreen.validation'
import { CreateSessionScreenFormTypes } from './types.d'

const buttonLabels = [
  {
    back: 'Cancel',
    forward: 'Next',
  },
  {
    back: 'Back',
    forward: 'Set Time/Date',
  },
  {
    back: 'Back',
    forward: 'Set Pricing',
  },
  {
    back: 'Back',
    forward: 'Confirm',
  },
  {
    back: 'Back',
    forward: 'Create',
  },
]

const CreateSessionScreen = () => {
  const isFocused = useIsFocused()
  const [currentStep, setCurrentStep] = useState(0)
  const [lastStep, setLastStep] = useState(4)
  const [groupUuid, setGroupUuid] = useState('')

  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState(false)
  const [noWalletAccount, setNoWalletAccount] = useState(false)
  const { params } = useRoute<CreateSessionScreenRouteProps>()
  const navigation = useNavigation<CreateSessionScreenNavigationProps>()
  const { data: meQuery = { me: { accountNumber: '' } }, loading: meLoading } = useMeQuery()
  const [createSessionMutation, { loading }] = useCreateMultipleSessionsMutation()

  useEffect(() => {
    if (params.groupUuid) {
      setGroupUuid(params.groupUuid)
    }
  }, [params.groupUuid])

  const handleSubmit = async (values: CreateSessionScreenFormTypes) => {
    setSubmitting(true)
    if (currentStep === 3) {
      const sessions = calculateSessions(values)
      setFieldValue('sessions', sessions)
    }

    if (currentStep !== lastStep) {
      setSubmitting(false)
      return null
    }

    const link = values.links[0]

    try {
      await createSessionMutation({
        variables: {
          ...values,
          dates: values.sessions,
          activityId: values.activity.id,
          isPrivate: values.privacy === 'PRIVATE',
          address: values.venueAddress.address,
          addressLatitude: values.venueAddress.latitude,
          addressLongitude: values.venueAddress.longitude,
          links: link.url ? values.links : [],
          minAge: values.minAge ? parseInt(values.minAge, 10) : null,
          maxAge: values.maxAge ? parseInt(values.maxAge, 10) : null,
          minParticipants: values.minParticipants ? parseInt(values.minParticipants, 10) : null,
          maxParticipants: values.maxParticipants ? parseInt(values.maxParticipants, 10) : null,
          groupUuid,
        },
        refetchQueries: [
          {
            query: GET_GROUP,
            variables: {
              groupUuid,
            },
          },
          {
            query: ME_QUERY,
          },
        ],
        awaitRefetchQueries: true,
      })
      if (params?.backRoute) {
        navigation.navigate(params.backRoute, {
          groupUuid: params?.groupUuid,
          sessionAdded: true,
        })
      } else {
        navigation.replace('DashboardGroupScreen', { groupUuid })
      }
    } catch (e) {
      setSubmitting(false)
      showErrorMessage({
        message: 'Warning',
        description: "Oops, something's gone wrong. Please try again or send us feedback.",
        duration: 6000,
      })
    }
  }

  const formik = useFormik({
    enableReinitialize: false,
    onSubmit: handleSubmit,
    initialValues: initialValues(),
    validationSchema: schemaArray[currentStep],
  })

  const {
    isSubmitting,
    isValid,
    setErrors,
    setFieldTouched,
    setFieldValue,
    setSubmitting,
    submitForm,
    setTouched,
    validateForm,
    values,
  } = formik

  useLayoutEffect(() => {
    if (!meQuery) return
    navigation.setOptions({
      headerRight: () => (
        <Button type="clear" title="CANCEL" onPress={() => toggleConfirmationDialog()} />
      ),
      headerLeft: () => <HeaderIcon onPress={prevStep} />,
    })
  }, [meQuery])

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <HeaderIcon onPress={prevStep} />,
    })
  })

  useEffect(() => {
    const backAction = () => {
      if (isFocused) {
        prevStep()
        return true
      }
    }
    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction)
    return () => backHandler.remove()
  }, [isFocused, prevStep])

  useEffect(() => {
    if (params?.activity) {
      const {
        activity: { name, id },
      } = params
      setFieldTouched('activity')
      setFieldValue('activity', { name, id })
    }
    if (params?.venueAddress) {
      const { venueAddress } = params
      setFieldValue('venueAddress', venueAddress)
    }
    if (params?.accountAdded) {
      setCurrentStep(Math.min(currentStep + 1, lastStep))
    }
  }, [currentStep, params, lastStep, setFieldTouched, setFieldValue])

  const calculateCurrentProgress = () => {
    const numberOfSteps = lastStep + 1
    const currentStepAdjusted = currentStep + 1

    return currentStepAdjusted / numberOfSteps
  }

  const prevStep = () => {
    const newStep = Math.max(currentStep - 1, 0)

    if (currentStep === newStep) {
      toggleConfirmationDialog()
      return true
    }

    setErrors({})
    setCurrentStep(newStep)
    setNoWalletAccount(false)

    return true
  }

  const nextStep = () => {
    const { paymentMethod, prices } = values

    submitForm().then(() => {
      if (isValid) {
        const goToNext = () => {
          const currStep = Math.min(currentStep + 1, lastStep)
          setCurrentStep(currStep)

          validateForm()
          setTouched({})
        }
        const isFree = prices.length === 1 && prices[0]?.value === '0'
        if (currentStep === 3 && !meQuery.me.accountNumber && paymentMethod !== defaultPayMethod) {
          if (!isFree) {
            toggleWalletAccountDialog()
          } else {
            setFieldValue('paymentMethod', defaultPayMethod)
            goToNext()
          }
        } else {
          goToNext()
        }
      } else {
        showErrorMessage({
          message: 'Warning',
          description: "Oops, something's not right there. Double check the fields and try again.",
          duration: 6000,
        })
      }
    })
  }

  const toggleConfirmationDialog = () => {
    setIsConfirmationDialogVisible(!isConfirmationDialogVisible)
  }

  const toggleWalletAccountDialog = () => {
    setNoWalletAccount(!noWalletAccount)
  }

  const onSetupAccount = () => {
    navigation.navigate('MyWalletScreen', { backRoute: 'CreateSessionScreen' })
  }

  const RenderConfirmDialog = () => (
    <ConfirmDialog
      title="Careful..."
      message="Are you sure? Changes will be lost."
      negativeButton={{
        title: 'Stay',
        onPress: () => setIsConfirmationDialogVisible(false),
      }}
      positiveButton={{
        title: 'Exit',
        onPress: () => {
          setIsConfirmationDialogVisible(false)
          navigation.goBack()
        },
      }}
      visible={isConfirmationDialogVisible}
    />
  )

  const buttonText = buttonLabels[currentStep]

  if (meLoading) return <Loading />

  return (
    <SafeAreaView style={styles.container}>
      <RenderConfirmDialog />
      <KeyboardAvoidingView style={styles.container}>
        <View style={styles.stepContainer}>
          <ProgressBar progress={calculateCurrentProgress()} />
        </View>
        {currentStep === 0 && <StepOneForm {...formik} />}
        {currentStep === 1 && <StepTwoForm {...formik} />}
        {currentStep === 2 && <StepThreeForm {...formik} />}
        {currentStep === 3 && <StepFourForm {...formik} />}
        {currentStep === 4 && <StepFiveSummary {...formik} />}
        <View style={styles.buttonContainer}>
          <View style={styles.button}>
            <DualButton
              leftTitle={buttonText.back}
              rightTitle={buttonText.forward}
              leftOnPress={prevStep}
              rightOnPress={nextStep}
              loading={loading || isSubmitting}
            />
          </View>
        </View>
      </KeyboardAvoidingView>
      <NoWalletAccountPopup
        visible={noWalletAccount}
        toggle={toggleWalletAccountDialog}
        onSetUpAccount={onSetupAccount}
      />
    </SafeAreaView>
  )
}

const schemaArray = [Step1Schema, Step2Schema, Step3Schema, Step4Schema]

const initialValues = () => ({
  name: '',
  activity: {
    name: '',
    id: '',
  },
  gender: defaultGender,
  skillLevel: defaultSkillLevel,
  minAge: '',
  maxAge: '',
  minParticipants: '',
  maxParticipants: '',
  privacy: defaultPrivacyValue,
  venueType: defaultVenue,
  prices: [
    {
      value: '0',
      description: '',
    },
  ],
  links: [{ name: '', url: '' }],
  description: '',
  rules: '',
  notes: '',
  refundPolicy: defaultRefundPolicy,
  acceptCash: false,
  paymentMethod: 'APP',
  sessions: [],
  venueAddress: {
    latitude: null,
    longitude: null,
    address: '',
  },

  recurringType: 'ONCE',
  recurringEveryOtherWeek: false,
  recurringDaysOfTheWeek: [0, 1, 2, 3, 4, 5, 6],
  recurringEndDate: undefined,
  sessionStartDate: undefined,
  sessionEndDate: undefined,
})

export default CreateSessionScreen
