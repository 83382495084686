import { ListItem } from '@rneui/themed'
import { Image, ScrollView, View } from 'react-native'

import { IMAGE_EVENT_HEADER } from '@src/assets/images'
import Text from '@src/components/Text'
import {
  formatAgeRange,
  formatEventDates,
  formatGender,
  formatLinks,
  formatParticipants,
  formatPricing,
  formatPrivacy,
  formatRefundPolicy,
  formatSkillLevel,
  formatVenueType,
} from '@src/utils/forms'

import useStyle from './styles'

const StepFive = ({ errors, values }) => {
  const styles = useStyle()

  const data = [
    {
      name: 'Group Name',
      subtitle: values.name,
    },
    {
      name: 'Activity Type',
      subtitle: values.activity.name,
    },
    {
      name: 'Gender',
      subtitle: formatGender(values.gender),
    },
    {
      name: 'Age Range',
      subtitle: formatAgeRange(values.minAge, values.maxAge),
    },
    {
      name: 'Participants needed',
      subtitle: formatParticipants(values.minParticipants, values.maxParticipants),
    },
    {
      name: 'Skill Level',
      subtitle: formatSkillLevel(values.skillLevel),
    },
    {
      name: 'Privacy',
      subtitle: formatPrivacy(values.privacy),
    },
    {
      name: 'Venue Location',
      subtitle: values.venueAddress.address,
    },
    {
      name: 'Group Dates',
      subtitle: formatEventDates(values.sessions),
    },
    {
      name: 'Venue Type',
      subtitle: formatVenueType(values.venueType),
    },
    {
      name: 'Pricing',
      subtitle: formatPricing(values.prices),
    },
    {
      name: 'Refund Policy',
      subtitle: formatRefundPolicy(values.refundPolicy),
    },
    {
      name: 'Group Description',
      subtitle: values.description,
    },
    {
      name: 'Group Notes',
      subtitle: values.notes,
    },
    {
      name: 'Group Links',
      subtitle: formatLinks(values.links),
    },
  ]

  let imageSource
  if (values.image) {
    imageSource = { uri: values.image.uri }
  } else {
    imageSource = IMAGE_EVENT_HEADER
  }

  return (
    <ScrollView
      style={styles.stepFiveScrollViewStyle}
      contentContainerStyle={styles.scrollViewContent}>
      <View style={styles.listItemContainer}>
        {errors.form && <Text>{errors.form}</Text>}
        <Image source={imageSource} style={styles.imageStyle} />
        {data.map((item) => (
          <ListItem key={item.name} bottomDivider containerStyle={styles.itemContainer}>
            <ListItem.Title style={styles.stepFiveTitle}>{item.name}</ListItem.Title>
            <ListItem.Subtitle style={styles.stepFiveSubtitle}>{item.subtitle}</ListItem.Subtitle>
          </ListItem>
        ))}
      </View>
    </ScrollView>
  )
}

export default StepFive
