import { useRoute, useNavigation } from '@react-navigation/native'
import { Button, Input } from '@rneui/themed'
import { useFormik, FormikHelpers } from 'formik'
import { View } from 'react-native'

import { colors } from '@src/config/theme'
import { GET_WALL_POSTS } from '@src/graphql/queries'
import { useWallCreatePostMutation } from '@src/graphql/types'
import {
  DashboardWallCommentReplyScreenRouteProps,
  DashboardWallCommentReplyScreenNavigationProps,
} from '@src/types'

import styles from './DashboardWallCommentReplyScreen.styles'
import validationSchema from './DashboardWallCommentReplyScreen.validation'
import { CommentFormType } from './types'

const DashboardWallCommentReplyScreen = () => {
  const navigation = useNavigation<DashboardWallCommentReplyScreenNavigationProps>()
  const {
    params: { parentUuid, groupUuid },
  } = useRoute<DashboardWallCommentReplyScreenRouteProps>()
  const [wallCreatePost, { loading }] = useWallCreatePostMutation({
    update(cache, { data }) {
      if (!data?.wallCreatePost?.post) {
        return
      }

      const {
        wallCreatePost: { post },
      } = data

      const { wallPosts } = cache.readQuery({
        query: GET_WALL_POSTS,
        variables: { groupUuid, parentUuid },
      })

      cache.writeQuery({
        query: GET_WALL_POSTS,
        data: {
          wallPosts: [post, ...wallPosts],
        },
        variables: { groupUuid, parentUuid },
      })
    },
    onCompleted: ({ wallCreatePost: { ok } }) => {
      if (ok) {
        navigation.goBack()
      } else {
        throw new Error('Failed to create wall post')
      }
    },
  })

  const handleOnSubmit = async (
    { comment }: CommentFormType,
    { setFieldError }: FormikHelpers<CommentFormType>,
  ): Promise<void> => {
    try {
      await wallCreatePost({
        variables: {
          groupUuid,
          content: comment,
          parentUuid,
        },
      })
    } catch (error) {
      setFieldError('form', 'Please try again later')
    }
  }

  const initialValues = {
    comment: '',
  }

  const { values, handleChange, setFieldTouched, errors, touched, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: handleOnSubmit,
    validationSchema,
  })

  return (
    <View>
      <View style={styles.postComment}>
        <Button
          title="POST"
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          onPress={handleSubmit as any}
          type="clear"
          containerStyle={undefined}
          loading={loading}
        />
      </View>
      <Input
        placeholder="Add a comment"
        placeholderTextColor={colors.black}
        value={values.comment}
        onBlur={() => setFieldTouched('comment')}
        onChangeText={handleChange('comment')}
        errorMessage={touched.comment && errors.comment ? errors.comment : undefined}
        autoFocus
        editable={!loading}
        inputContainerStyle={styles.inputContainer}
        inputStyle={styles.inputStyle}
        multiline
      />
    </View>
  )
}

export default DashboardWallCommentReplyScreen
