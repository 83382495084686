import { BottomTabNavigationOptions, createBottomTabNavigator } from '@react-navigation/bottom-tabs'

import TabBarIcon from '@src/components/TabBarIcon'
import { colors, fonts } from '@src/config/theme'
import AttendingScreen from '@src/screens/MainTab/AttendingScreen'
import CreateScreen from '@src/screens/MainTab/CreateScreen'
import GroupScreen from '@src/screens/MainTab/GroupScreen'
import ProfileScreen from '@src/screens/MainTab/ProfileScreen'

const BottomTab = createBottomTabNavigator()

const MyActiveIcon = () => <TabBarIcon name="zap" />
const GroupIcon = () => <TabBarIcon name="users" />
const CreateIcon = () => <TabBarIcon name="plus-circle" />
const ProfileIcon = () => <TabBarIcon name="user" />

export default function MainTabNavigator() {
  const tabBarOptions: BottomTabNavigationOptions = {
    headerTitleStyle: {
      fontFamily: fonts.default.bold,
    },
    tabBarActiveTintColor: colors.primary,
    tabBarInactiveTintColor: colors.grey1,
    tabBarLabelStyle: {
      fontFamily: fonts.default.bold,
      fontSize: 9,
    },
    tabBarItemStyle: {},
    tabBarStyle: {
      height: 70,
      paddingBottom: 15,
      paddingTop: 10,
    },
  }

  return (
    <BottomTab.Navigator screenOptions={tabBarOptions} initialRouteName="AttendingScreen">
      <BottomTab.Screen
        name="AttendingScreen"
        component={AttendingScreen}
        options={{
          title: 'MY ACTIVE',
          tabBarIcon: MyActiveIcon,
          tabBarLabel: 'MY ACTIVE',
        }}
      />
      <BottomTab.Screen
        name="GroupScreen"
        component={GroupScreen}
        options={{
          title: 'GROUPS',
          tabBarIcon: GroupIcon,
          tabBarLabel: 'GROUPS',
        }}
      />
      <BottomTab.Screen
        name="CreateScreen"
        component={CreateScreen}
        options={{
          title: 'CREATE',
          tabBarLabel: 'CREATE',
          tabBarIcon: CreateIcon,
        }}
      />
      <BottomTab.Screen
        name="ProfileScreen"
        component={ProfileScreen}
        options={{
          title: 'PROFILE',
          tabBarLabel: 'PROFILE',
          tabBarIcon: ProfileIcon,
        }}
      />
    </BottomTab.Navigator>
  )
}
