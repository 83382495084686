import { Query } from '@apollo/client/react/components'
import { useNavigation } from '@react-navigation/native'
import { Button, Divider, Icon } from '@rneui/themed'
import moment from 'moment'
import { useLayoutEffect } from 'react'
import { Platform, SectionList, View } from 'react-native'

import EmptyNotice from '@src/components/EmptyNotice'
import HeaderIcon from '@src/components/HeaderIcon'
import OrfiListItem from '@src/components/ListItem/OrfiListItem'
import Loading from '@src/components/Loading'
import NotificationTick from '@src/components/NotificationTick'
import Text from '@src/components/Text'
import TopBanner from '@src/components/TopBanner'
import { colors } from '@src/config/theme'
import { attendingOptions } from '@src/graphql/apollo'
import { GET_SESSION_MEMBERSHIP } from '@src/graphql/queries'
import { MembershipStatus, useSessionMembershipQuery } from '@src/graphql/types'
import { AttendingScreenNavigationProps } from '@src/types'
import getInitials from '@src/utils/getInitials'

import styles from './AttendingScreen.styles'

const AttendingScreen = () => {
  const navigation = useNavigation<AttendingScreenNavigationProps>()
  const {
    data: upcoming,
    refetch,
    loading: upcomingLoading,
  } = useSessionMembershipQuery({
    variables: { ...attendingOptions },
  })

  useLayoutEffect(() => {
    const headerRight = () => (
      <View style={styles.headerRight}>
        <HeaderIcon
          name="plus-circle"
          type="feather"
          onPress={() => navigation.navigate('SelectGroupScreen')}
          right
        />
        <NotificationTick />
      </View>
    )
    navigation.setOptions({
      headerRight,
    })
  }, [navigation])

  const onPress = (session) => {
    const { name, id } = session
    navigation.navigate('SessionDetailScreen', { sessionId: id, name })
  }

  const navigateToSelectGroup = () => navigation.navigate('SelectGroupScreen')

  const attendingCTA = () => (
    <Icon name="chevron-right" type="feather" color={colors.grey} size={20} />
  )

  const renderItem = ({ item }) => {
    const { session, status } = item
    const { status: sessionStatus } = session
    const past = moment().isAfter(session.startAt)
    const waiting = status === 'WAITING'
    const cancelled = sessionStatus.toLowerCase() === 'cancelled'

    return (
      <OrfiListItem
        status={
          waiting ? 'in waiting list' : cancelled ? 'cancelled' : past ? 'inactive' : 'default'
        }
        avatarUri={session.image || session.activity[0]?.defaultBannerImage}
        avatarPlaceholder={getInitials(session?.name)}
        topBadge={session.owner.isOrfiVerified ? 'orfi' : ''}
        title={session?.name}
        subtitle={moment(session?.startAt).format('dddd, DD MMM, H:mm')}
        bottomText={session?.address}
        callToAction={attendingCTA()}
        onPress={() => onPress(session)}
        divider
      />
    )
  }

  return (
    <>
      <TopBanner />

      <View style={styles.container}>
        <Query
          query={GET_SESSION_MEMBERSHIP}
          variables={{
            status_In: ['ATTENDING'],
            startAt_Lte: `${moment().format()}`,
            orderBy: '-start_at',
            limit: 10,
            offset: 0,
          }}
          fetchPolicy="cache-and-network">
          {({ loading, data, fetchMore }) => {
            if (loading && !data?.sessionMembership) return <Loading />
            const upcomingData =
              upcoming?.sessionMembership.filter(
                ({ status }) => status === MembershipStatus.Attending,
              ) || []
            const pendingPaymentData =
              upcoming?.sessionMembership.filter(
                ({ status }) => status === MembershipStatus.PaymentPending,
              ) || []
            const passData = data?.sessionMembership || []
            let allData = []
            if (upcomingData.length || passData.length || pendingPaymentData.length) {
              allData = [
                { title: 'Active', data: upcomingData },
                { title: 'Payment Pending', data: pendingPaymentData },
                { title: 'Past Sessions', data: passData },
              ]
            }
            return (
              <SectionList
                sections={allData}
                keyExtractor={(item, index) => index.toString()}
                stickySectionHeadersEnabled={false}
                contentContainerStyle={styles.sectionList}
                renderSectionHeader={({ section: { title } }) =>
                  title ? (
                    <View style={styles.sectionTitleVw}>
                      <Text style={styles.sectionTitle}>{title}</Text>
                      <Divider />
                    </View>
                  ) : null
                }
                renderItem={renderItem}
                ListHeaderComponent={() =>
                  !upcomingData.length && passData.length ? (
                    <View style={styles.headerContainer}>
                      <Text style={styles.headerText}>You have no upcoming sessions.</Text>
                      <Button
                        title="Create a session"
                        titleStyle={styles.exploreTitle}
                        type="clear"
                        onPress={navigateToSelectGroup}
                      />
                    </View>
                  ) : null
                }
                ListEmptyComponent={
                  <View style={styles.emptyFlatList}>
                    <EmptyNotice
                      title="NOTHING TO SHOW"
                      text={
                        "This is where you'll find sessions you're attending... once you've started attending sessions!"
                      }
                    />
                  </View>
                }
                onEndReachedThreshold={0.01}
                onEndReached={() => {
                  if (data?.sessionMembership.length > 9) {
                    fetchMore({
                      variables: {
                        offset: data.sessionMembership.length,
                      },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev
                        return {
                          ...prev,
                          sessionMembership: [
                            ...prev.sessionMembership,
                            ...fetchMoreResult.sessionMembership,
                          ],
                        }
                      },
                    })
                  }
                }}
                onRefresh={refetch}
                refreshing={upcomingLoading}
                removeClippedSubviews={Platform.OS !== 'ios'}
                initialNumToRender={10}
                updateCellsBatchingPeriod={100}
                windowSize={10}
              />
            )
          }}
        </Query>
      </View>
    </>
  )
}

export default AttendingScreen
