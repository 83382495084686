import { StyleSheet } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  content: {
    flex: 1,
  },
  icon: {
    opacity: 0.5,
  },
  title: {
    textTransform: 'capitalize',
    fontSize: 16,
    fontFamily: fonts.default.bold,
    marginBottom: 10,
  },
  sectionTitle: {
    paddingBottom: 6,
    marginHorizontal: 15,
    borderBottomWidth: 0.3,
    marginTop: 35,
    marginBottom: 10,
  },
  delButton: {
    marginTop: 30,
    opacity: 0.25,
    width: 120,
    marginLeft: 10,
  },
  delButtonTitle: {
    fontSize: 14,
    color: colors.secondary,
  },
  listItemContainer: {
    padding: 20,
  },
  avatarTitleStyle: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  ownerStyle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.primary,
  },
  sessions: {
    marginLeft: 20,
    marginTop: 20,
    marginBottom: 10,
    fontSize: 12,
    fontWeight: 'bold',
    color: colors.secondary,
  },
  divider: {
    marginLeft: 20,
  },
  subtitleTextPrimary: {
    color: colors.primary,
  },
  subtitleTextSecondary: {
    color: colors.secondary,
  },
  searchBarContainer: {
    paddingVertical: 0,
    backgroundColor: '#F4F4F4',
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },
  searchBarInputContainer: {
    backgroundColor: '#F4F4F4',
  },
  searchBarInputStyle: {
    fontFamily: fonts.default.italic,
    color: colors.secondary,
  },
  collapseStyle: {
    marginBottom: 0,
  },
  iconStyle: {
    paddingTop: 6,
  },
  iconContainer: {
    width: 70,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 30,
  },
})

export default styles
