import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { offsetLimitPagination } from '@apollo/client/utilities'
import moment from 'moment'

import { MembershipStatusEnum } from '@src/graphql/types'

import apolloLink from './apolloLinks'

import getEnvVars from '../config/environment'
import { getAuthToken } from '../utils/auth'

const startAt = `${moment().subtract(30, 'minutes').format()}`
const oneWeek = `${moment().add(14, 'days').format()}`

export const attendingOptions = {
  orderBy: 'start_at',
  status_In: [MembershipStatusEnum.Attending, MembershipStatusEnum.Waiting],
  startAt_Gte: startAt,
  limit: 50,
  offset: 0,
}

export const defaultData = {
  auth: {
    __typename: 'auth',
    status: false,
  },
}

const authMiddleware = setContext(async (req, { headers }) => {
  const token = await getAuthToken()

  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : '',
    },
  }
})

const errorMiddleware = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    )
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

const apolloCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        sessionMembership: {
          merge(_ignored, incoming) {
            return incoming
          },
        },
        organisedEvents: {
          merge(_ignored, incoming) {
            return incoming
          },
        },
        wallPosts: offsetLimitPagination(['groupUuid', 'parentUuid']),
        userNotifications: {
          keyArgs: ['groupUuid', 'parentUuid'],
          merge(existing, incoming, { args: { offset = 0 } }) {
            // Slicing is necessary because the existing data is
            // immutable, and frozen in development.
            const merged = existing ? existing.slice(0) : []
            for (let i = 0; i < incoming.length; ++i) {
              merged[offset + i] = incoming[i]
            }
            return merged
          },
        },
      },
    },
    WallPostType: {
      keyFields: ['uuid'],
    },
    WallType: {
      keyFields: ['uuid'],
    },
    WallReactionType: {
      keyFields: ['uuid'],
    },
    UserNotificationType: {
      keyFields: ['uuid'],
    },
  },
})

const client = new ApolloClient({
  link: ApolloLink.from([authMiddleware, errorMiddleware, apolloLink]),
  cache: apolloCache,
})

export { client }
