import { useNavigation, useRoute } from '@react-navigation/native'
import { Button, ListItem } from '@rneui/themed'
import { orderBy } from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { RefreshControl, ScrollView, View } from 'react-native'

import EmptyNotice from '@src/components/EmptyNotice'
import OrfiListItem from '@src/components/ListItem/OrfiListItem'
import Loading from '@src/components/Loading'
import Text from '@src/components/Text'
import { useGroupQuery } from '@src/graphql/types'
import {
  SelectSessionTransferScreenNavigationProps,
  SelectSessionTransferScreenRouteProps,
} from '@src/types'

import styles from './SelectSessionTransferScreen.styles'

const SelectSessionTransferScreen = () => {
  const navigation = useNavigation<SelectSessionTransferScreenNavigationProps>()
  const { params } = useRoute<SelectSessionTransferScreenRouteProps>()
  const [selected, setSelected] = useState('')

  const {
    loading,
    data: groupData,
    error,
    refetch,
  } = useGroupQuery({
    variables: {
      groupUuid: params?.groupUuid,
    },
  })

  useEffect(() => {
    if (params?.sessionAdded) {
      refetch()
    }
  }, [params, refetch])

  if (loading) return <Loading />

  if (error) {
    return (
      <EmptyNotice
        title="NOTHING TO SHOW"
        text="Seems like there are no sessions for this group."
      />
    )
  }

  const { group } = groupData
  const { sessions } = group

  const upcomingSessions = orderBy(
    (sessions || []).filter((i) => moment() <= moment(i.startAt) && i.id !== params.sessionId),
    ['startAt'],
  )

  return (
    <View style={styles.container}>
      <View style={styles.headerVW}>
        <Text style={styles.headerText}>
          {'Which sessions would you like\nto transfer the participants to?'}
        </Text>
      </View>
      {!upcomingSessions.length && (
        <Text style={styles.upcomingSessionText}>There are no upcoming sessions</Text>
      )}
      <ScrollView refreshControl={<RefreshControl refreshing={loading} onRefresh={refetch} />}>
        {upcomingSessions.map((session) => {
          const isSelected = session.id === selected
          return (
            <View key={session.id}>
              <OrfiListItem
                type="default"
                status="default"
                avatarUri={session?.image || session?.activity[0]?.defaultBannerImage}
                title={session?.name}
                subtitle={moment(session?.startAt).format('dddd, DD MMM, H:mm')}
                callToAction={
                  <ListItem.CheckBox
                    checked={isSelected}
                    onPress={() => setSelected(isSelected ? '' : session.id)}
                  />
                }
                onPress={() => setSelected(isSelected ? '' : session.id)}
                divider
              />
            </View>
          )
        })}

        <View style={styles.feeInfoView}>
          <Button
            title="+ create a new session"
            titleStyle={styles.feeInfo}
            type="clear"
            onPress={() =>
              navigation.navigate('CreateSessionScreen', {
                groupUuid: params?.groupUuid,
                backRoute: 'SelectSessionTransferScreen',
              })
            }
          />
        </View>
      </ScrollView>
      <View style={styles.bottomView}>
        <Button
          title="Confirm Selection"
          titleStyle={styles.buttonStyle}
          onPress={() =>
            navigation.navigate('TransferMembershipOptionScreen', {
              sessionId: selected,
              user: params.user,
              prevSessionId: params.sessionId,
            })
          }
          disabled={!selected}
          containerStyle={styles.bottomButtonContainer}
        />
      </View>
    </View>
  )
}

export default SelectSessionTransferScreen
