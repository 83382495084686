import { useNavigation, useRoute } from '@react-navigation/native'
import { Button, Input } from '@rneui/themed'
import React, { useEffect, useState } from 'react'
import { ImageBackground, ScrollView, useWindowDimensions, View } from 'react-native'

import { IMAGE_EMAIL_BACKGROUND } from '@src/assets/images'
import { showErrorMessage, showSuccessMessage } from '@src/components/FlashMessage'
import Text from '@src/components/Text'
import { colors } from '@src/config/theme'
import { ME_QUERY } from '@src/graphql/queries'
import { useSendVerificationEmailMutation, useVerifyUserMutation } from '@src/graphql/types'
import type {
  EmailConfirmationScreenRouteProps,
  EmailConfirmationScreenNavigationProps,
} from '@src/types'

import styles from './EmailConfirmationScreen.styles'

const EmailConfirmationScreen = () => {
  const [code, setCode] = useState('')
  const [verifyUserEmail, { loading: verifyLoading }] = useVerifyUserMutation()
  const [sendVerificationEmail, { loading: sendLoading }] = useSendVerificationEmailMutation()

  const navigation = useNavigation<EmailConfirmationScreenNavigationProps>()
  const { params: { isSignUp = false } = {} } = useRoute<EmailConfirmationScreenRouteProps>()

  const { width, height } = useWindowDimensions()

  useEffect(() => {
    if (!isSignUp) sendVerification()
  }, [isSignUp, sendVerification])

  const sendVerification = async () => {
    try {
      await sendVerificationEmail()
      showSuccessMessage({
        message: '',
        description: 'Verification code has been sent to your email address',
      })
    } catch (error) {
      showErrorMessage({ message: '', description: error.message })
    }
  }

  const verifyCode = async () => {
    try {
      await verifyUserEmail({
        variables: { code },
        refetchQueries: [{ query: ME_QUERY }],
        awaitRefetchQueries: true,
      })
      showSuccessMessage({ message: '', description: 'Your email address has been verified' })
      navigation.replace('MainTab', {})
    } catch (error) {
      showErrorMessage({ message: '', description: error.message })
    }
  }

  const loading = sendLoading || verifyLoading

  return (
    <ImageBackground source={IMAGE_EMAIL_BACKGROUND} style={[styles.bg, { width, height }]}>
      <ScrollView contentContainerStyle={styles.container} keyboardShouldPersistTaps="handled">
        <View style={styles.wrapper}>
          <View>
            <Text style={styles.mainTitle}>Confirm your email</Text>
            <Text style={styles.subTitle}>
              {
                'We’ve sent you an email with a confirmation code.\nEnter the code below to confirm your email.'
              }
            </Text>
          </View>
          <View style={styles.inputView}>
            <Input
              placeholder="Enter confirmation code"
              placeholderTextColor={colors.grey7}
              textAlign="center"
              value={code}
              onChangeText={(text) => setCode(text)}
              autoCapitalize="characters"
            />
          </View>
          <View style={styles.btnView}>
            <View style={styles.buttonInnerContainer}>
              <Button
                title="Submit"
                loading={verifyLoading}
                disabled={loading || !code}
                onPress={verifyCode}
              />

              <Button
                title="Resend code"
                type="clear"
                titleStyle={styles.resendButton}
                onPress={sendVerification}
                loading={sendLoading}
                disabled={loading}
              />
            </View>
          </View>
        </View>
      </ScrollView>
      <View style={styles.skip}>
        <Button
          title="SKIP"
          type="clear"
          titleStyle={styles.skipButton}
          onPress={() => navigation.goBack()}
        />
      </View>
    </ImageBackground>
  )
}

export default EmailConfirmationScreen
