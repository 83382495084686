import { useNavigation } from '@react-navigation/native'
import { Icon, Input } from '@rneui/themed'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import { ScrollView, TouchableOpacity, View, TextInput } from 'react-native'

import EventLinkForm from '@src/components/forms/EventLinks'
import InputPicker from '@src/components/forms/InputPicker'
import { pickerVenue } from '@src/config/forms'
import { colors } from '@src/config/theme'

import useStyle from './styles'

const StepTwoForm = ({
  values,
  touched,
  errors,
  isSubmitting,
  handleChange,
  setFieldTouched,
  setFieldValue,
}) => {
  const navigation = useNavigation()
  const styles = useStyle()
  const handlePickerValue = (field, value) => {
    setFieldValue(field, value)
  }

  const onSelectVenueAddress = () => {
    const { venue } = values

    navigation.navigate('SelectVenueScreen', {
      backRoute: 'CreateSessionScreen',
      venue,
    })
  }

  const onAddLink = () => {
    const { links } = values
    links.push({ name: '', url: '' })
    setFieldValue('links', links)
  }

  const onDeleteItem = (index) => {
    const { links } = values
    links.splice(index, 1)
    setFieldValue('links', links)
  }

  const onChangeLinkItem = ({ index, key, value }) => {
    const { links } = values
    const newData = {}

    newData[key] = value
    links[index] = { ...links[index], ...newData }
    setFieldValue('links', links)
  }

  const rulesNotesRef = useRef<TextInput>(null)

  return (
    <ScrollView style={styles.scrollViewStyle}>
      <TouchableOpacity style={styles.container} onPress={onSelectVenueAddress}>
        <Input
          label="LOCATION"
          errorMessage={
            touched.venueAddress && errors.venueAddress ? 'Location is required' : undefined
          }
          leftIcon={
            <Icon
              name="magnifying-glass"
              type="entypo"
              size={16}
              color={colors.black}
              containerStyle={values.venueAddress.address === '' ? styles.icon : {}}
            />
          }
          placeholder="Try ‘Manchester M3 3EE’"
          placeholderTextColor={colors.greyOutline}
          value={values.venueAddress.address}
          editable={false}
          pointerEvents="none"
          leftIconContainerStyle={styles.iconContainer}
          containerStyle={styles.containerStyle}
        />
      </TouchableOpacity>

      <View style={styles.containerStyle}>
        <InputPicker
          label="VENUE TYPE"
          value={values.venueType}
          items={pickerVenue}
          errorMessage={touched.venueType && errors.venueType ? errors.venueType : undefined}
          editable={!isSubmitting}
          onValueChange={(value: string) => handlePickerValue('venueType', value)}
          containerStyle={styles.pickerStyle}
        />
      </View>

      <EventLinkForm
        links={values.links}
        onAddLink={onAddLink}
        onDeleteItem={onDeleteItem}
        onChangeLinkItem={onChangeLinkItem}
        errors={touched.links && errors.links}
      />

      <Input
        label="DESCRIBE YOUR EVENT"
        onSubmitEditing={() => rulesNotesRef.current?.focus()}
        value={values.description}
        placeholder="Type here..."
        placeholderTextColor={colors.greyOutline}
        onChangeText={handleChange('description')}
        inputContainerStyle={styles.inputContainerStyle}
        inputStyle={styles.inputStyle}
        onBlur={() => setFieldTouched('description')}
        multiline
        errorMessage={touched.description && errors.description ? errors.description : undefined}
        containerStyle={styles.containerStyle}
      />

      <Input
        label="RULES / NOTES (OPTIONAL)"
        value={values.notes}
        placeholder="Type here..."
        placeholderTextColor={colors.greyOutline}
        onChangeText={handleChange('notes')}
        containerStyle={styles.inputContainer}
        inputContainerStyle={styles.inputContainerStyle}
        inputStyle={styles.inputStyle}
        onBlur={() => setFieldTouched('notes')}
        multiline
        errorMessage={touched.notes && errors.notes ? errors.notes : undefined}
        ref={rulesNotesRef}
      />
    </ScrollView>
  )
}

StepTwoForm.propTypes = {
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
}

export default StepTwoForm
