import { StyleSheet } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  activityItem: {
    borderColor: colors.greyOutline,
    borderBottomWidth: 0,
  },
  activityName: {
    fontFamily: fonts.default.semiBold,
    fontSize: 16,
    paddingVertical: 5,
  },
})

export default styles
