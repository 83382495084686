import { useNavigation, useRoute } from '@react-navigation/native'
import { Button, Input } from '@rneui/themed'
import { useFormik } from 'formik'
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { BackHandler, Keyboard, SafeAreaView, ScrollView, View } from 'react-native'

import ConfirmDialog from '@src/components/ConfirmDialog'
import { showErrorMessage, showSuccessMessage } from '@src/components/FlashMessage'
import HeaderIcon from '@src/components/HeaderIcon'
import Text from '@src/components/Text'
import { colors } from '@src/config/theme'
import { useCreateUpdateTrackTraceGroupMutation, useMeQuery } from '@src/graphql/types'
import {
  DashboardTrackTraceScreenNavigationProps,
  DashboardTrackTraceScreenRouteProps,
} from '@src/types'

import styles from './DashboardTrackTraceScreen.styles'
import validationSchema from './DashboardTrackTraceScreen.validation'

const DashboardTrackTraceScreen = () => {
  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState(false)
  const navigation = useNavigation<DashboardTrackTraceScreenNavigationProps>()
  const { params } = useRoute<DashboardTrackTraceScreenRouteProps>()

  const { data: { me = { name: '', surname: '', email: '' } } = {} } = useMeQuery()

  const [updateTrackAndTrace, { loading }] = useCreateUpdateTrackTraceGroupMutation()

  const handleOnSubmit = async (values: {
    firstName: string
    lastName: string
    phoneNumber: string
    email: string
    form?: string
  }) => {
    try {
      await updateTrackAndTrace({
        variables: { ...values, groupUuid: params.groupUuid },
      })
      showSuccessMessage({
        message: 'Track & Trace',
        description: 'Your information has been saved',
      })
      navigation.goBack()
    } catch (error) {
      showErrorMessage({ message: '', description: error?.message })
    }
  }

  const { values, touched, errors, dirty, handleChange, handleSubmit, setFieldTouched } = useFormik(
    {
      enableReinitialize: true,
      validationSchema,
      initialValues: {
        firstName: me.name,
        lastName: me.surname,
        phoneNumber: '',
        email: me.email,
      },
      onSubmit: handleOnSubmit,
    },
  )

  const checkInputStates = useCallback(() => {
    if (dirty) {
      setIsConfirmationDialogVisible(true)
    } else {
      navigation.goBack()
    }
  }, [dirty, navigation])

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <HeaderIcon onPress={() => checkInputStates()} />,
    })
  }, [checkInputStates, navigation, values])

  useEffect(() => {
    const backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
      dirty ? setIsConfirmationDialogVisible(true) : navigation.goBack()
      return null
    })
    return () => backHandler.remove()
  })

  const renderConfirmDialog = () => (
    <ConfirmDialog
      title="Careful..."
      message="Are you sure? Changes will be lost."
      negativeButton={{
        title: 'Stay',
        onPress: () => setIsConfirmationDialogVisible(false),
      }}
      positiveButton={{
        title: 'Exit',
        onPress: () => {
          setIsConfirmationDialogVisible(false)
          navigation.goBack()
        },
      }}
      visible={isConfirmationDialogVisible}
    />
  )

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.content}>
        {errors.form && <Text>{errors.form}</Text>}

        <Input
          label="FIRST NAME"
          onSubmitEditing={Keyboard.dismiss}
          placeholderTextColor={colors.greyOutline}
          value={values.firstName}
          onBlur={() => setFieldTouched('firstName')}
          onChangeText={handleChange('firstName')}
          errorMessage={touched.firstName && errors.firstName ? errors.firstName : undefined}
          editable={!loading}
        />
        <Input
          label="LAST NAME"
          onSubmitEditing={Keyboard.dismiss}
          placeholderTextColor={colors.greyOutline}
          value={values.lastName}
          onBlur={() => setFieldTouched('lastName')}
          onChangeText={handleChange('lastName')}
          errorMessage={touched.lastName && errors.lastName ? errors.lastName : undefined}
          editable={!loading}
        />
        <Input
          label="PHONE NUMBER"
          onSubmitEditing={Keyboard.dismiss}
          placeholderTextColor={colors.greyOutline}
          value={values.phoneNumber}
          onBlur={() => setFieldTouched('phoneNumber')}
          onChangeText={handleChange('phoneNumber')}
          errorMessage={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : undefined}
          editable={!loading}
        />
        <Input
          label="EMAIL"
          onSubmitEditing={Keyboard.dismiss}
          placeholderTextColor={colors.greyOutline}
          value={values.email}
          onBlur={() => setFieldTouched('email')}
          onChangeText={handleChange('email')}
          errorMessage={touched.email && errors.email ? errors.email : undefined}
          editable={!loading}
        />
      </ScrollView>

      <View style={styles.bottom}>
        <Button
          title="Submit"
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          onPress={handleSubmit as any}
          titleStyle={styles.resetTitle}
          disabled={loading}
          loading={loading}
        />
      </View>
      {renderConfirmDialog()}
    </SafeAreaView>
  )
}

export default DashboardTrackTraceScreen
