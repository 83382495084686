import { StyleSheet } from 'react-native'

import { colors, fonts } from '@src/config/theme'
import { isIos } from '@src/utils/platformHelpers'

const styles = StyleSheet.create({
  bg: {
    flex: 1,
    backgroundColor: colors.white,
  },
  container: {
    flex: 1,
  },
  wrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainTitle: {
    color: colors.primary,
    fontSize: 16,
    fontFamily: fonts.default.bold,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  subTitle: {
    textAlign: 'center',
    color: colors.secondary,
    marginTop: 10,
  },
  inputView: {
    width: '60%',
    alignItems: 'center',
    justifyContent: 'center',
    height: 300,
  },
  btnView: {
    width: '100%',
    alignItems: 'center',
    position: 'absolute',
    bottom: 40,
  },
  skip: {
    position: 'absolute',
    top: isIos ? 40 : 0,
    right: 40,
  },
  skipButton: {
    color: colors.grey7,
  },
  resendButton: {
    textDecorationLine: 'underline',
  },
  buttonInnerContainer: {
    width: '70%',
  },
})

export default styles
