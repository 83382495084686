import { useNavigation, useRoute } from '@react-navigation/native'
import { Button, ListItem } from '@rneui/themed'
import { uniqBy } from 'lodash'
import moment from 'moment'
import { useState } from 'react'
import { RefreshControl, ScrollView, View } from 'react-native'

import EmptyNotice from '@src/components/EmptyNotice'
import OrfiListItem from '@src/components/ListItem/OrfiListItem'
import Loading from '@src/components/Loading'
import Text from '@src/components/Text'
import { useGroupQuery } from '@src/graphql/types'
import { SelectSessionScreenNavigationProps, SelectSessionScreenRouteProps } from '@src/types'

import styles from './SelectSessionScreen.styles'

const SelectSessionScreen = () => {
  const navigation = useNavigation<SelectSessionScreenNavigationProps>()
  const { params } = useRoute<SelectSessionScreenRouteProps>()
  const [selected, setSelected] = useState('')

  const {
    loading,
    data: groupData,
    error,
    refetch,
  } = useGroupQuery({
    variables: {
      groupUuid: params?.groupUuid,
    },
  })

  if (loading) return <Loading />

  if (error) {
    return (
      <EmptyNotice
        title="NOTHING TO SHOW"
        text="Seems like there are no sessions for this group."
      />
    )
  }

  const { group } = groupData
  const { sessions } = group

  return (
    <View style={styles.container}>
      <View style={styles.headerVW}>
        <Text style={styles.headerText}>{'Which session do you want\nto duplicate?'}</Text>
      </View>
      <ScrollView refreshControl={<RefreshControl refreshing={loading} onRefresh={refetch} />}>
        {uniqBy(sessions, 'name').map((session) => {
          const isSelected = session.id === selected
          return (
            <View key={session.id}>
              <OrfiListItem
                type="default"
                status="default"
                avatarUri={session.image || session.activity[0]?.defaultBannerImage}
                title={session?.name}
                subtitle={moment(session?.startAt).format('dddd, DD MMM, H:mm')}
                callToAction={
                  <ListItem.CheckBox
                    checked={isSelected}
                    onPress={() => setSelected(isSelected ? '' : session.id)}
                  />
                }
                onPress={() => setSelected(isSelected ? '' : session.id)}
                divider
              />
            </View>
          )
        })}

        <View style={styles.feeInfoView}>
          <Button
            title="+ create a new session"
            titleStyle={styles.feeInfo}
            type="clear"
            onPress={() =>
              navigation.navigate('CreateSessionScreen', { groupUuid: params?.groupUuid })
            }
          />
        </View>
      </ScrollView>
      <View style={styles.bottomView}>
        <Button
          title="Duplicate Session"
          titleStyle={styles.buttonTitle}
          onPress={() => navigation.navigate('DuplicateSessionScreen', { sessionId: selected })}
          disabled={!selected}
          containerStyle={styles.bottomButtonContainer}
        />
      </View>
    </View>
  )
}

export default SelectSessionScreen
