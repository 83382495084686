import { FC } from 'react'
import { View } from 'react-native'
import { Button } from '@rneui/themed'
import Modal from 'react-native-modal'

import Text from '@src/components/Text'

import styles from './ConfirmDialog.styles'
import { ConfirmDialogProps } from './types'

const ConfirmDialog: FC<ConfirmDialogProps> = ({
  visible,
  title,
  message,
  negativeButton,
  positiveButton,
  children,
}) => {
  const renderMessage = () => (
    <View style={styles.contentContainer}>
      {title ? (
        <Text h4 style={styles.titleText}>
          {title}
        </Text>
      ) : null}
      <Text>{message}</Text>
    </View>
  )

  const renderButtons = () => (
    <View style={styles.buttonContainer}>
      <Button type="clear" {...negativeButton} testID="negative" />
      <Button type="clear" {...positiveButton} testID="positive" />
    </View>
  )

  const renderContent = () => {
    if (children) return children
    return renderMessage()
  }

  return (
    <Modal isVisible={visible}>
      <View style={styles.container}>
        {renderContent()}
        {renderButtons()}
      </View>
    </Modal>
  )
}

export default ConfirmDialog
