import { Button } from '@rneui/themed'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { Dimensions, StyleSheet, View } from 'react-native'

import OrfiAvatar from '@src/components/OrfiAvatar'
import Text from '@src/components/Text'
import { colors, fonts } from '@src/config/theme'

const { width } = Dimensions.get('screen')

const ProfileForm = ({
  title,
  avatar,
  name,
  onAvatarPress,
  date,
  setIsAddToCalendar,
  verifiedStyle,
  isOwner,
}) => (
  <View style={styles.container}>
    <View style={styles.organiserContainer}>
      <OrfiAvatar
        type="profile"
        status="default"
        size="large"
        uri={avatar}
        raised
        onPress={onAvatarPress}
      />
      <View style={styles.section2}>
        <Text numberOfLines={2} style={styles.name}>
          {name}
        </Text>
        <Text style={styles.role}>Organiser</Text>
      </View>
    </View>
    <View style={styles.divider} />
    <View style={styles.innerContainer}>
      <Text style={styles.title}>{title}</Text>
      <View style={styles.textContainer}>
        <Text style={styles.date}>
          {`${moment(date).format('dddd')}\n${moment(date).format('DD MMMM, YYYY')}`}
        </Text>
        {isOwner ? (
          <Button
            type="clear"
            icon={{
              name: 'calendar',
              type: 'feather',
              size: 20,
              color: colors.primary,
            }}
            onPress={() => setIsAddToCalendar(true)}
          />
        ) : null}
      </View>
    </View>
  </View>
)

const avatarDiameter = 100

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
    paddingHorizontal: 20,
    paddingVertical: 25,
  },
  section1: {
    width,
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alphaOverlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: colors.primary,
    opacity: 0.8,
  },
  avatarContainer: {
    width: avatarDiameter,
    height: avatarDiameter,
    shadowOffset: { width: 2, height: 2 },
    shadowColor: 'black',
    shadowOpacity: 0.2,
    shadowRadius: 2.62,
    elevation: 4,
    borderRadius: avatarDiameter / 2,
  },
  title: {
    color: colors.secondary,
    fontSize: 21,
    fontFamily: fonts.default.bold,
  },
  date: {
    color: colors.primary,
    fontSize: 13,
    fontFamily: fonts.default.bold,
    marginTop: 15,
    width: '80%',
  },
  section2: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  name: {
    color: colors.secondary,
    textAlign: 'center',
    fontSize: 13,
    fontFamily: fonts.default.bold,
    marginTop: 10,
  },
  role: {
    color: colors.secondary,
    fontSize: 10,
    fontFamily: fonts.default.regular,
  },
  favIcon: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  organiserContainer: {
    flex: 3,
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 0,
  },
  divider: {
    flex: 1,
    backgroundColor: 'transparent',
  },
  innerContainer: {
    flex: 5,
    marginTop: 15,
  },
  textContainer: {
    flexDirection: 'row',
  },
})

ProfileForm.propTypes = {
  title: PropTypes.string,
  address: PropTypes.string,
  avatar: PropTypes.string,
  name: PropTypes.string,
  role: PropTypes.string,
  favorited: PropTypes.bool,
  backgroundImage: PropTypes.string,
  onAvatarPress: PropTypes.func,
  setIsAddToCalendar: PropTypes.func,
  date: PropTypes.string,
  verifiedStyle: PropTypes.object,
  isOwner: PropTypes.bool,
}

ProfileForm.defaultProps = {
  title: 'Sunday Basketball Scrimmage',
  address: `Sugden Sports Centre, 114 Grosvenor Street,${'\n'}Manchester, M1 7HL`,
  avatar: null,
  name: 'JONAS URBONAS',
  role: 'Group Organiser',
  favorited: false,
  backgroundImage: 'https://navajotimes.com/wp-content/uploads/2017/05/bball-basketball.jpg',
  onAvatarPress: () => {},
  setIsAddToCalendar: () => {},
  date: '',
  verifiedStyle: {},
  isOwner: false,
}

export default ProfileForm
