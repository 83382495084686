import { useNavigation, useRoute } from '@react-navigation/native'
import { Button, CheckBox, SearchBar } from '@rneui/themed'
import moment from 'moment'
import React, { useLayoutEffect, useState } from 'react'
import { FlatList, SafeAreaView, View } from 'react-native'

import EmptyNotice from '@src/components/EmptyNotice'
import OrfiListItem from '@src/components/ListItem/OrfiListItem'
import Loading from '@src/components/Loading'
import Text from '@src/components/Text'
import { colors, fonts } from '@src/config/theme'
import { useGroupQuery, useTransferGroupOwnershipMutation } from '@src/graphql/types'
import {
  DashboardTransferOwnershipScreenNavigationProps,
  DashboardTransferOwnershipScreenRouteProps,
} from '@src/types'
import getInitials from '@src/utils/getInitials'

import styles from './DashboardTransferOwnershipScreen.styles'

const DashboardTransferOwnershipScreen = () => {
  const navigation = useNavigation<DashboardTransferOwnershipScreenNavigationProps>()
  const { params } = useRoute<DashboardTransferOwnershipScreenRouteProps>()
  const [searchText, setSearchText] = useState('')
  const [selectedUser, setSelectedUser] = useState(0)

  const {
    loading,
    data: {
      group = {
        uuid: '',
        name: '',
        description: '',
        image: '',
        isAdmin: false,
        groupUsers: { user: { id: 0, avatar: '', name: '', surname: '' } },
      },
    } = {},
    refetch: groupRefetch,
    error,
  } = useGroupQuery({
    variables: {
      groupUuid: params.groupUuid,
    },
  })

  const [makeUserOwner, { loading: transferLoading }] = useTransferGroupOwnershipMutation({
    onCompleted: async () => {
      await Promise.all([groupRefetch()])
      navigation.navigate('DashboardGroupScreen', { groupUuid: params.groupUuid })
    },
  })

  useLayoutEffect(() => {
    if (group) {
      const title = group?.name
      navigation.setOptions({
        headerTitle: title,
        headerTitleStyle: {
          fontFamily: fonts.default.bold,
        },
        headerRight: () => (
          <Button
            icon={{
              name: 'user-plus',
              type: 'feather',
              color: colors.secondary,
            }}
            type="clear"
            onPress={() =>
              navigation.navigate('DashboardInviteMemberScreen', {
                groupUuid: group.uuid,
                groupName: group.name,
              })
            }
          />
        ),
      })
    }
  }, [group, navigation])

  if (loading) {
    return <Loading />
  }

  if (error) {
    return (
      <EmptyNotice title="NOTHING TO SHOW" text="Seems like there are no members for this group." />
    )
  }

  const onTransfer = () => {
    try {
      makeUserOwner({
        variables: {
          groupUuid: group.uuid,
          userId: selectedUser,
        },
      })
    } catch (err) {
      console.log('err', err)
    }
  }

  const searchMembers = (groupUsers || []).filter(
    (grp) =>
      grp.user.name.toLowerCase().includes(searchText.toLowerCase()) ||
      grp.user.surname.toLowerCase().includes(searchText.toLowerCase()),
  )

  return (
    <SafeAreaView style={styles.container}>
      <SearchBar
        containerStyle={styles.searchBoxContainer}
        inputContainerStyle={styles.searchBoxInputContainer}
        inputStyle={styles.searchBarStyle}
        placeholder="Search by name"
        onChangeText={(text) => setSearchText(text)}
        value={searchText}
      />
      <FlatList
        data={searchMembers}
        renderItem={({
          item,
        }: {
          item: {
            user: { id: number; name: string; surname: string; avatar: string }
            createdAt: string
          }
        }) => {
          const isSelected = item.user?.id === selectedUser
          return (
            <OrfiListItem
              title={`${item?.user?.name} ${item?.user?.surname}`}
              subtitle={`Member since ${moment(item.createdAt).format('Do MMM, YYYY')}`}
              avatarUri={item?.user?.avatar}
              avatarPlaceholder={getInitials(item?.user?.name || '')}
              callToAction={
                <View style={styles.listItemsStyle}>
                  <CheckBox
                    onPress={() => setSelectedUser(isSelected ? 0 : item?.user.id)}
                    {...{
                      iconType: 'material-community',
                      checkedIcon: 'check-circle',
                      uncheckedIcon: 'checkbox-blank-circle-outline',
                      checkedColor: '#03D20A',
                      checked: isSelected,
                      size: 32,
                    }}
                  />
                </View>
              }
              onPress={() => setSelectedUser(isSelected ? 0 : item?.user.id)}
            />
          )
        }}
        ListHeaderComponent={
          <View style={styles.listHeaderContainer}>
            <Text style={styles.listHeaderText}>
              {
                'By transferring ownership you give up all control\nof your group to the selected member. Once done,\nyou will become a regular member.\n\nIf you want to simply add another Admin, you can\ndo so by visiting the Members section of the group'
              }
            </Text>
          </View>
        }
      />

      <View style={styles.bottomView}>
        <Button
          title="Transfer Group Ownership"
          onPress={onTransfer}
          disabled={!selectedUser || transferLoading}
          loading={transferLoading}
          containerStyle={styles.bottomButtonContainer}
        />
      </View>
    </SafeAreaView>
  )
}

export default DashboardTransferOwnershipScreen
