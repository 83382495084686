import { Button } from '@rneui/themed'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { View } from 'react-native'

import PricingForm from '@src/components/forms/PricingForm'
import FeeInformationModal from '@src/components/PopupModal/FeeInformation'
import Text from '@src/components/Text'
import { colors, fonts } from '@src/config/theme'

import useStyle from './styles'

const StepFourForm = ({ values, touched, errors, setFieldValue }) => {
  const styles = useStyle()

  const handlePickerValue = (field, value) => {
    setFieldValue(field, value)
  }

  const onChangeCashAccepted = () => {
    const { acceptCash } = values
    setFieldValue('acceptCash', !acceptCash)
  }

  const onAddPrice = () => {
    const { prices } = values
    prices.push({ value: '0', description: '' })
    setFieldValue('prices', prices)
  }

  const onDeleteItem = (index) => {
    const { prices } = values
    prices.splice(index, 1)
    setFieldValue('prices', prices)
  }

  const onChangePriceItemField = ({ index, key, value }) => {
    const { prices } = values
    const newData = {}

    newData[key] = value
    prices[index] = { ...prices[index], ...newData }

    setFieldValue('prices', prices)
  }

  const [feeModal, onFeeInfo] = useState(false)

  const paymentMethods = [
    { label: 'In-app Only', value: 'APP' },
    { label: 'Cash Only', value: 'SITE' },
    { label: 'In-app + Cash', value: 'ALL' },
  ].map((method) => {
    const selected = values.paymentMethod === method.value
    return (
      <Button
        key={method.label}
        title={method.label}
        titleStyle={{
          color: selected ? colors.white : colors.secondary,
          fontFamily: selected ? fonts.default.bold : fonts.default.regular,
        }}
        buttonStyle={{
          borderRadius: 5,
          height: 40,
          paddingHorizontal: 15,
          backgroundColor: selected ? colors.primary : '#fff',
          borderWidth: 1,
          borderColor: selected ? colors.primary : colors.greyOutline,
        }}
        onPress={() => setFieldValue('paymentMethod', method.value)}
      />
    )
  })

  return (
    <View style={styles.container}>
      <View style={styles.paymentContainer}>
        <Text style={styles.paymentText}>ACCEPTED PAYMENT METHOD</Text>
        <View style={styles.paymentMethodContainer}>{paymentMethods}</View>
      </View>

      <PricingForm
        prices={values.prices}
        paymentMethod={values.paymentMethod}
        onAddPrice={onAddPrice}
        onDeleteItem={onDeleteItem}
        onChangePriceItem={onChangePriceItemField}
        onFeeInfo={() => onFeeInfo(!feeModal)}
        errors={touched.prices && errors.prices}
      />
      <FeeInformationModal visible={feeModal} onClose={() => onFeeInfo(!feeModal)} />
    </View>
  )
}

StepFourForm.propTypes = {
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
}

export default StepFourForm
