import { useNavigation } from '@react-navigation/native'
import { Icon } from '@rneui/themed'
import { FC } from 'react'

import { colors } from '@src/config/theme'

import styles from './HeaderIcon.styles'
import { HeaderIconProps } from './types'

const HeaderIcon: FC<HeaderIconProps> = ({
  onPress,
  color = colors.black,
  name = 'ios-arrow-back',
  type = 'ionicon',
  size = 24,
  right = false,
}) => {
  const navigation = useNavigation()
  const resolveOnPress = onPress || navigation.goBack

  const containerStyle = right ? styles.rightContainerStyle : {}

  return (
    <Icon
      name={name}
      type={type}
      size={size}
      color={color}
      onPress={resolveOnPress}
      containerStyle={containerStyle}
      testID="icon"
    />
  )
}

export default HeaderIcon
