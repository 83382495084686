import setColor from 'color'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Animated, Platform, StyleSheet, View } from 'react-native'

import { colors } from '@src/config/theme'

const INDETERMINATE_DURATION = 2000
const INDETERMINATE_MAX_WIDTH = 0.6

const ProgressBar = ({ progress, indeterminate, style, color }) => {
  const [width, setWidth] = useState(0)
  const [timer, setTimer] = useState(new Animated.Value(0))
  const [fade, setFade] = useState(new Animated.Value(0))

  let indeterminateAnimation = null

  useEffect(() => {
    startAnimation()
  }, [progress])

  const onLayout = (event) => {
    setWidth(event.nativeEvent.layout.width)
  }

  const startAnimation = () => {
    // Show progress bar
    Animated.timing(fade, {
      duration: 200,
      toValue: 1,
      useNativeDriver: true,
      isInteraction: false,
    }).start()

    // Animate progress bar
    if (indeterminate) {
      if (!indeterminateAnimation) {
        indeterminateAnimation = Animated.timing(timer, {
          duration: INDETERMINATE_DURATION,
          toValue: 1,
          // Animated.loop does not work if useNativeDriver is true on web
          useNativeDriver: Platform.OS !== 'web',
          isInteraction: false,
        })
      }

      // Reset timer to the beginning
      timer.setValue(0)

      Animated.loop(indeterminateAnimation).start()
    } else {
      Animated.timing(timer, {
        duration: 200,
        toValue: progress,
        useNativeDriver: true,
        isInteraction: false,
      }).start()
    }
  }

  const stopAnimation = () => {
    if (indeterminateAnimation) {
      indeterminateAnimation.stop()
    }

    Animated.timing(fade, {
      duration: 200,
      toValue: 0,
      useNativeDriver: true,
      isInteraction: false,
    }).start()
  }

  const trackTintColor = setColor(color).alpha(0.38).rgb().string()

  return (
    <View onLayout={onLayout}>
      <Animated.View
        style={[styles.container, { backgroundColor: trackTintColor, opacity: fade }, style]}>
        <Animated.View
          style={[
            styles.progressBar,
            {
              backgroundColor: color,
              width,
              transform: [
                {
                  translateX: timer.interpolate(
                    indeterminate
                      ? {
                          inputRange: [0, 0.5, 1],
                          outputRange: [
                            -0.5 * width,
                            -0.5 * INDETERMINATE_MAX_WIDTH * width,
                            0.7 * width,
                          ],
                        }
                      : {
                          inputRange: [0, 1],
                          outputRange: [-0.5 * width, 0],
                        },
                  ),
                },
                {
                  // Workaround for workaround for https://github.com/facebook/react-native/issues/6278
                  scaleX: timer.interpolate(
                    indeterminate
                      ? {
                          inputRange: [0, 0.5, 1],
                          outputRange: [0.0001, INDETERMINATE_MAX_WIDTH, 0.0001],
                        }
                      : {
                          inputRange: [0, 1],
                          outputRange: [0.0001, 1],
                        },
                  ),
                },
              ],
            },
          ]}
        />
      </Animated.View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 4,
    overflow: 'hidden',
  },
  progressBar: {
    flex: 1,
  },
})

ProgressBar.defaultProps = {
  progress: 0,
  color: colors.primary,
  style: {},
  indeterminate: false,
}

ProgressBar.propTypes = {
  indeterminate: PropTypes.bool,
  progress: PropTypes.number,
  color: PropTypes.string,
  style: PropTypes.object,
}

export default ProgressBar
