import { useNavigation } from '@react-navigation/native'
import { Button, Input } from '@rneui/themed'
import * as ImagePicker from 'expo-image-picker'
import * as Linking from 'expo-linking'
import * as MediaLibrary from 'expo-media-library'
import { useFormik } from 'formik'
import { useContext, useState } from 'react'
import { Keyboard, View } from 'react-native'

import { showErrorMessage } from '@src/components/FlashMessage'
import RowLayout from '@src/components/forms/RowLayout'
import OrfiAvatar from '@src/components/OrfiAvatar'
import SignupProfilePopup from '@src/components/PopupModal/SignupProfilePopup'
import Text from '@src/components/Text'
import getEnvVars from '@src/config/environment'
import { colors } from '@src/config/theme'
import { AuthContext } from '@src/context/AuthContext'
import { useCreateUserMutation } from '@src/graphql/types'
import type { AuthScreenNavigationProps } from '@src/types'
import generateRNFile from '@src/utils/generateRNFile'

import styles from './SignUpForm.styles'
import validationSchema from './SignUpForm.validation'

const { privacyPolicyUrl, termsUrl } = getEnvVars()

const SignUpForm = () => {
  const [securePassword, setSecurePassword] = useState(true)
  const [modalVisible, setModalVisible] = useState(false)
  const [createUserMutation, { loading: signUpLoading }] = useCreateUserMutation()
  const navigation = useNavigation<AuthScreenNavigationProps>()

  const { loginToken } = useContext(AuthContext)

  const signUp = async () => {
    try {
      await createUserMutation({
        variables: {
          ...values,
          email: values.email.trim(),
        },
        onCompleted: async ({ createUser: { token } }) => {
          await loginToken(token)
        },
      })

      navigation.replace('EmailConfirmationScreen', { isSignUp: true })
    } catch (error) {
      showErrorMessage({ message: '', description: error.message })
    }
  }

  const { values, errors, touched, handleChange, setFieldTouched, handleSubmit, setFieldValue } =
    useFormik({
      enableReinitialize: true,
      validationSchema,
      initialValues: {
        name: '',
        surname: '',
        email: '',
        password: '',
        avatar: '',
      },
      onSubmit: async (_values) => {
        if (_values.avatar) {
          await signUp()
        } else setModalVisible(true)
      },
    })

  const closeModalAndSubmit = () => {
    setModalVisible(false)
    setTimeout(signUp, 200)
  }

  const uploadFromModal = () => {
    setModalVisible(false)
    setTimeout(() => pickImage(), 200)
  }

  const pickImage = async () => {
    const { status } = await MediaLibrary.requestPermissionsAsync()
    if (status !== 'granted') {
      // eslint-disable-next-line no-alert
      alert('Sorry, we need camera roll permissions to make this work!')
    } else {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
      })
      if (!result.canceled) {
        const file = generateRNFile(result.assets[0].uri, 'Avatar')
        setFieldTouched('avatar')
        setFieldValue('avatar', file)
      }
    }
  }

  const imageSource = values.avatar ? values.avatar.uri : ''

  return (
    <View testID="SignUpForm">
      {errors.form && <Text>{errors.form}</Text>}
      <View style={styles.avatar}>
        <OrfiAvatar
          type="profile"
          status="edit"
          size="large"
          uri={imageSource}
          title=""
          onPress={pickImage}
        />
      </View>
      <RowLayout
        left={
          <Input
            placeholder="First Name"
            placeholderTextColor={colors.greyOutline}
            textContentType="none"
            onSubmitEditing={Keyboard.dismiss}
            value={values.name}
            onBlur={() => setFieldTouched('name')}
            onChangeText={handleChange('name')}
            errorMessage={touched.name && errors.name ? errors.name : undefined}
            editable // !isSubmitting
            autoCapitalize="words"
            leftIcon={{
              type: 'feather',
              name: 'user',
              color: colors.grey7,
              size: 17,
            }}
            testID="signupFirstName"
          />
        }
        right={
          <Input
            placeholder="Last Name"
            placeholderTextColor={colors.greyOutline}
            textContentType="none"
            onSubmitEditing={Keyboard.dismiss}
            value={values.surname}
            onBlur={() => setFieldTouched('surname')}
            onChangeText={handleChange('surname')}
            errorMessage={touched.surname && errors.surname ? errors.surname : undefined}
            editable // !isSubmitting
            autoCapitalize="words"
            leftIcon={{
              type: 'feather',
              name: 'user',
              color: colors.grey7,
              size: 0,
            }}
            testID="signupLastName"
          />
        }
      />

      <Input
        placeholder="Email"
        placeholderTextColor={colors.greyOutline}
        textContentType="none"
        onSubmitEditing={Keyboard.dismiss}
        value={values.email}
        onBlur={() => setFieldTouched('email')}
        onChangeText={handleChange('email')}
        errorMessage={touched.email && errors.email ? errors.email : undefined}
        editable // !isSubmitting
        autoCapitalize="none"
        leftIcon={{
          type: 'feather',
          name: 'mail',
          color: colors.grey7,
          size: 17,
        }}
        testID="signupEmail"
        keyboardType="email-address"
      />
      <Input
        placeholder="Password"
        placeholderTextColor={colors.greyOutline}
        textContentType="none"
        onSubmitEditing={Keyboard.dismiss}
        value={values.password}
        onBlur={() => setFieldTouched('password')}
        onChangeText={handleChange('password')}
        errorMessage={touched.password && errors.password ? errors.password : undefined}
        editable // !isSubmitting
        secureTextEntry={securePassword}
        leftIcon={{
          type: 'feather',
          name: 'lock',
          color: colors.grey7,
          size: 17,
        }}
        testID="signupPassword"
        rightIcon={
          <Button
            onPress={() => setSecurePassword(!securePassword)}
            icon={{
              type: 'feather',
              name: securePassword ? 'eye' : 'eye-off',
              color: colors.grey7,
              size: 17,
            }}
            type="clear"
            testID="eye-sign"
          />
        }
        autoCapitalize="none"
      />

      <View style={styles.termsContainer}>
        <Text style={styles.termsText}>
          {'By signing up you agree to our '}
          <Text style={styles.textLink} link onPress={() => Linking.openURL(termsUrl)}>
            T&Cs{' '}
          </Text>
          {'and '}
          <Text style={styles.textLink} link onPress={() => Linking.openURL(privacyPolicyUrl)}>
            Privacy Policy{' '}
          </Text>
        </Text>
      </View>

      <Button
        title="Sign Up"
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        onPress={handleSubmit as any}
        disabled={signUpLoading}
        loading={signUpLoading}
        style={styles.button}
        testID="submitSignup"
      />
      <SignupProfilePopup
        visible={modalVisible}
        closeSubmit={closeModalAndSubmit}
        onClose={() => setModalVisible(false)}
        upload={uploadFromModal}
      />
    </View>
  )
}

export default SignUpForm
