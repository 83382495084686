import { StyleSheet } from 'react-native'
import { moderateScale } from 'react-native-size-matters'

import { colors, fonts } from '@src/config/theme'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  sectionTitleText: {
    fontSize: 14,
    textTransform: 'uppercase',
  },
  sectionTitleMainText: {
    color: colors.primary,
  },
  sectionTitleView: {
    backgroundColor: '#FFFFFF',
    margin: 20,
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderColor: colors.greyOutline,
  },
  sectionTitleMainView: {
    borderColor: colors.primary,
  },
  leftItemView: {
    width: 60,
    height: 60,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
  },
  leftItemDay: {
    fontFamily: fonts.default.bold,
    fontSize: 16,
    color: colors.grey7,
  },
  leftItemMonth: {
    fontSize: 10,
    fontFamily: fonts.default.bold,
    color: colors.grey7,
    textTransform: 'uppercase',
  },
  leftItemTick: {
    position: 'absolute',
    top: 0,
    right: 3,
    backgroundColor: '#fff',
  },
  cancelledWrapper: {
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.5)',
    width: 60,
    height: 60,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 60,
  },
  cancelledText: {
    color: colors.white,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: moderateScale(7.5),
    width: 50,
    fontFamily: fonts.default.bold,
  },
  emptySectionList: {
    height: '100%',
    marginTop: 50,
  },
  sectionListContainer: {
    paddingBottom: 75,
  },
})

export default styles
