import { StyleSheet } from 'react-native'

import { colors } from '@src/config/theme'
import { scaleVertical } from '@src/utils/scale'

const styles = StyleSheet.create({
  button: {
    marginTop: scaleVertical(6),
    marginBottom: scaleVertical(10),
  },
  termsContainer: {
    width: '80%',
    alignSelf: 'center',
    paddingVertical: 12,
    textAlign: 'center',
    // marginBottom: scaleVertical(0),
  },
  termsText: {
    textAlign: 'center',
  },
  avatar: {
    width: 90,
    height: 90,
    marginTop: 5,
    alignSelf: 'center',
  },
  textLink: {
    color: colors.primary,
  },
})

export default styles
