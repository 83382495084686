import { openBrowserAsync } from 'expo-web-browser'
import { FC } from 'react'
import { Pressable, View } from 'react-native'

import Text from '@src/components/Text'
import { getUrlName } from '@src/utils/url'

import styles from './EventLinksSection.styles'
import { EventLinksSectionProps } from './types'

const EventLinksSection: FC<EventLinksSectionProps> = ({
  links,
  title = 'USEFUL LINKS',
  linkContainer = {},
}) => (
  <View style={[styles.linkContainer, linkContainer]}>
    <Text style={styles.title}>{title}</Text>
    {links.map((link) =>
      link.url ? (
        <View key={link.url} style={styles.linkVw}>
          <Text style={styles.linkTitle}>{link.name || getUrlName(link.url)}: </Text>
          <Pressable onPress={() => openBrowserAsync(link.url)}>
            <Text numberOfLines={1} style={styles.url}>
              {link.url}
            </Text>
          </Pressable>
        </View>
      ) : null,
    )}
  </View>
)

export default EventLinksSection
