import { useRoute } from '@react-navigation/native'
import { useNavigation } from '@react-navigation/native'
import { Input, Text } from '@rneui/themed'
import { useMemo, useEffect } from 'react'
import { FlatList, View, Pressable } from 'react-native'

import EmptyNotice from '@src/components/EmptyNotice'
import Loading from '@src/components/Loading'
import WallItem from '@src/components/WallItem'
import { colors } from '@src/config/theme'
import { useWallPostQuery, useWallPostsQuery } from '@src/graphql/types'
import {
  DashboardWallCommentScreenNavigationProps,
  DashboardWallCommentScreenRouteProps,
} from '@src/types'
import isDefined from '@src/utils/isDefined'

import CommentItem from './components/CommentItem'
import styles from './DashboardWallCommentScreen.styles'

const DashboardWallCommentScreen = () => {
  const navigation = useNavigation<DashboardWallCommentScreenNavigationProps>()
  const {
    params: { parentUuid, groupUuid },
  } = useRoute<DashboardWallCommentScreenRouteProps>()
  const { data: { wallPost } = {}, loading } = useWallPostQuery({
    variables: { postUuid: parentUuid },
  })

  const {
    data: { wallPosts = [] } = {},
    loading: replyLoading,
    fetchMore,
    refetch,
    startPolling,
    stopPolling,
  } = useWallPostsQuery({ variables: { groupUuid, parentUuid, offset: 0, limit: 5 } })

  useEffect(() => {
    startPolling(30000)
    return () => {
      stopPolling()
    }
  }, [startPolling, stopPolling])

  //  Remove null or undefined entries from the wallPosts array
  const filteredPosts = useMemo(() => wallPosts.filter(isDefined), [wallPosts])
  const fetchMoreData = () => {
    return fetchMore({ variables: { offset: wallPosts.length } })
  }

  if (loading && !wallPost && replyLoading) return <Loading />

  return (
    <View style={styles.viewContainer}>
      <FlatList
        data={filteredPosts}
        keyExtractor={(post) => post.uuid}
        onRefresh={refetch}
        refreshing={false}
        onEndReachedThreshold={0.5}
        onEndReached={fetchMoreData}
        ListHeaderComponent={
          isDefined(wallPost) ? (
            <>
              <WallItem
                post={wallPost}
                isCommentPressable={false}
                isCommentEnabled={false}
                isDeleteVisible={false}
              />
              <Text style={styles.commentHeader}>Comments</Text>
            </>
          ) : null
        }
        renderItem={({ item }) => <CommentItem post={item} refetch={refetch} />}
        ListEmptyComponent={
          <EmptyNotice title="NOTHING TO SHOW" text={'No comments yet! Be the first to comment!'} />
        }
      />
      <View style={styles.outerInputContainer}>
        <Pressable
          onPress={() =>
            navigation.navigate('DashboardWallCommentReplyScreen', { parentUuid, groupUuid })
          }>
          <Input
            placeholder="Add a comment"
            placeholderTextColor={colors.black}
            editable={false}
            inputContainerStyle={styles.inputContainer}
            inputStyle={styles.inputStyle}
            multiline
            pointerEvents="none"
          />
        </Pressable>
      </View>
    </View>
  )
}

export default DashboardWallCommentScreen
