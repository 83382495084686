import { StackActions, useNavigation, useRoute } from '@react-navigation/native'
import { Divider } from '@rneui/themed'
import Constants from 'expo-constants'
import moment from 'moment'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import {
  AppState,
  AppStateStatus,
  FlatList,
  Platform,
  SafeAreaView,
  ScrollView,
  Share,
  View,
} from 'react-native'

import DualButton from '@src/components/DualButton'
import EmptyNotice from '@src/components/EmptyNotice'
import { showErrorMessage } from '@src/components/FlashMessage'
import HeaderIcon from '@src/components/HeaderIcon'
import Loading from '@src/components/Loading'
import BankedPopup from '@src/components/PopupModal/BankedPopup'
import PaymentConfirmationPopup from '@src/components/PopupModal/PaymentConfirmationPopup'
import Text from '@src/components/Text'
import getEnvVars from '@src/config/environment'
import { attendingOptions } from '@src/graphql/apollo'
import { GET_SESSION_MEMBERSHIP, GET_SESSION_QUERY, ME_QUERY } from '@src/graphql/queries'
import {
  MembershipStatus,
  useMembershipQuery,
  useMeQuery,
  useSessionQuery,
} from '@src/graphql/types'
import { useJoinSessionMutation } from '@src/graphql/types'
import Checkout from '@src/rn-banked-checkout'
import { ConfirmationScreenNavigationProps, ConfirmationScreenRouteProps } from '@src/types.d'

import ConfirmationList from './ConfirmationList'
import { styles } from './ConfirmationScreen.styles'

const { bankedKey } = getEnvVars()

const ConfirmationScreen = () => {
  const navigation = useNavigation<ConfirmationScreenNavigationProps>()
  const { params } = useRoute<ConfirmationScreenRouteProps>()
  const [modalVisible, setModalVisible] = useState(false)
  const [joining, setJoining] = useState(false)
  const [paymentId, setPaymentId] = useState('')
  const [membershipId, setMembershipId] = useState(0)
  const [bankedPopup, setBankedPopup] = useState(false)
  const [showCheckOut, setShowCheckOut] = useState(false)
  const appState = useRef(AppState.currentState)
  const {
    data: { session = {} } = {},
    loading,
    error,
  } = useSessionQuery({
    variables: { id: params.sessionId },
  })

  const { data: meData, loading: meLoading, error: meError } = useMeQuery()

  const { data: { membership = {} } = {}, error: membershipError } = useMembershipQuery({
    variables: { sessionId: params.sessionId },
  })

  const filteredMembership = session?.membership.filter(
    ({ user: { uuid } }) => uuid === meData?.me?.uuid,
  )

  const paymentPending = filteredMembership
    ?.map(({ status }) => status)
    .includes(MembershipStatus.PaymentPending)

  useEffect(() => {
    const appStateListener = AppState.addEventListener('change', handleAppStateChange)

    return () => {
      appStateListener.remove()
    }
  }, [])

  const handleAppStateChange = (nextAppState: AppStateStatus) => {
    if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
      if (paymentId && showCheckOut) {
        navigation.dispatch(StackActions.replace('PaymentCompletedScreen', { paymentId }))
      }
    }

    appState.current = nextAppState
  }

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <HeaderIcon onPress={handleBackClick} />,
    })

    const handleBackClick = () => {
      if (showCheckOut) {
        setShowCheckOut(false)
        return
      }
      navigation.goBack()
    }
  }, [showCheckOut, navigation])

  const [joinSessionMutation] = useJoinSessionMutation()

  useEffect(() => {
    if (params?.paymentId) {
      setShowCheckOut(false)
      setBankedPopup(true)
    }
  }, [params])

  if (loading || meLoading) return <Loading />
  if (error || meError)
    return (
      <View style={styles.errorContainer}>
        <EmptyNotice title="NOTHING TO SHOW" text="An error occurred fetching this session!" />
      </View>
    )

  const getFormattedPrice = () => {
    const { priceId } = params
    const price = session?.prices.find((item) => item.id === priceId)
    return [price.value, `${price.description} (£${price.value})`]
  }

  const getItems = () => {
    const startAt = moment(session.startAt)
    const formattedDate = startAt.format('DD MMMM, YYYY')
    const formattedTime = startAt.format('HH:mm')

    return [
      {
        title: session.name,
        comment: 'Session Name',
      },
      {
        title: session.activity[0]?.name,
        comment: 'Activity Type',
      },
      {
        title: `${formattedDate}${'\n'}${formattedTime}`,
        comment: 'Time & Date',
      },
      {
        title: session?.address,
        comment: 'Address',
      },
      {
        title: getFormattedPrice()[1],
        comment: 'Joining Option',
      },
    ]
  }

  const onBack = () => {
    navigation.goBack()
  }

  const onPay = async () => {
    const { sessionId, priceId, paymentMethod } = params

    if (!session.group.isMember && session.group.isPrivate) {
      navigation.navigate('DashboardInviteReceivedScreen', {
        uuid: session.group.uuid,
        fromSession: true,
      })
    } else {
      setJoining(true)

      await joinSessionMutation({
        variables: {
          priceId,
          sessionId,
          paymentMethod,
          isExpo: Constants.appOwnership === 'expo',
        },
        refetchQueries: [
          {
            query: GET_SESSION_MEMBERSHIP,
            variables: {
              ...attendingOptions,
            },
          },
          { query: GET_SESSION_QUERY, variables: { id: sessionId } },
          { query: ME_QUERY },
        ],
        awaitRefetchQueries: true,
      })
        .then(({ data }) => {
          const { joinSession } = data
          if (joinSession?.membership?.bankedPaymentId && joinSession?.membership?.status) {
            const { id, bankedPaymentId } = joinSession.membership
            setPaymentId(bankedPaymentId)
            setMembershipId(id)
            setShowCheckOut(true)
          } else setModalVisible(true)
        })
        .catch((e) => {
          console.log('error', e)
          showErrorMessage({
            message: 'Error',
            description:
              'There was something wrong with your payment. Please try again and if you’re still having trouble, send us an issue report and we will rectify it as soon as possible.',
          })
        })
        .finally(() => setJoining(false))
    }
  }

  const onBackHome = () => {
    setModalVisible(false)
    setBankedPopup(false)
    navigation.navigate('HomeStackScreen')
  }

  const onGroup = () => {
    const { uuid } = session.group
    setModalVisible(false)
    setBankedPopup(false)
    navigation.navigate('DashboardGroupScreen', { groupUuid: uuid })
  }

  const onShare = async () => {
    const dateFormat = moment(session.startAt).format('MMMM D')
    const timeFormat = moment(session.startAt).format('HH:mm')
    const url = `https://app.orfiactive.com/session/${session.id}`

    try {
      const result = await Share.share({
        message: `I've just joined ${
          session?.activity?.name
        } on ${dateFormat} at ${timeFormat}, check it out here. ${
          Platform.OS === 'android' ? url : ''
        }`,
        url,
      })

      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (err) {
      // eslint-disable-next-line no-alert
      alert(err.message)
    }
  }

  const onClose = () => {
    setModalVisible(false)
  }

  const handleTryAgain = () => {
    setPaymentId('')
    setMembershipId(0)
    setBankedPopup(false)
    onPay()
  }

  const { paymentMethod } = params
  const items = getItems()
  const [price, priceDescription] = getFormattedPrice()

  return (
    <SafeAreaView style={styles.container}>
      {!showCheckOut ? (
        <>
          <ScrollView style={styles.content}>
            <PaymentConfirmationPopup
              visible={modalVisible}
              onClose={onClose}
              onShare={onShare}
              onGroup={onGroup}
              onBackHome={onBackHome}
              price={priceDescription}
            />
            <View style={styles.comments}>
              <Text style={styles.commentText}>
                You’re about to join a session with the following details:
              </Text>
            </View>
          </ScrollView>
          <FlatList
            data={items}
            keyExtractor={(item) => item.comment}
            renderItem={({ item: { comment, title } }) => (
              <ConfirmationList title={title} comment={comment} />
            )}
            ItemSeparatorComponent={() => <Divider />}
            style={styles.list}
          />
          <View style={styles.buttons}>
            <DualButton
              leftTitle="Back"
              rightTitle={
                paymentPending
                  ? 'Continue Payment'
                  : paymentMethod === 'SITE' || price === '0'
                  ? 'Join'
                  : `Pay £${price.toFixed(2)} & Join`
              }
              leftOnPress={onBack}
              rightOnPress={paymentPending ? () => setShowCheckOut(true) : onPay}
              loading={joining}
              disabled={joining}
            />
          </View>
        </>
      ) : (
        <Checkout
          paymentId={paymentId ? paymentId : membership?.bankedPaymentId}
          apiKey={bankedKey}
          onCancel={() => setShowCheckOut(false)}
        />
      )}
      <BankedPopup
        status={membershipError ? 'FAILED' : membership?.status}
        price={price}
        visible={bankedPopup}
        tryAgain={handleTryAgain}
        close={() => setBankedPopup(false)}
        onShare={onShare}
        onGroup={onGroup}
        onBackHome={onBackHome}
      />
    </SafeAreaView>
  )
}

export default ConfirmationScreen
