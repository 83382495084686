import moment from 'moment'
import { RRule } from 'rrule'

import getPickerLabel from './getPickerLabel'

import {
  pickerGender,
  pickerPrivacy,
  pickerRefundPolicy,
  pickerSkillLevel,
  pickerVenue,
  recurringTypeDaily,
  recurringTypeMonthly,
  recurringTypeOnce,
  recurringTypeWeekly,
} from '../config/forms'

export const formatParticipants = (inputMin, inputMax) => {
  const min = parseInt(inputMin || 0, 10)
  const max = parseInt(inputMax || 0, 10)

  let textValue = ''
  switch (true) {
    case min && !max:
      textValue = `min ${min}`
      break
    case !min && max:
      textValue = `max ${max}`
      break
    case min > 0 && max > 0:
      textValue = `min ${min} - max ${max}`
      break
    default:
      textValue = 'N/A'
      break
  }

  return textValue
}

export const formatAgeRange = (inputMin, inputMax) => {
  const min = parseInt(inputMin || 0, 10)
  const max = parseInt(inputMax || 0, 10)

  let textValue = ''
  switch (true) {
    case min && !max:
      textValue = `${min}+`
      break
    case max && !min:
      textValue = `<${max}`
      break
    case min > 0 && max > 0:
      textValue = `${min} - ${max}`
      break
    default:
      textValue = 'N/A'
      break
  }

  return textValue
}

export const formatEventDates = (sessions) => {
  const formatString = 'D MMM, YYYY'

  if (sessions.length <= 1) {
    return moment(sessions[0].startAt).format(formatString)
  }

  const minSession = sessions.reduce((acc, session) => {
    const sessionStartAt = moment(session.startAt)
    const accStartAt = moment(acc.startAt)
    return sessionStartAt < accStartAt ? session : acc
  })

  const maxSession = sessions.reduce((acc, session) => {
    const sessionStartAt = moment(session.startAt)
    const accStartAt = moment(acc.startAt)

    return sessionStartAt > accStartAt ? session : acc
  })

  const minSessionFormat = moment(minSession.startAt).format(formatString)
  const maxSessionFormat = moment(maxSession.startAt).format(formatString)

  return `${minSessionFormat} - ${maxSessionFormat}`
}

export const formatPricing = (prices) => {
  let textValue = ''
  prices.forEach(({ description, value }, index) => {
    const newline = index > 0 ? '\n' : ''
    textValue = `${textValue}${newline}£${value} (${description})`
  })

  return textValue
}

export const calcAmountToReceive = (price) => price - ((1.4 * price) / 100 + 0.2).toFixed(2)

export const formatAttendingCount = (attendingCount = 0, maxCount = 0, withSpace = true) => {
  if (maxCount) {
    return withSpace ? `${attendingCount} / ${maxCount}` : `${attendingCount}/${maxCount}`
  }

  return attendingCount
}

export const formatGender = (value) => getPickerLabel(pickerGender, value)

export const formatPrivacy = (value) => getPickerLabel(pickerPrivacy, value)

export const formatRefundPolicy = (value) => getPickerLabel(pickerRefundPolicy, value)

export const formatSkillLevel = (value) => getPickerLabel(pickerSkillLevel, value)

export const formatVenueType = (value) => getPickerLabel(pickerVenue, value)

export const formatLinks = (value) => value.map((link) => link.url).join(', \n')

export const calculateSessions = (values) => {
  const {
    sessionStartDate,
    sessionEndDate,

    recurringType,
    recurringEveryOtherWeek,
    recurringDaysOfTheWeek,
    recurringEndDate,
  } = values

  const recurrenceEnd = moment(recurringEndDate).endOf('day').toDate()

  const MAX_SESSION_COUNT = 12
  const sessions = []
  let options = {}
  let endDateOptions = {}

  if (recurringType === recurringTypeOnce) {
    const session = {
      startAt: moment(sessionStartDate).toISOString(),
    }

    if (sessionEndDate) {
      session.endAt = moment(sessionEndDate).toISOString()
    }

    sessions.push(session)
    return sessions
  }

  if (recurringType === recurringTypeDaily) {
    options = {
      freq: RRule.DAILY,
      dtstart: new Date(values.sessionStartDate),
      interval: 1,
      count: MAX_SESSION_COUNT,
    }

    endDateOptions = {
      freq: RRule.DAILY,
      dtstart: new Date(values.sessionEndDate),
      interval: 1,
      count: MAX_SESSION_COUNT,
    }

    if (values.recurringEndDate) {
      options.until = recurrenceEnd
      endDateOptions.until = recurrenceEnd
    } else {
      options.count = MAX_SESSION_COUNT
      endDateOptions.count = MAX_SESSION_COUNT
    }
  }

  if (recurringType === recurringTypeWeekly) {
    options = {
      freq: RRule.WEEKLY,
      dtstart: new Date(values.sessionStartDate),
      interval: recurringEveryOtherWeek ? 2 : 1,
      byweekday: recurringDaysOfTheWeek,
      count: MAX_SESSION_COUNT,
    }

    endDateOptions = {
      freq: RRule.WEEKLY,
      dtstart: new Date(values.sessionEndDate),
      interval: recurringEveryOtherWeek ? 2 : 1,
      byweekday: recurringDaysOfTheWeek,
      count: MAX_SESSION_COUNT,
    }

    if (values.recurringEndDate) {
      options.until = recurrenceEnd
      endDateOptions.until = recurrenceEnd
    } else {
      options.count = MAX_SESSION_COUNT
      endDateOptions.count = MAX_SESSION_COUNT
    }
  }

  if (recurringType === recurringTypeMonthly) {
    options = {
      freq: RRule.MONTHLY,
      dtstart: new Date(values.sessionStartDate),
      count: MAX_SESSION_COUNT,
    }

    endDateOptions = {
      freq: RRule.MONTHLY,
      dtstart: new Date(values.sessionEndDate),
      count: MAX_SESSION_COUNT,
    }

    if (values.recurringEndDate) {
      options.until = recurrenceEnd
      endDateOptions.until = recurrenceEnd
    } else {
      options.count = MAX_SESSION_COUNT
      endDateOptions.count = MAX_SESSION_COUNT
    }
  }

  const startAt = new RRule(options).all()
  const endAt = new RRule(endDateOptions).all()
  const dateObj = startAt.map((val, index) => ({ startAt: val, endAt: endAt[index] }))

  dateObj.forEach((item, index) => {
    // loop through the date object and add extra check to make sure it doesnt exceed 12
    if (index !== MAX_SESSION_COUNT) {
      sessions.push({
        startAt: moment(item.startAt).toISOString(),
        endAt: moment(item.endAt).toISOString(),
      })
    }
  })

  return sessions
}
