import { useNavigation, useRoute } from '@react-navigation/native'
import { Button, Icon, ListItem } from '@rneui/themed'
import moment from 'moment'
import React, { useLayoutEffect, useState } from 'react'
import { FlatList, View } from 'react-native'

import Loading from '@src/components/Loading'
import LeaveGroupPopup from '@src/components/PopupModal/LeaveGroupPopup'
import { colors } from '@src/config/theme'
import { ME_QUERY } from '@src/graphql/queries'
import { useGroupQuery, useLeaveGroupMutation } from '@src/graphql/types'
import { DashboardMoreScreenNavigationProps, DashboardMoreScreenRouteProps } from '@src/types'

import styles from './DashboardMoreScreen.styles'

const DashboardMoreScreen = () => {
  const navigation = useNavigation<DashboardMoreScreenNavigationProps>()
  const { params } = useRoute<DashboardMoreScreenRouteProps>()
  const [isDelete, setIsDelete] = useState(false)
  const [isLeave, setIsLeave] = useState(false)

  const [leaveGroup, { loading: leaveLoading }] = useLeaveGroupMutation({
    refetchQueries: [
      {
        query: ME_QUERY,
      },
    ],
    awaitRefetchQueries: true,
  })

  const {
    loading,
    data: groupData,
    error: groupError,
  } = useGroupQuery({
    variables: {
      groupUuid: params?.groupUuid,
    },
  })

  useLayoutEffect(() => {
    if (groupData) {
      navigation.setOptions({
        headerRight: () => (
          <Button
            title="+ INVITE"
            onPress={() =>
              navigation.navigate('DashboardInviteMemberScreen', {
                groupUuid: groupData.group.uuid,
                groupName: groupData.group.name,
              })
            }
            type="clear"
          />
        ),
      })
    }
  }, [groupData])

  const onLeaveGroup = async () => {
    try {
      await leaveGroup({
        variables: {
          groupUuid: params?.groupUuid,
        },
      })
      navigation.navigate('GroupScreen')
    } catch (error) {
      console.log('error', error)
    }
  }

  if (loading) return <Loading />

  if (groupError) return <View />

  const goToPage = (name, screenProps = {}) => {
    navigation.navigate(name, screenProps)
  }

  const { group } = groupData

  const getMenuOptions = () => {
    let menuOptions = [
      {
        title: 'Invite Members',
        icon: 'user-plus',
        type: 'feather',
        key: 'DashboardInviteMemberScreen',
        onPress: () =>
          goToPage('DashboardInviteMemberScreen', {
            groupUuid: groupData?.group?.uuid,
            groupName: groupData.group.name,
          }),
      },
      {
        title: 'Session History',
        icon: 'access-time',
        type: 'ionicons',
        key: 'DashboardPassedSessionScreen',
        onPress: () => goToPage('DashboardPassedSessionScreen', { groupUuid: group?.uuid }),
      },
      // {
      //   title: 'Notification Preferences',
      //   icon: 'bell',
      //   type: 'feather',
      //   key: 'Notification',
      //   onPress: openSetting,
      // },
    ]

    if (group.isAdmin) {
      menuOptions = [
        {
          title: 'Manage Members',
          icon: 'users',
          type: 'feather',
          key: 'Manage-Members',
          onPress: () => goToPage('DashboardMembersScreen', { groupUuid: group.uuid }),
        },
        {
          title: 'Edit Group Details',
          icon: 'edit',
          type: 'feather',
          key: 'EditEventScreen',
          onPress: () => goToPage('EditGroupScreen', { groupUuid: group.uuid }),
        },
        {
          title: 'Duplicate Session',
          icon: 'copy',
          type: 'feather',
          key: 'SelectSessionScreen',
          onPress: () => goToPage('SelectSessionScreen', { groupUuid: group.uuid }),
        },
        {
          title: 'Edit Sessions',
          icon: 'tool',
          type: 'feather',
          key: 'SelectSessionEditScreen',
          onPress: () => goToPage('SelectSessionEditScreen', { groupUuid: group.uuid }),
        },
        // {
        //   title: 'Transfer Ownership',
        //   icon: 'zap',
        //   type: 'feather',
        //   key: 'TransferOwnership',
        //   onPress: () => goToPage('DashboardTransferOwnershipScreen', { groupUuid: group.uuid }),
        // },
        ...menuOptions,
      ]

      if (group.isOwner) {
        menuOptions.push({
          title: 'Wallet',
          icon: 'credit-card',
          type: 'feather',
          key: 'MyWallet',
          onPress: () => goToPage('MyWalletScreen'),
        })
      }
    } else {
      menuOptions = [
        {
          title: 'View Members',
          icon: 'users',
          type: 'feather',
          key: 'DashboardMembersScreen',
          onPress: () => goToPage('DashboardMembersScreen', { groupUuid: group?.uuid }),
        },
        ...menuOptions,
      ]
    }
    menuOptions.push({
      title: 'Track & Trace',
      icon: 'crosshair',
      type: 'feather',
      key: 'Track&Trace',
      onPress: () => goToPage('DashboardTrackTraceScreen', { groupUuid: group?.uuid }),
    })

    return menuOptions
  }

  const canLeave = () =>
    group.sessions.some(
      (s) =>
        moment() < moment(s.startAt) && s.isUserAttending && s.status.toLowerCase() !== 'cancelled',
    )

  const menuOptions = getMenuOptions()

  return (
    <View style={styles.container}>
      <FlatList
        data={menuOptions}
        renderItem={({ item: { key, title, icon, onPress, type } }) => (
          <ListItem key={key} onPress={onPress} bottomDivider>
            <Icon name={icon} type={type} color={colors.grey} size={20} />
            <ListItem.Content style={styles.listRenderContent}>
              <ListItem.Title style={styles.title}>{title}</ListItem.Title>
            </ListItem.Content>
            <ListItem.Chevron />
          </ListItem>
        )}
      />
      {!group.isAdmin && (
        <ListItem
          disabled={group?.isAdmin}
          onPress={() => (group?.isAdmin ? setIsDelete(true) : setIsLeave(true))}
          style={styles.leaveContainer}>
          <Icon name="trash-2" type="feather" color={colors.primary} size={20} />
          <ListItem.Content style={styles.listItemContainer}>
            <ListItem.Title style={styles.leave}>Leave Group</ListItem.Title>
          </ListItem.Content>
        </ListItem>
      )}
      <LeaveGroupPopup
        onClose={() => setIsLeave(false)}
        onDelete={onLeaveGroup}
        visible={isLeave}
        loading={leaveLoading}
        cantLeave={canLeave()}
      />
    </View>
  )
}

export default DashboardMoreScreen
