import { useNavigation } from '@react-navigation/native'
import { Icon } from '@rneui/themed'
import moment from 'moment'
import { FC, useState } from 'react'
import { View, Pressable } from 'react-native'

import ConfirmDialog from '@src/components/ConfirmDialog'
import OrfiAvatar from '@src/components/OrfiAvatar'
import Text from '@src/components/Text'
import { colors } from '@src/config/theme'
import {
  useWallToggleReactionMutation,
  useMeQuery,
  useWallDeletePostMutation,
} from '@src/graphql/types'
import getInitials from '@src/utils/getInitials'

import { WallItemPropType } from './types'
import styles from './WallItem.styles'

const WallItem: FC<WallItemPropType> = ({
  post: {
    uuid: postUuid,
    content,
    user,
    userHasLiked,
    reactionCount,
    postCount,
    createdAt,
    isAdmin,
    wall: {
      group: { uuid: groupUuid, isAdmin: userIsAdmin },
    },
  },
  isCommentEnabled = true,
  isCommentPressable = true,
  isDeleteVisible = true,
}) => {
  const navigation = useNavigation()
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false)
  // Hacky method to hide the component when deleted
  const [isDeleted, setIsDeleted] = useState(false)

  const {
    data: { me: { uuid: userUuid = '' } = {} },
  } = useMeQuery()
  const [toggleReaction] = useWallToggleReactionMutation()
  const [deletePost, { loading }] = useWallDeletePostMutation({
    update(cache, { data }) {
      if (!data?.wallDeletePost?.ok) {
        return
      }

      cache.modify({
        fields: {
          wallPosts(posts = [], { readField }) {
            return posts.filter((post) => readField('uuid', post) !== postUuid)
          },
        },
      })

      setIsDeleted(true)
    },
  })

  const onPressHeart = async () => {
    await toggleReaction({ variables: { postUuid } })
  }
  const onPressDelete = async () => {
    await deletePost({ variables: { postUuid } })
  }

  if (isDeleted) {
    return
  }

  const groupRole = isAdmin ? 'Admin' : 'Member'
  const responseCountCheck = reactionCount > 99 ? '99+' : reactionCount
  const postCountCheck = postCount > 99 ? '99+' : postCount
  const userUuidCheck = user.uuid === userUuid ? true : false

  const navigateToComment = () =>
    navigation.navigate('DashboardWallCommentScreen', {
      parentUuid: postUuid,
      groupUuid,
    })

  return (
    <Pressable
      disabled={!isCommentPressable}
      onPress={navigateToComment}
      style={({ pressed }) => [{ opacity: pressed ? 0.5 : 1.0 }]}>
      <View style={styles.container}>
        <View style={styles.header}>
          <OrfiAvatar size="small" uri={user.avatar} title={getInitials(user.name)} border />
          <View style={styles.outerUserContainer}>
            <View style={styles.headerRowContainer}>
              <View style={styles.userContainer}>
                <Text style={styles.nameText}>{user.name}</Text>
              </View>
              {isDeleteVisible && (userUuidCheck || userIsAdmin) ? (
                <Pressable
                  disabled={loading}
                  onPress={() => setIsDeleteDialogVisible(!isDeleteDialogVisible)}>
                  <View style={styles.deletePostContainer}>
                    <Text style={styles.deletePostText}>Delete</Text>
                  </View>
                </Pressable>
              ) : undefined}
            </View>
            <View style={styles.timestamp}>
              {isAdmin && <Icon name="crown" type="material-community" color="#D4AF37" size={16} />}
              <Text style={styles.subRowText}>{groupRole}</Text>
              <Icon name={'circle'} size={4} color={colors.grey5} style={styles.icon} />
              <Text style={styles.subRowText}>{moment(createdAt).fromNow(true)}</Text>
            </View>
          </View>
        </View>
        <View style={styles.content}>
          <Text>{content}</Text>
        </View>
        <View style={styles.footer}>
          <View style={styles.innerFooter}>
            <Pressable
              onPress={onPressHeart}
              style={({ pressed }) => [{ opacity: pressed ? 0.5 : 1.0 }]}>
              <View style={styles.responseContainer}>
                <Icon
                  name={userHasLiked ? 'heart' : 'heart-outline'}
                  type="material-community"
                  color={userHasLiked ? colors.primary : colors.grey4}
                />
                <Text style={styles.responseText}>{responseCountCheck}</Text>
              </View>
            </Pressable>
          </View>
          {isCommentEnabled && (
            <Pressable
              onPress={navigateToComment}
              style={({ pressed }) => [{ opacity: pressed ? 0.5 : 1.0 }]}>
              <View style={styles.responseContainer}>
                <Icon name={'message-square'} type="feather" color={colors.grey4} />
                <Text style={styles.responseText}>{postCountCheck}</Text>
              </View>
            </Pressable>
          )}
        </View>
      </View>

      <ConfirmDialog
        message="Are you sure you want to delete this post?"
        title=""
        negativeButton={{
          title: 'Cancel',
          onPress: () => setIsDeleteDialogVisible(!isDeleteDialogVisible),
        }}
        positiveButton={{
          title: 'Delete Post',
          onPress: () => {
            onPressDelete()
            setIsDeleteDialogVisible(!isDeleteDialogVisible)
          },
        }}
        visible={isDeleteDialogVisible}
      />
    </Pressable>
  )
}

export default WallItem
