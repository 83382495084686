import { useNavigation } from '@react-navigation/native'
import { Button, Divider, Input } from '@rneui/themed'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { Keyboard, SafeAreaView, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import SendFeedbackPopup from '@src/components/PopupModal/SendFeedbackPopup'
import Text from '@src/components/Text'
import { colors } from '@src/config/theme'
import { useCreateFeedbackEntryMutation } from '@src/graphql/types'
import { SendFeedbackScreenNavigationProps } from '@src/types.d'

import styles from './SendFeedbackScreen.styles'
import validationSchema from './SendFeedbackScreen.validation'

const SendFeedbackScreen = () => {
  const navigation = useNavigation<SendFeedbackScreenNavigationProps>()
  const [modalVisible, setIsModalVisible] = useState(false)
  const [createFeedback, { loading }] = useCreateFeedbackEntryMutation()

  const onSubmit = async (values, { setFieldError }) => {
    Keyboard.dismiss()
    try {
      await createFeedback({ variables: { message: values.feedback } })
      setIsModalVisible(true)
    } catch (error) {
      setFieldError('form', error.message)
    }
  }

  const { values, handleChange, setFieldTouched, errors, touched, handleSubmit } = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      feedback: '',
    },
    onSubmit,
  })

  const onClose = () => {
    setIsModalVisible(!modalVisible)
    navigation.goBack()
  }

  return (
    <SafeAreaView style={styles.container}>
      <SendFeedbackPopup visible={modalVisible} onClose={onClose} />
      <KeyboardAwareScrollView style={styles.content}>
        <Text style={styles.comment}>
          Our app is built for you, the user, and we want to provide you with the best experience
          possible. It would be great if you could share any feedback with us below – the more
          critical the better.
          {'\n\n'}
          That’s the only way we get better, right?
        </Text>
        <Text style={styles.title}>YOUR MESSAGE</Text>
        <Divider />
        <Input
          placeholder="Enter your message here..."
          placeholderTextColor={colors.greyOutline}
          value={values.feedback}
          onBlur={() => setFieldTouched('feedback')}
          onChangeText={handleChange('feedback')}
          errorMessage={touched.feedback && errors.feedback ? errors.feedback : undefined}
          editable={!loading}
          inputContainerStyle={styles.inputContainer}
          inputStyle={styles.inputStyle}
          multiline
        />
        <Divider />
      </KeyboardAwareScrollView>
      <View style={styles.bottom}>
        <Button
          title="Send Feedback"
          loading={loading}
          disabled={loading}
          onPress={handleSubmit}
          titleStyle={styles.sendTitle}
        />
      </View>
    </SafeAreaView>
  )
}

export default SendFeedbackScreen
