import { StyleSheet } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const styles = StyleSheet.create({
  item: {
    backgroundColor: '#fff',
    flex: 1,
    borderRadius: 5,
    paddingHorizontal: 20,
    paddingVertical: 20,
    marginRight: 10,
    marginTop: 17,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  emptyDate: {
    height: 5,
    flex: 1,
    paddingTop: 30,
  },
  title: {
    fontFamily: fonts.default.bold,
    fontSize: 13,
    color: colors.secondary,
  },
  name: {
    fontFamily: fonts.default.regular,
    fontSize: 19,
    color: colors.secondary,
    marginTop: 3,
  },
  copy: {
    color: colors.grey7,
    fontFamily: fonts.default.regular,
    fontSize: 13,
  },
  participantsVw: {
    flexDirection: 'row',
    marginTop: 15,
    flex: 2,
  },
  participants: {
    color: colors.grey7,
    fontFamily: fonts.default.regular,
    flex: 2,
  },
  attendingVw: {
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 2,
  },
  attending: {
    color: colors.primary,
    fontFamily: fonts.default.regular,
    marginLeft: 5,
  },
  cancelledVw: {
    ...StyleSheet.absoluteFill,
    backgroundColor: 'rgba(0,0,0,0.7)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cancelledText: {
    fontSize: 19,
    color: colors.white,
    fontFamily: fonts.default.bold,
  },
  duplicateText: {
    color: colors.grey5,
    textTransform: 'uppercase',
    fontFamily: fonts.default.regular,
  },
  duplicateContainer: {
    alignItems: 'center',
  },
  attendingIcon: {
    marginTop: 3,
  },
  subtitleSpacer: {
    flex: 1,
  },
  iconStyle: {
    paddingTop: 4,
  },
})

export default styles
