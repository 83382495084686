import {
  createNativeStackNavigator,
  NativeStackNavigationOptions,
} from '@react-navigation/native-stack'
import { useContext } from 'react'

import HeaderIcon from '@src/components/HeaderIcon'
import { colors, fonts } from '@src/config/theme'
import { AuthContext } from '@src/context/AuthContext'
import AuthScreen from '@src/screens/AuthScreen'
import CancelSessionScreen from '@src/screens/CancelSessionScreen'
import CreateGroupScreen from '@src/screens/Events/CreateGroupScreen'
import CreateSessionScreen from '@src/screens/Events/CreateSessionScreen'
import DuplicateSessionScreen from '@src/screens/Events/DuplicateSessionScreen'
import EditBulkSessionScreen from '@src/screens/Events/EditBulkSessionScreen'
import EditGroupScreen from '@src/screens/Events/EditGroupScreen'
import EditSessionScreen from '@src/screens/Events/EditSessionScreen'
import SelectActivityScreen from '@src/screens/Events/SelectActivityScreen'
import SelectGroupScreen from '@src/screens/Events/SelectGroupScreen'
import SelectSessionEditScreen from '@src/screens/Events/SelectSessionEditScreen'
import SelectSessionScreen from '@src/screens/Events/SelectSessionScreen'
import SelectSessionTransferScreen from '@src/screens/Events/SelectSessionTransferScreen'
import TransferMembershipOptionScreen from '@src/screens/Events/TransferMembershipOptionScreen'
import DashboardAboutScreen from '@src/screens/GroupDashboard/DashboardAboutScreen'
import DashboardGroupScreen from '@src/screens/GroupDashboard/DashboardGroupScreen'
import DashboardInviteMemberScreen from '@src/screens/GroupDashboard/DashboardInviteMemberScreen'
import DashboardInviteReceivedScreen from '@src/screens/GroupDashboard/DashboardInviteReceivedScreen'
import DashboardMembersScreen from '@src/screens/GroupDashboard/DashboardMembersScreen'
import DashboardMoreScreen from '@src/screens/GroupDashboard/DashboardMoreScreen'
import DashboardPassedSessionScreen from '@src/screens/GroupDashboard/DashboardPassedSessionScreen'
import DashboardSessionsScreen from '@src/screens/GroupDashboard/DashboardSessionsScreen'
import DashboardTrackTraceScreen from '@src/screens/GroupDashboard/DashboardTrackTraceScreen'
import DashboardTransferOwnershipScreen from '@src/screens/GroupDashboard/DashboardTransferOwnershipScreen'
import DashboardWallCommentReplyScreen from '@src/screens/GroupDashboard/DashboardWallCommentReplyScreen'
import DashboardWallCommentScreen from '@src/screens/GroupDashboard/DashboardWallCommentScreen'
import DashboardWallScreen from '@src/screens/GroupDashboard/DashboardWallScreen'
import AttendeesListScreen from '@src/screens/HomeScreen/AttendeesListScreen'
import ConfirmationScreen from '@src/screens/HomeScreen/ConfirmationScreen'
import JoiningOptionsScreen from '@src/screens/HomeScreen/JoiningOptionsScreen'
import PaymentCompletedScreen from '@src/screens/HomeScreen/PaymentCompletedScreen'
import NotificationScreen from '@src/screens/Notifications'
import ConfirmPasswordScreen from '@src/screens/Profile/ConfirmPasswordScreen'
import EditProfileScreen from '@src/screens/Profile/EditProfileScreen'
import EmailConfirmationScreen from '@src/screens/Profile/EmailConfirmationScreen'
import FaqScreen from '@src/screens/Profile/FaqScreen'
import MyWalletScreen from '@src/screens/Profile/MyWalletScreen'
import NewPasswordScreen from '@src/screens/Profile/NewPasswordScreen'
import ResetPasswordScreen from '@src/screens/Profile/ResetPasswordScreen'
import SendFeedbackScreen from '@src/screens/Profile/SendFeedbackScreen'
import PublicProfileScreen from '@src/screens/PublicProfileScreen'
import SelectVenueScreen from '@src/screens/SelectVenueScreen'
import SessionDetailScreen from '@src/screens/SessionDetailScreen'
import { RootStackParamList } from '@src/types'

import MainTabNavigator from './MainTabNavigator'

const Stack = createNativeStackNavigator<RootStackParamList>()

const RootStack = () => {
  const { isAuthenticated } = useContext(AuthContext)

  const screenOptions: NativeStackNavigationOptions = {
    headerLeft: HeaderIcon,
    headerTitleStyle: {
      fontFamily: fonts.default.bold,
    },
  }

  return (
    <Stack.Navigator screenOptions={screenOptions} initialRouteName="MainTab">
      {isAuthenticated ? (
        <Stack.Group>
          <Stack.Screen
            name="MainTab"
            component={MainTabNavigator}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="CancelSessionScreen"
            component={CancelSessionScreen}
            options={{ title: 'CANCEL SESSION' }}
          />
          <Stack.Screen
            name="PublicProfileScreen"
            component={PublicProfileScreen}
            options={{ title: 'MEMBER PROFILE' }}
          />
          <Stack.Screen
            name="SelectActivityScreen"
            component={SelectActivityScreen}
            options={{ title: 'SELECT ACTIVITY' }}
          />
          <Stack.Screen
            name="SelectVenueScreen"
            component={SelectVenueScreen}
            options={{ title: 'SELECT LOCATION ' }}
          />
          <Stack.Screen
            name="EditProfileScreen"
            component={EditProfileScreen}
            options={{ title: 'EDIT PROFILE' }}
          />
          <Stack.Screen
            name="JoiningOptionsScreen"
            component={JoiningOptionsScreen}
            options={{ title: 'JOIN SESSION' }}
          />
          <Stack.Screen
            name="ConfirmationScreen"
            component={ConfirmationScreen}
            options={{ title: 'PAYMENT CONFIRMATION' }}
          />
          <Stack.Screen
            name="MyWalletScreen"
            component={MyWalletScreen}
            options={{ title: 'MY WALLET' }}
          />
          <Stack.Screen
            name="SendFeedbackScreen"
            component={SendFeedbackScreen}
            options={{ title: 'FEEDBACK' }}
          />
          <Stack.Screen name="FaqScreen" component={FaqScreen} options={{ title: 'FAQ' }} />
          <Stack.Screen
            name="DashboardPassedSessionScreen"
            component={DashboardPassedSessionScreen}
            options={{ title: 'PAST SESSIONS' }}
          />
          <Stack.Screen
            name="EditGroupScreen"
            component={EditGroupScreen}
            options={{ title: 'EDIT GROUP' }}
          />
          <Stack.Screen
            name="NotificationScreen"
            component={NotificationScreen}
            options={{ title: 'NOTIFICATIONS' }}
          />
          <Stack.Screen
            name="DashboardGroupScreen"
            component={DashboardGroupScreen}
            options={{
              headerStyle: {
                backgroundColor: colors.primary,
              },
              headerTintColor: colors.white,
            }}
          />
          <Stack.Screen
            name="CreateSessionScreen"
            component={CreateSessionScreen}
            options={{ title: 'CREATE' }}
          />
          <Stack.Screen
            name="EditSessionScreen"
            component={EditSessionScreen}
            options={{ title: 'EDIT SESSION' }}
          />
          <Stack.Screen
            name="EditBulkSessionScreen"
            component={EditBulkSessionScreen}
            options={{ title: 'EDIT SESSIONS' }}
          />
          <Stack.Screen
            name="SelectSessionScreen"
            component={SelectSessionScreen}
            options={{ title: 'SELECT SESSION' }}
          />
          <Stack.Screen
            name="SelectSessionEditScreen"
            component={SelectSessionEditScreen}
            options={{ title: 'SELECT SESSIONS' }}
          />
          <Stack.Screen
            name="DuplicateSessionScreen"
            component={DuplicateSessionScreen}
            options={{ title: 'DUPLICATE SESSION' }}
          />
          <Stack.Screen
            name="SelectGroupScreen"
            component={SelectGroupScreen}
            options={{ title: 'ADD NEW SESSION' }}
          />
          <Stack.Screen
            name="CreateGroupScreen"
            component={CreateGroupScreen}
            options={{ title: 'CREATE A GROUP' }}
          />
          <Stack.Screen
            name="DashboardAboutScreen"
            component={DashboardAboutScreen}
            options={{ title: 'ABOUT' }}
          />
          <Stack.Screen
            name="DashboardSessionsScreen"
            component={DashboardSessionsScreen}
            options={{ title: 'GROUP CALENDAR' }}
          />
          <Stack.Screen
            name="DashboardWallScreen"
            component={DashboardWallScreen}
            options={{ title: 'GROUP WALL' }}
          />
          <Stack.Screen
            name="DashboardWallCommentScreen"
            component={DashboardWallCommentScreen}
            options={{ title: 'COMMENTS' }}
          />

          <Stack.Screen
            name="DashboardMoreScreen"
            component={DashboardMoreScreen}
            options={{ title: 'MORE' }}
          />
          <Stack.Screen
            name="DashboardInviteMemberScreen"
            component={DashboardInviteMemberScreen}
            options={{ title: 'INVITE' }}
          />
          <Stack.Screen
            name="DashboardMembersScreen"
            component={DashboardMembersScreen}
            options={{ title: 'MEMBERS' }}
          />
          <Stack.Screen
            name="DashboardTransferOwnershipScreen"
            component={DashboardTransferOwnershipScreen}
            options={{ title: 'TRANSFER OWNERSHIP' }}
          />
          <Stack.Screen
            name="DashboardTrackTraceScreen"
            component={DashboardTrackTraceScreen}
            options={{ title: 'TRACK & TRACE' }}
          />
          <Stack.Screen
            name="SelectSessionTransferScreen"
            component={SelectSessionTransferScreen}
            options={{ title: 'SELECT SESSION' }}
          />
          <Stack.Screen
            name="TransferMembershipOptionScreen"
            component={TransferMembershipOptionScreen}
            options={{ title: 'SELECT MEMBERSHIP OPTION' }}
          />
          <Stack.Screen
            name="PaymentCompletedScreen"
            component={PaymentCompletedScreen}
            options={{ header: () => null }}
          />

          <Stack.Group>
            <Stack.Screen
              name="AttendeesListScreen"
              component={AttendeesListScreen}
              options={{ title: 'ATTENDING LIST' }}
            />
          </Stack.Group>

          <Stack.Group screenOptions={{ presentation: 'modal' }}>
            <Stack.Screen
              name="DashboardWallCommentReplyScreen"
              component={DashboardWallCommentReplyScreen}
              options={{ title: 'CREATE POST' }}
            />
          </Stack.Group>
        </Stack.Group>
      ) : (
        <Stack.Group>
          <Stack.Screen name="AuthScreen" component={AuthScreen} options={{ headerShown: false }} />
          <Stack.Screen
            name="ResetPasswordScreen"
            component={ResetPasswordScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="ConfirmPasswordScreen"
            component={ConfirmPasswordScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="NewPasswordScreen"
            component={NewPasswordScreen}
            options={{ headerShown: false }}
          />
        </Stack.Group>
      )}

      <Stack.Screen
        name="EmailConfirmationScreen"
        component={EmailConfirmationScreen}
        options={{ header: () => null }}
      />
      <Stack.Screen
        name="DashboardInviteReceivedScreen"
        component={DashboardInviteReceivedScreen}
        options={{ title: 'GROUP INVITATION' }}
      />
      <Stack.Screen
        name="SessionDetailScreen"
        component={SessionDetailScreen}
        options={{ title: 'SESSION DETAILS' }}
      />
    </Stack.Navigator>
  )
}

export default RootStack
