import { Button, Icon } from '@rneui/themed'
import PropTypes from 'prop-types'
import React from 'react'
import { Dimensions, Modal, StyleSheet, TouchableWithoutFeedback, View } from 'react-native'

import Text from '@src/components/Text'
import { colors, fonts } from '@src/config/theme'

const { height } = Dimensions.get('screen')

const TrackTraceModal = ({ visible, onClose }) => (
  <Modal transparent visible={visible} animationType="none" onRequestClose={onClose}>
    <TouchableWithoutFeedback onPress={onClose}>
      <View style={{ ...StyleSheet.absoluteFill, ...styles.container }}>
        <TouchableWithoutFeedback>
          <View style={styles.content}>
            <View style={styles.icon}>
              <Icon color={colors.secondary} size={40} type="feather" name="check-circle" />
            </View>
            <View style={styles.texts}>
              <Text style={styles.comment}>
                {
                  'We have received your request\nand will email you the track and trace\ninformation as soon as possible.'
                }
              </Text>
            </View>

            <Button
              title="Got it!"
              onPress={onClose}
              containerStyle={styles.gradientButtonContainer}
              titleStyle={styles.gradientButtonText}
              buttonStyle={styles.gradientButtonStyle}
            />
          </View>
        </TouchableWithoutFeedback>
      </View>
    </TouchableWithoutFeedback>
  </Modal>
)

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#1119',
    alignItems: 'center',
    zIndex: 1000,
  },
  content: {
    width: '80%',
    borderRadius: 6,
    padding: 20,
    shadowOffset: { width: 2, height: 2 },
    shadowColor: 'black',
    shadowOpacity: 0.2,
    backgroundColor: colors.white,
    marginTop: height / 5,
    alignItems: 'center',
  },
  texts: {
    paddingHorizontal: 10,
    alignItems: 'center',
    marginBottom: 20,
  },
  icon: {
    marginVertical: 20,
  },
  percentText: {
    position: 'absolute',
    top: 0,
    fontFamily: fonts.default.bold,
  },
  title: {
    color: colors.secondary,
    fontSize: 12,
    // fontWeight: 'bold',
    fontFamily: fonts.default.bold,
    textAlign: 'center',
  },
  comment: {
    margin: 10,
    color: colors.secondary,
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 15,
    lineHeight: 17,
  },
  gradientButtonStyle: {
    borderRadius: 23,
    height: 42,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 0,
  },

  gradientButtonContainer: {
    height: 42,
    width: '80%',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  gradientButtonText: {
    fontSize: 12,
    fontFamily: fonts.default.semiBold,
  },
  // gradientButton: { width: buttonWidth },
})

TrackTraceModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default TrackTraceModal
