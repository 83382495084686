import PropTypes from 'prop-types'
import React from 'react'
import { View } from 'react-native'

import OrfiAvatar from '@src/components/OrfiAvatar'
import Text from '@src/components/Text'

import styles from './ProfileOverViewForm.styles'

const ProfileOverViewForm = ({
  avatar,
  participated,
  organized,
  name,
  dateJoined,
  organizedDetails,
}) => (
  <View style={styles.container}>
    <View style={organizedDetails ? styles.section1Org : styles.section1}>
      <View style={styles.avatarContainer}>
        <OrfiAvatar type="profile" size="large" uri={avatar} title={`${name[0]}${name[1]}`} />
      </View>

      <View style={organizedDetails ? styles.detailGroup : styles.organiserContainer}>
        <Text testID="name" style={styles.name}>
          {name}
        </Text>
        {organizedDetails ? (
          <>
            <Text style={styles.organiserDetailText}>
              Member since: <Text style={styles.organiserBoldText}>{dateJoined}</Text>
            </Text>
            <Text style={styles.organiserDetailText}>
              Sessions attended: <Text style={styles.organiserBoldText}>{participated}</Text> |
              Sessions organised <Text style={styles.organiserBoldText}>{organized}</Text>
            </Text>
          </>
        ) : null}
      </View>
    </View>
  </View>
)

ProfileOverViewForm.propTypes = {
  avatar: PropTypes.string,
  availableForInvites: PropTypes.bool,
  participated: PropTypes.number,
  organized: PropTypes.number,
  friends: PropTypes.number,
  name: PropTypes.string,
  dateJoined: PropTypes.string,
  age: PropTypes.number,
  address: PropTypes.string,
  onPressAvailableForInvites: PropTypes.func,
  favoriteActivities: PropTypes.array,
  organizedDetails: PropTypes.bool,
  contactDetails: PropTypes.bool,
}

ProfileOverViewForm.defaultProps = {
  avatar: null,
  availableForInvites: false,
  dateJoined: 'December 25th, 2019',
  participated: 0,
  organized: 0,
  friends: 1,
  name: 'Jonas Urbonas',
  age: 28,
  address: 'Manchester, UK',
  favoriteActivities: ['Football', 'Basketball', 'Tennis'],
  onPressAvailableForInvites: () => {},
  organizedDetails: false,
  contactDetails: false,
}

export default ProfileOverViewForm
