import { useRoute } from '@react-navigation/native'
import { Button } from '@rneui/themed'
import React, { FC, useEffect, useState } from 'react'
import { Image, ImageBackground, LayoutAnimation, SafeAreaView, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import { IMAGE_AUTH_BACKGROUND, IMAGE_LOGO } from '@src/assets/images'
import LogInForm from '@src/components/forms/LoginForm'
import SignUpForm from '@src/components/forms/SignUpForm'
import { AuthScreenRouteProps } from '@src/types'

import useStyle from './AuthScreen.styles'
import { FormCategoryEnum } from './types.d'

const AuthScreen: FC = () => {
  const { params: { screenCategory = 0 } = {} } = useRoute<AuthScreenRouteProps>()

  const [selectedCategory, setSelectedCategory] = useState<FormCategoryEnum>(0)
  const styles = useStyle()

  useEffect(() => {
    setSelectedCategory(screenCategory)
  }, [screenCategory])

  const selectCategory = (selectedCat: FormCategoryEnum) => {
    LayoutAnimation.easeInEaseOut()
    setSelectedCategory(selectedCat)
  }

  const isLoginPage = selectedCategory === FormCategoryEnum.Login
  const isSignUpPage = selectedCategory === FormCategoryEnum.SignUp

  const Form = isLoginPage ? LogInForm : SignUpForm

  return (
    <ImageBackground source={IMAGE_AUTH_BACKGROUND} style={styles.background}>
      <SafeAreaView style={styles.container}>
        <KeyboardAwareScrollView
          style={isLoginPage ? styles.paddedContainer : styles.container}
          keyboardShouldPersistTaps="handled">
          <Image style={styles.image} source={IMAGE_LOGO} />

          <View style={styles.formContainer}>
            <View
              style={[
                styles.categorySelectorContainer,
                !!selectedCategory && styles.selectedCategorySelectorContainer,
              ]}>
              <View style={[styles.buttonContainer, !selectedCategory && styles.categoryDivider]}>
                <Button
                  type="clear"
                  activeOpacity={0.7}
                  onPress={() => selectCategory(0)}
                  containerStyle={styles.buttonContainer}
                  titleStyle={[styles.categoryText, isLoginPage && styles.selectedCategoryText]}
                  title="Log In"
                  testID="logInTab"
                />
              </View>
              <View style={[styles.buttonContainer, !!selectedCategory && styles.categoryDivider]}>
                <Button
                  type="clear"
                  activeOpacity={0.7}
                  onPress={() => selectCategory(1)}
                  containerStyle={styles.buttonContainer}
                  titleStyle={[styles.categoryText, isSignUpPage && styles.selectedCategoryText]}
                  title="Sign Up"
                  testID="signUpTab"
                />
              </View>
            </View>
            <Form />
          </View>
        </KeyboardAwareScrollView>
      </SafeAreaView>
    </ImageBackground>
  )
}

export default AuthScreen
