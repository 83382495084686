import { useNavigation } from '@react-navigation/native'
import { Button, ListItem } from '@rneui/themed'
import React, { useState } from 'react'
import { RefreshControl, ScrollView, View } from 'react-native'

import OrfiListItem from '@src/components/ListItem/OrfiListItem'
import Loading from '@src/components/Loading'
import Text from '@src/components/Text'
import { colors } from '@src/config/theme'
import { useMeQuery } from '@src/graphql/types'
import { SelectGroupScreenNavigationProps } from '@src/types'
import getInitials from '@src/utils/getInitials'

import styles from './SelectGroupScreen.styles'

const SelectGroupScreen = () => {
  const navigation = useNavigation<SelectGroupScreenNavigationProps>()
  const [selected, setSelected] = useState('')

  const { data: meData, loading, refetch } = useMeQuery()

  if (loading) return <Loading />

  const { me } = meData
  const groupsManaged = me?.groupuserSet
    .reduce((prev, curr) => [curr.group, ...prev], [])
    .filter((grp) => grp.isAdmin)

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <Text style={styles.headerText}>{'Which group does the\nsession belong to?'}</Text>
      </View>
      <ScrollView refreshControl={<RefreshControl refreshing={loading} onRefresh={refetch} />}>
        {groupsManaged.map((grp) => {
          const isSelected = grp.uuid === selected
          const acts = grp.activities.map((i) => i?.name).join(', ')
          return (
            <View key={grp.uuid}>
              <OrfiListItem
                type="default"
                status="default"
                avatarUri={grp?.image || null}
                avatarPlaceholder={getInitials(grp?.name)}
                title={grp?.name}
                subtitle={`${acts}  ${acts ? '|' : ''} ${grp.groupUsers.length} members`}
                callToAction={
                  <ListItem.CheckBox
                    checked={isSelected}
                    onPress={() => setSelected(isSelected ? '' : grp.uuid)}
                  />
                }
                onPress={() => setSelected(isSelected ? '' : grp.uuid)}
                divider
              />
            </View>
          )
        })}

        <View style={styles.feeInfoView}>
          <Button
            title="+ create a new group"
            titleStyle={styles.feeInfo}
            type="clear"
            onPress={() => navigation.navigate('CreateGroupScreen')}
          />
        </View>
      </ScrollView>
      <View style={styles.bottomView}>
        <Button
          title="Add Session"
          titleStyle={styles.buttonTitle}
          icon={{
            name: 'plus',
            type: 'feather',
            color: colors.white,
            size: 16,
            iconStyle: { paddingTop: 2 },
          }}
          onPress={() => navigation.navigate('CreateSessionScreen', { groupUuid: selected })}
          disabled={!selected}
          containerStyle={styles.bottomButtonContainer}
        />
      </View>
    </View>
  )
}

export default SelectGroupScreen
