import { StyleSheet } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const styles = StyleSheet.create({
  scrollVW: { flex: 1, backgroundColor: colors.white },
  row: {
    flexDirection: 'row',
  },
  column: {
    flex: 1,
    flexDirection: 'column',
  },
  inputTouchableOpacity: {
    width: '100%',
  },
  rightIcon: {
    marginRight: 0,
    padding: 0,
  },
  title: {
    // textAlign: 'center',
    paddingHorizontal: 10,
    color: colors.secondary,
    fontSize: 12,
    fontFamily: fonts.default.bold,
    marginTop: 12,
  },
  buttonContainer: {
    justifyContent: 'space-around',
    width: '100%',
    alignSelf: 'center',
    padding: 20,
    backgroundColor: colors.white,
  },
  buttonContainerStyle: {
    flex: 1,
    marginHorizontal: 4,
  },
  imageBackground: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    height: 200,
  },
  imageBackgroundIcon: {
    color: '#FFFFFF',
  },
  imageBackgroundIconStyling: {
    paddingTop: 16,
  },
  imageBackgroundText: {
    color: '#FFFFFF',
    textTransform: 'uppercase',
  },
  inputContainerStyle: {
    height: 200,
    borderWidth: 1,
    justifyContent: 'flex-start',
    padding: 8,
    marginTop: 24,
  },
  inputStyle: {
    alignSelf: 'flex-start',
    textAlignVertical: 'top',
    height: '100%',
  },
  inputLabels: {
    fontFamily: fonts.default.bold,
    fontSize: 12,
    // alignSelf: 'center',
  },
  locationPressable: {
    flex: 1,
  },
  locationInput: {
    marginLeft: 0,
  },
  sessionInputView: {
    paddingHorizontal: 10,
  },
  imageDialog: {
    padding: 0,
    marginBottom: 200,
  },
  imageDialogButtonContainer: {
    marginBottom: 0,
  },
  removeButton: {
    color: colors.secondary,
  },
  addButton: {
    color: colors.primary,
  },
  divider: {
    backgroundColor: colors.grey,
  },
})

export default styles
