import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '2.5%',
    margin: '2.5%',
    marginLeft: '5%',
  },
  imageContainer: {
    width: 60,
    height: 60,
    borderRadius: 30,
    backgroundColor: '#F3F3F3',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '5%',
  },
  image: {
    width: 40,
    height: 40,
    resizeMode: 'contain',
  },
  imageUnavailable: {
    tintColor: '#CFCFCF',
  },
  title: {
    color: '#292929',
    fontFamily: 'Hermes-RegularCond',
    fontSize: 14,
  },
  titleContainer: {
    flexDirection: 'column',
  },
  titleUnavailable: {
    color: '#B8B8B8',
  },
})

export default styles
