import { useNavigation, useRoute } from '@react-navigation/native'
import { Button, Input } from '@rneui/themed'
import { useFormik } from 'formik'
import moment from 'moment'
import { useLayoutEffect, useState } from 'react'
import { Keyboard, SafeAreaView, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import { showErrorMessage, showSuccessMessage } from '@src/components/FlashMessage'
import Loading from '@src/components/Loading'
import DeleteWalletPopup from '@src/components/PopupModal/DeleteWalletPopup'
import { colors } from '@src/config/theme'
import { ME_QUERY } from '@src/graphql/queries'
import { useMeQuery, useUpdateUserMutation } from '@src/graphql/types'
import { MyWalletScreenRouteProps, MyWalletScreenNavigationProps } from '@src/types.d'
import formatSortCode from '@src/utils/formatSortCode'

import styles from './MyWalletScreen.styles'
import validationSchema from './MyWalletScreen.validation'

const MyWalletScreen = () => {
  const [visible, setVisible] = useState(false)
  const navigation = useNavigation<MyWalletScreenNavigationProps>()
  const { params } = useRoute<MyWalletScreenRouteProps>()

  const { data: meData = {}, loading } = useMeQuery()

  const [updateUser, { loading: deleting }] = useUpdateUserMutation()

  const handleOnSubmit = async (values, actions) => {
    try {
      await updateUser({
        variables: {
          ...meData.me,
          ...values,
          accountNumber: values.accountNumber,
          accountSortCode: values.accountSortCode.split('-').join(''),
        },
        refetchQueries: [{ query: ME_QUERY }],
        awaitRefetchQueries: true,
      })
      showSuccessMessage({
        message: 'Success',
        description: 'Your wallet account has been updated',
      })
      setTimeout(() => {
        if (params?.backRoute) {
          navigation.navigate(`${params?.backRoute}`, { accountAdded: true })
        } else navigation.goBack()
      }, 500)
    } catch (error) {
      showErrorMessage({
        message: 'Warning',
        description:
          'Something’s not right, please check your details or contact our support team.',
      })
    }
    actions.setSubmitting(false)
  }

  const { values, errors, touched, isSubmitting, handleChange, setFieldTouched, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      validationSchema,
      initialValues: {
        accountName: meData?.me?.accountName || '',
        accountNumber: `${meData?.me?.accountNumber || ''}`,
        accountSortCode: formatSortCode(`${meData?.me?.accountSortCode}`),
      },
      onSubmit: handleOnSubmit,
    })

  useLayoutEffect(() => {
    const me = meData?.me
    navigation.setOptions({
      headerRight: () => (
        <Button title={me.accountNumber ? 'UPDATE' : 'ADD'} onPress={handleSubmit} type="clear" />
      ),
    })
  }, [meData])

  const callApi = async () => {
    if (visible) setVisible(false)
    try {
      await updateUser({
        variables: {
          ...meData.me,
          accountName: '',
          accountNumber: '',
          accountSortCode: '',
        },
        refetchQueries: [{ query: ME_QUERY }],
        awaitRefetchQueries: true,
      })
      showSuccessMessage({
        message: 'Success',
        description: 'Your wallet account has been deleted',
      })
      setTimeout(() => navigation.goBack(), 500)
    } catch (error) {
      showErrorMessage({
        message: 'Warning',
        description:
          'Something’s not right, please check your details or contact our support team.',
      })
    }
  }

  const checkCanDelete = () => {
    const cantDelete = meData.me?.groupuserSet?.some(
      (groupUser) =>
        groupUser.group.isOwner &&
        groupUser.group.sessions.some(
          (session) =>
            moment() < moment(session.startAt) &&
            session.paymentMethod !== 'SITE' &&
            session.status.toLowerCase() !== 'cancelled',
        ),
    )
    return !cantDelete
  }

  if (loading) return <Loading />

  const canDelete = checkCanDelete()

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.content}>
        <KeyboardAwareScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={styles.scrollVw}>
          <Input
            label="ACCOUNT NAME"
            labelStyle={styles.label}
            containerStyle={styles.inputContainer}
            returnKeyType="done"
            value={values.accountName}
            onSubmitEditing={Keyboard.dismiss}
            placeholder="John"
            placeholderTextColor={colors.greyOutline}
            onBlur={() => setFieldTouched('accountName')}
            onChangeText={handleChange('accountName')}
            errorMessage={
              touched.accountName && errors.accountName ? errors.accountName : undefined
            }
            editable={!isSubmitting}
          />
          <Input
            label="ACCOUNT NUMBER"
            labelStyle={styles.label}
            containerStyle={styles.inputContainer}
            keyboardType="numeric"
            returnKeyType="done"
            maxLength={8}
            value={values.accountNumber}
            onSubmitEditing={Keyboard.dismiss}
            placeholder="123456789"
            placeholderTextColor={colors.greyOutline}
            onBlur={() => setFieldTouched('accountNumber')}
            onChangeText={handleChange('accountNumber')}
            errorMessage={
              touched.accountNumber && errors.accountNumber ? errors.accountNumber : undefined
            }
            editable={!isSubmitting}
          />
          <Input
            label="SORT CODE"
            labelStyle={styles.label}
            containerStyle={styles.inputContainer}
            keyboardType="numeric"
            returnKeyType="done"
            maxLength={8}
            value={formatSortCode(values.accountSortCode)}
            onSubmitEditing={Keyboard.dismiss}
            placeholder="22-33-44"
            placeholderTextColor={colors.greyOutline}
            onBlur={() => setFieldTouched('accountSortCode')}
            onChangeText={handleChange('accountSortCode')}
            errorMessage={
              touched.accountSortCode && errors.accountSortCode ? errors.accountSortCode : undefined
            }
            editable={!isSubmitting}
          />
        </KeyboardAwareScrollView>
        <View style={[styles.addBtn, { width: meData.me?.accountNumber ? '90%' : '70%' }]}>
          {meData.me?.accountNumber ? (
            <Button
              title="DELETE"
              onPress={() => setVisible(true)}
              disabled={deleting || isSubmitting}
              loading={deleting || isSubmitting}
              containerStyle={styles.buttonContainerStyle}
              buttonStyle={styles.deleteButton}
            />
          ) : null}
          <Button
            title={meData?.me?.accountNumber ? 'UPDATE' : 'ADD'}
            onPress={handleSubmit}
            disabled={isSubmitting || deleting}
            loading={isSubmitting || deleting}
            containerStyle={styles.buttonContainerStyle}
          />
        </View>
      </View>
      <DeleteWalletPopup
        visible={visible}
        onClose={() => setVisible(false)}
        onDelete={callApi}
        canDelete={canDelete}
      />
    </SafeAreaView>
  )
}

export default MyWalletScreen
