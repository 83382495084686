import { ListItem } from '@rneui/themed'
import PropTypes from 'prop-types'
import { Dimensions, StyleSheet, View } from 'react-native'

import OrfiAvatar from '@src/components/OrfiAvatar'
import { colors } from '@src/config/theme'

import OrfiCTA from './OrfiCTA'
import OrfiDivider from './OrfiDivider'
import OrfiListContent from './OrfiListContent'

const { width } = Dimensions.get('window')

const maxWidth = width < 700 ? width * 0.65 : 500

const getOnPress = (callToAction, onPress) => {
  if (onPress) {
    return { onPress }
  }
  if (callToAction) {
    return { disabled: true }
  }
  return null
}

const renderAvatar = (type, status, avatarUri, avatarPlaceholder, topBadge, bottomLabel) => (
  <View style={styles.avatarContainer}>
    <OrfiAvatar
      type={type}
      status={status}
      size="medium"
      uri={avatarUri}
      title={avatarPlaceholder}
      topBadge={topBadge}
      bottomLabel={bottomLabel}
    />
  </View>
)

const renderContent = (type, status, title, subtitle, bottomText) => (
  <View style={styles.contentContainer}>
    <OrfiListContent
      type={type}
      status={status}
      title={title}
      subtitle={subtitle}
      bottomText={bottomText}
    />
  </View>
)

const OrfiListItem = ({
  type,
  status,
  avatarUri,
  avatarPlaceholder,
  topBadge,
  bottomLabel,
  title,
  subtitle,
  bottomText,
  callToAction,
  onPress,
  divider,
}) => (
  <>
    <ListItem containerStyle={styles.itemContainer} pad={15} {...getOnPress(callToAction, onPress)}>
      {renderAvatar(type, status, avatarUri, avatarPlaceholder, topBadge, bottomLabel)}
      <ListItem.Content>
        {renderContent(type, status, title, subtitle, bottomText)}
      </ListItem.Content>
      <OrfiCTA callToAction={callToAction} />
    </ListItem>
    {divider && <OrfiDivider />}
  </>
)

const styles = StyleSheet.create({
  itemContainer: {
    flex: 1,
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  avatarContainer: {
    width: 55,
    height: 55,
  },
  contentContainer: {
    width,
    maxWidth,
  },
})

OrfiListItem.propTypes = {
  type: PropTypes.oneOf(['default', 'reduced', 'notification', 'profile']),
  status: PropTypes.string,
  avatarUri: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  avatarPlaceholder: PropTypes.string,
  topBadge: PropTypes.oneOfType([PropTypes.oneOf(['orfi', 'check', '']), PropTypes.number]),
  bottomLabel: PropTypes.oneOf(['paid', 'cash', '']),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  bottomText: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
  callToAction: PropTypes.element,
  divider: PropTypes.bool,
  onPress: PropTypes.func,
}

OrfiListItem.defaultProps = {
  type: 'default',
  status: 'default',
  avatarUri: '',
  avatarPlaceholder: '',
  topBadge: '',
  bottomLabel: '',
  subtitle: '',
  bottomText: '',
  callToAction: undefined,
  divider: false,
  onPress: undefined,
}

export default OrfiListItem
