import { Button, Icon } from '@rneui/themed'
import PropTypes from 'prop-types'
import React from 'react'
import { Dimensions, Modal, StyleSheet, TouchableWithoutFeedback, View } from 'react-native'

import Text from '@src/components/Text'
import { colors, fonts } from '@src/config/theme'

const { height, width } = Dimensions.get('screen')

const NotifyPopup = ({ visible, onClose }) => (
  <Modal transparent visible={visible} animationType="none" onRequestClose={onClose}>
    <TouchableWithoutFeedback onPress={onClose}>
      <View style={{ ...StyleSheet.absoluteFill, ...styles.container }}>
        <TouchableWithoutFeedback>
          <View style={styles.content}>
            <Icon
              color={colors.secondary}
              size={40}
              containerStyle={styles.icon}
              type="feather"
              name="thumbs-up"
            />
            <View style={styles.texts}>
              <Text style={styles.title}>YOU’RE IN THE LIST!</Text>
              <Text style={styles.comment}>
                We’ll notify you if space opens up on this session, giving you the chance to join.
                Good luck!
              </Text>
            </View>

            <View style={styles.buttons}>
              <Button
                title="Got it"
                onPress={onClose}
                containerStyle={styles.gradientButtonContainer}
                titleStyle={styles.gradientButtonText}
                buttonStyle={styles.gradientButtonStyle}
                style={styles.gradientButton}
              />
            </View>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </TouchableWithoutFeedback>
  </Modal>
)

const buttonWidth = width * 0.8 - 60

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#1119',
    alignItems: 'center',
    zIndex: 1000,
  },
  content: {
    width: '80%',
    borderRadius: 6,
    padding: 20,
    shadowOffset: { width: 2, height: 2 },
    shadowColor: 'black',
    shadowOpacity: 0.2,
    backgroundColor: colors.white,
    marginTop: height / 5,
    alignItems: 'center',
  },
  texts: {
    paddingHorizontal: 10,
    alignItems: 'center',
  },
  icon: {
    marginVertical: 30,
  },
  title: {
    color: colors.secondary,
    fontSize: 12,
    fontFamily: fonts.default.bold,
    textAlign: 'center',
  },
  comment: {
    margin: 10,
    color: colors.secondary,
    fontSize: 10,
    textAlign: 'center',
    marginVertical: 20,
    lineHeight: 17,
  },

  gradientButtonStyle: {
    borderRadius: 23,
    height: 42,
    backgroundColor: colors.secondary,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 0,
  },

  gradientButtonContainer: {
    height: 42,
    width: buttonWidth,
    justifyContent: 'center',
    alignItems: 'center',
  },
  gradientButtonText: {
    fontSize: 12,
    fontFamily: fonts.default.semiBold,
  },
  gradientButton: { width: buttonWidth },
})

NotifyPopup.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default NotifyPopup
