import { useNavigation, useRoute } from '@react-navigation/native'
import { Button, Divider, Input } from '@rneui/themed'
import { useFormik, FormikHelpers } from 'formik'
import moment from 'moment'
import React, { useLayoutEffect, useState } from 'react'
import { SafeAreaView, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import CancelSessionPopup from '@src/components/PopupModal/CancelSessionPopup'
import Text from '@src/components/Text'
import { colors } from '@src/config/theme'
import { attendingOptions } from '@src/graphql/apollo'
import { GET_GROUP, GET_SESSION_MEMBERSHIP, GET_SESSION_QUERY } from '@src/graphql/queries'
import { useCancelSessionMutation, useSessionQuery } from '@src/graphql/types'
import { CancelSessionScreenNavigationProps, CancelSessionScreenRouteProps } from '@src/types'

import styles from './CancelSessionScreen.styles'
import validationSchema from './CancelSessionScreen.validation'
import { CancelSessionFormValueTypes } from './types.d'

const CancelSessionScreen = () => {
  const navigation = useNavigation<CancelSessionScreenNavigationProps>()
  const { params } = useRoute<CancelSessionScreenRouteProps>()
  const [isCancelDialogVisible, setIsCancelDialogVisible] = useState(false)
  const [cancelSessionMutation, { loading: cancelLoading }] = useCancelSessionMutation()
  const { data: { session } = {} } = useSessionQuery({
    variables: { id: params?.sessionId },
  })

  const cancelSession = async (
    values: CancelSessionFormValueTypes,
    { setFieldError }: FormikHelpers<CancelSessionFormValueTypes>,
  ) => {
    try {
      await cancelSessionMutation({
        variables: { sessionId: params.sessionId, reason: values.reason },
        refetchQueries: [
          {
            query: GET_GROUP,
            variables: {
              groupUuid: params.groupUuid,
            },
          },
          {
            query: GET_SESSION_QUERY,
            variables: {
              id: params.sessionId,
            },
          },
          {
            query: GET_SESSION_MEMBERSHIP,
            variables: {
              ...attendingOptions,
            },
          },
        ],
        awaitRefetchQueries: true,
      })
      navigation.goBack()
    } catch (error) {
      setFieldError('form', 'Please try again later')
    }
  }

  const { values, handleChange, setFieldTouched, errors, touched, handleSubmit, isSubmitting } =
    useFormik({
      enableReinitialize: true,
      validationSchema,
      initialValues: {
        reason: '',
      },
      onSubmit: cancelSession,
    })

  useLayoutEffect(() => {
    const HeaderIcon = () => (
      <HeaderIcon
        icon={{
          name: 'x',
          type: 'feather',
          size: 26,
          color: colors.black,
        }}
      />
    )
    navigation.setOptions({
      HeaderIcon,
    })
  }, [navigation])

  const onSubmit = () => {
    setIsCancelDialogVisible(false)
    setTimeout(() => handleSubmit(), 300)
  }

  const onCancel = () => {
    setIsCancelDialogVisible(false)
    setTimeout(() => navigation.goBack(), 200)
  }

  return (
    <SafeAreaView style={styles.container}>
      <CancelSessionPopup onClose={onCancel} onCancel={onSubmit} visible={isCancelDialogVisible} />
      <KeyboardAwareScrollView style={styles.content}>
        <Text style={styles.comment}>
          {"You're about to cancel the"}
          <Text style={styles.dateText}>
            {' '}
            {session?.name} {moment(session?.startAt).format('dddd Do, H:mm')}
          </Text>{' '}
          session.
          {'\n\n'}
          Once the session is cancelled, all participants will be immediately notified.
          {'\n\n'}
          All your remaining sessions will not be affected.
          {'\n\n'}
        </Text>
        <Text style={styles.title}>Reason for cancelling</Text>
        <Divider />
        <Input
          placeholder="Type your message..."
          placeholderTextColor={colors.greyOutline}
          value={values.reason}
          onBlur={() => setFieldTouched('reason')}
          onChangeText={handleChange('reason')}
          errorMessage={touched.reason && errors.reason ? errors.reason : undefined}
          editable={!isSubmitting}
          inputContainerStyle={styles.inputContainer}
          inputStyle={styles.inputStyle}
          multiline
        />
        <Divider />
      </KeyboardAwareScrollView>
      <View style={styles.bottom}>
        <Button
          title="Cancel Session"
          onPress={() => setIsCancelDialogVisible(true)}
          titleStyle={styles.sendTitle}
          loading={cancelLoading}
          disabled={cancelLoading}
        />
      </View>
    </SafeAreaView>
  )
}

export default CancelSessionScreen
