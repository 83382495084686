import { useNavigation } from '@react-navigation/native'
import { Button, Input } from '@rneui/themed'
import { useFormik } from 'formik'
import { useContext, useState } from 'react'
import { Keyboard, View } from 'react-native'

import Text from '@src/components/Text'
import { colors } from '@src/config/theme'
import { AuthContext } from '@src/context/AuthContext'
import { showErrorMessage } from '@src/components/FlashMessage'

import styles from './LogInForm.styles'
import validationSchema from './LoginForm.validation'

const LogInForm = () => {
  const { login } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)

  const [securePassword, setSecurePassword] = useState(true)
  const navigation = useNavigation()

  const { values, handleChange, setFieldTouched, errors, touched, handleSubmit } = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (_values) => {
      await logIn(_values)
    },
  })

  const logIn = async () => {
    setIsLoading(true)

    try {
      await login(values.email.trim(), values.password.trim())
    } catch (error) {
      showErrorMessage({ message: '', description: error.message })
    } finally {
      setIsLoading(false)
    }
  }

  const handleForgotPassword = () => {
    navigation.navigate('ResetPasswordScreen')
  }

  return (
    <View testID="LoginForm">
      {errors.form && <Text>{errors.form}</Text>}

      <Input
        placeholder="Email"
        placeholderTextColor={colors.greyOutline}
        textContentType="none"
        onSubmitEditing={Keyboard.dismiss}
        value={values.email}
        onBlur={() => setFieldTouched('email')}
        onChangeText={handleChange('email')}
        errorMessage={touched.email && errors.email ? errors.email : undefined}
        editable // !isSubmitting
        autoCapitalize="none"
        leftIcon={styles.mailIcon}
        testID="email"
        keyboardType="email-address"
      />
      <Input
        placeholder="Password"
        placeholderTextColor={colors.greyOutline}
        textContentType="none"
        onSubmitEditing={Keyboard.dismiss}
        value={values.password}
        onBlur={() => setFieldTouched('password')}
        onChangeText={handleChange('password')}
        errorMessage={touched.password && errors.password ? errors.password : undefined}
        editable // !isSubmitting
        secureTextEntry={securePassword}
        leftIcon={styles.lockIcon}
        rightIcon={
          <Button
            testID="eye"
            onPress={() => setSecurePassword(!securePassword)}
            icon={{
              type: 'feather',
              name: securePassword ? 'eye' : 'eye-off',
              color: colors.grey7,
              size: 17,
            }}
            type="clear"
          />
        }
        testID="password"
        autoCapitalize="none"
      />

      <View style={styles.buttonContainer}>
        <Button
          onPress={handleForgotPassword}
          title="Forgot password?"
          type="clear"
          titleStyle={styles.forgotText}
        />
      </View>

      <Button
        title="Log In"
        onPress={handleSubmit}
        disabled={isLoading}
        loading={isLoading}
        style={styles.button}
        containerStyle={styles.button}
        testID="submitlogin"
      />
    </View>
  )
}

export default LogInForm
