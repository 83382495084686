import { StyleSheet } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    backgroundColor: '#FFFFFF',
  },
  content: {
    paddingTop: 30,
    flex: 1,
  },
  bottom: {
    paddingVertical: 20,
    paddingHorizontal: 50,
  },
  comment: {
    fontSize: 12,
    color: colors.secondary,
    marginHorizontal: 50,
    // fontWeight: '100',
    fontFamily: fonts.default.light,
    textAlign: 'center',
  },
  inputContainer: {
    height: 200,
    borderBottomWidth: 0,
    paddingVertical: 15,
    paddingHorizontal: 10,
  },
  inputStyle: {
    height: '100%',
    textAlignVertical: 'top',
  },
  rightIcon: {
    marginRight: 0,
    padding: 0,
  },
  title: {
    fontSize: 12,
    // fontWeight: 'bold',
    fontFamily: fonts.default.bold,
    color: colors.secondary,
    marginTop: 50,
    marginLeft: 20,
    marginBottom: 15,
  },
  sendTitle: {
    fontSize: 14,
    // fontWeight: 'bold',
    fontFamily: fonts.default.bold,
  },
})

export default styles
