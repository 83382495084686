import moment from 'moment'
import { FC, useState } from 'react'
import { View, Pressable } from 'react-native'
import { Icon } from '@rneui/themed'

import ConfirmDialog from '@src/components/ConfirmDialog'
import OrfiAvatar from '@src/components/OrfiAvatar'
import Text from '@src/components/Text'
import { WallItemPropType } from '@src/components/WallItem/types'
import { colors } from '@src/config/theme'
import {
  useWallToggleReactionMutation,
  useMeQuery,
  useWallDeletePostMutation,
} from '@src/graphql/types'
import getInitials from '@src/utils/getInitials'

import styles from './CommentItem.styles'

const CommentItem: FC<WallItemPropType> = ({
  post: {
    uuid: postUuid,
    content,
    user,
    userHasLiked,
    reactionCount,
    createdAt,
    isAdmin,
    wall: {
      group: { isAdmin: userIsAdmin },
    },
  },
  refetch = () => null,
}) => {
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false)
  // Hacky method to hide the component when deleted
  const [isDeleted, setIsDeleted] = useState(false)

  const {
    data: { me: { uuid: userUuid = '' } = {} },
  } = useMeQuery()
  const [toggleReaction] = useWallToggleReactionMutation()
  const [deletePost, { loading }] = useWallDeletePostMutation({
    update(cache, { data }) {
      if (!data?.wallDeletePost?.ok) {
        return
      }

      cache.modify({
        fields: {
          wallPosts(posts = [], { readField }) {
            return posts.filter((post) => readField('uuid', post) !== postUuid)
          },
        },
      })

      setIsDeleted(true)
    },
  })

  if (isDeleted) {
    return
  }

  const onPressHeart = async () => {
    await toggleReaction({ variables: { postUuid } })
  }
  const onPressDelete = async () => {
    await deletePost({ variables: { postUuid } })
  }

  const groupRole = isAdmin ? 'Admin' : 'Member'
  const responseCountCheck = reactionCount > 99 ? '99+' : reactionCount
  const userUuidCheck = user.uuid === userUuid ? true : false

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <OrfiAvatar size="small" uri={user.avatar} title={getInitials(user.name)} border />
        <View style={styles.outerUserContainer}>
          <View style={styles.headerRowContainer}>
            <View style={styles.userContainer}>
              <Text style={styles.nameText}>{user.name}</Text>
            </View>
            {userUuidCheck || userIsAdmin ? (
              <Pressable
                disabled={loading}
                onPress={() => setIsDeleteDialogVisible(!isDeleteDialogVisible)}>
                <View style={styles.deletePostContainer}>
                  <Text style={styles.deletePostText}>Delete</Text>
                </View>
              </Pressable>
            ) : undefined}
          </View>
          <View style={styles.timestamp}>
            {isAdmin && <Icon name="crown" type="material-community" color="#D4AF37" size={16} />}
            <Text style={styles.subRowText}>{groupRole}</Text>
            <Icon
              name={'circle'}
              tvParallaxProperties={undefined}
              size={4}
              color={colors.grey5}
              style={styles.icon}
            />
            <Text style={styles.subRowText}>{moment(createdAt).fromNow(true)}</Text>
          </View>
        </View>
      </View>
      <View style={styles.content}>
        <Text>{content}</Text>
      </View>
      <View style={styles.footer}>
        <Pressable
          onPress={onPressHeart}
          style={({ pressed }) => [{ opacity: pressed ? 0.5 : 1.0 }]}>
          <View style={styles.responseContainer}>
            <Icon
              name={userHasLiked ? 'heart' : 'heart-outline'}
              type="material-community"
              color={userHasLiked ? colors.primary : colors.grey4}
            />
            <Text style={styles.responseText}>{responseCountCheck}</Text>
          </View>
        </Pressable>
      </View>

      <ConfirmDialog
        message="Are you sure you want to delete this post?"
        title=""
        negativeButton={{
          title: 'Cancel',
          onPress: () => setIsDeleteDialogVisible(!isDeleteDialogVisible),
        }}
        positiveButton={{
          title: 'Delete Post',
          onPress: () => {
            onPressDelete()
            setIsDeleteDialogVisible(!isDeleteDialogVisible)
            refetch()
          },
        }}
        visible={isDeleteDialogVisible}
      />
    </View>
  )
}

export default CommentItem
