import { Platform, StyleSheet, useWindowDimensions } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const useStyle = () => {
  const { width, height } = useWindowDimensions()

  const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
    row: {
      flexDirection: 'row',
    },
    column: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    inputTouchableOpacity: {
      width: '100%',
    },
    inputContainerStyle: {
      height: 200,
      borderWidth: 1,
      justifyContent: 'flex-start',
      paddingVertical: 8,
      marginTop: 24,
    },
    inputContainer: {
      paddingHorizontal: 20,
    },
    inputStyle: {
      alignSelf: 'flex-start',
      textAlignVertical: 'top',
      height: '100%',
    },
    rightIcon: {
      marginRight: 0,
      padding: 0,
    },
    title: {
      textAlign: 'center',
      color: colors.secondary,
      fontSize: 12,
      fontFamily: fonts.default.bold,
      marginTop: 12,
    },
    imageBackground: {
      justifyContent: 'center',
      alignItems: 'center',
      height: height / 3,
    },
    imageBackgroundIcon: {
      color: '#FFFFFF',
    },
    imageBackgroundIconStyling: {
      paddingTop: 16,
    },
    imageBackgroundText: {
      color: '#FFFFFF',
      textTransform: 'uppercase',
      fontFamily: fonts.default.regular,
    },
    inputLabels: {
      fontFamily: fonts.default.bold,
      fontSize: 12,
      alignSelf: 'center',
    },
    textHeader: {
      paddingHorizontal: 20,
      fontSize: 12,
      fontFamily: fonts.default.bold,
    },
    resetRecurringView: {
      position: 'absolute',
      right: 20,
      top: -3,
    },
    resetRecurringBtn: {
      height: 'auto',
      paddingHorizontal: 12,
      paddingVertical: 0,
    },
    containerStyle: {
      paddingHorizontal: 20,
    },
    pickerStyle: {
      paddingLeft: 20,
    },
    paymentText: {
      fontFamily: fonts.default.bold,
      fontSize: 12,
      color: colors.secondary,
      paddingHorizontal: 20,
    },
    noteView: {
      backgroundColor: colors.primary,
      paddingVertical: 5,
      alignItems: 'center',
    },
    note: {
      color: colors.white,
      textAlign: 'center',
      fontFamily: fonts.default.bold,
      fontSize: Platform.OS === 'ios' ? 12 : 11,
    },
    checkboxTextStyle: {
      marginLeft: 20,
    },
    checkboxContainerStyle: {
      marginBottom: 20,
    },
    multiSelectField: {
      marginTop: 10,
    },
    buttonText: {
      fontSize: 12,
    },
    keyboardDatePickerStyle: {
      margin: 50,
      maxWidth: 500,
    },
    keyboardDatePickerContainer: {
      width: width * 0.95,
      maxWidth: 700,
      justifyContent: 'center',
      alignItems: 'center',
    },
    scrollViewContainer: {
      paddingBottom: 70,
    },
    scrollViewStyle: {
      flex: 1,
    },
    overlayStyle: {
      padding: 0,
      marginBottom: 200,
    },
    overlayContainer: {
      marginBottom: 0,
    },
    addButtonTitle: {
      color: colors.secondary,
    },
    removeButtonTitle: {
      color: colors.primary,
    },
    divider: {
      backgroundColor: colors.grey,
    },
    nameInputLabel: {
      fontFamily: fonts.default.bold,
    },
    iconContainer: {
      marginLeft: 0,
    },
    stepFiveScrollViewStyle: {
      height: height * 0.8,
      width,
    },
    scrollViewContent: {
      flexGrow: 1,
      alignItems: 'center',
    },
    itemContainer: {
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingVertical: 30,
      paddingHorizontal: 15,
    },
    stepFiveTitle: {
      flex: 1,
      fontFamily: fonts.default.bold,
      fontSize: 14,
    },
    stepFiveSubtitle: {
      flex: 2,
      textAlign: 'right',
      fontFamily: fonts.default.regular,
      fontSize: 14,
    },
    listItemContainer: {
      width,
      maxWidth: 700,
    },
    imageStyle: {
      height: height / 3,
    },
    paymentContainer: {
      paddingTop: 30,
    },
    paymentMethodContainer: {
      flexDirection: 'row',
      paddingVertical: 20,
      paddingHorizontal: 20,
      justifyContent: 'space-around',
    },
  })

  return styles
}

export default useStyle
