import { StyleSheet } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  listItemContainer: {
    borderBottomWidth: 0,
    paddingBottom: 0,
  },
  title: {
    marginTop: 5,
    color: colors.black,
    fontSize: 14,
    fontFamily: fonts.default.semiBold,
  },
  leaveContainer: {
    marginBottom: 10,
  },
  leave: {
    fontSize: 14,
    color: colors.primary,
    fontFamily: fonts.default.semiBold,
  },
  listRenderContent: {
    paddingVertical: 10,
  },
})

export default styles
