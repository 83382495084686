import { StyleSheet } from 'react-native'

import { fonts, colors } from '@src/config/theme'

const common = StyleSheet.create({
  section: {
    marginBottom: 16,
  },
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    padding: 16,
    ...common.section,
  },
  header: {
    ...common.section,
    flexDirection: 'row',
  },
  outerUserContainer: {
    flexDirection: 'column',
    flex: 1,
  },
  userContainer: {
    paddingLeft: 8,
    flex: 1,
  },
  timestamp: {
    flexDirection: 'row',
    marginLeft: 8,
  },
  subRowText: {
    color: colors.grey5,
  },
  content: {
    ...common.section,
  },
  footer: {
    flexDirection: 'row',
  },
  innerFooter: {
    flexDirection: 'row',
    paddingRight: 10,
  },
  nameText: {
    fontFamily: fonts.default.bold,
  },
  responseText: {
    paddingTop: 3,
    paddingLeft: 3,
    width: 30,
  },
  responseContainer: {
    flexDirection: 'row',
  },
  icon: {
    paddingHorizontal: 3,
    marginTop: -3,
  },
  deletePostText: {
    color: colors.primary,
    textAlign: 'right',
  },
  deletePostContainer: {
    flex: 1,
  },
  headerRowContainer: {
    flexDirection: 'row',
  },
})

export default styles
