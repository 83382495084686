import { StyleSheet } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  section1: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 25,
  },
  section1Org: {
    flex: 1,
    marginBottom: 25,
    alignItems: 'center',
    justifyContent: 'center',
  },
  detailGroup: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 15,
  },
  name: {
    fontSize: 14,
    color: colors.secondary,
    fontFamily: fonts.default.bold,
    marginBottom: 5,
  },
  age: {
    fontSize: 12,
    color: colors.grey7,
    marginBottom: 5,
    fontFamily: fonts.default.light,
  },
  address: {
    fontSize: 12,
    color: colors.grey7,
    fontFamily: fonts.default.light,
  },
  switchContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  availableComment: {
    fontSize: 10,
    color: colors.secondary,
    marginTop: 10,
  },
  avatarPart: {
    position: 'relative',
  },
  badge: {
    position: 'absolute',
    backgroundColor: colors.success,
    width: 11,
    height: 11,
    borderRadius: 5,
    top: 2,
    right: 2,
    borderWidth: 1,
    borderColor: colors.white,
  },
  badgeInner: {
    backgroundColor: colors.success,
    width: 9,
    height: 9,
    borderRadius: 4,
  },
  section2: {
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 25,
  },
  section3: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  count: {
    fontSize: 14,
    color: colors.black,
    fontFamily: fonts.default.bold,
    marginBottom: 5,
  },
  comment: {
    fontSize: 12,
    color: colors.black,
  },
  verifiedStyle: {
    right: 6,
    top: 2,
    width: 30,
    height: 30,
  },
  avatarContainer: {
    width: 90,
    height: 90,
  },
  organiserContainer: {
    marginLeft: 15,
  },
  organiserDetailText: {
    lineHeight: 30,
  },
  organiserBoldText: {
    fontFamily: fonts.default.bold,
  },
})

export default styles
