import { useNavigation } from '@react-navigation/native'
import { Divider, Icon, ListItem, Text } from '@rneui/themed'
import * as Application from 'expo-application'
import * as Linking from 'expo-linking'
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { FlatList, SafeAreaView, View, StyleSheet } from 'react-native'

import ConfirmDialog from '@src/components/ConfirmDialog'
import HeaderIcon from '@src/components/HeaderIcon'
import Loading from '@src/components/Loading'
import NotificationTick from '@src/components/NotificationTick'
import ProfileOverViewForm from '@src/components/Profile/ProfileOverViewForm'
import TopBanner from '@src/components/TopBanner'
import getEnvVars from '@src/config/environment'
import { colors } from '@src/config/theme'
import { AuthContext } from '@src/context/AuthContext'
import { useMeQuery } from '@src/graphql/types'
import { useDeleteUserMutation } from '@src/graphql/types'
import { ProfileScreenNavigationProps } from '@src/types'

import styles from './ProfileScreen.styles'

const { termsUrl } = getEnvVars()

const ProfileScreen = () => {
  const navigation = useNavigation<ProfileScreenNavigationProps>()
  const { isAuthenticated, logout } = useContext(AuthContext)
  const [isLogOutDialogVisible, setIsLogOutDialogVisible] = useState(false)
  const [isDeleteAccDialogVisible, setIsDeleteAccDialogVisible] = useState(false)
  const [isLogOut, setIsLogOut] = useState(false)
  const { data: { me } = {}, loading } = useMeQuery()
  const [deleteUser] = useDeleteUserMutation()

  const deleteAccount = async () => {
    try {
      await deleteUser({
        onCompleted: () => {
          logout()
        },
      })
    } catch (error) {
      console.log(error)
    }
  }

  useLayoutEffect(() => {
    const headerRight = () => (
      <View style={styles.headerRight}>
        <HeaderIcon
          name="log-out"
          type="feather"
          onPress={() => setIsLogOutDialogVisible(!isLogOutDialogVisible)}
          right
        />
        <NotificationTick />
      </View>
    )

    navigation.setOptions({
      headerRight,
    })
  }, [isAuthenticated, isLogOutDialogVisible, navigation])

  useEffect(() => {
    if (isLogOut) {
      const execLogout = () => {
        logout()
        setIsLogOut(false)
      }
      execLogout()
    }
  }, [isLogOut, logout])

  const menuOptions = [
    {
      title: 'Edit Profile',
      icon: 'user',
      type: 'feather',
      key: 'EditProfileScreen',
      onPress: () => goToPage('EditProfileScreen'),
    },
    {
      title: 'Wallet',
      icon: 'credit-card',
      type: 'feather',
      key: 'MyWallet',
      onPress: () => goToPage('MyWalletScreen'),
    },

    {
      title: 'FAQs',
      icon: 'help-circle',
      type: 'feather',
      key: 'FAQ',
      onPress: () => goToPage('FaqScreen'),
    },
    {
      title: 'Feedback / Report Issue',
      icon: 'info',
      type: 'feather',
      key: 'SendFeedbackScreen',
      onPress: () => goToPage('SendFeedbackScreen'),
    },
    {
      title: 'Privacy Policy & Terms',
      icon: 'lock',
      type: 'feather',
      key: 'PrivacyPolicy',
      onPress: () => Linking.openURL(termsUrl),
    },
    {
      title: 'Log Out',
      icon: 'log-out',
      type: 'feather',
      key: 'logout',
      onPress: () => setIsLogOutDialogVisible(!isLogOutDialogVisible),
    },
    {
      title: 'Delete Account',
      icon: 'trash',
      type: 'feather',
      key: 'delete-acc',
      onPress: () => setIsDeleteAccDialogVisible(!isDeleteAccDialogVisible),
      buttonColor: colors.red,
    },
  ]

  const goToPage = (name: string) => {
    navigation.navigate(name)
  }

  const logOut = () => {
    setIsLogOutDialogVisible(!isLogOutDialogVisible)
    setTimeout(() => setIsLogOut(true), 120)
  }

  const renderLogOutDialog = () => (
    <ConfirmDialog
      message="Are you sure?"
      title=""
      negativeButton={{
        title: 'Cancel',
        onPress: () => setIsLogOutDialogVisible(!isLogOutDialogVisible),
      }}
      positiveButton={{
        title: 'Log Out',
        onPress: logOut,
      }}
      visible={isLogOutDialogVisible}
    />
  )

  const renderDeleteAccDialog = () => (
    <ConfirmDialog
      message="Are you sure you want to delete your account?"
      title=""
      negativeButton={{
        title: 'Cancel',
        onPress: () => setIsDeleteAccDialogVisible(!isDeleteAccDialogVisible),
      }}
      positiveButton={{
        title: 'Delete Account',
        onPress: deleteAccount,
      }}
      visible={isDeleteAccDialogVisible}
    />
  )

  if (loading) return <Loading />
  if (!me) return <View />

  return (
    <SafeAreaView style={styles.container}>
      <TopBanner />
      {renderLogOutDialog()}
      {renderDeleteAccDialog()}
      <FlatList
        data={menuOptions}
        renderItem={({ item: { key, title, icon, onPress, type, buttonColor } }) => (
          <ListItem
            key={key}
            containerStyle={styles.listItemContainer}
            onPress={onPress}
            bottomDivider>
            <Icon
              name={icon}
              type={type}
              color={colors.secondary}
              style={styles.icon}
              iconStyle={{ color: buttonColor }}
              size={20}
            />
            <ListItem.Content style={styles.contentContainerStyle}>
              <ListItem.Title style={StyleSheet.flatten([styles.title, { color: buttonColor }])}>
                {title}
              </ListItem.Title>
            </ListItem.Content>
            <ListItem.Chevron />
          </ListItem>
        )}
        ListHeaderComponent={() => (
          <>
            <ProfileOverViewForm
              name={`${me?.name} ${me?.surname}`}
              avatar={me.avatar}
              participated={me.attendingSessionCount}
              organized={me.organisedEventsCount}
              dateJoined={me.dateJoined}
            />
            <Divider />
          </>
        )}
      />
      <View style={styles.versionContainer}>
        <Text style={styles.versionText}>
          {Application.nativeApplicationVersion} / {Application.nativeBuildVersion}
        </Text>
      </View>
    </SafeAreaView>
  )
}

export default ProfileScreen
