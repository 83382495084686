import { StyleSheet } from 'react-native'

import { colors } from '@src/config/theme'

const styles = StyleSheet.create({
  overlayStyle: {
    padding: 0,
  },
  divider: {
    backgroundColor: colors.grey,
    height: 0.5,
  },
})

export default styles
