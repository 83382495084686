import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#ffffff',
  },
  contentContainer: {
    margin: 24,
    marginBottom: 12,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginVertical: 6,
    marginHorizontal: 12,
  },
  messageText: {
    fontSize: 18,
  },
  titleText: {
    marginBottom: 10,
  },
})

export default styles
