import * as Yup from 'yup'

export const Step1Schema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  activity: Yup.object()
    .shape({
      id: Yup.number().required(),
      name: Yup.string().required(),
    })
    .required('Activity is required'),
  gender: Yup.string(),
  privacy: Yup.string().required('Privacy level is required'),
  skillLevel: Yup.string().required('Skill Level is required'),
  minAge: Yup.number()
    .typeError('Age must be a number')
    .nullable()
    .test('minAgeTest', 'Minimum age must be less than the maximum', function (value) {
      const maxAge = this.resolve(Yup.ref('maxAge'))
      return !value || !maxAge || value <= maxAge
    }),
  maxAge: Yup.number()
    .typeError('Age must be a number')
    .nullable()
    .test('maxAgeTest', 'Maximum age must be greater than the minimum', function (value) {
      const minAge = this.resolve(Yup.ref('maxAge'))
      return !value || !minAge || value >= minAge
    }),
  minParticipants: Yup.number()
    .typeError('Minimum number of participants must be a number')
    .nullable()
    .test(
      'minParticipantsTest',
      'Minimum number of participants must be less than the maximum',
      function (value) {
        const maxParticipants = this.resolve(Yup.ref('maxParticipants'))
        return !value || !maxParticipants || value <= maxParticipants
      },
    ),
  maxParticipants: Yup.number()
    .typeError('Maximum number of participants must be a number')
    .nullable()
    .test(
      'maxParticipantsTest',
      'Maximum number of participants must be greater than the minimum',
      function (value) {
        const minParticipants = this.resolve(Yup.ref('minParticipants'))
        return !value || !minParticipants || value >= minParticipants
      },
    ),
})

export const Step2Schema = Yup.object().shape({
  venueAddress: Yup.object()
    .shape({
      address: Yup.string().required(),
      latitude: Yup.number().required(),
      longitude: Yup.number().required(),
    })
    .required('Select a venue'),
  // sessions: Yup.array()
  //   .of(Yup.string())
  //   .required('Please choose a session date'),
  venueType: Yup.string().required('Please select a venue type'),
  links: Yup.array().of(
    Yup.object().shape({
      name: Yup.string(),
      url: Yup.string().url('Invalid link entered'),
      // when('name', {
      //   is: val => val !== '',
      //   then: Yup.string()
      //     .url('Invalid link entered')
      //     .required('Url is required'),
      //   otherwise: Yup.string().url('Invalid link entered'),
      // }),
    }),
  ),
  description: Yup.string(),
  rules: Yup.string(),
  notes: Yup.string(),
})

export const Step3Schema = Yup.object().shape({
  sessionStartDate: Yup.string().required(),
  sessionEndDate: Yup.string().required(),
})

export const Step4Schema = Yup.object().shape({
  prices: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required('Price is required'),
        description: Yup.string().required('Description is required'),
      }),
    )
    .required('Please provide pricing options for your session'),
})
