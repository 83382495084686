import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  activity: Yup.object().shape({
    id: Yup.number().required(),
    name: Yup.string().required(),
  }),
  gender: Yup.string(),
  privacy: Yup.string().required('Privacy level is required'),
  skillLevel: Yup.string().required('Skill Level is required'),
  minAge: Yup.number()
    .typeError('Age must be a number')
    .nullable()
    .test('minAgeTest', 'Minimum age must be less than the maximum', function (value) {
      const maxAge: number = this.resolve(Yup.ref('maxAge'))
      return !value || !maxAge || value <= maxAge
    }),
  maxAge: Yup.number()
    .typeError('Age must be a number')
    .nullable()
    .test('maxAgeTest', 'Maximum age must be greater than the minimum', function (value) {
      const minAge: number = this.resolve(Yup.ref('maxAge'))
      return !value || !minAge || value >= minAge
    }),
  minParticipants: Yup.number()
    .typeError('Minimum number of participants must be a number')
    .nullable()
    .test(
      'minParticipantsTest',
      'Minimum number of participants must be less than the maximum',
      function (value) {
        const maxParticipants: number = this.resolve(Yup.ref('maxParticipants'))
        return !value || !maxParticipants || value <= maxParticipants
      },
    ),
  maxParticipants: Yup.number()
    .typeError('Maximum number of participants must be a number')
    .nullable()
    .test(
      'maxParticipantsTest',
      'Maximum number of participants must be greater than the minimum',
      function (value) {
        const minParticipants: number = this.resolve(Yup.ref('minParticipants'))
        return !value || !minParticipants || value >= minParticipants
      },
    ),
  sessionStartDate: Yup.string().required(),
  sessionEndDate: Yup.string().required(),
})

export default validationSchema
