import { StyleSheet } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const styles = StyleSheet.create({
  linkContainer: {
    paddingHorizontal: 20,
    paddingVertical: 20,
  },
  linkTitle: { fontFamily: fonts.default.bold, textTransform: 'capitalize' },
  linkVw: { flexDirection: 'row', marginBottom: 10 },
  title: {
    color: colors.secondary,
    fontSize: 16,
    fontFamily: fonts.default.bold,
    marginBottom: 15,
  },
  url: {
    fontFamily: fonts.default.bold,
    textDecorationLine: 'underline',
    color: colors.primary,
  },
})

export default styles
