import { Icon } from '@rneui/themed'
import PropTypes from 'prop-types'
import React from 'react'
import {
  Dimensions,
  Modal,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native'

import DualButton from '@src/components/DualButton'
import Text from '@src/components/Text'
import { colors, fonts } from '@src/config/theme'

const { height, width } = Dimensions.get('screen')

const PaymentConfirmationPopup = ({ visible, onClose, onBackHome, onGroup, onShare, price }) => (
  <Modal transparent visible={visible} animationType="none" onRequestClose={onClose}>
    <TouchableWithoutFeedback onPress={onClose}>
      <View style={{ ...StyleSheet.absoluteFill, ...styles.container }}>
        <TouchableWithoutFeedback>
          <View style={styles.content}>
            <Icon
              color={colors.secondary}
              size={40}
              style={styles.icon}
              name="clap"
              type="orfi"
              iconStyle={styles.iconStyle}
            />
            <View style={styles.texts}>
              <Text style={styles.title}>YOU'RE IN!</Text>
              <Text style={styles.comment}>
                {`Your payment of ${price} has been confirmed and your place has been reserved. Have fun!`}
              </Text>
            </View>

            <TouchableOpacity onPress={onShare} style={styles.shareContainer}>
              <View style={styles.shareContainer}>
                <Icon
                  type="feather"
                  name="share"
                  size={28}
                  color={colors.primary}
                  iconStyle={styles.icon}
                />
                <Text style={styles.shareText}>SHARE</Text>
              </View>
            </TouchableOpacity>

            <View style={styles.buttons}>
              <DualButton
                leftTitle="Back"
                rightTitle="To Group"
                leftOnPress={onBackHome}
                rightOnPress={onGroup}
              />
            </View>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </TouchableWithoutFeedback>
  </Modal>
)

const buttonWidth = (width * 0.8 - 40 - 10) / 2

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#1119',
    alignItems: 'center',
    zIndex: 1000,
  },
  content: {
    width: '80%',
    borderRadius: 6,
    padding: 20,
    shadowOffset: { width: 2, height: 2 },
    shadowColor: 'black',
    shadowOpacity: 0.2,
    backgroundColor: colors.white,
    marginTop: height / 5,
    alignItems: 'center',
  },
  shareText: {
    fontSize: 12,
    marginTop: 8,
    color: colors.primary,
  },
  shareContainer: {
    alignSelf: 'center',
    marginVertical: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  texts: {
    paddingTop: 20,
    paddingHorizontal: 10,
    alignItems: 'center',
  },
  icon: {
    paddingTop: 8,
  },
  title: {
    color: colors.secondary,
    fontSize: 12,
    fontFamily: fonts.default.bold,
    textAlign: 'center',
  },
  comment: {
    margin: 10,
    color: colors.secondary,
    fontSize: 10,
    textAlign: 'center',
    marginVertical: 20,
    lineHeight: 17,
  },
  buttonStyle: {
    borderRadius: 23,
    height: 42,
    backgroundColor: colors.secondary,
    alignItems: 'center',
    paddingVertical: 0,
  },
  gradientButtonStyle: {
    borderRadius: 23,
    height: 42,
    backgroundColor: colors.secondary,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 0,
  },
  buttonText: {
    color: colors.white,
    fontSize: 10,
    fontFamily: fonts.default.semiBold,
    textAlign: 'center',
  },
  buttonContainer: {
    borderRadius: 23,
    height: 35,
    justifyContent: 'center',
    alignItems: 'center',
    width: buttonWidth,
    marginRight: 10,
  },
  button: { width: '45%' },
  gradientButtonContainer: {
    height: 35,
    width: buttonWidth,
    justifyContent: 'center',
    alignItems: 'center',
  },
  gradientButtonText: {
    fontSize: 10,
    fontFamily: fonts.default.semiBold,
  },
  gradientButton: {
    width: '45%',
  },
  buttons: {
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 20,
  },
  iconStyle: {
    lineHeight: 40,
    paddingTop: 40,
  },
})

PaymentConfirmationPopup.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onGroup: PropTypes.func.isRequired,
  onBackHome: PropTypes.func.isRequired,
  onShare: PropTypes.func.isRequired,
  price: PropTypes.string.isRequired,
}

export default PaymentConfirmationPopup
