import { showMessage } from 'react-native-flash-message'

import { colors } from '@src/config/theme'

import styles from './flashMessage.styles'

export const showErrorMessage = ({
  description = '',
  message = 'Error',
  duration = 10000,
}: {
  description?: string
  message?: string
  duration?: number
}) => {
  showMessage({
    message: message,
    description: `${description}`,
    type: 'danger',
    icon: 'danger',
    duration: duration,
    style: styles.showMessageStyle,
  })
}

export const showSuccessMessage = ({
  description = '',
  message = 'success',
  duration = 3000,
}: {
  description?: string
  message?: string
  duration?: number
}) => {
  showMessage({
    message: message,
    description: `${description}`,
    type: 'success',
    icon: 'success',
    duration: duration,
    backgroundColor: colors.success,
    style: styles.showMessageStyle,
  })
}
