import PropTypes from 'prop-types'
import { Dimensions, StyleSheet, View } from 'react-native'

import Text from '@src/components/Text'
import { colors, fonts } from '@src/config/theme'

const { width } = Dimensions.get('window')

const getSubtitleColor = (type, status) => {
  if (status !== 'default' || type === 'reduced') {
    return { color: colors.grey7 }
  }
  return { color: colors.secondary }
}

const getTitleColor = (type, status) => {
  if (type === 'notification') {
    if (status === 'New') return { color: colors.primary }
    return { color: colors.secondary }
  }
  if (status === 'default') {
    return { color: colors.secondary }
  }
  return { color: colors.grey7 }
}

const OrfiListContent = ({ type, status, title, subtitle, bottomText }) => (
  <View style={styles.contentContainer}>
    {typeof title !== 'function' ? (
      <Text
        style={{
          fontSize: 14,
          fontFamily: fonts.default.bold,
          ...getTitleColor(type, status),
        }}>
        {title}
      </Text>
    ) : (
      title
    )}
    {subtitle ? (
      <Text
        style={{
          fontSize: 12,
          fontFamily: fonts.default.regular,
          ...getSubtitleColor(type, status),
        }}>
        {subtitle}
      </Text>
    ) : null}
    {typeof bottomText !== 'function' ? (
      <Text style={styles.bottomText}>{bottomText}</Text>
    ) : (
      bottomText
    )}
  </View>
)

const styles = StyleSheet.create({
  contentContainer: {
    alignItems: 'flex-start',
    width: width * 0.65,
    justifyContent: 'space-between',
    marginTop: 10,
    marginBottom: -15,
  },
  bottomText: {
    marginTop: 10,
    fontSize: 10,
    fontFamily: fonts.default.light,
    color: colors.black,
  },
})

OrfiListContent.propTypes = {
  type: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  bottomText: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
}

OrfiListContent.defaultProps = {
  subtitle: '',
  bottomText: '',
}

export default OrfiListContent
