import PropTypes from 'prop-types'
import React from 'react'
import { Dimensions, Modal, StyleSheet, TouchableWithoutFeedback, View } from 'react-native'

import DualButton from '@src/components/DualButton'
import Text from '@src/components/Text'
import { colors, fonts } from '@src/config/theme'

const { height, width } = Dimensions.get('screen')

const CancelSessionPopup = ({ visible, onClose, onCancel }) => (
  <Modal transparent visible={visible} animationType="none" onRequestClose={onClose}>
    <TouchableWithoutFeedback onPress={onClose}>
      <View style={{ ...StyleSheet.absoluteFill, ...styles.container }}>
        <TouchableWithoutFeedback>
          <View style={styles.content}>
            <View style={styles.texts}>
              <Text style={styles.title}>ARE YOU SURE?</Text>
              <Text style={styles.comment}>
                {
                  'Once you cancel this session we’ll immediately let all of your participants know not to turn up.\n\nAs the organiser, you are responsible for carrying out any refunds. Alternatively, you can move your participants to your future sessions by going to '
                }
                <Text style={styles.bold}>Session Details</Text>
                {', tapping on '}
                <Text style={styles.bold}>View All Participants</Text>
                {' and then tapping on the '}
                <Text style={styles.bold}>three dots</Text>
                {' of each participant to see the available options.'}
              </Text>
            </View>

            <View style={styles.buttons}>
              <DualButton
                leftTitle="Go Back"
                rightTitle="Cancel"
                leftOnPress={onClose}
                rightOnPress={onCancel}
              />
            </View>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </TouchableWithoutFeedback>
  </Modal>
)

const buttonWidth = (width * 0.8 - 40 - 10) / 2

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#1119',
    alignItems: 'center',
    zIndex: 1000,
  },
  content: {
    width: '80%',
    borderRadius: 6,
    paddingHorizontal: 20,
    paddingTop: 40,
    paddingBottom: 30,
    shadowOffset: { width: 2, height: 2 },
    shadowColor: 'black',
    shadowOpacity: 0.2,
    backgroundColor: colors.white,
    marginTop: height / 5,
    alignItems: 'center',
  },
  shareText: {
    fontSize: 12,
    marginTop: 8,
    color: colors.primary,
  },
  shareContainer: {
    alignSelf: 'center',
    marginVertical: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  texts: {
    paddingHorizontal: 10,
    alignItems: 'center',
  },
  icon: {
    marginVertical: 30,
  },
  title: {
    color: colors.secondary,
    fontSize: 12,
    // fontWeight: 'bold',
    fontFamily: fonts.default.bold,
    textAlign: 'center',
  },
  comment: {
    margin: 7,
    color: colors.secondary,
    fontSize: 10,
    textAlign: 'center',
    // marginVertical: 20,
    lineHeight: 17,
  },
  buttonStyle: {
    borderRadius: 23,
    height: 42,
    backgroundColor: colors.secondary,
    alignItems: 'center',
    paddingVertical: 0,
  },
  gradientButtonStyle: {
    borderRadius: 23,
    height: 42,
    backgroundColor: colors.secondary,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 0,
  },
  buttonText: {
    color: colors.white,
    fontSize: 10,
    // fontWeight: '500',
    fontFamily: fonts.default.semiBold,
    textAlign: 'center',
  },
  buttonContainer: {
    borderRadius: 23,
    height: 35,
    justifyContent: 'center',
    alignItems: 'center',
    width: buttonWidth,
    marginRight: 10,
  },
  button: { width: '45%' },
  gradientButtonContainer: {
    height: 35,
    width: buttonWidth,
    justifyContent: 'center',
    alignItems: 'center',
  },
  gradientButtonText: {
    fontSize: 10,
    // fontWeight: '500',
    fontFamily: fonts.default.semiBold,
  },
  gradientButton: { width: '45%' },
  buttons: {
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 20,
  },
  bold: {
    fontFamily: fonts.default.bold,
  },
})

CancelSessionPopup.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default CancelSessionPopup
