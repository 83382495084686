import { ApolloProvider } from '@apollo/client'
import { registerCustomIconType, ThemeProvider } from '@rneui/themed'
import { StatusBar } from 'expo-status-bar'
import moment from 'moment'
import { FC, ReactNode } from 'react'
import { StyleSheet } from 'react-native'
import FlashMessage from 'react-native-flash-message'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import * as Sentry from 'sentry-expo'

import OrfiIcons from '@src/config/OrfiIcon'
import theme, { colors } from '@src/config/theme'
import { AuthProvider } from '@src/context/AuthContext'
import { client } from '@src/graphql/apollo'
import AppNavigator from '@src/navigation/AppNavigator'

import useCachedResources from './hooks/useCachedResources'

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: function (number, withoutSuffix) {
      return withoutSuffix ? 'now' : 'a few seconds'
    },
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mth',
    MM: '%dmth',
    y: '1y',
    yy: '%dy',
  },
})

Sentry.init({
  dsn: 'https://7fda1414f31540edb4cce8e26d979e9d@o233566.ingest.sentry.io/1396568',
  enableInExpoDevelopment: true,
  debug: true, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
})

type SplashGuardProps = {
  children: ReactNode
}

const SplashGuard: FC<SplashGuardProps> = ({ children }) => {
  const isLoadingComplete = useCachedResources()
  return isLoadingComplete ? <>{children}</> : null
}

const App = () => {
  return (
    <SafeAreaProvider>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <SplashGuard>
              <StatusBar style="dark" />
              <AppNavigator />
              <FlashMessage position="top" style={styles.flashView} />
            </SplashGuard>
          </AuthProvider>
        </ThemeProvider>
      </ApolloProvider>
    </SafeAreaProvider>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  flashView: { alignItems: 'center' },
})

registerCustomIconType('orfi', OrfiIcons)

export default App
