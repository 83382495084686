import { StyleSheet } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    paddingTop: 10,
  },
  sectionList: {
    paddingBottom: 75,
  },
  buttonContainer: {
    position: 'absolute',
    bottom: 16,
    right: 0,
    left: 0,
    alignItems: 'center',
  },
  buttonStyle: {
    paddingHorizontal: 25,
  },
  avatarContainer: {
    width: 60,
    height: 60,
    borderRadius: 60,
    overflow: 'hidden',
  },
  waitingListWrapper: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
  waitingListText: {
    color: colors.white,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 10,
    width: 50,
    fontFamily: fonts.default.bold,
  },
  dividerVw: {
    paddingHorizontal: 14,
    flexDirection: 'row',
    alignItems: 'center',
  },
  divider: {
    flex: 1,
    marginLeft: 16,
    borderBottomColor: colors.grey,
    borderBottomWidth: 0.8,
  },
  floatBtnView: {
    position: 'absolute',
    bottom: 14,
    right: 10,
  },
  floatBtn: {
    backgroundColor: colors.grey3,
    height: 54,
    width: 54,
    borderRadius: 27,
  },
  leftItemTick: {
    position: 'absolute',
    top: 0,
    right: 3,
    backgroundColor: '#fff',
  },
  address: {
    color: colors.secondary,
    opacity: 0.6,
    paddingBottom: 5,
    fontSize: 11,
    marginTop: 14,
  },
  contentStyle: {
    paddingTop: 0,
    paddingBottom: 0,
    borderBottomWidth: 0,
  },
  sectionTitleVw: {
    paddingHorizontal: 16,
    marginTop: 35,
    marginBottom: 5,
  },
  sectionTitle: {
    color: colors.secondary,
    fontFamily: fonts.default.bold,
    textTransform: 'uppercase',
    fontSize: 15,
    paddingBottom: 7,
  },
  exploreTitle: {
    textTransform: 'uppercase',
    textDecorationLine: 'underline',
    fontFamily: fonts.default.bold,
  },
  emptyFlatList: {
    alignItems: 'center',
    paddingTop: 30,
    paddingHorizontal: 20,
  },
  textFlatList: {
    color: colors.primary,
    fontSize: 15,
    textAlign: 'center',
  },
})

export default styles
