export interface AuthState {
  isAuthenticated: boolean
  isInitialised: boolean
  token: string | undefined
}

export interface AuthContextProps extends AuthState {
  login: (email: string, password: string) => Promise<void>
  loginToken: (token: string) => Promise<void>
  logout: () => void
}

export enum ActionEnum {
  INITIALISE = 'INITIALISE',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

// Action payloads

export interface InitialPayload {
  isAuthenticated: boolean
}

export interface LoginPayload {
  token: string
}

// Actions

export interface InitaliseAction {
  type: ActionEnum.INITIALISE
  payload: InitialPayload
}

export interface LoginAction {
  type: ActionEnum.LOGIN
  payload: LoginPayload
}

export interface LogoutAction {
  type: ActionEnum.LOGOUT
}

export type ReducerActions = InitaliseAction | LoginAction | LogoutAction
