import { StyleSheet } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  content: {
    // flex: 1,
    paddingBottom: 150,
  },
  img: {
    height: 200,
    width: '100%',
  },
  participantView: {
    alignSelf: 'center',
    justifyContent: 'center',
    marginTop: 30,
    marginBottom: 20,
    width: '90%',
  },
  topShadow: {
    position: 'absolute',
    zIndex: 100,
    top: -5,
    height: 35,
    width: '100%',
  },
  addressView: {
    flexDirection: 'row',
    paddingVertical: 20,
    paddingHorizontal: 20,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addressText: {
    fontFamily: fonts.default.bold,
    color: colors.secondary,
  },
  addressIconView: {
    height: 40,
    width: 40,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
  },
  linkContainer: {
    paddingHorizontal: 20,
    paddingVertical: 20,
  },
  linkVw: { flexDirection: 'row', marginBottom: 10 },
  title: {
    color: colors.secondary,
    fontSize: 18,
    fontFamily: fonts.default.bold,
    marginBottom: 15,
    textAlign: 'center',
  },
  url: {
    fontFamily: fonts.default.bold,
    textDecorationLine: 'underline',
    color: colors.primary,
  },
  linkTitle: { fontFamily: fonts.default.bold, textTransform: 'capitalize', flex: 1 },
  orgTitle: {
    color: colors.secondary,
    fontSize: 21,
    fontFamily: fonts.default.bold,
    textAlign: 'center',
    width: '85%',
  },
  date: {
    color: colors.primary,
    fontSize: 13,
    fontFamily: fonts.default.bold,
    marginBottom: 20,
    width: '80%',
    textAlign: 'center',
  },
  bottomButtonContainer: {
    position: 'absolute',
    right: 0,
    left: 0,
    bottom: 20,
    alignItems: 'center',
  },
  orgBy: {
    fontSize: 14,
    fontFamily: fonts.default.bold,
    color: colors.secondary,
  },
  featureVw: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  groupTextContainer: {
    marginVertical: 30,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  groupText: {
    textAlign: 'center',
    color: colors.secondary,
  },
  adminContainer: {
    marginTop: 30,
  },
  adminText: {
    fontFamily: fonts.default.bold,
    fontSize: 18,
    textAlign: 'center',
    paddingBottom: 0,
  },
})

export default styles
