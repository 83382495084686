import { useNavigation } from '@react-navigation/native'
import { Divider } from '@rneui/themed'
import React, { FC, useEffect, useState } from 'react'
import { SectionList, View } from 'react-native'

import EmptyNotice from '@src/components/EmptyNotice'
import OrfiListItem from '@src/components/ListItem/OrfiListItem'
import Loading from '@src/components/Loading'
import Text from '@src/components/Text'
import { colors, fonts } from '@src/config/theme'
import { useMeQuery, useUserNotificationsQuery } from '@src/graphql/types'
import { NotificationScreenNavigationProps } from '@src/types'
import {
  getNotificationTimestamp,
  setNotificationTimestamp,
} from '@src/utils/notificationTimestamp'

import styles from './NotificationScreen.styles'

const NotificationScreen: FC = () => {
  const navigation = useNavigation<NotificationScreenNavigationProps>()
  const [lastTimestamp, setLastTimestamp] = useState<null | string>(null)

  const { data: meData, loading: meLoading } = useMeQuery()
  const {
    loading,
    data: { userNotifications = [] } = {},
    refetch,
    fetchMore,
    startPolling,
    stopPolling,
  } = useUserNotificationsQuery({
    variables: {
      orderBy: '-created_at',
      limit: 10,
      offset: 0,
    },
    skip: !meData,
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (meData) {
      startPolling(30000)
    }

    return () => {
      stopPolling()
    }
  }, [meData, startPolling, stopPolling])

  useEffect(() => {
    const getLastReadTimeStamp = async () => {
      const timestamp = await getNotificationTimestamp()
      if (timestamp) setLastTimestamp(timestamp)
    }
    getLastReadTimeStamp()
  }, [])

  const fetchMoreData = () => {
    return fetchMore({ variables: { offset: userNotifications.length } })
  }

  if (meLoading || loading) return <Loading />

  const handleData = (notifications = []) => {
    if (!notifications.length) return []
    const newest = notifications[0]
    let allData = []
    if (lastTimestamp) {
      if (lastTimestamp === newest.createdAt) {
        allData = [{ title: 'Older', data: notifications, new: true }]
      } else {
        const lastIndex = notifications.findIndex((i) => i.createdAt === lastTimestamp)
        const unread = lastIndex === -1 ? [] : notifications.slice(0, lastIndex)
        const read =
          lastIndex === -1 ? notifications : notifications.slice(lastIndex, notifications.length)
        if (unread.length) {
          allData.push({ title: 'New', data: unread, new: true })
        }
        if (read.length) {
          allData.push({ title: 'Older', data: read, new: false })
        }
      }
    } else {
      allData = [{ title: 'New', data: notifications, new: true }]
    }
    setNotificationTimestamp(newest.createdAt)
    return allData
  }

  const handleClick = (notification) => {
    if (!notification) return
    try {
      const data = JSON.parse(notification)
      if (data?.navigation && data?.navigation?.screen) {
        const { screen, props = {} } = data?.navigation
        navigation.navigate(screen, props)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const renderItem = ({ item, section: { title } }) => {
    const { message, data } = item
    return (
      <OrfiListItem
        type="notification"
        status={title}
        title={message}
        onPress={() => handleClick(data)}
        divider
      />
    )
  }

  const allData = handleData(userNotifications)
  const sectionOne = allData.length ? allData[0] : {}

  return (
    <View style={styles.container}>
      <SectionList
        sections={allData}
        keyExtractor={(item, index) => index.toString()}
        stickySectionHeadersEnabled={false}
        contentContainerStyle={styles.sectionList}
        scrollIndicatorInsets={{ right: 1 }}
        renderSectionHeader={({ section: { title } }) =>
          title ? (
            <View style={styles.sectionTitleVw}>
              <Text
                style={[
                  styles.sectionTitle,
                  { color: title === 'New' ? colors.primary : colors.secondary },
                ]}>
                {title}
              </Text>
              <Divider
                style={{
                  backgroundColor: title === 'New' ? colors.primary : colors.grey,
                  height: 1.1,
                }}
              />
            </View>
          ) : null
        }
        renderItem={renderItem}
        ListEmptyComponent={
          <View style={styles.emptyFlatList}>
            <EmptyNotice title="NOTHING TO SHOW" text={"You don't have notifications yet!"} />
          </View>
        }
        ListHeaderComponent={() =>
          sectionOne?.title === 'Older' ? (
            <View style={styles.headerFlatList}>
              <Text style={styles.headerText}>No new notifications.</Text>
            </View>
          ) : null
        }
        onEndReachedThreshold={0.5}
        onEndReached={fetchMoreData}
        onRefresh={refetch}
        refreshing={loading}
      />
    </View>
  )
}

export default NotificationScreen
