import { useNavigation, useRoute } from '@react-navigation/native'
import { Button, Divider, Icon, Input, Overlay } from '@rneui/themed'
import * as ImagePicker from 'expo-image-picker'
import * as MediaLibrary from 'expo-media-library'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { BackHandler, ImageBackground, Keyboard, TouchableOpacity, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import { DEFAULT_EVENT_IMAGE } from '@src/assets/images'
import ConfirmDialog from '@src/components/ConfirmDialog'
import DualButton from '@src/components/DualButton'
import EmptyNotice from '@src/components/EmptyNotice'
import { showErrorMessage, showSuccessMessage } from '@src/components/FlashMessage'
import StepFourForm from '@src/components/forms/CreateEvent/StepFourForm'
import StepThreeForm from '@src/components/forms/CreateEvent/StepThreeForm'
import EventLinkForm from '@src/components/forms/EventLinks'
import InputPicker from '@src/components/forms/InputPicker'
import HeaderIcon from '@src/components/HeaderIcon'
import Loading from '@src/components/Loading'
import NoWalletAccountPopup from '@src/components/PopupModal/NoWalletAccount'
import Text from '@src/components/Text'
import {
  defaultPayMethod,
  defaultRefundPolicy,
  pickerGender,
  pickerPrivacy,
  pickerSkillLevel,
  pickerVenue,
} from '@src/config/forms'
import { colors } from '@src/config/theme'
import { GET_GROUP, ME_QUERY } from '@src/graphql/queries'
import { useSessionQuery, useMeQuery, useCreateMultipleSessionsMutation } from '@src/graphql/types'
import { DuplicateSessionScreenNavigationProps, DuplicateSessionScreenRouteProps } from '@src/types'
import { calculateSessions } from '@src/utils/forms'
import generateRNFile from '@src/utils/generateRNFile'

import styles from './DuplicateSessionScreen.styles'
import validationSchema from './DuplicateSessionScreen.validation'

const DuplicateSessionScreen = () => {
  const navigation = useNavigation<DuplicateSessionScreenNavigationProps>()
  const { params } = useRoute<DuplicateSessionScreenRouteProps>()
  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState(false)
  const [imgDialog, setImgDialog] = useState(false)
  const [noWalletAccount, setNoWalletAccount] = useState(false)
  const { data: { me: meQuery = { accountNumber: '' } } = {} } = useMeQuery()
  const {
    data: { session } = {},
    loading: sessionLoading,
    error,
  } = useSessionQuery({
    variables: {
      id: params.sessionId,
    },
  })

  const definedSession = session

  const getLinks = (links = []) => {
    if (links.length) {
      return links.map((l) => ({
        name: l.name,
        url: l.url,
      }))
    }
    return [
      {
        name: '',
        url: '',
      },
    ]
  }

  const checkPaymentMethod = () => {
    if (!definedSession?.group?.isOwner) {
      submitSession(values)
      return
    }
    const { paymentMethod, prices } = values
    const isFree = prices?.length === 1 && prices[0]?.value === 0
    if (!meQuery.accountNumber && paymentMethod !== defaultPayMethod && !isFree) {
      toggleWalletAccountDialog()
    } else {
      const newValues = { ...values, paymentMethod: isFree ? defaultPayMethod : paymentMethod }
      submitSession(newValues)
    }
  }

  const {
    values,
    errors,
    touched,
    dirty,
    handleChange,
    setFieldTouched,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      name: definedSession?.name,
      activity: {
        name: definedSession?.activity[0]?.name,
        id: definedSession?.activity[0]?.id,
      },
      image: null,
      gender: definedSession?.gender,
      prices: definedSession?.prices?.map((p) => ({
        value: p?.value,
        description: p?.description,
      })),
      paymentMethod: definedSession?.paymentMethod,
      skillLevel: definedSession?.skillLevel,
      minAge: definedSession?.minAge ? definedSession?.minAge.toString() : '',
      maxAge: definedSession?.maxAge ? definedSession?.maxAge.toString() : '',
      minParticipants: definedSession?.minParticipants
        ? definedSession?.minParticipants.toString()
        : '',
      maxParticipants: definedSession?.maxParticipants
        ? definedSession?.maxParticipants.toString()
        : '',
      privacy: definedSession?.isPrivate ? 'PRIVATE' : 'PUBLIC',
      notes: definedSession?.notes,
      description: definedSession?.description,
      venueType: definedSession?.venueType,
      venueAddress: {
        address: definedSession?.address,
        latitude: definedSession?.addressLatitude,
        longitude: definedSession?.addressLongitude,
      },
      sessionEndDate: undefined,
      sessionStartDate: undefined,
      groupUuid: definedSession?.group?.uuid,
      rules: definedSession?.rules,
      isAcceptingCash: definedSession?.isAcceptingCash,
      links: getLinks(definedSession?.links),
      refundPolicy: defaultRefundPolicy,
      recurringType: 'ONCE',
      recurringEveryOtherWeek: false,
      recurringDaysOfTheWeek: [0, 1, 2, 3, 4, 5, 6],
    },
    onSubmit: checkPaymentMethod,
  })

  const [createSessionMutation, { loading: sessionCreateLoading }] =
    useCreateMultipleSessionsMutation({
      onCompleted: async () => {},
    })

  const checkInputStates = () => {
    if (dirty) {
      setIsConfirmationDialogVisible(true)
    } else {
      navigation.goBack()
    }
  }

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <HeaderIcon onPress={() => checkInputStates()} />,
    })
  }, [values])

  useEffect(() => {
    const backHandler = BackHandler.addEventListener('hardwareBackPress', () => checkInputStates())
    return () => backHandler.remove()
  })

  useEffect(() => {
    if (params?.activity) {
      const {
        activity: { name, id },
      } = params
      setFieldTouched('activity')
      setFieldValue('activity', { name, id })
    }
    if (params?.venueAddress) {
      const { venueAddress } = params
      setFieldValue('venueAddress', venueAddress)
      setFieldTouched('venueAddress')
    }
    if (params?.accountAdded) {
      submitSession()
    }
  }, [params, setFieldTouched, setFieldValue])

  const submitSession = async () => {
    const sessions = calculateSessions(values)
    const link = values.links[0]
    const variables = {
      ...values,
      links: link.url ? values.links : [],
      activityId: values.activity.id,
      isPrivate: values.privacy === 'PRIVATE',
      minAge: values.minAge ? parseInt(values.minAge, 10) : null,
      maxAge: values.maxAge ? parseInt(values.maxAge, 10) : null,
      minParticipants: values.minParticipants ? parseInt(values.minParticipants, 10) : null,
      maxParticipants: values.maxParticipants ? parseInt(values.maxParticipants, 10) : null,
      address: values.venueAddress.address,
      addressLatitude: values.venueAddress.latitude,
      addressLongitude: values.venueAddress.longitude,
      dates: sessions,
    }

    if (values.newImage) {
      variables.image = values.newImage
    }

    try {
      await createSessionMutation({
        variables,
        refetchQueries: [
          {
            query: GET_GROUP,
            variables: {
              groupUuid: definedSession?.group.uuid,
            },
          },
          {
            query: ME_QUERY,
          },
        ],
        awaitRefetchQueries: true,
      })
      showSuccessMessage({
        message: 'Duplicated',
        description: `${definedSession?.name} has been duplicated.`,
      })
      navigation.replace('DashboardGroupScreen', { groupUuid: definedSession?.group.uuid })
    } catch (e) {
      showErrorMessage({
        message: 'Warning',
        description: "Oops, something's gone wrong. Please try again or send us feedback.",
        duration: 6000,
      })
    }
  }

  const goToSelectActivity = () => {
    navigation.navigate('SelectActivityScreen', {
      backRoute: 'DuplicateSessionScreen',
    })
  }

  const toggleConfirmationDialog = () => {
    setIsConfirmationDialogVisible(!isConfirmationDialogVisible)
  }

  const pickImage = async () => {
    const { status } = await MediaLibrary.requestPermissionsAsync()
    if (status !== 'granted') {
      // eslint-disable-next-line no-alert
      alert('Sorry, we need camera roll permissions to make this work!')
    } else {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
      })

      if (!result.canceled) {
        const file = generateRNFile(result.assets[0].uri, 'eventPicture')
        handleChange(file)
        setFieldTouched('newImage')
      }
    }
  }

  const handlePickerValue = (field, value) => {
    setFieldValue(field, value)
    setFieldTouched(field)
  }

  const onSelectVenueAddress = (venue) => {
    navigation.navigate('SelectVenueScreen', {
      backRoute: 'DuplicateSessionScreen',
      venue,
    })
  }

  const onAddLink = () => {
    const { links } = values
    const lnks = [...links, { name: '', url: '' }]
    setFieldValue('links', lnks)
  }

  const onDeleteItem = (index) => {
    const { links } = values
    const lnks = [...links].splice(index, 1)
    setFieldValue('links', lnks)
  }

  const onChangeLinkItem = ({ index, key, value }) => {
    const { links } = values
    const newData = {}
    const lnks = [...links]

    newData[key] = value
    lnks[index] = {
      ...lnks[index],
      ...newData,
    }
    setFieldValue('links', lnks)
  }

  const toggleWalletAccountDialog = () => {
    setNoWalletAccount(!noWalletAccount)
  }

  const onSetupAccount = () => {
    navigation.navigate('MyWalletScreen', { backRoute: 'DuplicateSessionScreen' })
  }

  const imageSrc = values.newImage ? values.newImage.uri : values.image
  const loading = sessionCreateLoading

  if (sessionLoading) return <Loading />
  if (error)
    return (
      <EmptyNotice
        title="NOTHING TO SHOW"
        text="Seems like this session has been deleted or cancelled."
      />
    )

  return (
    <>
      <KeyboardAwareScrollView style={styles.scrollVW}>
        <ImageBackground
          style={styles.imageBackground}
          source={imageSrc ? { uri: imageSrc } : DEFAULT_EVENT_IMAGE}>
          <TouchableOpacity onPress={() => (values.newImage ? setImgDialog(true) : pickImage())}>
            <Icon
              size={40}
              type="feather"
              name="camera"
              style={styles.imageBackgroundIcon}
              color="#FFFFFF"
              iconStyle={styles.imageBackgroundIconStyling}
            />
            <Text style={styles.imageBackgroundText}>Add Session Image</Text>
          </TouchableOpacity>
        </ImageBackground>
        <View>
          <Input
            label="NAME"
            onSubmitEditing={Keyboard.dismiss}
            placeholder={'e.g "Saturday Morning tennis practice"'}
            placeholderTextColor={colors.greyOutline}
            value={values.name}
            onFocus={() => setFieldTouched('name')}
            onChangeText={handleChange('name')}
            errorMessage={touched.name && errors.name ? errors.name : undefined}
            editable={!loading}
          />

          <TouchableOpacity style={styles.inputTouchableOpacity} onPress={goToSelectActivity}>
            <Input
              label="ACTIVITY"
              placeholder="Select..."
              placeholderTextColor={colors.greyOutline}
              value={values.activity.name}
              onFocus={() => setFieldTouched('activity')}
              onChangeText={handleChange('activity')}
              errorMessage={
                touched.activity && errors.activity ? 'Activity is required' : undefined
              }
              editable={false}
              pointerEvents="none"
              rightIcon={{
                type: 'material-community',
                name: 'chevron-right',
              }}
              rightIconContainerStyle={styles.rightIcon}
            />
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.locationPressable}
            onPress={() => onSelectVenueAddress(values.venue)}>
            <Input
              label="LOCATION"
              errorMessage={
                touched.venueAddress && errors.venueAddress ? 'Location is required' : undefined
              }
              leftIcon={
                <Icon
                  name="magnifying-glass"
                  type="entypo"
                  size={16}
                  color={colors.black}
                  containerStyle={values.venueAddress.address === '' ? styles.icon : {}}
                />
              }
              placeholder="Try ‘Manchester M3 3EE’"
              placeholderTextColor={colors.greyOutline}
              value={values.venueAddress.address}
              editable={false}
              pointerEvents="none"
              leftIconContainerStyle={styles.locationInput}
            />
          </TouchableOpacity>

          <StepThreeForm
            {...{
              values,
              handleChange,
              setFieldTouched,
              errors,
              touched,
              handleSubmit,
              setFieldValue,
            }}
          />

          {definedSession?.group.isOwner ? (
            <StepFourForm
              {...{
                values,
                handleChange,
                setFieldTouched,
                errors,
                touched,
                handleSubmit,
                setFieldValue,
              }}
            />
          ) : null}

          <EventLinkForm
            links={values.links}
            onAddLink={onAddLink}
            onDeleteItem={onDeleteItem}
            onChangeLinkItem={onChangeLinkItem}
            errors={touched.links && errors.links}
          />

          <Text style={styles.title}>AGE RANGE</Text>
          <View style={styles.row}>
            <View style={styles.column}>
              <Input
                keyboardType="numeric"
                returnKeyType="done"
                onSubmitEditing={Keyboard.dismiss}
                placeholder="Minimum Age"
                placeholderTextColor={colors.greyOutline}
                value={values.minAge}
                onFocus={() => setFieldTouched('minAge')}
                onChangeText={handleChange('minAge')}
                errorMessage={touched.minAge && errors.minAge ? errors.minAge : undefined}
                editable={!loading}
              />
            </View>
            <View style={styles.column}>
              <Input
                keyboardType="numeric"
                returnKeyType="done"
                onSubmitEditing={Keyboard.dismiss}
                placeholder="Maximum Age"
                placeholderTextColor={colors.greyOutline}
                value={values.maxAge}
                onFocus={() => setFieldTouched('maxAge')}
                onChangeText={handleChange('maxAge')}
                errorMessage={touched.maxAge && errors.maxAge ? errors.maxAge : undefined}
                editable={!loading}
              />
            </View>
          </View>

          <Text style={styles.title}>PARTICIPANTS NEEDED</Text>
          <View style={styles.row}>
            <View style={styles.column}>
              <Input
                keyboardType="numeric"
                returnKeyType="done"
                onSubmitEditing={Keyboard.dismiss}
                placeholder="Minimum"
                placeholderTextColor={colors.greyOutline}
                value={values.minParticipants}
                onFocus={() => setFieldTouched('minParticipants')}
                onChangeText={handleChange('minParticipants')}
                errorMessage={
                  touched.minParticipants && errors.minParticipants
                    ? errors.minParticipants
                    : undefined
                }
                editable={!loading}
              />
            </View>

            <View style={styles.column}>
              <Input
                keyboardType="numeric"
                returnKeyType="done"
                onSubmitEditing={Keyboard.dismiss}
                placeholder="Maximum"
                placeholderTextColor={colors.greyOutline}
                value={values.maxParticipants}
                onFocus={() => setFieldTouched('maxParticipants')}
                onChangeText={handleChange('maxParticipants')}
                errorMessage={
                  touched.maxParticipants && errors.maxParticipants
                    ? errors.maxParticipants
                    : undefined
                }
                editable={!loading}
              />
            </View>
          </View>

          <View style={styles.sessionInputView}>
            <InputPicker
              label="GENDER"
              placeholder="Group gender restrictions"
              value={values.gender}
              items={pickerGender}
              errorMessage={touched.gender && errors.gender ? errors.gender : undefined}
              editable={!loading}
              onValueChange={(value) => setFieldValue('gender', value)}
            />

            <InputPicker
              label="SKILL LEVEL"
              value={values.skillLevel}
              items={pickerSkillLevel}
              errorMessage={touched.skillLevel && errors.skillLevel ? errors.skillLevel : undefined}
              editable={!loading}
              onValueChange={(value) => setFieldValue('skillLevel', value)}
            />

            <InputPicker
              label="PRIVACY"
              value={values.privacy}
              items={pickerPrivacy}
              errorMessage={touched.privacy && errors.privacy ? errors.privacy : undefined}
              editable={!loading}
              onValueChange={(value) => setFieldValue('privacy', value)}
            />

            <InputPicker
              label="VENUE TYPE"
              value={values.venueType}
              items={pickerVenue}
              errorMessage={touched.venueType && errors.venueType ? errors.venueType : undefined}
              editable={!loading}
              onValueChange={(value) => handlePickerValue('venueType', value)}
            />
          </View>

          <Input
            label="DESCRIBE YOUR EVENT"
            value={values.description}
            placeholder="Type here..."
            placeholderTextColor={colors.greyOutline}
            onChangeText={handleChange('description')}
            inputContainerStyle={styles.inputContainerStyle}
            inputStyle={styles.inputStyle}
            onFocus={() => setFieldTouched('description')}
            multiline
            errorMessage={
              touched.description && errors.description ? errors.description : undefined
            }
          />

          <Input
            label="RULES / NOTES (OPTIONAL)"
            value={values.notes}
            placeholder="Type here..."
            placeholderTextColor={colors.greyOutline}
            onChangeText={handleChange('notes')}
            containerStyle={styles.inputContainer}
            inputContainerStyle={styles.inputContainerStyle}
            inputStyle={styles.inputStyle}
            onFocus={() => setFieldTouched('notes')}
            multiline
            errorMessage={touched.notes && errors.notes ? errors.notes : undefined}
          />
        </View>
      </KeyboardAwareScrollView>

      <View style={styles.buttonContainer}>
        <DualButton
          leftTitle="Cancel"
          rightTitle="Duplicate"
          leftOnPress={() => checkInputStates()}
          rightOnPress={() => handleSubmit()}
        />
      </View>

      <NoWalletAccountPopup
        visible={noWalletAccount}
        toggle={toggleWalletAccountDialog}
        onSetUpAccount={() => onSetupAccount()}
      />

      <Overlay
        isVisible={imgDialog}
        windowBackgroundColor="rgba(0, 0, 0, .5)"
        overlayBackgroundColor="#fff"
        width="70%"
        height="auto"
        onBackdropPress={() => setImgDialog(false)}
        overlayStyle={styles.imageDialog}>
        <View style={styles.imageDialogButtonContainer}>
          <Button
            title="Remove"
            titleStyle={styles.removeButton}
            type="clear"
            onPress={async () => {
              setImgDialog(false)
              setFieldValue('newImage', '')
            }}
          />
          <Divider style={styles.divider} />
          <Button
            title="Add Image"
            titleStyle={styles.addButton}
            type="clear"
            onPress={() => {
              setImgDialog(false)
              pickImage
            }}
          />
        </View>
      </Overlay>
      <ConfirmDialog
        title="Careful..."
        message="If you exit this page your updates will be lost."
        negativeButton={{
          title: 'Cancel',
          onPress: toggleConfirmationDialog,
        }}
        positiveButton={{
          title: 'Exit',
          onPress: () => {
            toggleConfirmationDialog()
            navigation.goBack()
          },
        }}
        visible={isConfirmationDialogVisible}
      />
    </>
  )
}

DuplicateSessionScreen.propTypes = {
  route: PropTypes.object.isRequired,
}

export default DuplicateSessionScreen
