import PropTypes from 'prop-types'
import React from 'react'
import { Modal, StyleSheet, TouchableWithoutFeedback, View } from 'react-native'

import DualButton from '@src/components/DualButton'
import Text from '@src/components/Text'

import useStyle from './AddCalendarPopup.styles'

const AddToCalendarPopup = ({ visible, onClose, onAdd }) => {
  const styles = useStyle()

  return (
    <Modal transparent visible={visible} animationType="none" onRequestClose={onClose}>
      <TouchableWithoutFeedback onPress={onClose}>
        <View style={{ ...StyleSheet.absoluteFill, ...styles.container }}>
          <TouchableWithoutFeedback>
            <View style={styles.content}>
              <View style={styles.texts}>
                <Text style={styles.title}>ADD TO CALENDAR</Text>
                <Text style={styles.comment}>
                  {'Would you like to add this session\nto your calendar?'}
                </Text>
              </View>

              <View style={styles.buttons}>
                <DualButton
                  leftTitle="Cancel"
                  rightTitle="Add to Calender"
                  leftOnPress={onClose}
                  rightOnPress={onAdd}
                />
              </View>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  )
}

AddToCalendarPopup.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
}

export default AddToCalendarPopup
