import { Badge, Icon } from '@rneui/themed'
import { FC } from 'react'
import { View } from 'react-native'

import styles from './TabBarIcon.styles'
import { TabBarIconProps } from './types'

const TabBarIcon: FC<TabBarIconProps> = ({ name, withBadge = false }) => (
  <View>
    <Icon name={name} size={25} style={styles.icon} type="feather" />
    {withBadge ? (
      <Badge
        value="NEW"
        containerStyle={styles.badgeContainer}
        badgeStyle={styles.badgeStyle}
        textStyle={styles.badgeText}
      />
    ) : null}
  </View>
)

export default TabBarIcon
