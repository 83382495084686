import { Button, Icon, Input } from '@rneui/themed'
import PropTypes from 'prop-types'
import { Keyboard, View } from 'react-native'

import Text from '@src/components/Text'
import { colors } from '@src/config/theme'

import styles from './EventLinks.styles'

const LinkItem = ({ link, onChange, onDelete, showDelete, errors }) => (
  <View style={styles.itemContainer}>
    <Input
      onSubmitEditing={Keyboard.dismiss}
      value={link.url}
      placeholder="e.g https://orfiactive.com"
      onChangeText={(url) => {
        onChange('url', url.trim())
      }}
      autoCapitalize="none"
      autoCorrect={false}
      containerStyle={styles.link}
      inputStyle={styles.linkText}
      errorMessage={errors.url}
    />

    <Input
      value={link.name}
      onSubmitEditing={Keyboard.dismiss}
      placeholder="Description"
      containerStyle={styles.desc}
      onChangeText={(text) => onChange('name', text)}
      inputStyle={styles.descText}
      errorMessage={errors.name}
    />
    {showDelete && (
      <Button
        onPress={onDelete}
        icon={<Icon name="ios-close" type="ionicon" color={colors.primary} size={34} />}
        type="clear"
      />
    )}
  </View>
)

LinkItem.propTypes = {
  link: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  showDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  errors: PropTypes.object,
}

LinkItem.defaultProps = {
  showDelete: false,
  onDelete: () => {},
  errors: {},
}

const EventLinkForm = ({ links, onAddLink, onDeleteItem, onChangeLinkItem, errors }) => (
  <View style={styles.container}>
    <View>
      <Text style={styles.title}>EVENT LINKS</Text>
    </View>
    <LinkItem
      link={links[0]}
      onChange={(key, value) => {
        onChangeLinkItem({ index: 0, key, value })
      }}
      errors={errors && errors[0]}
    />
    {links.map((item, index) =>
      index === 0 ? null : (
        <LinkItem
          key={index}
          link={item}
          onChange={(key, value) => {
            onChangeLinkItem({ index, key, value })
          }}
          onDelete={() => onDeleteItem(index)}
          key={item.link}
          showDelete
          errors={errors && errors[index]}
        />
      ),
    )}
    <Button
      title="ADD MORE LINKS"
      onPress={onAddLink}
      type="clear"
      icon={{
        name: 'plus',
        type: 'feather',
        size: 15,
        color: colors.primary,
      }}
      iconRight
      buttonStyle={styles.addMoreBtn}
    />
  </View>
)

EventLinkForm.propTypes = {
  links: PropTypes.array.isRequired,
  onAddLink: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  onChangeLinkItem: PropTypes.func.isRequired,
  errors: PropTypes.array,
}

EventLinkForm.defaultProps = {
  errors: undefined,
}

export default EventLinkForm
