import { StyleSheet } from 'react-native'

import { fonts, colors } from '@src/config/theme'

export const itemStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 15,
  },
  comment: {
    flex: 1,
    color: colors.black,
    fontSize: 12,
    fontFamily: fonts.default.bold,
  },
  content: {
    flex: 3,
    color: colors.black,
    fontSize: 12,
    textAlign: 'right',
    flexShrink: 1,
  },
})

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  content: {
    flex: 1,
  },
  list: {
    paddingHorizontal: 25,
  },
  buttons: {
    marginHorizontal: 20,
    marginVertical: 20,
  },
  comments: {
    width: '60%',
    alignSelf: 'center',
    marginTop: 40,
    marginBottom: 30,
  },
  commentText: {
    color: colors.secondary,
    fontSize: 12,
    textAlign: 'center',
  },
  buttonContainer: {
    flex: 1,
    margin: 5,
  },
  button: {
    backgroundColor: colors.secondary,
  },
  buttonText: {
    color: colors.white,
    fontSize: 14,
    // fontWeight: '500',
    fontFamily: fonts.default.bold,
  },
  errorContainer: {
    flex: 1,
  },
})
