import { useNavigation, useRoute } from '@react-navigation/native'
import { Button } from '@rneui/themed'
import { sortBy } from 'lodash'
import React, { useEffect, useLayoutEffect } from 'react'
import { SafeAreaView, View } from 'react-native'

import EmptyNotice from '@src/components/EmptyNotice'
import HeaderIcon from '@src/components/HeaderIcon'
import Loading from '@src/components/Loading'
import { useGroupQuery, useMembershipQuery } from '@src/graphql/types'
import DashboardCalendarScreen from '@src/screens/GroupDashboard/DashboardCalendarScreen'
import {
  DashboardSessionsScreenNavigationProps,
  DashboardSessionsScreenRouteProps,
} from '@src/types'

import styles from './DashboardSessionsScreen.styles'

const DashboardSessionsScreen = () => {
  const navigation = useNavigation<DashboardSessionsScreenNavigationProps>()
  const { params } = useRoute<DashboardSessionsScreenRouteProps>()
  const {
    loading,
    data: {
      group = {
        uuid: '',
        name: '',
        description: '',
        image: '',
        isAdmin: false,
        groupUsers: { user: { id: 0, avatar: '', name: '', surname: '' } },
        sessions: {},
      },
    } = {},
    error,
    refetch,
  } = useGroupQuery({
    variables: {
      groupUuid: params?.groupUuid,
    },
  })

  useLayoutEffect(() => {
    if (!group?.isAdmin) {
      return
    }

    const headerRight = () => (
      <View style={styles.headerContainer}>
        <HeaderIcon
          name="tool"
          type="feather"
          onPress={() => navigation.navigate('SelectSessionEditScreen', { groupUuid: group.uuid })}
          right
        />
        <HeaderIcon
          name="plus"
          type="feather"
          onPress={() => navigation.navigate('CreateSessionScreen', { groupUuid: group.uuid })}
        />
      </View>
    )

    navigation.setOptions({
      headerRight,
    })
  }, [group, navigation])

  useEffect(() => {
    if (params?.newSession) {
      refetch()
    }
  }, [params, refetch])

  if (loading) {
    return <Loading />
  }

  if (error) {
    return (
      <EmptyNotice
        title="NOTHING TO SHOW"
        text="Seems like there are no longer upcoming sessions for this group."
      />
    )
  }

  const ordered = sortBy(group?.sessions, ['startAt'])

  return (
    <SafeAreaView style={styles.container}>
      <DashboardCalendarScreen data={ordered} group={group} />
      {group?.isAdmin ? (
        <View style={styles.buttonContainer}>
          <Button
            title="Add a Session"
            icon={{
              name: 'plus',
              type: 'feather',
              color: '#fff',
            }}
            onPress={() => navigation.navigate('CreateSessionScreen', { groupUuid: group.uuid })}
            containerStyle={styles.buttonContainerStyle}
          />
        </View>
      ) : null}
    </SafeAreaView>
  )
}

export default DashboardSessionsScreen
