import { useNavigation } from '@react-navigation/native'
import { Button, Icon, ListItem } from '@rneui/themed'
import React, { useEffect, useState } from 'react'
import { ScrollView, TouchableOpacity, View } from 'react-native'
import Accordion from 'react-native-collapsible/Accordion'

import Text from '@src/components/Text'
import { colors } from '@src/config/theme'
import { FaqScreenNavigationProps } from '@src/types'

import styles from './FaqScreen.styles'

const SECTIONS = [
  {
    title: 'Is Orfi Active free for both organisers and participants?',
    content:
      'Orfi Active is completely free to download, browse and use as a participant and an organiser with no limitations to how many recurring sessions, participants or groups you have.',
  },
  {
    title: 'What are your in-app payment fees?',
    content:
      'Currently there are NO FEES for in-app transactions to help organisers, teams and clubs get back on their feet during this challenging time.\nAfter everything comes back to normal, our fees will go back to being only 1.9% + 10p per transaction (lowest on the market)',
  },
  {
    title: 'How secure is my data?',
    content:
      'We take every measure to protect your data and use all the necessary safety procedures. When you sign up, you will notice that we only ask for you name, email and password, the absolute basics that are crucial for the app to function.',
  },
  {
    title: 'How do I invite people to my sessions?',
    content:
      "The best way to invite people to your sessions is to simply share the session details with them. You can do that by going to the 'Session Details' screen and pressing the 'Share' icon on the top right corner of the screen.\nPeople that already have Orfi Active installed will immediately see the session details you've shared and will be able to join in just a few taps. People that do not have Orfi Active installed will be asked to do so and once they have it on their phones, it would be best if they pressed on your shared session link once again to be transferred to the correct screen to join.",
  },
  {
    title: 'Can I still edit a session after it has been created?',
    content:
      'You absolutely can.\nNot only that, once you make any changes to a session, all your participants will be notified immediately of the most important changes, such as time/date amendments, change of venue location, session cost and other details.',
  },
]

const FaqScreen = () => {
  const navigation = useNavigation<FaqScreenNavigationProps>()
  const [activeSections, setActiveSections] = useState([])

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button
          title="GET IN TOUCH"
          type="clear"
          onPress={() => navigation.navigate('SendFeedbackScreen')}
        />
      ),
    })
  }, [])

  const renderHeader = (content, index, isActive, sections) => (
    <View style={styles.header}>
      <ListItem
        bottomDivider={!isActive}
        hasTVPreferredFocus={undefined}
        tvParallaxProperties={undefined}>
        <ListItem.Content style={styles.hdrListContaiiner}>
          <ListItem.Title style={{ color: isActive ? colors.primary : colors.secondary }}>
            {content.title}
          </ListItem.Title>
        </ListItem.Content>
        <Icon name={isActive ? 'chevron-small-up' : 'chevron-small-down'} type="entypo" size={20} />
      </ListItem>
    </View>
  )

  const renderContent = (section) => (
    <View style={styles.content}>
      <Text style={styles.contentText}>{section.content}</Text>
    </View>
  )

  return (
    <ScrollView contentContainerStyle={styles.scrollVw}>
      <Accordion
        touchableComponent={TouchableOpacity}
        sections={SECTIONS}
        activeSections={activeSections}
        renderHeader={renderHeader}
        renderContent={renderContent}
        onChange={(section) => setActiveSections(section)}
      />
    </ScrollView>
  )
}

export default FaqScreen
