import { StyleSheet, useWindowDimensions } from 'react-native'

import { fonts } from '@src/config/theme'

const useStyle = () => {
  const { height } = useWindowDimensions()
  const containerPadding = Math.round(height / 8.34)

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: containerPadding,
      paddingBottom: containerPadding,
    },
    headerContainer: {
      marginBottom: 0,
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '75%',
    },
    iconSad: {
      fontSize: 64,
      marginBottom: 20,
      paddingTop: 48,
    },
    title: {
      color: '#888888',
      fontSize: 18,
      // fontWeight: 'bold',
      fontFamily: fonts.default.bold,
      textAlign: 'center',
      marginBottom: 20,
    },
    text: {
      color: '#888888',
      fontSize: 16,
      textAlign: 'center',
    },
  })

  return styles
}

export default useStyle
