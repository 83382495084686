import { useNavigation } from '@react-navigation/native'
import { Button } from '@rneui/themed'
import React, { FC } from 'react'
import { useWindowDimensions, View } from 'react-native'

import { useMeQuery } from '@src/graphql/types'

import styles from './TopBanner.styles'

const TopBanner: FC = () => {
  const { width } = useWindowDimensions()
  const { data: { me = { isEmailVerified: false } } = {}, loading, error } = useMeQuery()

  const navigation = useNavigation()

  const handleNavigate = () => navigation.navigate('EmailConfirmationScreen')

  if (loading || error || me.isEmailVerified) return null

  return (
    <View style={[styles.bannerContainer, { width }]}>
      <Button
        title="VERIFY YOUR EMAIL"
        containerStyle={styles.buttonContainer}
        buttonStyle={styles.button}
        titleStyle={styles.buttonTitle}
        onPress={handleNavigate}
      />
    </View>
  )
}

export default TopBanner
