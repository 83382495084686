import getEnvVars from '@src/config/environment'
import { HttpLink } from '@apollo/client'

export const API_URL = getEnvVars().apiUrl

const httpLink = new HttpLink({
  uri: API_URL,
  credentials: 'same-origin',
})

export default httpLink
