import { Icon } from '@rneui/themed'
import { FC } from 'react'
import { View } from 'react-native'
import AwesomeIcon from 'react-native-vector-icons/FontAwesome'

import Text from '@src/components/Text'

import useStyle from './EmptyNotice.styles'
import { EmptyNoticePropsType } from './types'

const EmptyNotice: FC<EmptyNoticePropsType> = ({ title, text, icon }) => {
  const styles = useStyle()

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        {icon ? (
          <Icon color="#888888" iconStyle={styles.iconSad} {...icon} />
        ) : (
          <AwesomeIcon color="#888888" name="frown-o" style={styles.iconSad} />
        )}
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.text}>{text}</Text>
      </View>
    </View>
  )
}

export default EmptyNotice
