import { useNavigation } from '@react-navigation/native'
import { Button, Divider, Icon, Input, Overlay } from '@rneui/themed'
import * as ImagePicker from 'expo-image-picker'
import * as MediaLibrary from 'expo-media-library'
import PropTypes from 'prop-types'
import { useState, useRef } from 'react'
import {
  ImageBackground,
  Keyboard,
  ScrollView,
  TouchableOpacity,
  View,
  TextInput,
  Pressable,
} from 'react-native'

import { IMAGE_EVENT_HEADER } from '@src/assets/images'
import InputPicker from '@src/components/forms/InputPicker'
import RowLayout from '@src/components/forms/RowLayout'
import Text from '@src/components/Text'
import { pickerGender } from '@src/config/forms'
import { colors } from '@src/config/theme'
import generateRNFile from '@src/utils/generateRNFile'

import useStyle from './styles'

const StepOneForm = ({
  values,
  touched,
  errors,
  isSubmitting,
  handleChange,
  setFieldTouched,
  setFieldValue,
}) => {
  const [imgDialog, setImgDialog] = useState(false)
  const navigation = useNavigation()
  const styles = useStyle()

  const goToSelectActivity = () => {
    navigation.navigate('SelectActivityScreen', { backRoute: 'CreateSessionScreen' })
  }

  const handlePickerValue = (field, value) => {
    setFieldValue(field, value)
  }

  const pickImage = async () => {
    const { status } = await MediaLibrary.requestPermissionsAsync()
    if (status !== 'granted') {
      // eslint-disable-next-line no-alert
      alert('Sorry, we need camera roll permissions to make this work!')
    } else {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
      })

      if (!result.canceled) {
        const file = generateRNFile(result.assets[0].uri, 'Cover')
        setFieldTouched('image')
        setFieldValue('image', file)
      }
    }
  }

  let imageSource
  if (values.image) {
    imageSource = { uri: values.image.uri }
  } else {
    imageSource = IMAGE_EVENT_HEADER
  }

  const maxAgeRef = useRef<TextInput>(null)
  const minParticipantsRef = useRef<TextInput>(null)
  const maxParticipantsRef = useRef<TextInput>(null)

  return (
    <ScrollView contentContainerStyle={styles.scrollViewContainer} style={styles.scrollViewStyle}>
      <ImageBackground style={styles.imageBackground} source={imageSource}>
        <Pressable onPress={() => (values.image ? setImgDialog(true) : pickImage())}>
          <Icon
            size={40}
            type="feather"
            name="camera"
            style={styles.imageBackgroundIcon}
            color="#FFFFFF"
            iconStyle={styles.imageBackgroundIconStyling}
          />
          <Text style={styles.imageBackgroundText}>Add Session Image</Text>
        </Pressable>
      </ImageBackground>

      <Input
        label="NAME"
        labelStyle={styles.nameInputLabel}
        onSubmitEditing={goToSelectActivity}
        placeholder={'e.g "Saturday Morning tennis practice"'}
        placeholderTextColor={colors.greyOutline}
        value={values.name}
        onBlur={() => setFieldTouched('name')}
        onChangeText={handleChange('name')}
        errorMessage={touched.name && errors.name ? errors.name : undefined}
        editable={!isSubmitting}
        containerStyle={styles.containerStyle}
        returnKeyType="next"
      />

      <TouchableOpacity style={styles.inputTouchableOpacity} onPress={goToSelectActivity}>
        <Input
          label="ACTIVITY"
          placeholder="Select..."
          placeholderTextColor={colors.greyOutline}
          value={values.activity.name}
          onBlur={() => setFieldTouched('activity')}
          onChangeText={handleChange('activity')}
          errorMessage={touched.activity && errors.activity ? 'Activity is required' : undefined}
          editable={false}
          pointerEvents="none"
          rightIcon={{
            type: 'material-community',
            name: 'chevron-right',
          }}
          rightIconContainerStyle={styles.rightIcon}
          containerStyle={styles.containerStyle}
        />
      </TouchableOpacity>

      <View style={styles.containerStyle}>
        <InputPicker
          label="GENDER"
          placeholder="Group gender restrictions"
          placeholderTextColor={colors.greyOutline}
          value={values.gender}
          items={pickerGender}
          errorMessage={touched.gender && errors.gender ? errors.gender : undefined}
          editable={!isSubmitting}
          onValueChange={(value) => handlePickerValue('gender', value)}
          containerStyle={styles.pickerStyle}
        />
      </View>

      <RowLayout
        label="AGE RANGE (optional)"
        left={
          <Input
            keyboardType="numeric"
            onSubmitEditing={() => maxAgeRef.current?.focus()}
            placeholder="Minimum Age"
            placeholderTextColor={colors.greyOutline}
            value={values.minAge}
            onBlur={() => setFieldTouched('minAge')}
            onChangeText={handleChange('minAge')}
            errorMessage={touched.minAge && errors.minAge ? errors.minAge : undefined}
            editable={!isSubmitting}
            returnKeyType="next"
          />
        }
        right={
          <Input
            keyboardType="numeric"
            returnKeyType="next"
            onSubmitEditing={() => minParticipantsRef.current?.focus()}
            placeholder="Maximum Age"
            placeholderTextColor={colors.greyOutline}
            value={values.maxAge}
            onBlur={() => setFieldTouched('maxAge')}
            onChangeText={handleChange('maxAge')}
            errorMessage={touched.maxAge && errors.maxAge ? errors.maxAge : undefined}
            editable={!isSubmitting}
            ref={maxAgeRef}
          />
        }
      />

      <RowLayout
        label="PARTICIPANTS NEEDED (optional)"
        left={
          <Input
            keyboardType="numeric"
            returnKeyType="next"
            onSubmitEditing={() => maxParticipantsRef.current?.focus()}
            placeholder="Minimum"
            placeholderTextColor={colors.greyOutline}
            value={values.minParticipants}
            onBlur={() => setFieldTouched('minParticipants')}
            onChangeText={handleChange('minParticipants')}
            errorMessage={
              touched.minParticipants && errors.minParticipants ? errors.minParticipants : undefined
            }
            editable={!isSubmitting}
            containerStyle={styles.containerStyle}
            ref={minParticipantsRef}
          />
        }
        right={
          <Input
            keyboardType="numeric"
            placeholder="Maximum"
            placeholderTextColor={colors.greyOutline}
            returnKeyType="done"
            onSubmitEditing={Keyboard.dismiss}
            value={values.maxParticipants}
            onBlur={() => setFieldTouched('maxParticipants')}
            onChangeText={handleChange('maxParticipants')}
            errorMessage={
              touched.maxParticipants && errors.maxParticipants ? errors.maxParticipants : undefined
            }
            editable={!isSubmitting}
            containerStyle={styles.containerStyle}
            ref={maxParticipantsRef}
          />
        }
      />

      <View style={styles.containerStyle}>
        {/* <InputPicker
          label="SKILL LEVEL"
          value={values.skillLevel}
          items={pickerSkillLevel}
          errorMessage={touched.skillLevel && errors.skillLevel ? errors.skillLevel : undefined}
          editable={!isSubmitting}
          onValueChange={(value) => handlePickerValue('skillLevel', value)}
          containerStyle={styles.pickerStyle}
        /> */}
      </View>

      <Overlay
        isVisible={imgDialog}
        onBackdropPress={() => setImgDialog(false)}
        overlayStyle={styles.overlayStyle}>
        <View style={styles.overlayContainer}>
          <Button
            title="Remove"
            titleStyle={styles.addButtonTitle}
            type="clear"
            onPress={() => {
              setImgDialog(false)
              setFieldValue('image', '')
            }}
          />
          <Divider style={styles.divider} />
          <Button
            title="Add Image"
            titleStyle={styles.removeButtonTitle}
            type="clear"
            onPress={() => {
              setImgDialog(false)
              pickImage()
            }}
          />
        </View>
      </Overlay>
    </ScrollView>
  )
}

StepOneForm.propTypes = {
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
}

export default StepOneForm
