import { StyleSheet } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const styles = StyleSheet.create({
  title: {
    paddingHorizontal: 20,
    color: colors.secondary,
    fontSize: 12,
    fontFamily: fonts.default.bold,
    marginTop: 20,
  },
  itemContainer: {
    flexDirection: 'row',
    paddingHorizontal: 5,
    marginVertical: 10,
  },
  link: {
    width: '45%',
    paddingVertical: 0,
    marginVertical: 0,
  },
  descText: {
    fontSize: 14,
    color: colors.secondary,
    marginLeft: 0,
  },
  desc: {
    paddingVertical: 0,
    flex: 1,
    marginLeft: 5,
    marginVertical: 0,
  },
  linkText: {
    fontSize: 14,
    color: colors.secondary,
    fontStyle: 'italic',
  },
  linkTextActive: {
    fontSize: 14,
    color: colors.secondary,
  },
  addIcon: {
    marginVertical: 20,
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  error: {
    margin: 5,
    fontSize: 12,
    color: colors.error,
  },
  buttonContainer: {
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  feeInfoView: {
    position: 'absolute',
    right: 0,
    bottom: -7,
  },
  feeInfo: {
    alignSelf: 'flex-end',
    textDecorationLine: 'underline',
    fontFamily: fonts.default.regular,
  },
  addMoreBtn: {
    alignSelf: 'center',
  },
})

export default styles
