import { useRoute, useNavigation } from '@react-navigation/native'
import { Divider, Icon } from '@rneui/themed'
import moment from 'moment'
import React from 'react'
import { FlatList, SafeAreaView } from 'react-native'

import OrfiListItem from '@src/components/ListItem/OrfiListItem'
import Loading from '@src/components/Loading'
import ProfileOverViewForm from '@src/components/Profile/ProfileOverViewForm'
import Text from '@src/components/Text'
import { colors } from '@src/config/theme'
import { useUserQuery } from '@src/graphql/types'
import { PublicProfileScreenRouteProps, PublicProfileScreenNavigationProps } from '@src/types.d'
import getInitials from '@src/utils/getInitials'

import styles from './PublicProfileScreen.styles'

const PublicProfileScreen = () => {
  const navigation = useNavigation<PublicProfileScreenNavigationProps>()
  const { params } = useRoute<PublicProfileScreenRouteProps>()
  const { loading, data } = useUserQuery({
    variables: {
      id: params?.userId,
    },
  })

  if (loading) return <Loading />

  const renderItem = ({ item }) => {
    const { group } = item
    const acts = (group.activities || []).map((i) => i?.name).join(', ')

    return (
      <OrfiListItem
        avatarUri={group.image}
        avatarPlaceholder={getInitials(group?.name)}
        topBadge=""
        title={group?.name}
        subtitle={acts}
        callToAction={<Icon name="chevron-right" type="feather" color={colors.grey} size={20} />}
        onPress={() => navigation.navigate('DashboardGroupScreen', { groupUuid: group?.uuid })}
        divider
      />
    )
  }

  const { user = {} } = data

  const managedGroups = (user?.groupuserSet || []).filter((i) => i?.group?.isAdmin)

  const formatDate = (date) => moment(date).format('MMMM Do, YYYY')

  return (
    <SafeAreaView style={styles.container}>
      <Text style={styles.sessions}>ORGANISED GROUPS</Text>
      <Divider style={styles.divider} />
      <FlatList
        keyExtractor={(grp) => grp?.uuid}
        data={managedGroups}
        renderItem={renderItem}
        ListHeaderComponent={
          <ProfileOverViewForm
            avatar={user?.avatar}
            name={`${user?.name} ${user?.surname}`}
            participated={user?.attendingSessionCount}
            organized={user?.organisedEventsCount}
            dateJoined={formatDate(user?.dateJoined)}
            friends={0}
            organizedDetails
          />
        }
      />
    </SafeAreaView>
  )
}

export default PublicProfileScreen
