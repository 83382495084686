import { gql } from '@apollo/client'

import { Group, Session, User, WallPost, Wall } from './fragments'

export const ME_QUERY = gql`
  query me {
    me {
      ...MeUser
    }
  }
  ${User.fragments.me}
`

export const GET_USER_QUERY = gql`
  query user($id: Int!) {
    user(id: $id) {
      ...User
    }
  }
  ${User.fragments.common}
`

export const ACTIVITIES_QUERY = gql`
  query activites {
    activities {
      id
      name
      isFeatured
      icon
      defaultBannerImage
    }
  }
`

export const ORGANISED_SESSIONS = gql`
  query organisedSessions {
    organisedSessions {
      ...Session
    }
  }
  ${Session.fragments.common}
`

export const GET_SESSION_QUERY = gql`
  query session($id: Int!) {
    session(id: $id) {
      ...Session
    }
  }
  ${Session.fragments.common}
`

export const GET_SESSION_MEMBERSHIP = gql`
  query sessionMembership(
    $limit: Int
    $offset: Int
    $orderBy: String
    $status_In: [MembershipStatusEnum]
    $startAt_Gte: DateTime
    $startAt_Lte: DateTime
  ) {
    sessionMembership(
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      status_In: $status_In
      startAt_Gte: $startAt_Gte
      startAt_Lte: $startAt_Lte
    ) {
      id
      status
      bankedPaymentId
      price {
        id
        uuid
        value
      }
      session {
        id
        status
        startAt
        name
        image
        isOwner
        address
        group {
          id
          uuid
        }
        owner {
          id
        }
        activity {
          id
          defaultBannerImage
          name
        }
        createdAt
        maxParticipants
        usersAttendingCount
      }
    }
  }
`

export const GET_USER_NOTIFICATIONS = gql`
  query userNotifications($limit: Int, $offset: Int, $orderBy: String) {
    userNotifications(limit: $limit, offset: $offset, orderBy: $orderBy) {
      uuid
      user {
        id
        email
        name
        avatar
      }
      message
      data
      createdAt
    }
  }
`

export const GET_USER_MEMBERSHIP = gql`
  query membership($id: Int, $sessionId: Int) {
    membership(id: $id, sessionId: $sessionId) {
      id
      uuid
      user {
        id
        email
        name
        avatar
      }
      paymentMethod
      bankedPaymentId
      bankedPaymentStatus
      status
      createdAt
    }
  }
`

export const GET_GROUP = gql`
  query group($groupUuid: String) {
    group(groupUuid: $groupUuid) {
      ...Group
    }
  }
  ${Group.fragments.common}
`

export const GET_GROUPS_REQUESTS = gql`
  query groupRequests($groupUuid: String) {
    groupRequests(groupUuid: $groupUuid) {
      uuid
      createdAt
      updatedAt
      id
      group {
        id
        uuid
        name
      }
      requestUser {
        id
        name
        surname
        email
        avatar
      }
    }
  }
`

export const GET_WALL_POSTS = gql`
  query wallPosts($groupUuid: ID!, $parentUuid: ID, $limit: Int, $offset: Int) {
    wallPosts(groupUuid: $groupUuid, parentUuid: $parentUuid, limit: $limit, offset: $offset) {
      ...WallPost
      user {
        ...User
      }
      wall {
        ...Wall
      }
      parent {
        ...WallPost
      }
    }
  }
  ${Wall.fragments.common}
  ${WallPost.fragments.common}
  ${User.fragments.common}
`

export const GET_WALL_POST = gql`
  query wallPost($postUuid: ID!) {
    wallPost(postUuid: $postUuid) {
      ...WallPost
      user {
        ...User
      }
      wall {
        ...Wall
      }
      parent {
        ...WallPost
      }
    }
  }
  ${Wall.fragments.common}
  ${WallPost.fragments.common}
  ${User.fragments.common}
`
