import { Icon } from '@rneui/themed'
import PropTypes from 'prop-types'
import React from 'react'
import { Dimensions, Modal, StyleSheet, TouchableWithoutFeedback, View } from 'react-native'

import DualButton from '@src/components/DualButton'
import Text from '@src/components/Text'
import { colors, fonts } from '@src/config/theme'

const { height, width } = Dimensions.get('screen')

const SessionFullPopup = ({ visible, onClose, onBack, onJoin }) => (
  <Modal transparent visible={visible} animationType="none" onRequestClose={onClose}>
    <TouchableWithoutFeedback onPress={onClose}>
      <View style={{ ...StyleSheet.absoluteFill, ...styles.container }}>
        <TouchableWithoutFeedback>
          <View style={styles.content}>
            <Icon
              color={colors.secondary}
              size={40}
              containerStyle={styles.icon}
              type="material-community"
              name="emoticon-neutral"
            />
            <View style={styles.texts}>
              <Text style={styles.title}>THIS SESSION IS NOW FULL!</Text>
              <Text style={styles.comment}>
                The maximum number of attendees for this session has been reached. However, you can
                still join the waiting list and if anyone drops out, we’ll immediately let you know.
              </Text>
            </View>

            <View style={styles.buttons}>
              <DualButton
                leftTitle="Back"
                rightTitle="Join"
                leftOnPress={onBack}
                rightOnPress={onJoin}
              />
            </View>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </TouchableWithoutFeedback>
  </Modal>
)

const buttonWidth = (width * 0.8 - 60 - 10) / 2

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#1119',
    alignItems: 'center',
    zIndex: 1000,
  },
  content: {
    width: '80%',
    borderRadius: 6,
    padding: 20,
    shadowOffset: { width: 2, height: 2 },
    shadowColor: 'black',
    shadowOpacity: 0.2,
    backgroundColor: colors.white,
    marginTop: height / 5,
    alignItems: 'center',
  },
  texts: {
    paddingHorizontal: 10,
    alignItems: 'center',
  },
  icon: {
    marginVertical: 30,
  },
  title: {
    color: colors.secondary,
    fontSize: 12,
    fontFamily: fonts.default.bold,
    textAlign: 'center',
  },
  comment: {
    margin: 10,
    color: colors.secondary,
    fontSize: 10,
    textAlign: 'center',
    marginVertical: 20,
    lineHeight: 17,
  },
  buttonStyle: {
    borderRadius: 23,
    height: 42,
    backgroundColor: colors.secondary,
    alignItems: 'center',
    paddingVertical: 0,
  },
  gradientButtonStyle: {
    borderRadius: 23,
    height: 42,
    backgroundColor: colors.secondary,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 0,
  },
  buttonText: {
    color: colors.white,
    fontSize: 12,
    fontFamily: fonts.default.semiBold,
    textAlign: 'center',
  },
  buttonContainer: {
    borderRadius: 23,
    height: 35,
    justifyContent: 'center',
    alignItems: 'center',
    width: buttonWidth,
    marginRight: 10,
  },
  button: { width: buttonWidth },
  gradientButtonContainer: {
    height: 35,
    width: buttonWidth,
    justifyContent: 'center',
    alignItems: 'center',
  },
  gradientButtonText: {
    fontSize: 12,
    fontFamily: fonts.default.semiBold,
  },
  gradientButton: { width: buttonWidth },
  buttons: {
    justifyContent: 'space-between',
    marginTop: 20,
  },
})

SessionFullPopup.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onJoin: PropTypes.func.isRequired,
}

export default SessionFullPopup
