import { useIsFocused, useNavigation } from '@react-navigation/native'
import { FC, useContext, useEffect, useState } from 'react'

import HeaderIcon from '@src/components/HeaderIcon'
import { colors } from '@src/config/theme'
import { AuthContext } from '@src/context/AuthContext'
import { useUserNotificationsQuery } from '@src/graphql/types'
import { getNotificationTimestamp } from '@src/utils/notificationTimestamp'

const NotificationTick: FC = () => {
  const [lastTimestamp, setLastTimestamp] = useState<string | null>(null)
  const isFocused = useIsFocused()
  const navigation = useNavigation()

  const { isAuthenticated } = useContext(AuthContext)
  const { data, refetch } = useUserNotificationsQuery({
    variables: {
      orderBy: '-created_at',
      limit: 1,
    },
  })

  const onPress = () => navigation.navigate('NotificationScreen')

  let color

  useEffect(() => {
    const getLastReadTimeStamp = async () => {
      const timestamp = await getNotificationTimestamp()
      if (timestamp) setLastTimestamp(timestamp)
    }
    getLastReadTimeStamp()
    if (isAuthenticated) refetch()
  }, [isAuthenticated, isFocused, refetch])

  if (data?.userNotifications?.length) {
    const latest = data?.userNotifications[0]
    const isNew = latest?.createdAt !== lastTimestamp
    color = isNew ? colors.primary : undefined
  }

  return <HeaderIcon name="bell" type="feather" onPress={onPress} color={color} right />
}

export default NotificationTick
