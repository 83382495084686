import { Platform, StyleSheet, useWindowDimensions } from 'react-native'

import { colors, fonts } from '@src/config/theme'
import { scaleVertical } from '@src/utils/scale'

const useStyle = () => {
  const { height, width } = useWindowDimensions()

  const styles = StyleSheet.create({
    background: {
      flex: 1,
      width,
      height,
    },
    paddedContainer: {
      flex: 1,
      paddingTop: 64,
    },
    container: {
      flex: 1,
    },
    contentContainer: {
      flex: 1,
    },
    containContainer: { marginTop: Platform.OS === 'android' ? 20 : 0 },
    image: {
      width: undefined,
      height: scaleVertical(35),
      resizeMode: 'contain',
      marginTop: scaleVertical(30),
      marginBottom: scaleVertical(40),
    },
    categorySelectorContainer: {
      width: width - 60,
      flexDirection: 'row',
      alignSelf: 'center',
    },
    selectedCategorySelectorContainer: {
      marginBottom: 20,
    },
    formContainer: {
      backgroundColor: 'white',
      width: width - 60,
      borderRadius: 10,
      padding: 8,
      alignSelf: 'center',
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.22,
      shadowRadius: 2.22,
      elevation: 3,
      // zIndex: 1000,
      marginBottom: Platform.OS === 'android' ? 50 : 0,
    },
    selectedCategoryText: {
      opacity: 1,
      color: colors.primary,
    },
    categoryText: {
      textAlign: 'center',
      textTransform: 'uppercase',
      color: colors.grey5,
      fontSize: 16,
      fontFamily: fonts.default.bold,
      backgroundColor: 'transparent',
      opacity: 0.8,
    },
    categoryDivider: {
      borderBottomColor: colors.primary,
      borderBottomWidth: 1,
    },
    buttonContainer: {
      flex: 1,
    },
  })

  return styles
}

export default useStyle
