import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .min(8, 'Your password must be atleast 8 characters.')
    .required('Password is required'),
  passwordConfirmation: Yup.string().test(
    'passwords-match',
    'Passwords must match',
    function (value) {
      return this.parent.password === value
    },
  ),
})

export default validationSchema
