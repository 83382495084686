import PropTypes from 'prop-types'
import { View } from 'react-native'

import Text from '@src/components/Text'

import styles from './RowLayout.styles'

const RowLayout = ({ label, left, right, customStyle, leftStyle, rightStyle }) => (
  <View style={[styles.container, customStyle]}>
    {label ? (
      <Text label style={styles.labelText}>
        {label}
      </Text>
    ) : null}
    <View style={styles.row}>
      <View style={[styles.column, leftStyle]}>{left}</View>
      <View style={[styles.column, rightStyle]}>{right}</View>
    </View>
  </View>
)

RowLayout.defaultProps = {
  label: undefined,
  customStyle: {},
  leftStyle: {},
  rightStyle: {},
}

RowLayout.propTypes = {
  label: PropTypes.string,
  left: PropTypes.element.isRequired,
  right: PropTypes.element.isRequired,
  customStyle: PropTypes.object,
  leftStyle: PropTypes.object,
  rightStyle: PropTypes.object,
}

export default RowLayout
