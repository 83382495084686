import { StyleSheet } from 'react-native'

import { fonts } from '@src/config/theme'

const styles = StyleSheet.create({
  badgeContainer: {
    position: 'absolute',
    top: -2,
    right: -24,
  },
  badgeStyle: {
    borderWidth: 2,
  },
  badgeText: {
    fontFamily: fonts.default.bold,
    fontSize: 10,
  },
  icon: {
    marginBottom: 0,
  },
})

export default styles
