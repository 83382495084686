import { useNavigation, useRoute } from '@react-navigation/native'
import { Avatar, Button } from '@rneui/themed'
import { useContext, useEffect } from 'react'
import { ScrollView, View } from 'react-native'

import { DEFAULT_EVENT_IMAGE } from '@src/assets/images'
import EmptyNotice from '@src/components/EmptyNotice'
import { showErrorMessage, showSuccessMessage } from '@src/components/FlashMessage'
import Loading from '@src/components/Loading'
import Text from '@src/components/Text'
import { AuthContext } from '@src/context/AuthContext'
import { GET_GROUP, ME_QUERY } from '@src/graphql/queries'
import { useGroupQuery, useJoinGroupMutation } from '@src/graphql/types'
import {
  DashboardInviteReceivedScreenNavigationProps,
  DashboardInviteReceivedScreenRouteProps,
} from '@src/types'

import styles from './DashboardInviteReceivedScreen.styles'

const DashboardInviteReceivedScreen = () => {
  const navigation = useNavigation<DashboardInviteReceivedScreenNavigationProps>()
  const { params } = useRoute<DashboardInviteReceivedScreenRouteProps>()
  const { isAuthenticated } = useContext(AuthContext)

  useEffect(() => {
    if (!isAuthenticated) {
      navigation.replace('AuthScreen')
    }
  }, [isAuthenticated, navigation])

  const {
    loading,
    data: groupData,
    error: groupError,
  } = useGroupQuery({
    variables: {
      groupUuid: params.uuid,
    },
  })

  useEffect(() => {
    if (groupData?.group?.isMember) {
      navigation.navigate('DashboardGroupScreen', { groupUuid: params.uuid })
    }
  }, [groupData?.group?.isMember, navigation, params.uuid])

  const [requestToJoin, { loading: requestLoading }] = useJoinGroupMutation()

  const onJoin = async () => {
    try {
      await requestToJoin({
        variables: {
          groupUuid: params.uuid,
        },
        refetchQueries: [
          {
            query: GET_GROUP,
            variables: {
              groupUuid: params.uuid,
            },
          },
          {
            query: ME_QUERY,
          },
        ],
        awaitRefetchQueries: true,
      })
      if (!groupData.group.isPrivate) {
        navigation.navigate('DashboardGroupScreen', { groupUuid: params.uuid })
      } else {
        showSuccessMessage({
          message: 'Success',
          description: 'Request to join group has been sent.',
        })
        navigation.goBack()
      }
    } catch (error) {
      showErrorMessage({
        message: 'Warning',
        description: 'Oops, something went wrong. Could not join group.',
        duration: 6000,
      })
    }
  }

  if (loading) return <Loading />

  if (groupError) {
    return (
      <EmptyNotice
        title="GROUP NOT FOUND"
        text={
          "Hmm, we can't find the group you're looking for.\n It may have been deleted by the organiser, or maybe it just got lost in the Matrix"
        }
      />
    )
  }

  const { group } = groupData

  const getMessage = () => {
    if (group.hasRequested)
      return 'You have already requested to join.\n\nWe will let you know once the group administrator has approved your request.'
    if (params.fromSession)
      return "In order to join the session, you need to join the group first.\n\nRequest to join below, and we'll let you know once you've been accepted."
    if (group.isPrivate)
      return `You have been invited to join the ${group.name} group!\n\nAs this is a private group, request to join the group by pressing the button below and the admin will review your request.\n\nYou will immediately be notified once your request has been granted.`
    return `You have been invited to join the ${group.name} group!\n\nJoining a group lets you find new sessions and access important group information.\n\nSo click the button below and join the fun!`
  }

  return (
    <View style={styles.outerContainer}>
      <ScrollView contentContainerStyle={styles.scrollView}>
        <View style={styles.container}>
          <Avatar
            source={
              group?.image
                ? {
                    uri: group?.image,
                  }
                : DEFAULT_EVENT_IMAGE
            }
            size="large"
            rounded
          />
          <Text style={styles.title}>{groupData?.group?.name}</Text>
          <Text style={styles.text}>{getMessage()}</Text>
        </View>

        <View style={styles.bottomButtonContainer}>
          <View style={styles.bottomButtonInnerContainer}>
            {!group.hasRequested ? (
              <Button
                title={groupData.group.isPrivate ? 'Request to join' : 'Join Group'}
                onPress={onJoin}
                disabled={requestLoading}
                loading={requestLoading}
              />
            ) : (
              <Button
                title="Back"
                onPress={() => navigation.navigate('GroupScreen')}
                buttonStyle={styles.buttonStyle}
              />
            )}
          </View>
        </View>
      </ScrollView>
    </View>
  )
}

export default DashboardInviteReceivedScreen
