import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .strict(false)
    .trim()
    .email("This doesn't look like an email")
    .required('Email is required'),
  password: Yup.string().trim().required('Password is required'),
})

export default validationSchema
