import React from 'react'
import { Image, Text, TouchableOpacity, View } from 'react-native'

import styles from './Provider.styles'

import { UNAVAILABLE } from '../strings'

const Provider = ({ title, logo, id, status, statusDetail, onPress }) => (
  <TouchableOpacity onPress={() => onPress(id)}>
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <Image
          style={[styles.image, status === 'UNAVAILABLE' && styles.imageUnavailable]}
          source={{ uri: logo }}
        />
      </View>
      <View style={styles.titleContainer}>
        <Text style={[styles.title, status === 'UNAVAILABLE' && styles.titleUnavailable]}>
          {title}
        </Text>
        {status === 'UNAVAILABLE' && <Text style={styles.titleUnavailable}>{UNAVAILABLE}</Text>}
      </View>
    </View>
  </TouchableOpacity>
)

export default Provider
