import { Button, Icon } from '@rneui/themed'
import PropTypes from 'prop-types'
import React from 'react'
import { Dimensions, Modal, StyleSheet, TouchableWithoutFeedback, View } from 'react-native'

import Text from '@src/components/Text'
import { colors, fonts } from '@src/config/theme'

const { height, width } = Dimensions.get('screen')

const SendFeedbackPopup = ({ visible, onClose, title, comment }) => (
  <Modal transparent visible={visible} animationType="none" onRequestClose={onClose}>
    <TouchableWithoutFeedback onPress={onClose}>
      <View style={{ ...StyleSheet.absoluteFill, ...styles.container }}>
        <TouchableWithoutFeedback>
          <View style={styles.content}>
            <Icon
              color={colors.secondary}
              size={40}
              style={styles.icon}
              name="mail"
              type="orfi"
              iconStyle={styles.iconStyle}
            />
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.comment}>{comment}</Text>
            <Button
              title="Got it"
              onPress={onClose}
              containerStyle={styles.buttonContainer}
              buttonStyle={styles.buttonStyle}
              titleStyle={styles.buttonText}
              style={styles.button}
            />
          </View>
        </TouchableWithoutFeedback>
      </View>
    </TouchableWithoutFeedback>
  </Modal>
)
const styles = StyleSheet.create({
  container: {
    backgroundColor: '#1119',
    alignItems: 'center',
    zIndex: 1000,
  },
  content: {
    width: '80%',
    borderRadius: 6,
    padding: 30,
    shadowOffset: { width: 2, height: 2 },
    shadowColor: 'black',
    shadowOpacity: 0.2,
    backgroundColor: colors.white,
    marginTop: height / 5,
    alignItems: 'center',
  },
  icon: {
    marginBottom: 30,
  },
  title: {
    color: colors.secondary,
    fontSize: 14,
    // fontWeight: 'bold',
    fontFamily: fonts.default.bold,
    textAlign: 'center',
  },
  comment: {
    color: colors.secondary,
    fontSize: 10,
    textAlign: 'center',
    marginVertical: 20,
    lineHeight: 17,
  },
  buttonStyle: {
    borderRadius: 23,
    height: 42,
    backgroundColor: colors.secondary,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 0,
    width: (width * 3) / 5,
  },
  buttonText: {
    color: colors.white,
    fontFamily: fonts.default.bold,
    fontSize: 12,
    textAlign: 'center',
  },
  buttonContainer: {
    borderRadius: 23,
    height: 42,
    width: (width * 3) / 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    width: (width * 3) / 5,
  },
  iconStyle: {
    lineHeight: 40,
  },
})

SendFeedbackPopup.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  comment: PropTypes.string,
}

SendFeedbackPopup.defaultProps = {
  title: 'THANK YOU!',
  comment: 'Your feedback has been sent. The Orfi team will review it as soon as possible.',
}

export default SendFeedbackPopup
