import { StyleSheet } from 'react-native'

import { fonts, colors } from '@src/config/theme'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  content: {
    flex: 1,
  },
  icon: {
    opacity: 0.5,
  },
  title: {
    textTransform: 'capitalize',
    fontSize: 14,
    fontFamily: fonts.default.bold,
    paddingBottom: 7,
    paddingTop: 10,
  },
  sectionTitle: {
    paddingBottom: 6,
    marginHorizontal: 15,
    borderBottomWidth: 0.3,
    marginTop: 35,
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  delButton: {
    marginTop: 30,
    opacity: 0.25,
    width: 120,
    marginLeft: 10,
  },
  delButtonTitle: {
    fontSize: 14,
    color: colors.secondary,
  },
  listItemContainer: {
    padding: 20,
  },
  avatarTitleStyle: {
    textTransform: 'uppercase',
  },
  sessions: {
    marginLeft: 20,
    marginTop: 20,
    marginBottom: 10,
    fontSize: 12,
    fontWeight: 'bold',
    color: colors.secondary,
  },
  avatarContainer: {
    width: 50,
    height: 50,
    borderRadius: 50,
    overflow: 'hidden',
  },
  contentStyle: {
    paddingTop: 5,
    paddingBottom: 20,
    borderBottomWidth: 0,
  },
  dividerVw: {
    paddingHorizontal: 14,
    flexDirection: 'row',
    alignItems: 'center',
  },
  divider: {
    flex: 1,
    marginLeft: 16,
    borderBottomColor: colors.grey,
    borderBottomWidth: 0.5,
  },
  requestBtn: {
    width: '70%',
    alignSelf: 'center',
    marginBottom: 30,
  },
  titleView: {
    flexDirection: 'row',
    paddingVertical: 5,
    alignItems: 'center',
  },
  checkInView: {
    flexDirection: 'row',
    paddingVertical: 5,
  },
  checkInText: {
    paddingLeft: 10,
    fontFamily: fonts.default.regular,
  },
  titleContainer: {
    marginBottom: 20,
  },
})

export default styles
