import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginVertical: 10,
  },
  row: {
    flexDirection: 'row',
    flex: 1,
  },
  column: {
    flex: 1,
    flexDirection: 'column',
  },
  labelText: {
    alignSelf: 'flex-start',
    paddingHorizontal: 20,
  },
})

export default styles
