import { Button, Icon, Input } from '@rneui/themed'
import PropTypes from 'prop-types'
import { Keyboard, StyleSheet, useWindowDimensions, View } from 'react-native'

import Text from '@src/components/Text'
import { colors } from '@src/config/theme'

import styles from './PricingForm.styles'

const re = /[^0-9.]+$/

const PricingItem = ({ price, onChange, onDelete, showDelete, errors }) => {
  const { width } = useWindowDimensions()
  return (
    <View style={StyleSheet.flatten([{ width, maxWidth: 700 }, styles.itemContainer])}>
      <View style={styles.priceValueInput}>
        <Input
          keyboardType="decimal-pad"
          returnKeyType="done"
          onSubmitEditing={Keyboard.dismiss}
          value={`${price?.value}`}
          onChangeText={(text) => {
            const newText = text.replace(re, '')
            onChange('value', newText)
          }}
          containerStyle={styles.price}
          inputStyle={styles.priceText}
          leftIconContainerStyle={styles.leftIconContainer}
          leftIcon={<Icon name="currency-gbp" type="material-community" size={14} />}
          errorMessage={errors.value}
        />
      </View>
      <View style={styles.priceDescriptionInput}>
        <Input
          value={price?.description}
          onSubmitEditing={Keyboard.dismiss}
          placeholder="Price desciption"
          containerStyle={styles.desc}
          onChangeText={(text) => onChange('description', text)}
          inputStyle={price?.description === '' ? styles.descText : styles.descTextActive}
          errorMessage={errors.description}
        />
      </View>
      <View style={{ width: showDelete ? '10%' : 0 }}>
        {showDelete && (
          <Button
            onPress={onDelete}
            icon={<Icon name="ios-close" type="ionicon" color={colors.primary} size={34} />}
            type="clear"
          />
        )}
      </View>
    </View>
  )
}

PricingItem.propTypes = {
  price: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  showDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  paymentMethod: PropTypes.string.isRequired,
  errors: PropTypes.object,
}

PricingItem.defaultProps = {
  showDelete: false,
  onDelete: () => {},
  errors: {},
}

const PricingForm = ({
  prices = [],
  onAddPrice,
  onDeleteItem,
  onChangePriceItem,
  errors,
  onFeeInfo,
  paymentMethod,
}) => {
  const { width } = useWindowDimensions()
  return (
    <View style={{ flex: 1, width, maxWidth: 700, alignItems: 'center' }}>
      <View style={StyleSheet.compose([styles.headers, { width, maxWidth: 700 }])}>
        <Text style={styles.title}>PRICE</Text>
        <Button
          title="* Fee Information"
          titleStyle={styles.feeInfo}
          type="clear"
          onPress={onFeeInfo}
        />
      </View>
      <PricingItem
        price={prices[0]}
        onChange={(key, value) => {
          onChangePriceItem({ index: 0, key, value })
        }}
        errors={errors && errors[0]}
        paymentMethod={paymentMethod}
      />
      {prices.map((item, index) =>
        index === 0 ? null : (
          <PricingItem
            price={item}
            onChange={(key, value) => {
              onChangePriceItem({ index, key, value })
            }}
            onDelete={() => onDeleteItem(index)}
            key={index.toString()}
            showDelete
            errors={errors && errors[index]}
            paymentMethod={paymentMethod}
          />
        ),
      )}
      <Button
        title="ADD EXTRA PRICE OPTIONS"
        onPress={onAddPrice}
        type="clear"
        icon={{
          name: 'plus',
          type: 'feather',
          size: 15,
          color: colors.primary,
        }}
        iconRight
        titleStyle={styles.buttonTitleStyle}
        buttonStyle={styles.addMoreBtn}
      />
    </View>
  )
}

PricingForm.propTypes = {
  prices: PropTypes.array.isRequired,
  onAddPrice: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  onChangePriceItem: PropTypes.func.isRequired,
  paymentMethod: PropTypes.string.isRequired,
  errors: PropTypes.object,
}

PricingForm.defaultProps = {
  errors: undefined,
}

export default PricingForm
