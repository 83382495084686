import { FC } from 'react'
import { StyleSheet, View } from 'react-native'

import Text from '@src/components/Text'
import { colors, fonts } from '@src/config/theme'

export type ParagraphPropTypes = {
  title: string
  comment: string
}

const Paragraph: FC<ParagraphPropTypes> = ({ title, comment }) => (
  <View style={styles.container}>
    <Text style={styles.title}>{title}</Text>
    <Text style={styles.content}>{comment}</Text>
  </View>
)

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingVertical: 20,
  },
  title: {
    color: colors.secondary,
    fontSize: 16,
    fontFamily: fonts.default.bold,
    marginBottom: 15,
  },
  content: {
    color: colors.secondary,
    fontSize: 14,
  },
})

export default Paragraph
