import { useNavigation } from '@react-navigation/native'
import { Icon } from '@rneui/themed'
import { groupBy } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { Agenda } from 'react-native-calendars'

import { colors, fonts } from '@src/config/theme'
import { GroupType, SessionType, useMembershipQuery } from '@src/graphql/types'
import { DashboardCalendarScreenNavigationProps } from '@src/types'
import { formatAttendingCount } from '@src/utils/forms'

import styles from './DashboardCalendarScreen.styles'

const calendarTheme = {
  backgroundColor: '#EDF1FA',
  calendarBackground: '#ffffff',
  selectedDayBackgroundColor: colors.primary,
  selectedDayTextColor: '#ffffff',
  todayTextColor: '#2d4150',
  dayTextColor: '#2d4150',
  textDisabledColor: '#d9e1e8',
  dotColor: colors.primary,
  selectedDotColor: '#ffffff',
  arrowColor: 'orange',
  disabledArrowColor: '#d9e1e8',
  textMonthFontFamily: fonts.default.regular,
  textDayFontWeight: '300',
  textMonthFontWeight: 'bold',
  textDayHeaderFontWeight: '300',
  agendaTodayColor: '#7a92a5',
}

const DashboardCalendarScreen = ({ group, data }: { group: GroupType; data: SessionType[] }) => {
  const navigation = useNavigation<DashboardCalendarScreenNavigationProps>()
  const [items, setItems] = useState({})
  const [date, setDate] = useState(null)

  useEffect(() => {
    if (data && date) {
      setItems({})
      loadItems(date)
    }
  }, [data])

  const { data: { membership } = {} } = useMembershipQuery({ variables: { sessionId: 24560 } })

  const loadItems = (day) => {
    const groups = groupBy(data, (d) => moment(d.startAt).format('YYYY-MM-DD'))
    const dataGroup = {}
    Object.keys(groups).forEach((i) => {
      dataGroup[i] = groups[i].map((session) => ({ ...session, height: 120 }))
    })
    setTimeout(() => {
      for (let i = -15; i < 85; i++) {
        const time = day.timestamp + i * 24 * 60 * 60 * 1000
        const strTime = timeToString(time)
        if (!dataGroup[strTime]) {
          dataGroup[strTime] = []
        }
      }
      setItems(dataGroup)
      setDate(day)
    }, 1000)
  }

  const formatTitle = ({ startAt, endAt }: { startAt: Date; endAt: Date }) => {
    const endTime = moment(endAt).format('HH:mm')
    const startTime = moment(startAt).format('HH:mm')
    return `${startTime} ${endAt ? `- ${endTime}` : ''}`
  }

  const formatSubtitle = (maxParticipants, { isUserAttending, usersAttendingCount }) => (
    <View style={styles.participanztsVw}>
      <Text style={styles.participants}>
        Participants {formatAttendingCount(usersAttendingCount, maxParticipants, false)}
      </Text>
      <View style={styles.subtitleSpacer} />
      {isUserAttending ? (
        <View style={styles.attendingVw}>
          <Icon name="check" size={14} color={colors.primary} iconStyle={styles.attendingIcon} />
          <Text style={styles.attending}>You're attending</Text>
        </View>
      ) : null}
    </View>
  )

  const onPressSession = (item: SessionType) => {
    navigation.push('SessionDetailScreen', { sessionId: item.id, name: item.name })
  }

  const renderItem = (item: SessionType) => (
    <View style={[styles.item, { minHeight: item.height }]}>
      <TouchableOpacity
        onPress={() => onPressSession(item)}
        style={{ width: !group.isAdmin ? '100%' : '70%' }}>
        <Text style={styles.title}>{formatTitle(item)}</Text>
        <Text style={styles.name}>{item.name}</Text>
        {formatSubtitle(item?.maxParticipants, item)}
      </TouchableOpacity>
      <View style={styles.duplicateContainer}>
        {group.isAdmin ? (
          <TouchableOpacity
            onPress={() => navigation.navigate('DuplicateSessionScreen', { sessionId: item.id })}>
            <Icon
              name="copy"
              type="feather"
              color={colors.grey5}
              size={28}
              iconStyle={styles.iconStyle}
            />
            <Text style={styles.duplicateText}>Duplicate</Text>
          </TouchableOpacity>
        ) : null}
      </View>
      {item?.status.toLowerCase() === 'cancelled' ? (
        <View style={styles.cancelledVw}>
          <Text style={styles.cancelledText}>SESSION CANCELLED</Text>
        </View>
      ) : null}
    </View>
  )

  const renderEmptyDate = () => <View style={styles.emptyDate} />

  const rowHasChanged = (r1, r2) => r1.name !== r2.name

  const timeToString = (time: Date) => {
    const newDate = new Date(time)
    return newDate.toISOString().split('T')[0]
  }

  return (
    <Agenda
      items={items}
      loadItemsForMonth={loadItems}
      renderItem={renderItem}
      renderEmptyDate={renderEmptyDate}
      rowHasChanged={rowHasChanged}
      theme={calendarTheme}
    />
  )
}

export default DashboardCalendarScreen
