import { StyleSheet } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  content: {
    flex: 1,
  },
  icon: {
    opacity: 0.5,
  },
  title: {
    textTransform: 'capitalize',
    fontSize: 16,
    fontFamily: fonts.default.bold,
    marginBottom: 10,
  },
  sectionTitle: {
    paddingBottom: 6,
    marginHorizontal: 15,
    borderBottomWidth: 0.3,
    marginTop: 35,
    marginBottom: 10,
  },
  delButton: {
    marginTop: 30,
    opacity: 0.25,
    width: 120,
    marginLeft: 10,
  },
  delButtonTitle: {
    fontSize: 14,
    color: colors.secondary,
  },
  listItemContainer: {
    padding: 20,
  },
  avatarTitleStyle: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  ownerStyle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.primary,
  },
  sessions: {
    marginLeft: 20,
    marginTop: 20,
    marginBottom: 10,
    fontSize: 12,
    fontWeight: 'bold',
    color: colors.secondary,
  },
  divider: {
    marginLeft: 20,
  },
  bottomView: {
    position: 'absolute',
    right: 0,
    left: 0,
    paddingTop: 10,
    paddingBottom: 20,
    bottom: 0,
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  bottomButtonContainer: {
    width: '70%',
    borderRadius: 60,
  },
  searchBoxContainer: {
    paddingVertical: 0,
    backgroundColor: '#F4F4F4',
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },
  searchBoxInputContainer: {
    backgroundColor: '#F4F4F4',
  },
  listHeaderContainer: {
    paddingTop: 45,
    paddingBottom: 10,
  },
  listHeaderText: {
    textAlign: 'center',
  },
  listItemsStyle: {
    width: 70,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 30,
  },
  searchBarStyle: {
    fontFamily: fonts.default.italic,
    color: colors.secondary,
  },
})

export default styles
