import { View, Text } from 'react-native'

import { itemStyles } from './ConfirmationScreen.styles'

const ConfirmationList = ({ title, comment }: { title: string; comment: string }) => (
  <View style={itemStyles.container}>
    <Text style={itemStyles.comment}>{comment}</Text>
    <Text style={itemStyles.content}>{title}</Text>
  </View>
)

export default ConfirmationList
