import { StyleSheet } from 'react-native'

import { colors } from '@src/config/theme'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  leftButtonContainer: {
    flex: 1,
    paddingRight: 8,
  },
  rightButtonContainer: {
    flex: 1,
    paddingLeft: 8,
  },
  leftButtonStyle: {
    backgroundColor: colors.secondary,
  },
})

export default styles
