import * as Yup from 'yup'

import { validName } from '@src/utils/validation'

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .matches(validName, 'Special or accented characters are not supported')
    .required('First name is required'),
  avatar: Yup.string(),
  email: Yup.string().email("This doesn't look like an email").required('Email is required'),
  surname: Yup.string()
    .trim()
    .matches(validName, 'Special or accented characters are not supported')
    .required('Last name is required'),
  newPassword: Yup.string().min(8).nullable(),
})

export default validationSchema
