import { StyleSheet } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const styles = StyleSheet.create({
  text: {
    fontSize: 12,
    color: colors.secondary,
    fontFamily: fonts.default.bold,
    paddingTop: 10,
    paddingBottom: 5,
  },
  placeholder: {
    fontSize: 12,
    color: colors.secondary,
    fontFamily: fonts.default.bold,
  },
  iconContainer: {
    top: 12,
    right: 8,
  },
})

export const pageStyle = StyleSheet.create({
  inputIOS: {
    fontSize: 15,
    height: 46,
    width: '100%',
    marginBottom: 8,
    borderWidth: 0,
    borderBottomWidth: 1,
    borderBottomColor: colors.greyOutline,
    borderRadius: 8,
    color: colors.secondary,
    fontFamily: fonts.default.regular,
    paddingLeft: 8,
    paddingRight: 30,
  },
  inputAndroid: {
    fontSize: 15,
    height: 46,
    width: '100%',
    marginBottom: 8,
    borderWidth: 0,
    borderBottomWidth: 1,
    borderBottomColor: colors.greyOutline,
    borderRadius: 8,
    color: colors.secondary,
    fontFamily: fonts.default.regular,
    paddingLeft: 8,
    paddingRight: 30,
  },
})

export default styles
