import MomentUtils from '@date-io/moment'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { useNavigation, useRoute } from '@react-navigation/native'
import { Button, Divider, Icon, Input, Overlay } from '@rneui/themed'
import * as ImagePicker from 'expo-image-picker'
import * as MediaLibrary from 'expo-media-library'
import { useFormik } from 'formik'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useEffect, useLayoutEffect, useState, useCallback } from 'react'
import { BackHandler, ImageBackground, Keyboard, TouchableOpacity, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import { DEFAULT_EVENT_IMAGE } from '@src/assets/images'
import ConfirmDialog from '@src/components/ConfirmDialog'
import DualButton from '@src/components/DualButton'
import EmptyNotice from '@src/components/EmptyNotice'
import { showSuccessMessage, showErrorMessage } from '@src/components/FlashMessage'
import InputPicker from '@src/components/forms/InputPicker'
import HeaderIcon from '@src/components/HeaderIcon'
import Loading from '@src/components/Loading'
import NoWalletAccountPopup from '@src/components/PopupModal/NoWalletAccount'
import Text from '@src/components/Text'
import {
  defaultPayMethod,
  pickerGender,
  pickerPrivacy,
  pickerSkillLevel,
  pickerVenue,
} from '@src/config/forms'
import { colors, fonts } from '@src/config/theme'
import { GET_SESSION_QUERY } from '@src/graphql/queries'
import {
  useGroupQuery,
  useMeQuery,
  useSessionQuery,
  useUpdateSessionMutation,
  useUpdateSessionWithImageMutation,
} from '@src/graphql/types'
import { EditBulkSessionScreenNavigationProps, EditBulkSessionScreenRouteProps } from '@src/types'
import generateRNFile from '@src/utils/generateRNFile'

import useStyle from './EditBulkSessionScreen.styles'
import validationSchema from './EditBulkSessionScreen.validation'
import { EditBulkSessionScreenFormTypes } from './types.d'
const EditBulkSessionScreen = () => {
  const navigation = useNavigation<EditBulkSessionScreenNavigationProps>()
  const { params } = useRoute<EditBulkSessionScreenRouteProps>()
  const [isSessionStartDatePickerVisible, setIsSessionStartDatePickerVisible] = useState(false)
  const [isSessionEndDatePickerVisible, setIsSessionEndDatePickerVisible] = useState(false)
  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState(false)
  const [imgDialog, setImgDialog] = useState(false)
  const [noWalletAccount, setNoWalletAccount] = useState(false)
  const styles = useStyle()

  const {
    data: {
      session = {
        name: '',
        activity: [
          {
            name: '',
            id: 0,
          },
        ],
        image: '',
        gender: '',
        skillLevel: '',
        paymentMethod: '',
        minAge: '',
        maxAge: '',
        minParticipants: '',
        maxParticipants: '',
        privacy: '',
        notes: '',
        description: '',
        venueType: '',
        address: '',
        addressLatitude: 0,
        addressLongitude: 0,
        endAt: '',
        startAt: '',
        group: {
          isOwner: false,
        },
        isPrivate: true,
      },
    } = {},
    loading: sessionLoading,
    error,
  } = useSessionQuery({
    variables: {
      id: params?.sessionIds[0]?.id,
    },
  })

  const checkPaymentMethod = (values: EditBulkSessionScreenFormTypes) => {
    if (!session?.group?.isOwner) {
      submitEdit()
      return
    }
    const { paymentMethod } = values
    const isFree = session?.prices.length === 1 && session?.prices[0]?.value === '0'
    if (!meQuery?.me?.accountNumber && paymentMethod !== defaultPayMethod && !isFree) {
      toggleWalletAccountDialog()
    } else {
      const newValues = { ...values, paymentMethod: isFree ? defaultPayMethod : paymentMethod }
      submitEdit(newValues)
    }
  }

  const { values, handleChange, setFieldTouched, errors, touched, handleSubmit, setFieldValue } =
    useFormik({
      enableReinitialize: true,
      validationSchema,
      initialValues: {
        name: session?.name ? session?.name : '',
        activity: {
          name: session?.activity[0]?.name ? session?.activity[0]?.name : '',
          id: session?.activity[0]?.id ? session?.activity[0]?.id : 0,
        },
        image: session?.image ? session?.image : '',
        gender: session?.gender,
        skillLevel: session?.skillLevel,
        paymentMethod: session?.paymentMethod,
        minAge: session?.minAge ? session.minAge : 0,
        maxAge: session?.maxAge ? session.maxAge : 0,
        minParticipants: session?.minParticipants ? session?.minParticipants : 0,
        maxParticipants: session?.maxParticipants ? session?.maxParticipants : 0,
        privacy: session?.isPrivate ? 'PRIVATE' : 'PUBLIC',
        notes: session?.notes ? session?.notes : '',
        description: session?.description ? session?.description : '',
        venueType: session?.venueType,
        venueAddress: {
          address: session?.address ? session?.address : '',
          latitude: session?.addressLatitude ? session?.addressLatitude : 0,
          longitude: session?.addressLongitude ? session?.addressLongitude : 0,
        },
        sessionEndDate: session?.endAt ? session?.endAt : undefined,
        //sessionStartDate: session.startAt,
      },
      onSubmit: checkPaymentMethod,
    })

  const { data: meQuery } = useMeQuery()
  const { refetch: refetchGroup } = useGroupQuery({
    variables: {
      groupUuid: params?.groupUuid,
    },
  })

  const [sessionMutation, { loading: sessionUpdateLoading }] = useUpdateSessionWithImageMutation({
    onCompleted: async () => {},
  })

  const [sessionWithoutImageMutation, { loading: sessionWithoutImageUpdateLoading }] =
    useUpdateSessionMutation({
      onCompleted: async () => {},
    })

  const checkInputStates = useCallback(() => {
    if (Object.keys(touched).length) {
      setIsConfirmationDialogVisible(true)
    } else {
      navigation.goBack()
    }
  }, [navigation, touched])

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <HeaderIcon onPress={() => checkInputStates()} />,
    })
  }, [checkInputStates, navigation])

  useEffect(() => {
    const backHandler = BackHandler.addEventListener('hardwareBackPress', () => checkInputStates())
    return () => backHandler.remove()
  })

  useEffect(() => {
    if (params?.activity) {
      const {
        activity: { name, id },
      } = params
      setFieldTouched('activity')
      setFieldValue('activity', { name, id })
    }
    if (params?.venueAddress) {
      const { venueAddress } = params
      setFieldValue('venueAddress', venueAddress)
      setFieldTouched('venueAddress')
    }
    if (params?.accountAdded) {
      submitEdit()
    }
  }, [params, setFieldTouched, setFieldValue])

  const handleSessionStartDatePicker = (date: string) => {
    toggleSessionStartDatePicker()
    setTimeout(() => setFieldValue('sessionStartDate', date), 150)
  }

  const toggleSessionStartDatePicker = () => {
    setIsSessionStartDatePickerVisible(!isSessionStartDatePickerVisible)
  }

  const handleSessionEndDatePicker = (date: string) => {
    toggleSessionEndDatePicker()
    setTimeout(() => setFieldValue('sessionEndDate', date), 150)
  }

  const toggleSessionEndDatePicker = () => {
    setIsSessionEndDatePickerVisible(!isSessionEndDatePickerVisible)
  }

  const submitEdit = async () => {
    const editMutation = values.newImage ? sessionMutation : sessionWithoutImageMutation
    const sessions = params?.sessionIds

    const timeDiffStart = moment(values.sessionStartDate).diff(
      moment(sessions[0]?.startAt),
      'minutes',
    )
    const timeDiffEnd = moment(values.sessionEndDate).diff(moment(sessions[0].endAt), 'minutes')

    const editedSessions = params.sessionIds

    const valuesMap = sessions.map((s) => {
      const newStart = moment(s.startAt).add(timeDiffStart, 'minutes')
      const newEnd = moment(s.endAt).add(timeDiffEnd, 'minutes')

      const variables = {
        ...values,

        id: s.id,
        activityId: values.activity.id,
        isPrivate: values.privacy === 'PRIVATE',
        minAge: values.minAge ? parseInt(values.minAge, 10) : null,
        maxAge: values.maxAge ? parseInt(values.maxAge, 10) : null,
        minParticipants: values.minParticipants ? parseInt(values.minParticipants, 10) : null,
        maxParticipants: values.maxParticipants ? parseInt(values.maxParticipants, 10) : null,
        address: values.venueAddress.address,
        addressLatitude: values.venueAddress.latitude,
        addressLongitude: values.venueAddress.longitude,
        endAt: newEnd.toISOString(),
        startAt: newStart.toISOString(),
      }
      if (values.newImage) {
        variables.image = values.newImage
      }

      return editMutation({
        variables,
        options: {
          refetchQueries: [
            {
              query: GET_SESSION_QUERY,
              variables: {
                id: s.id,
              },
            },
          ],
        },
      })
    })

    try {
      await Promise.all(valuesMap)
      await refetchGroup()

      showSuccessMessage({
        message: 'Saved',
        description: `Successfully edited ${editedSessions.toString().length} sessions.`,
      })
      navigation.navigate('DashboardGroupScreen', {
        groupUuid: params.groupUuid,
      })
    } catch (e) {
      showErrorMessage({
        message: 'Warning',
        description: `${e.message}`,
      })
    }
  }

  const goToSelectActivity = () => {
    navigation.navigate('SelectActivityScreen', { backRoute: 'EditBulkSessionScreen' })
  }

  const pickImage = async () => {
    const { status } = await MediaLibrary.requestPermissionsAsync()
    if (status !== 'granted') {
      // eslint-disable-next-line no-alert
      alert('Sorry, we need camera roll permissions to make this work!')
    } else {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
      })

      if (!result.canceled) {
        const file = generateRNFile(result.assets[0].uri, 'eventPicture')
        handleChange(file)
        setFieldTouched('newImage')
      }
    }
  }

  const handlePickerValue = (field: string, value: string) => {
    setFieldValue(field, value)
    setFieldTouched(field)
  }

  const onSelectVenueAddress = () => {
    navigation.navigate('SelectVenueScreen', {
      backRoute: 'EditBulkSessionScreen',
    })
  }

  const toggleWalletAccountDialog = () => {
    setNoWalletAccount(!noWalletAccount)
  }

  const onSetupAccount = () => {
    navigation.navigate('MyWalletScreen', { backRoute: 'EditBulkSessionScreen' })
  }

  const paymentMethods = [
    { label: 'In-app Only', value: 'APP' },
    { label: 'Cash Only', value: 'SITE' },
    { label: 'In-app + Cash', value: 'ALL' },
  ]

  if (sessionLoading) return <Loading />
  if (error) {
    return (
      <EmptyNotice
        title="NOTHING TO SHOW"
        text="Seems like this session has been deleted or cancelled."
      />
    )
  }

  const imageSrc: string = values.newImage ? values.newImage.uri : values.image
  const minimumDate = new Date(values.sessionStartDate) || new Date()
  const loading = sessionUpdateLoading || sessionWithoutImageUpdateLoading

  return (
    <>
      <KeyboardAwareScrollView style={styles.scrollVW}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ImageBackground
            style={styles.imageBackground}
            source={imageSrc ? { uri: imageSrc } : DEFAULT_EVENT_IMAGE}>
            <TouchableOpacity onPress={() => (values.newImage ? setImgDialog(true) : pickImage())}>
              <Icon
                size={40}
                type="feather"
                name="camera"
                style={styles.imageBackgroundIcon}
                color="#FFFFFF"
                iconStyle={styles.imageBackgroundIconStyling}
              />
              <Text style={styles.imageBackgroundText}>Add Session Image</Text>
            </TouchableOpacity>
          </ImageBackground>
          <View style={styles.inputContainer}>
            <Input
              label="NAME"
              onSubmitEditing={Keyboard.dismiss}
              placeholder={'e.g "Saturday Morning tennis practice"'}
              placeholderTextColor={colors.greyOutline}
              value={values.name}
              onFocus={() => setFieldTouched('name')}
              onChangeText={handleChange('name')}
              errorMessage={touched.name && errors.name ? errors.name : undefined}
              editable={!loading}
            />

            <TouchableOpacity style={styles.inputTouchableOpacity} onPress={goToSelectActivity}>
              <Input
                label="ACTIVITY"
                placeholder="Select..."
                placeholderTextColor={colors.greyOutline}
                value={values.activity.name}
                onFocus={() => setFieldTouched('activity')}
                onChangeText={handleChange('activity')}
                errorMessage={
                  touched.activity && errors.activity ? 'Activity is required' : undefined
                }
                editable={false}
                pointerEvents="none"
                rightIcon={{
                  type: 'material-community',
                  name: 'chevron-right',
                }}
                rightIconContainerStyle={styles.rightIcon}
              />
            </TouchableOpacity>

            <TouchableOpacity
              style={styles.locationPressable}
              onPress={() => onSelectVenueAddress()}>
              <Input
                label="LOCATION"
                errorMessage={
                  touched.venueAddress && errors.venueAddress ? 'Location is required' : undefined
                }
                leftIcon={
                  <Icon name="magnifying-glass" type="entypo" size={16} color={colors.black} />
                }
                placeholder="Try ‘Manchester M3 3EE’"
                placeholderTextColor={colors.greyOutline}
                value={values.venueAddress.address}
                editable={false}
                pointerEvents="none"
                leftIconContainerStyle={styles.locationIconContainer}
              />
            </TouchableOpacity>

            {session?.group?.isOwner ? (
              <View style={styles.paymentContainer}>
                <Text style={styles.paymentText}>ACCEPTED PAYMENT METHOD</Text>
                <View style={styles.paymentInnerContainer}>
                  {paymentMethods.map((method) => {
                    const selected = values.paymentMethod === method.value
                    return (
                      <Button
                        key={method.label}
                        title={method.label}
                        titleStyle={{
                          color: selected ? colors.white : colors.secondary,
                          fontFamily: selected ? fonts.default.bold : fonts.default.regular,
                        }}
                        buttonStyle={styles.paymentButton}
                        onPress={() => setFieldValue('paymentMethod', method.value)}
                      />
                    )
                  })}
                </View>
              </View>
            ) : null}

            <DateTimePicker
              value={values.sessionStartDate}
              disablePast
              onAccept={handleSessionStartDatePicker}
              onChange={() => handleChange('sessionStartDate')}
              label="First session starts"
              onError={console.log}
              aria-errormessage={
                touched.sessionStartDate && errors.sessionStartDate ? errors.sessionStartDate : ''
              }
              format="dddd, DD MMM, H:mm"
              style={styles.keyboardDatePickerStyle}
            />

            <DateTimePicker
              value={values.sessionEndDate || values.sessionStartDate}
              disablePast
              onAccept={handleSessionEndDatePicker}
              onChange={() => handleChange('sessionEndDate')}
              label="First session ends"
              onError={console.log}
              minDate={minimumDate}
              aria-errormessage={
                touched.sessionEndDate && errors.sessionEndDate ? errors.sessionEndDate : ''
              }
              format="dddd, DD MMM, H:mm"
              style={styles.keyboardDatePickerStyle}
            />

            <Text style={styles.title}>AGE RANGE</Text>
            <View style={styles.row}>
              <View style={styles.column}>
                <Input
                  keyboardType="numeric"
                  returnKeyType="done"
                  onSubmitEditing={Keyboard.dismiss}
                  placeholder="Minimum Age"
                  placeholderTextColor={colors.greyOutline}
                  value={values.minAge}
                  onFocus={() => setFieldTouched('minAge')}
                  onChangeText={handleChange('minAge')}
                  errorMessage={touched.minAge && errors.minAge ? errors.minAge : undefined}
                  editable={!loading}
                />
              </View>
              <View style={styles.column}>
                <Input
                  keyboardType="numeric"
                  returnKeyType="done"
                  onSubmitEditing={Keyboard.dismiss}
                  placeholder="Maximum Age"
                  placeholderTextColor={colors.greyOutline}
                  value={values.maxAge}
                  onFocus={() => setFieldTouched('maxAge')}
                  onChangeText={handleChange('maxAge')}
                  errorMessage={touched.maxAge && errors.maxAge ? errors.maxAge : undefined}
                  editable={!loading}
                />
              </View>
            </View>

            <Text style={styles.title}>PARTICIPANTS NEEDED</Text>
            <View style={styles.row}>
              <View style={styles.column}>
                <Input
                  keyboardType="numeric"
                  returnKeyType="done"
                  onSubmitEditing={Keyboard.dismiss}
                  placeholder="Minimum"
                  placeholderTextColor={colors.greyOutline}
                  value={values.minParticipants}
                  onFocus={() => setFieldTouched('minParticipants')}
                  onChangeText={handleChange('minParticipants')}
                  errorMessage={
                    touched.minParticipants && errors.minParticipants
                      ? errors.minParticipants
                      : undefined
                  }
                  editable={!loading}
                />
              </View>

              <View style={styles.column}>
                <Input
                  keyboardType="numeric"
                  returnKeyType="done"
                  onSubmitEditing={Keyboard.dismiss}
                  placeholder="Maximum"
                  placeholderTextColor={colors.greyOutline}
                  value={values.maxParticipants}
                  onFocus={() => setFieldTouched('maxParticipants')}
                  onChangeText={handleChange('maxParticipants')}
                  errorMessage={
                    touched.maxParticipants && errors.maxParticipants
                      ? errors.maxParticipants
                      : undefined
                  }
                  editable={!loading}
                />
              </View>
            </View>

            <View style={styles.inputContainer}>
              <InputPicker
                label="GENDER"
                placeholder="Group gender restrictions"
                value={values.gender}
                items={pickerGender}
                errorMessage={touched.gender && errors.gender ? errors.gender : undefined}
                editable={!loading}
                onValueChange={(value: string) => setFieldValue('gender', value)}
              />
              <InputPicker
                label="SKILL LEVEL"
                value={values.skillLevel}
                items={pickerSkillLevel}
                errorMessage={
                  touched.skillLevel && errors.skillLevel ? errors.skillLevel : undefined
                }
                editable={!loading}
                onValueChange={(value: string) => setFieldValue('skillLevel', value)}
              />

              <InputPicker
                label="PRIVACY"
                value={values.privacy}
                items={pickerPrivacy}
                errorMessage={touched.privacy && errors.privacy ? errors.privacy : undefined}
                editable={!loading}
                onValueChange={(value: string) => setFieldValue('privacy', value)}
              />

              <InputPicker
                label="VENUE TYPE"
                value={values.venueType}
                items={pickerVenue}
                errorMessage={touched.venueType && errors.venueType ? errors.venueType : undefined}
                editable={!loading}
                onValueChange={(value: string) => handlePickerValue('venueType', value)}
              />
            </View>

            <Input
              label="DESCRIBE YOUR EVENT"
              value={values.description}
              placeholder="Type here..."
              placeholderTextColor={colors.greyOutline}
              onChangeText={handleChange('description')}
              inputContainerStyle={styles.inputContainerStyle}
              inputStyle={styles.inputStyle}
              onFocus={() => setFieldTouched('description')}
              multiline
              errorMessage={
                touched.description && errors.description ? errors.description : undefined
              }
            />

            <Input
              label="RULES / NOTES (OPTIONAL)"
              value={values.notes}
              placeholder="Type here..."
              placeholderTextColor={colors.greyOutline}
              onChangeText={handleChange('notes')}
              containerStyle={styles.inputContainer}
              inputContainerStyle={styles.inputContainerStyle}
              inputStyle={styles.inputStyle}
              onFocus={() => setFieldTouched('notes')}
              multiline
              errorMessage={touched.notes && errors.notes ? errors.notes : undefined}
            />
          </View>
        </MuiPickersUtilsProvider>
      </KeyboardAwareScrollView>

      <View style={styles.buttonContainer}>
        <DualButton
          leftTitle="Cancel"
          rightTitle="Submit"
          leftOnPress={() => checkInputStates()}
          rightOnPress={() => handleSubmit()}
          disabled={loading}
          loading={loading}
        />
      </View>

      <NoWalletAccountPopup
        visible={noWalletAccount}
        toggle={toggleWalletAccountDialog}
        onSetUpAccount={() => onSetupAccount()}
      />

      <Overlay
        isVisible={imgDialog}
        onBackdropPress={() => setImgDialog(false)}
        overlayStyle={styles.overlayStyle}>
        <View style={styles.overlayContainer}>
          <Button
            title="Remove"
            titleStyle={styles.removeButton}
            type="clear"
            onPress={() => {
              setImgDialog(false)
              //setFieldValue('newImage', '')
            }}
          />
          <Divider style={styles.divider} />
          <Button
            title="Add Image"
            titleStyle={styles.addButton}
            type="clear"
            onPress={() => {
              setImgDialog(false)
              //pickImage
            }}
          />
        </View>
      </Overlay>
      <ConfirmDialog
        title="Careful..."
        message="If you exit this page your updates will be lost."
        negativeButton={{
          title: 'Cancel',
          onPress: () => setIsConfirmationDialogVisible(false),
        }}
        positiveButton={{
          title: 'Exit',
          onPress: () => {
            setIsConfirmationDialogVisible(false)
            navigation.goBack()
          },
        }}
        visible={isConfirmationDialogVisible}
      />
    </>
  )
}

EditBulkSessionScreen.propTypes = {
  route: PropTypes.object.isRequired,
}

export default EditBulkSessionScreen
