import { StyleSheet } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const styles = StyleSheet.create({
  headers: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  title: {
    fontFamily: fonts.default.bold,
    fontSize: 12,
    color: colors.secondary,
    paddingHorizontal: 20,
  },
  feeInfo: {
    textDecorationLine: 'underline',
    fontFamily: fonts.default.regular,
    paddingHorizontal: 10,
  },
  itemContainer: {
    flexDirection: 'row',
    paddingHorizontal: 5,
  },
  price: {
    justifyContent: 'center',
    marginVertical: 0,
    padding: 0,
  },
  leftIconContainer: {
    marginVertical: 0,
  },
  priceText: {
    fontSize: 16,
    fontFamily: fonts.default.bold,
    color: colors.secondary,
    marginLeft: 0,
  },
  asterisk: { color: colors.primary },
  desc: {
    justifyContent: 'center',
    marginLeft: 5,
    marginVertical: 0,
  },
  descText: {
    fontSize: 14,
    color: colors.secondary,
    opacity: 0.5,
    fontStyle: 'italic',
    marginLeft: 3,
  },
  descTextActive: {
    fontSize: 14,
    color: colors.secondary,
    marginLeft: 3,
  },
  addIcon: {
    marginVertical: 20,
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  error: {
    margin: 5,
    fontSize: 12,
    color: colors.error,
  },
  buttonContainer: {
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  addMoreBtn: {
    paddingLeft: 20,
    marginTop: 20,
  },
  priceValueInput: {
    width: '20%',
  },
  priceDescriptionInput: {
    width: '70%',
  },
  buttonTitleStyle: {
    textDecorationLine: 'underline',
  },
})

export default styles
