import React, { FC } from 'react'
import { View } from 'react-native'

import Text from '@src/components/Text'

import styles from './MultiSelectField.styles'
import SelectButton from './SelectButton'
import { MultiSelectFieldPropsType } from './types.d'

const MultiSelectField: FC<MultiSelectFieldPropsType> = ({
  data,
  onPress,
  value = '',
  values = [],
  label = 'TYPE_BUTTON',
  customStyle = undefined,
  returnItem = false,
  labelStyle,
}) => (
  <View style={styles.container}>
    {label && (
      <Text label style={labelStyle ? labelStyle : styles.labelText}>
        {label}
      </Text>
    )}

    <View style={[styles.row, customStyle]}>
      {data.map((optionRow: { key: string; label: string; value: string }) => (
        <SelectButton
          key={optionRow.key}
          title={optionRow.label}
          onPress={() => onPress(returnItem ? optionRow : optionRow.value)}
          selected={optionRow.value === value || values.includes(optionRow.value)}
        />
      ))}
    </View>
  </View>
)

export default MultiSelectField
