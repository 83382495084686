import { gql } from '@apollo/client'

export const Session = {
  fragments: {
    common: gql`
      fragment Session on SessionType {
        id
        uuid
        createdAt
        name
        image
        startAt
        endAt
        status
        membership {
          id
          uuid
          attendance
          status
          createdAt
          user {
            uuid
            id
            name
            surname
            avatar
          }
          price {
            id
            uuid
            value
            description
          }
          paymentMethod
        }
        membershipAttending {
          id
          uuid
          attendance
          status
          createdAt
          user {
            id
            name
            surname
            avatar
          }
          paymentMethod
        }
        owner {
          id
          name
          surname
          avatar
        }
        activity {
          id
          name
          isFeatured
          icon
          defaultBannerImage
        }
        usersAttendingCount
        isUserAttending
        isJoinable
        gender
        skillLevel
        venueType
        minAge
        maxAge
        minParticipants
        maxParticipants
        address
        addressLatitude
        addressLongitude
        description
        cancellationReason
        rules
        notes
        isPrivate
        paymentMethod
        isOwner
        links {
          id
          name
          url
        }
        prices {
          id
          uuid
          description
          value
        }
        group {
          id
          uuid
          isPrivate
          isMember
          isOwner
          isAdmin
          sessions {
            id
            startAt
            status
            name
            endAt
          }
        }
      }
    `,
  },
}

export const User = {
  fragments: {
    me: gql`
      fragment MeUser on MeType {
        id
        uuid
        name
        surname
        email
        dateJoined
        organisedEventsCount
        attendingSessionCount
        avatar
        defaultAddress
        defaultAddressLatitude
        defaultAddressLongitude
        useDefaultAddress
        accountName
        accountNumber
        accountSortCode
        passwordResetCode
        verificationCode
        isEmailVerified
        groupuserSet {
          id
          uuid
          group {
            id
            uuid
            name
            image
            isActive
            isPrivate
            isOwner
            isMember
            isAdmin
            hasRequested
            sessions {
              id
              startAt
              status
              paymentMethod
            }
            activities {
              id
              name
            }
            userCount
            groupUsers {
              id
            }
          }
        }
      }
    `,
    common: gql`
      fragment User on UserType {
        id
        uuid
        name
        surname
        dateJoined
        organisedEventsCount
        attendingSessionCount
        avatar
        groupuserSet {
          id
          uuid
          group {
            id
            uuid
            name
            image
            isAdmin
            activities {
              id
              name
            }
          }
        }
      }
    `,
  },
}

export const Group = {
  fragments: {
    common: gql`
      fragment Group on GroupType {
        id
        uuid
        createdAt
        updatedAt
        name
        image
        isOwner
        isAdmin
        isActive
        isMember
        isPrivate
        description
        groupUsers {
          id
          isAdmin
          createdAt
          user {
            id
            name
            surname
            avatar
            email
          }
        }
        groupRequests {
          uuid
          createdAt
          updatedAt
          id
        }
        sessions {
          id
          uuid
          name
          image
          address
          status
          isUserAttending
          startAt
          endAt
          maxParticipants
          usersAttendingCount
          activity {
            id
            defaultBannerImage
            name
          }
          owner {
            id
            name
          }
          membership {
            id
            status
          }
        }
        activities {
          id
          name
        }
        userCount
        hasRequested
      }
    `,
  },
}

export const WallPost = {
  fragments: {
    common: gql`
      fragment WallPost on WallPostType {
        uuid
        content
        reactionCount
        postCount
        userHasLiked
        createdAt
        updatedAt
        isAdmin
      }
    `,
  },
}

export const Wall = {
  fragments: {
    common: gql`
      fragment Wall on WallType {
        uuid
        group {
          uuid
          isAdmin
          isOwner
        }
      }
    `,
  },
}
