import { useNavigation, useRoute } from '@react-navigation/native'
import { Button, Divider, Image } from '@rneui/themed'
import moment from 'moment'
import React, { useLayoutEffect } from 'react'
import { SafeAreaView, ScrollView, View } from 'react-native'

import { GROUP_FALLBACK } from '@src/assets/images'
import EmptyNotice from '@src/components/EmptyNotice'
import OrfiListItem from '@src/components/ListItem/OrfiListItem'
import Loading from '@src/components/Loading'
import Text from '@src/components/Text'
import { useGroupQuery } from '@src/graphql/types'
import { DashboardAboutScreenNavigationProps, DashboardAboutScreenRouteProps } from '@src/types'
import getInitials from '@src/utils/getInitials'

import styles from './DashboardAboutScreen.styles'

const DashboardAboutScreen = () => {
  const navigation = useNavigation<DashboardAboutScreenNavigationProps>()
  const { params } = useRoute<DashboardAboutScreenRouteProps>()

  const {
    loading,
    data: {
      group = {
        uuid: '',
        name: '',
        description: '',
        image: '',
        isAdmin: false,
        groupUsers: { user: { id: 0, avatar: '', name: '', surname: '' } },
      },
    } = {},
    error,
  } = useGroupQuery({
    variables: {
      groupUuid: params?.groupUuid,
    },
  })

  useLayoutEffect(() => {
    if (group) {
      navigation.setOptions({
        headerRight: () =>
          group?.isAdmin ? (
            <Button
              title="EDIT"
              onPress={() => navigation.navigate('EditGroupScreen', { groupUuid: group.uuid })}
              type="clear"
            />
          ) : (
            <Button
              title="+ INVITE"
              onPress={() =>
                navigation.navigate('DashboardInviteMemberScreen', {
                  groupUuid: group.uuid,
                  groupName: group.name,
                })
              }
              type="clear"
            />
          ),
      })
    }
  }, [group, navigation])

  if (loading) {
    return <Loading />
  }

  if (error) {
    return (
      <EmptyNotice title="NOTHING TO SHOW" text="Seems like there are no members for this group." />
    )
  }

  const onMemberPress = (user: { id: number }) => {
    navigation.navigate('PublicProfileScreen', { userId: user.id })
  }

  const admins = group?.groupUsers
    .reduce((prev, curr) => [curr, ...prev], [])
    .filter((grp) => grp.isAdmin)

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView contentContainerStyle={styles.content}>
        <View style={styles.groupTextContainer}>
          <Text style={styles.title}>{group?.name}</Text>
          <Text style={styles.groupText}>{group?.description}</Text>
        </View>

        <View>
          <Image
            source={
              group?.image
                ? {
                    uri: group?.image,
                  }
                : GROUP_FALLBACK
            }
            style={styles.img}
            resizeMode="cover"
            height={undefined}
            width={undefined}
          />
        </View>

        <Divider />

        <View style={styles.adminContainer}>
          <Text style={styles.adminText}>Group Admins</Text>
          {admins.map(
            (admin: {
              user: { id: number; avatar: string; name: string; surname: string }
              createdAt: string
            }) => (
              <OrfiListItem
                key={admin?.user?.id}
                avatarUri={admin?.user?.avatar}
                avatarPlaceholder={getInitials(admin?.user?.name)}
                title={`${admin?.user?.name} ${admin?.user?.surname}`}
                subtitle={`Member since ${moment(admin.createdAt).format('Do MMM, YYYY')}`}
                divider
                onPress={() => onMemberPress(admin?.user)}
              />
            ),
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

export default DashboardAboutScreen
