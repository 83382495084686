import { StyleSheet, useWindowDimensions } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const useStyle = () => {
  const { height, width } = useWindowDimensions()

  const styles = StyleSheet.create({
    bg: {
      flex: 1,
      width: width,
      height: height - 100,
      backgroundColor: colors.white,
    },
    container: {
      flex: 1,
    },
    headerContainer: {
      flex: 1,
      justifyContent: 'center',
      alignSelf: 'center',
      width: '40%',
      maxHeight: 160,
    },
    header: {
      color: colors.secondary,
      fontSize: 18,

      fontFamily: fonts.default.bold,
      textTransform: 'uppercase',
      textAlign: 'center',
    },
    formContainer: {
      flex: 2,
      justifyContent: 'space-evenly',
      backgroundColor: 'white',
      width: width - 60,
      maxHeight: 220,
      borderRadius: 10,
      alignSelf: 'center',
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.22,
      shadowRadius: 2.22,
      elevation: 3,
      // zIndex: 1000,
      marginBottom: 20,
      alignItems: 'center',
    },
    texts: {
      paddingHorizontal: 10,
      alignItems: 'center',
    },
    icon: {
      marginVertical: 20,
      width: 60,
      height: 60,
      borderWidth: 1,
      borderColor: colors.secondary,
      opacity: 0.8,
      borderRadius: 50,
      justifyContent: 'center',
    },
    percentText: {
      position: 'absolute',
      top: 0,
      fontFamily: fonts.default.bold,
    },
    title: {
      color: colors.secondary,
      fontSize: 12,
      textTransform: 'uppercase',
      fontFamily: fonts.default.bold,
      textAlign: 'center',
    },
    titleExtension: {
      color: colors.primary,
      fontFamily: fonts.default.bold,
    },
    comment: {
      margin: 10,
      color: colors.secondary,
      fontSize: 12,
      textAlign: 'center',
      marginBottom: 15,
      lineHeight: 17,
    },
    orfiIcon: {
      lineHeight: 26,
    },
  })

  return styles
}

export default useStyle
