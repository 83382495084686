import { Button, Divider, Overlay } from '@rneui/themed'
import PropTypes from 'prop-types'
import React from 'react'
import { View } from 'react-native'

import styles from './MembersModal.styles'

const MembersModal = ({ visible = false, toggle, actions = [] }) => (
  <Overlay isVisible={visible} onBackdropPress={toggle} overlayStyle={styles.overlayStyle}>
    <View>
      {actions?.map((act) => (
        <View key={act.label}>
          <Button
            title={act.label}
            titleStyle={{ color: act.labelColor }}
            type="clear"
            onPress={act.action}
          />
          <Divider style={styles.styles} />
        </View>
      ))}
    </View>
  </Overlay>
)

export default MembersModal

MembersModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
}
