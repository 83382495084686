import { StyleSheet } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    flex: 1,
  },
  content: {
    flex: 1,
  },
  icon: {
    opacity: 0.5,
  },
  title: {
    color: colors.black,
    fontSize: 14,
  },
  delButton: {
    marginTop: 30,
    opacity: 0.25,
    width: 120,
    marginLeft: 10,
  },
  listItemContainer: {
    padding: 20,
  },
  sessions: {
    marginLeft: 20,
    marginTop: 20,
    marginBottom: 10,
    fontSize: 12,
    // fontWeight: 'bold',
    fontFamily: fonts.default.bold,
    color: colors.secondary,
  },
  divider: {
    marginLeft: 20,
  },
})

export default styles
