import { Icon } from '@rneui/themed'
import PropTypes from 'prop-types'
import { StyleSheet } from 'react-native'
import RNPickerSelect from 'react-native-picker-select'

import Text from '@src/components/Text'
import { colors, fonts } from '@src/config/theme'

import styles, { pageStyle } from './InputPicker.styles'

const InputPicker = ({ items, value, onValueChange, label, ...props }) => (
  <>
    <Text style={styles.text}>{label}</Text>
    <RNPickerSelect
      items={items}
      value={value}
      onValueChange={onValueChange}
      useNativeAndroidPickerStyle={false}
      Icon={() => <Icon type="material-community" name="chevron-down" />}
      style={
        StyleSheet.flatten[
          {
            ...pageStyle,
            placeholder: {
              fontSize: 12,
              color: colors.secondary,
              fontFamily: fonts.default.bold,
            },
            iconContainer: {
              top: 12,
              right: 8,
            },
          }
        ]
      }
    />
  </>
)

InputPicker.propTypes = {
  items: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
}

export default InputPicker
