import { StyleSheet } from 'react-native'

import { colors, fonts } from '@src/config/theme'
import { scaleVertical } from '@src/utils/scale'

const styles = StyleSheet.create({
  button: {
    marginTop: scaleVertical(10),
    marginBottom: scaleVertical(5),
  },
  forgotText: {
    textDecorationLine: 'underline',
    fontFamily: fonts.default.regular,
  },
  mailIcon: {
    type: 'feather',
    name: 'mail',
    color: colors.grey7,
    size: 17,
  },
  lockIcon: {
    type: 'feather',
    name: 'lock',
    color: colors.grey7,
    size: 17,
  },
  buttonContainer: {
    alignItems: 'flex-end',
  },
})

export default styles
