import { useRoute, useNavigation } from '@react-navigation/native'
import { StackActions } from '@react-navigation/native'
import { Button, Input } from '@rneui/themed'
import React, { useState } from 'react'
import { ImageBackground, SafeAreaView, ScrollView, useWindowDimensions, View } from 'react-native'

import { IMAGE_EMAIL_BACKGROUND } from '@src/assets/images'
import Text from '@src/components/Text'
import { colors } from '@src/config/theme'
import { ConfirmPasswordScreenRouteProps, ConfirmPasswordScreenNavigationProps } from '@src/types.d'

import styles from './ConfirmPasswordScreen.styles'

const ConfirmPasswordScreen = () => {
  const navigation = useNavigation<ConfirmPasswordScreenNavigationProps>()
  const { params } = useRoute<ConfirmPasswordScreenRouteProps>()

  const [code, setCode] = useState('')

  const { width, height } = useWindowDimensions()

  const submit = () => {
    const { email } = params
    navigation.dispatch(StackActions.replace('NewPasswordScreen', { code, email }))
  }

  return (
    <ImageBackground source={IMAGE_EMAIL_BACKGROUND} style={[styles.bg, { width, height }]}>
      <SafeAreaView>
        <ScrollView
          contentContainerStyle={[styles.container, { width, height }]}
          keyboardShouldPersistTaps="handled">
          <View style={[styles.skip, { marginBottom: height / 5 }]}>
            <Button
              title="CANCEL"
              type="clear"
              titleStyle={styles.cancelButton}
              onPress={() => navigation.goBack()}
            />
          </View>
          <View style={styles.wrapper}>
            <View>
              <Text style={styles.mainTitle}>CONFIRM PASSWORD CHANGE</Text>
              <Text style={styles.subTitle}>
                {
                  'We’ve sent you an email with a confirmation code.\nEnter the code below to confirm your email and\nreset your password.'
                }
              </Text>
            </View>
            <View style={styles.inputView}>
              <Input
                placeholder="Enter confirmation code"
                placeholderTextColor={colors.grey7}
                textAlign="center"
                value={code}
                onChangeText={(text) => setCode(text)}
                autoCapitalize="characters"
              />
            </View>
          </View>
          <View style={styles.buttonView}>
            <View style={styles.innerButtonView}>
              <Button title="Submit" onPress={submit} disabled={!code} />
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
    </ImageBackground>
  )
}

export default ConfirmPasswordScreen
