import { FC } from 'react'
import { StyleSheet, Text as RNText } from 'react-native'

import styles from './Text.styles'
import { TextPropsType } from './types.d'

const Text: FC<TextPropsType> = ({ style, children = '', label, link, ...rest }) => {
  const textStyle = StyleSheet.flatten([
    styles.text,
    style,
    label && styles.labelStyle,
    link && styles.linkStyle,
  ])

  return (
    <RNText style={textStyle} {...rest}>
      {children}
    </RNText>
  )
}

export default Text
