import { StyleSheet } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const styles = StyleSheet.create({
  bg: {
    flex: 1,
    backgroundColor: colors.white,
  },
  container: {
    paddingBottom: 100,
  },
  wrapper: {
    flex: 1,
    alignItems: 'center',
  },
  mainTitle: {
    color: colors.primary,
    fontSize: 16,
    fontFamily: fonts.default.bold,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  subTitle: {
    textAlign: 'center',
    color: colors.secondary,
    marginTop: 10,
  },
  inputView: {
    width: '60%',
    alignItems: 'center',
    justifyContent: 'center',
    height: 250,
  },
  btnView: {
    width: '100%',
    alignItems: 'center',
    position: 'absolute',
    bottom: 90,
  },
  skip: {
    width: '100%',
    alignItems: 'flex-end',
    marginBottom: 100,
    paddingRight: 20,
  },
  centre: {
    textAlign: 'center',
  },
  innerButtonView: {
    width: '70%',
  },
  backButtonTitle: {
    color: colors.grey7,
  },
})

export default styles
