import { StyleSheet } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  content: {
    flex: 1,
  },
  scrollVw: {
    paddingTop: 15,
    paddingBottom: 150,
  },
  inputContainer: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    marginBottom: 35,
  },
  label: {
    alignSelf: 'flex-start',
  },
  addBtn: {
    width: '100%',
    alignSelf: 'center',
    position: 'absolute',
    bottom: 30,
    flexDirection: 'row',
  },
  buttonContainerStyle: {
    flex: 1,
    marginHorizontal: 7,
  },
  coverPhotoText: {
    fontFamily: fonts.default.bold,
    fontSize: 12,
    color: colors.secondary,
    paddingBottom: 15,
  },
  coverPhotoContainer: {
    paddingHorizontal: 20,
    marginBottom: 10,
  },
  imageBackground: {
    width: 100,
    height: 100,
    borderRadius: 5,
    overflow: 'hidden',
  },
  descriptionContainer: {
    height: 200,
    borderWidth: 1,
    justifyContent: 'flex-start',
    padding: 8,
    marginTop: 7,
  },
  descriptionStyle: {
    height: '100%',
    textAlignVertical: 'top',
  },
  buttonContainer: {
    justifyContent: 'space-around',
    width: '90%',
    alignSelf: 'center',
    paddingVertical: 6,
    paddingTop: 10,
    paddingBottom: 20,
  },
  bottomButtonContainer: {
    position: 'absolute',
    right: 0,
    left: 0,
    paddingTop: 10,
    paddingBottom: 20,
    bottom: 0,
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  listItemTitle: {
    fontFamily: fonts.default.bold,
    fontSize: 12,
    marginLeft: 8,
    color: colors.secondary,
  },
  mediaText: {
    color: colors.grey6,
    fontFamily: fonts.default.semiBold,
    textTransform: 'uppercase',
  },
  coverPressable: {
    borderWidth: 0.3,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
  },
  listItemStyle: {
    backgroundColor: colors.white,
  },
  modalStyle: {
    padding: 0,
    marginBottom: 200,
  },
  modalContainer: {
    marginBottom: 0,
  },
  removeButton: {
    color: colors.secondary,
  },
  addButton: {
    color: colors.primary,
  },
  divider: {
    backgroundColor: colors.grey,
  },
})

export default styles
