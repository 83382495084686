import { useNavigation, useRoute } from '@react-navigation/native'
import { Button, ListItem } from '@rneui/themed'
import { orderBy } from 'lodash'
import moment from 'moment'
import { useState } from 'react'
import { RefreshControl, ScrollView, View } from 'react-native'

import EmptyNotice from '@src/components/EmptyNotice'
import { showErrorMessage } from '@src/components/FlashMessage'
import OrfiListItem from '@src/components/ListItem/OrfiListItem'
import Loading from '@src/components/Loading'
import Text from '@src/components/Text'
import { useGroupQuery } from '@src/graphql/types'
import {
  SelectSessionEditScreenNavigationProps,
  SelectSessionEditScreenRouteProps,
} from '@src/types'

import styles from './SelectSessionEditScreen.styles'

const SelectSessionEditScreen = () => {
  const navigation = useNavigation<SelectSessionEditScreenNavigationProps>()
  const { params } = useRoute<SelectSessionEditScreenRouteProps>()
  const [selected, setSelected] = useState([])

  const {
    loading,
    data: groupData,
    error,
    refetch,
  } = useGroupQuery({
    variables: {
      groupUuid: params?.groupUuid,
    },
  })

  if (loading) return <Loading />

  if (error) {
    return (
      <EmptyNotice
        title="NOTHING TO SHOW"
        text="Seems like there are no sessions for this group."
      />
    )
  }

  const handleSelect = (session) => {
    if (!selected.length) {
      setSelected([session])
      return
    }
    const first = selected[0]
    if (session.activity[0].name === first.activity[0].name && session.name === first.name) {
      setSelected([...selected, session])
    } else {
      showErrorMessage({
        message: "Can't do that",
        description: 'To bulk edit, the sessions have to match',
        duration: 4000,
      })
    }
  }

  const removeSelect = (session) => {
    const newSelected = selected.filter((s) => s.id !== session.id)
    setSelected(newSelected)
  }

  const { group } = groupData
  const { sessions } = group

  const upcomingSessions = orderBy(
    (sessions || []).filter((i) => moment() <= moment(i.startAt)),
    ['startAt'],
  )

  if (!upcomingSessions.length) {
    return (
      <View style={styles.emptyNoticeContainer}>
        <EmptyNotice
          title="NOTHING TO SHOW"
          text="Seems like there are no upcoming sessions for this group."
        />
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <View style={styles.headerVW}>
        <Text style={styles.headerText}>{'Select sessions you’d like\namending'}</Text>
      </View>
      <ScrollView refreshControl={<RefreshControl refreshing={loading} onRefresh={refetch} />}>
        {upcomingSessions.map((session) => {
          const isSelected = selected.some((s) => session.id === s.id)
          return (
            <View key={session.id}>
              <OrfiListItem
                type="default"
                status="default"
                avatarUri={session.image || session.activity[0]?.defaultBannerImage}
                title={session?.name}
                subtitle={moment(session?.startAt).format('dddd, DD MMM, H:mm')}
                callToAction={
                  <ListItem.CheckBox
                    checked={isSelected}
                    onPress={() => (isSelected ? removeSelect(session) : handleSelect(session))}
                  />
                }
                onPress={() => (isSelected ? removeSelect(session) : handleSelect(session))}
                divider
              />
            </View>
          )
        })}

        <View style={styles.feeInfoView} />
      </ScrollView>
      <View style={styles.bottomView}>
        <Button
          title="Edit Sessions"
          titleStyle={styles.buttonTitle}
          onPress={() =>
            navigation.navigate('EditBulkSessionScreen', {
              sessionIds: selected,
              groupUuid: params?.groupUuid,
            })
          }
          disabled={!selected.length}
          containerStyle={styles.bottomButtonContainer}
        />
      </View>
    </View>
  )
}

export default SelectSessionEditScreen
