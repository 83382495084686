import { StyleSheet } from 'react-native'
import { moderateScale } from 'react-native-size-matters'

import { colors, fonts } from '@src/config/theme'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  sectionTitleText: {
    fontSize: 14,
    textTransform: 'uppercase',
  },
  sectionTitleMainText: {
    color: colors.primary,
  },
  sectionTitleView: {
    backgroundColor: '#FFFFFF',
    margin: 20,
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderColor: colors.greyOutline,
  },
  sectionTitleMainView: {
    borderColor: colors.primary,
  },
  leftItemView: {
    width: 60,
    height: 60,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
  },
  floatBtnView: {
    position: 'absolute',
    bottom: 20,
    right: 10,
  },
  floatBtn: {
    backgroundColor: colors.grey3,
    height: 70,
    width: 70,
    borderRadius: 35,
  },
  leftItemTick: {
    position: 'absolute',
    top: 0,
    right: 3,
    backgroundColor: '#fff',
  },
  buttonContainer: {
    justifyContent: 'center',
    width: '70%',
    alignSelf: 'center',
    height: 70,
  },
  buttonContainerStyle: {
    backgroundColor: 'transparent',
  },
  cancelledWrapper: {
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.5)',
    width: 60,
    height: 60,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 60,
  },
  cancelledText: {
    color: colors.white,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: moderateScale(7.5),
    width: 50,
    fontFamily: fonts.default.bold,
  },
  headerButtonStyle: {
    paddingHorizontal: 5,
  },
  headerContainer: {
    flexDirection: 'row',
  },
})

export default styles
