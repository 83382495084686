import { Platform } from 'react-native'

export const isIos = Platform.OS === 'ios'

export const isAndroid = Platform.OS === 'android'

export const isWeb = Platform.OS === 'web'

export const isWindows = Platform.OS === 'windows'

export const isMac = Platform.OS === 'macos'
