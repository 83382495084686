import { StyleSheet } from 'react-native'

import { colors } from '@src/config/theme'

const styles = StyleSheet.create({
  title: {
    textAlign: 'center',
    fontSize: 22,
    fontWeight: '300',
    marginBottom: 20,
  },
  header: {
    backgroundColor: '#fff',
  },
  hdrListContaiiner: {
    borderBottomWidth: 0,
    paddingBottom: 0,
  },
  headerText: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: '500',
  },
  content: {
    paddingHorizontal: 15,
    paddingTop: 0,
    paddingBottom: 30,
    backgroundColor: '#fff',
    borderColor: '#bcbbc1',
    borderBottomWidth: 0.3,
  },
  contentText: {
    fontSize: 12,
    color: colors.secondary,
  },
  scrollVw: {
    backgroundColor: colors.white,
    paddingBottom: 70,
    minHeight: '100%',
  },
})

export default styles
