import { Icon } from '@rneui/themed'
import { intersectionBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import Text from '@src/components/Text'
import { colors, fonts } from '@src/config/theme'
import { formatAgeRange, formatGender, formatSkillLevel, formatVenueType } from '@src/utils/forms'

const featuresWidth = { 2: '47%', 3: '30%', 4: '22%', 5: '17%' }

const getItems = (session, priceRange) => {
  const startAt = moment(session.startAt)
  let duration = ''
  if (session.endAt) {
    const endAt = moment(session.endAt)
    const durationInMins = Math.abs(Math.floor(moment.duration(endAt.diff(startAt)).asMinutes()))
    const days = Math.floor(durationInMins / 1440)
    const hours =
      days >= 1 ? Math.floor((durationInMins % 1440) / 60) : Math.floor(durationInMins / 60)
    const mins = Math.floor(durationInMins % 60)
    if (days) {
      duration = `${days}d`
    }
    if (hours) {
      duration = `${duration} ${hours} ${mins ? 'hrs' : 'hours'}`
    }
    if (mins) {
      duration = `${duration} ${mins} ${hours ? 'm' : 'mins'}`
    }
  }

  const getGenderIcon = () => {
    if (session.gender === 'MALE') return { type: 'orfi', name: 'mars' }
    if (session.gender === 'FEMALE') return { type: 'orfi', name: 'mars' }
    if (session.gender === 'NEUTRAL') return { type: 'feather', name: 'circle' }
    return { type: 'orfi', name: 'genders' }
  }

  return [
    {
      title: session.activity[0]?.name,
      comment: 'Activity',
      icon: { type: 'orfi', name: session.activity[0]?.icon },
    },
    {
      title: priceRange,
      comment: 'Price',
      icon: {
        type: 'orfi',
        name: 'price',
        size: 21,
        iconStyle: { lineHeight: 21 },
      },
    },
    {
      title: startAt.format('HH:mm'),
      comment: 'Starts',
      icon: { type: 'orfi', name: 'whistle' },
    },
    {
      title: duration || '-',
      comment: 'Duration',
      icon: { type: 'feather', name: 'clock' },
    },
    {
      title: formatAgeRange(session.minAge, session.maxAge),
      comment: 'Age',
      icon: { type: 'orfi', name: 'age' },
    },
    {
      title: formatSkillLevel(session.skillLevel),
      comment: 'Skill Level',
      icon: { type: 'feather', name: 'zap' },
    },
    {
      title: formatGender(session.gender),
      comment: 'Gender',
      icon: getGenderIcon(),
    },
    {
      title: formatVenueType(session.venueType),
      comment: 'Venue Type',
      icon: { type: 'feather', name: session.venueType === 'INDOOR' ? 'home' : 'sun' },
    },
  ]
}

const FeaturesForm = ({ session, priceRange, features, numOfCols, onPressItems }) => {
  let items = getItems(session, priceRange)
  if (features?.length) {
    const list = features.map((i) => ({ comment: i || '' }))
    items = intersectionBy(items, list, 'comment')
  }

  return (
    <View style={styles.container}>
      {items.map((item) => (
        <FeaturesItem
          key={item.comment}
          item={item}
          numOfCols={Math.min(numOfCols, 5)}
          onPressItems={onPressItems}
        />
      ))}
    </View>
  )
}

export const FeaturesItem = ({ item, numOfCols, onPressItems }) => (
  <TouchableOpacity
    disabled
    style={[styles.item, { width: featuresWidth[numOfCols] }]}
    onPress={() => {
      const onPress = onPressItems[item?.comment]
      if (onPress !== undefined) {
        onPress()
      }
    }}>
    <View style={styles.iconContainer}>
      <Icon size={24} iconStyle={styles.iconStyle} {...item.icon} color={colors.primary} />
    </View>
    <Text style={styles.comment}>{item.comment}</Text>
    <View style={styles.titleText}>
      <Text style={styles.title}>{item.title}</Text>
    </View>
    {item.subTitle && <Text style={styles.subTitle}>{item.subTitle}</Text>}
  </TouchableOpacity>
)

const styles = StyleSheet.create({
  container: {
    paddingVertical: 15,
    paddingHorizontal: 15,
    marginTop: 20,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
  },
  item: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '22%',
    marginBottom: 20,
    textAlign: 'center',
  },
  title: {
    color: colors.secondary,
    fontSize: 12,
    fontFamily: fonts.default.bold,
    marginBottom: 5,
    textAlign: 'center',
  },
  comment: {
    color: colors.grey7,
    fontSize: 10,
    marginTop: 10,
  },
  subTitle: {
    color: colors.secondary,
    fontSize: 10,
    fontFamily: fonts.default.bold,
    marginBottom: 5,
  },
  iconContainer: {
    height: 24,
  },
  iconStyle: {
    lineHeight: 24,
  },
  titleText: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },
})

FeaturesForm.propTypes = {
  onPressItems: PropTypes.object,
  session: PropTypes.object.isRequired,
  priceRange: PropTypes.string.isRequired,
  features: PropTypes.array,
  numOfCols: PropTypes.number,
}

FeaturesItem.propTypes = {
  onPressItems: PropTypes.object,
  item: PropTypes.object.isRequired,
  numOfCols: PropTypes.number,
}

FeaturesForm.defaultProps = {
  onPressItems: {},
  features: [],
  numOfCols: 4,
}

FeaturesItem.defaultProps = {
  onPressItems: {},
  numOfCols: 4,
}

export default FeaturesForm
