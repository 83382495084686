import { StyleSheet, useWindowDimensions } from 'react-native'

import { colors, fonts } from '@src/config/theme'

const useStyle = () => {
  const { width, height } = useWindowDimensions()

  const styles = StyleSheet.create({
    scrollVW: { flex: 1, backgroundColor: colors.white },
    row: {
      flexDirection: 'row',
    },
    column: {
      flex: 1,
      flexDirection: 'column',
    },
    inputTouchableOpacity: {
      width: '100%',
    },
    rightIcon: {
      marginRight: 0,
      padding: 0,
    },
    title: {
      paddingHorizontal: 10,
      color: colors.secondary,
      fontSize: 12,
      fontFamily: fonts.default.bold,
      marginTop: 12,
    },
    buttonContainer: {
      justifyContent: 'space-around',
      width: '100%',
      alignSelf: 'center',
      padding: 20,
      backgroundColor: colors.white,
    },
    buttonContainerStyle: {
      flex: 1,
      marginHorizontal: 4,
    },
    imageBackground: {
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      height: 200,
    },
    imageBackgroundIcon: {
      color: '#FFFFFF',
    },
    imageBackgroundIconStyling: {
      paddingTop: 16,
    },
    imageBackgroundText: {
      color: '#FFFFFF',
      textTransform: 'uppercase',
    },
    inputContainerStyle: {
      height: 200,
      borderWidth: 1,
      justifyContent: 'flex-start',
      padding: 8,
      marginTop: 24,
    },
    inputStyle: {
      alignSelf: 'flex-start',
      textAlignVertical: 'top',
      height: '100%',
    },
    inputLabels: {
      fontFamily: fonts.default.bold,
      fontSize: 12,
    },
    paymentText: {
      fontFamily: fonts.default.bold,
      fontSize: 12,
      color: colors.secondary,
      paddingHorizontal: 10,
    },
    keyboardDatePickerContainer: {
      width: width * 0.95,
      maxWidth: 700,
      justifyContent: 'center',
      alignItems: 'center',
    },
    keyboardDatePickerStyle: {
      margin: 50,
      maxWidth: 500,
    },
    inputContainer: {
      paddingHorizontal: 10,
    },
    locationIconContainer: {
      marginLeft: 0,
    },
    paymentContainer: {
      paddingTop: 10,
      marginBottom: 10,
    },
    paymentInnerContainer: {
      flexDirection: 'row',
      paddingVertical: 20,
      justifyContent: 'space-around',
    },
    paymentButton: {
      borderRadius: 5,
      height: 40,
      paddingHorizontal: 15,
      backgroundColor: colors.primary,
      borderWidth: 1,
      borderColor: colors.primary,
    },
    overlayStyle: {
      padding: 0,
      marginBottom: 200,
    },
    overlayContainer: {
      marginBottom: 0,
    },
    locationPressable: {
      flex: 1,
    },
    removeButton: {
      color: colors.secondary,
    },
    addButton: {
      color: colors.primary,
    },
    divider: {
      backgroundColor: colors.grey,
    },
    sessionEndInput: {
      marginTop: 20,
    },
  })

  return styles
}

export default useStyle
