import { StyleSheet } from 'react-native'

import { fonts, colors } from '@src/config/theme'

const styles = StyleSheet.create({
  viewContainer: {
    flex: 1,
  },
  outerInputContainer: {
    height: '15%',
  },
  inputContainer: {
    borderBottomWidth: 0,
    paddingVertical: 16,
    paddingHorizontal: 10,
    backgroundColor: colors.white,
    borderRadius: 16,
  },
  inputStyle: {
    height: '100%',
    textAlignVertical: 'top',
  },
  commentHeader: {
    fontSize: 16,
    paddingBottom: 8,
    paddingLeft: 8,
    fontFamily: fonts.default.bold,
  },
})

export default styles
