import { NavigationContainer } from '@react-navigation/native'

import LinkingConfiguration from './LinkingConfiguration'
import RootStack from './RootStack'

function App() {
  return (
    <NavigationContainer linking={LinkingConfiguration}>
      <RootStack />
    </NavigationContainer>
  )
}

export default App
