import MomentUtils from '@date-io/moment'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { withNavigation } from '@react-navigation/compat'
import { Button, CheckBox } from '@rneui/themed'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { ScrollView, View } from 'react-native'

import useStyle from './styles'

import {
  recurringType,
  recurringTypeOnce,
  recurringTypeDaily,
  recurringTypeWeekly,
  recurringTypeMonthly,
  daysOfTheWeek,
} from '../../../config/forms'
import MultiSelectField from '../../MultiSelectField'
import Text from '../../Text'

const StepThreeForm = ({
  values,
  touched,
  errors,
  handleChange,
  setFieldTouched,
  setFieldValue,
}) => {
  const [isSessionStartDatePickerVisible, setIsSessionStartDatePickerVisible] = useState(false)
  const [isSessionEndDatePickerVisible, setIsSessionEndDatePickerVisible] = useState(false)
  const [isRecurringEndDatePickerVisible, setIsRecurringEndDatePickerVisible] = useState(false)
  const styles = useStyle()

  const handleSessionStartDatePicker = (date: string) => {
    toggleSessionStartDatePicker()
    setTimeout(() => setFieldValue('sessionStartDate', date), 150)
  }

  const toggleSessionStartDatePicker = () => {
    setIsSessionStartDatePickerVisible(!isSessionStartDatePickerVisible)
  }

  const handleSessionEndDatePicker = (date: string) => {
    toggleSessionEndDatePicker()
    setTimeout(() => setFieldValue('sessionEndDate', date), 150)
  }

  const toggleSessionEndDatePicker = () => {
    setIsSessionEndDatePickerVisible(!isSessionEndDatePickerVisible)
  }

  const toggleRecurringEndDatePicker = () => {
    setIsRecurringEndDatePickerVisible(!isRecurringEndDatePickerVisible)
  }

  const handleRecurringEndDatePicker = (date: string) => {
    toggleRecurringEndDatePicker()
    setTimeout(() => setFieldValue('recurringEndDate', date), 150)
  }

  const toggleFieldValue = (field, value) => {
    const fieldValues = values[field] || []

    let newFieldValues = []
    if (fieldValues.includes(value)) {
      newFieldValues = fieldValues.filter((el) => el !== value)
    } else {
      newFieldValues = [...fieldValues, value]
    }

    if (newFieldValues.length === 7) {
      setFieldValue('recurringType', recurringTypeDaily)
    } else {
      setFieldValue('recurringType', recurringTypeWeekly)
    }

    setFieldTouched('recurringType')

    setFieldTouched(field)
    setFieldValue(field, newFieldValues)
  }

  const setRecurringType = (value) => {
    const { sessionStartDate } = values

    // Reset days of the week
    if (value === recurringTypeDaily) {
      setFieldValue('recurringDaysOfTheWeek', [0, 1, 2, 3, 4, 5, 6])
    }

    if (value === recurringTypeWeekly) {
      const dayOfWeek = moment(sessionStartDate).weekday()
      setFieldValue('recurringDaysOfTheWeek', [dayOfWeek ? dayOfWeek - 1 : 6])
    }

    setFieldValue('recurringType', value)
  }

  const getMonthlyValues = () => {
    if (!values.sessionStartDate) {
      return []
    }

    const date = moment(values.sessionStartDate)
    const dayStr = date.format('Do')

    return [{ label: `${dayStr} of each month`, value: '' }]
  }

  const monthlyValues = getMonthlyValues()
  const minimumDate = new Date(values.sessionStartDate) || new Date()
  const now = new Date()

  return (
    <ScrollView>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <>
          <View style={styles.keyboardDatePickerContainer}>
            <DateTimePicker
              value={values.sessionStartDate}
              disablePast
              onAccept={handleSessionStartDatePicker}
              onChange={() => handleChange('sessionStartDate')}
              label="First session starts"
              onError={console.log}
              //aria-errormessage={
              //  //touched.sessionStartDate && errors.sessionStartDate ? errors.sessionStartDate : ''
              //}
              format="dddd, DD MMM, H:mm"
              style={styles.keyboardDatePickerStyle}
            />
            <DateTimePicker
              value={values.sessionEndDate || values.sessionStartDate}
              disablePast
              onAccept={handleSessionEndDatePicker}
              onChange={() => handleChange('sessionEndDate')}
              label="First session ends"
              onError={console.log}
              minDate={minimumDate}
              aria-errormessage={
                touched.sessionEndDate && errors.sessionEndDate ? errors.sessionEndDate : ''
              }
              format="dddd, DD MMM, H:mm"
              style={styles.keyboardDatePickerStyle}
            />
          </View>

          {values.sessionStartDate && values.sessionEndDate && (
            <React.Fragment>
              <MultiSelectField
                label="My session will occur"
                data={recurringType}
                value={values.recurringType}
                onPress={setRecurringType}
              />

              {(values.recurringType === recurringTypeDaily ||
                values.recurringType === recurringTypeWeekly) && (
                <View>
                  <MultiSelectField
                    data={daysOfTheWeek}
                    values={values.recurringDaysOfTheWeek}
                    onPress={(value) => toggleFieldValue('recurringDaysOfTheWeek', value)}
                    customStyle={styles.multiSelectField}
                  />
                  {values.recurringType === recurringTypeWeekly && (
                    <CheckBox
                      title="Every other week?"
                      textStyle={styles.checkboxTextStyle}
                      containerStyle={styles.checkboxContainerStyle}
                      iconRight
                      iconType="material-community"
                      checkedIcon="check-circle"
                      uncheckedIcon="checkbox-blank-circle-outline"
                      checked={values.recurringEveryOtherWeek}
                      checkedColor="#03D20A"
                      size={32}
                      onPress={() =>
                        setFieldValue('recurringEveryOtherWeek', !values.recurringEveryOtherWeek)
                      }
                    />
                  )}
                </View>
              )}

              {values.recurringType === recurringTypeMonthly && (
                <MultiSelectField data={monthlyValues} value="" onPress={() => {}} />
              )}

              {values.recurringType !== recurringTypeOnce && (
                <View>
                  <Text style={styles.textHeader}>REOCCURRENCE ENDS</Text>

                  <DateTimePicker
                    autoOk
                    label="Select..."
                    disablePast
                    value={
                      values.recurringEndDate
                        ? new Date(values.recurringEndDate)
                        : new Date(values.sessionStartDate)
                    }
                    onChange={handleRecurringEndDatePicker}
                    onError={console.log}
                    minDate={new Date(values.sessionStartDate)}
                    aria-errormessage="Invalid date"
                    style={styles.keyboardDatePickerStyle}
                  />
                  {values.recurringEndDate ? (
                    <View style={styles.resetRecurringView}>
                      <Button
                        title="REMOVE"
                        titleStyle={styles.buttonText}
                        type="clear"
                        onPress={() => setFieldValue('recurringEndDate', undefined)}
                        buttonStyle={styles.resetRecurringBtn}
                      />
                    </View>
                  ) : null}
                  <View style={styles.noteView}>
                    <Text style={styles.note}>
                      Please note that a maximum of 12 recurring sessions will be created.
                    </Text>
                  </View>
                </View>
              )}
            </React.Fragment>
          )}
        </>
      </MuiPickersUtilsProvider>
    </ScrollView>
  )
}

StepThreeForm.propTypes = {
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
}

StepThreeForm.defaultProps = {
  isSubmitting: false,
}

export default withNavigation(StepThreeForm)
